import {
  ActionIcon,
  Card,
  CloseButton,
  createStyles,
  Group,
  Modal,
  Popover,
  Stack,
  Table,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import React, { useContext, useState } from "react";
import {
  ByggleadsContext,
  ByggleadsProps,
} from "../../providers/ByggleadsProvider";
import { ByggLeadTemplate } from "../../utils/Types";
import { getFirestore, setDoc, doc, serverTimestamp } from "firebase/firestore";
import { isPermitted, shortenString } from "../../utils/Functions";
import { IconCopy, IconDuplicate, IconEdit } from "../../components/Icons";
import { v4 as uuid } from "uuid";
import { ErrorCodes } from "../../utils/ErrorCodes";
import EmptyScreen from "../../components/EmptyScreen";
import { useClipboard } from "@mantine/hooks";
import { compileLeadTemplateFields } from "./LeadTemplateCreate";
import LeadTemplateEdit from "./LeadTemplateEdit";
import { showNotification } from "@mantine/notifications";

const useStyles = createStyles((theme, _params, getRef) => ({
  valueRow: {
    "&:nth-of-type(even)": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: theme.spacing.sm,

    "&.noPadding": {
      padding: 0,
    },
  },
}));

const LeadTemplateCollection = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  const { classes } = useStyles();
  const { templates, workspace, user } = useContext(ByggleadsContext);

  const [selectedTemplate, setSelectedTemplate] =
    useState<ByggLeadTemplate | null>(null);

  // useEffect(() => {
  //   const listener = onSnapshot(
  //     query(
  //       collectionGroup(getFirestore(), "leads"),
  //       where("status", "!=", StatusArchivedRef),
  //     ),
  //     snapshot => {

  //     },
  //     err => {
  //       console.error(err)
  //     }
  //   )
  // }, [])

  return (
    <>
      <Card>
        <Card.Section className={classes.section}>
          <Group position="apart" noWrap>
            <Title order={4}>Arbetsplatsens leadmallar</Title>
            <CloseButton onClick={handleClose} />
          </Group>
        </Card.Section>
        <Card.Section>
          {templates.length ? (
            <Table>
              <thead>
                <tr>
                  <th>Namn</th>
                  <th>Värden</th>
                  <th>Åtgärder</th>
                </tr>
              </thead>
              <tbody>
                {templates.map((template) => {
                  return (
                    <TemplateRow
                      template={template}
                      setSelectedTemplate={setSelectedTemplate}
                      key={template.id}
                      workspace={workspace}
                      user={user}
                    />
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <EmptyScreen />
          )}
        </Card.Section>
      </Card>
      <Modal
        opened={selectedTemplate !== null}
        onClose={() => setSelectedTemplate(null)}
        size="xl"
        withCloseButton={false}
        padding={0}
        id="TEMPLATE_EDIT_MODAL"
      >
        <LeadTemplateEdit
          template={selectedTemplate}
          handleClose={() => setSelectedTemplate(null)}
        />
      </Modal>
    </>
  );
};

export default LeadTemplateCollection;

const TemplateRow = ({
  template,
  setSelectedTemplate,
  workspace,
  user,
}: {
  template: ByggLeadTemplate;
  setSelectedTemplate: React.Dispatch<
    React.SetStateAction<ByggLeadTemplate | null>
  >;
  workspace: ByggleadsProps["workspace"];
  user: ByggleadsProps["user"];
}) => {
  const { role } = useContext(ByggleadsContext);
  const [opened, setOpened] = useState(false);
  const clipboard = useClipboard();
  const [copyLabel, setCopyLabel] = useState("Kopiera Zapier-kod");

  const duplicateTemplate = () => {
    if (!workspace?.ref || !user?.ref) {
      showNotification({
        message: ErrorCodes.standard.message,
        color: "red",
      });
      return;
    }
    const id = uuid();
    const params: Partial<ByggLeadTemplate> = {
      title: template.title + " kopia",
      fields: template.fields,
      id: id,
      workspace: workspace.ref.id,
      createdBy: user.ref,
      createdAt: serverTimestamp(),
    };
    setDoc(
      doc(getFirestore(), workspace.ref.path, "leadTemplates", id),
      params
    );
  };

  return (
    <tr>
      <td>
        <Tooltip
          label={template.title}
          transition={"pop"}
          transitionDuration={80}
          withArrow
          position="top"
        >
          <Text size="sm" weight={500}>
            {shortenString(template.title)}
          </Text>
        </Tooltip>
      </td>
      <td>
        <Popover
          opened={opened}
          onClose={() => setOpened(false)}
          position="bottom"
          withinPortal
          transition="pop"
          transitionDuration={80}
        >
          <Popover.Target>
            <Group align={"center"}>
              <Text
                size="sm"
                weight={500}
                color="dimmed"
                lineClamp={1}
                onClick={() => setOpened((p) => !p)}
                style={{ cursor: "pointer" }}
              >
                {shortenString(
                  template.fields.map((field) => field.title).join(", "),
                  32
                )}
              </Text>
            </Group>
          </Popover.Target>
          <Popover.Dropdown p={0}>
            {<TemplateFields template={template} />}
          </Popover.Dropdown>
        </Popover>
      </td>
      <td>
        <Group spacing={0}>
          <Tooltip
            label={"Redigera mall"}
            transition={"pop"}
            transitionDuration={80}
            withArrow
            position="top"
          >
            <ActionIcon onClick={() => setSelectedTemplate(template)}>
              <IconEdit side={18} />
            </ActionIcon>
          </Tooltip>
          {isPermitted(["superadmin"], role) && (
            <Tooltip
              label={copyLabel}
              transition={"pop"}
              transitionDuration={80}
              withArrow
              position="top"
              color={copyLabel === "Kopiera Zapier-kod" ? undefined : "teal"}
            >
              <ActionIcon
                onClick={() => {
                  clipboard.copy(
                    compileLeadTemplateFields(template.fields, template.id)
                  );
                  setCopyLabel("Kopierat till urklipp");
                }}
                onMouseLeave={() => {
                  setTimeout(() => {
                    setCopyLabel("Kopiera Zapier-kod");
                  }, 80);
                }}
              >
                <IconCopy side={18} />
              </ActionIcon>
            </Tooltip>
          )}
          <Tooltip
            label={"Duplicera mall"}
            transition={"pop"}
            transitionDuration={80}
            withArrow
            position="top"
          >
            <ActionIcon onClick={duplicateTemplate}>
              <IconDuplicate side={18} />
            </ActionIcon>
          </Tooltip>
        </Group>
      </td>
    </tr>
  );
};

const TemplateFields = ({ template }: { template: ByggLeadTemplate }) => {
  const { classes } = useStyles();
  return (
    <Stack spacing={0}>
      {template.fields.map((field) => {
        return (
          <Group
            position="apart"
            spacing={48}
            className={classes.valueRow}
            py={6}
            px="md"
          >
            <Text size="sm">{field.title}</Text>
            <Text size="xs" color={"dimmed"}>
              {field.type}
            </Text>
          </Group>
        );
      })}
    </Stack>
  );
};
