import {
  Button,
  createStyles,
  Group,
  Paper,
  Stack,
  Switch,
  Text,
  ThemeIcon,
} from '@mantine/core';
import { useContext, useState } from 'react';
import { ByggleadsContext } from '../../providers/ByggleadsProvider';
import { IconEnvelope, IconNotificationsFilled } from '../Icons';
import { updateDoc } from 'firebase/firestore';

import Mailbox from '../../assets/illustrations/mailbox.svg';
import { ByggUser, NotificationConfig } from '../../utils/Types';
import { ErrorCodes } from '../../utils/ErrorCodes';
import { showNotification } from '@mantine/notifications';

const useStyles = createStyles((theme, _params, getRef) => ({
  popupImage: {
    width: '100%',
    height: 384,
    maxHeight: '35vh',
    objectFit: 'contain',
    backgroundColor: theme.colors[theme.primaryColor][0],
    borderTopLeftRadius: theme.radius.sm,
    borderTopRightRadius: theme.radius.sm,
    padding: theme.spacing.sm,
  },
  fullWidth: {
    width: '100%',
  },
  userButton: {
    pointerEvents: 'none',
  },
  footer: {
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: theme.spacing.md,
  },
}));

const AllowNotificationsModal = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  const { classes } = useStyles();
  const { user } = useContext(ByggleadsContext);
  const [email, setEmail] = useState(false);
  const [push, setPush] = useState(false);

  const [loading, setLoading] = useState(false);
  const submit = async () => {
    if (loading || !user?.ref) return;
    setLoading(true);

    const params: Partial<ByggUser> = {
      notifications: {
        email: email
          ? Object.keys(NotificationConfig).map((e) => e as NotificationConfig)
          : [],
        push: push
          ? Object.keys(NotificationConfig).map((e) => e as NotificationConfig)
          : [],
      },
    };

    await updateDoc(user.ref, params)
      .then(() => {
        handleClose();
      })
      .catch((err) => {
        console.error(err);
        showNotification({
          message: ErrorCodes.standard.message,
          color: 'red',
        });
      });

    setLoading(false);
  };
  return (
    <>
      <img src={Mailbox} className={classes.popupImage} alt="" />
      <Stack px="md" mt={'lg'} spacing={'xs'} pb={'xl'} align={'flex-start'}>
        <Text size="lg" weight={500}>
          Vill du ta emot notiser från oss?
        </Text>
        <Text size="sm">
          Vi vill gärna skicka aviseringar till dig när något nytt händer på
          plattformen, till exempel när ett nytt lead trillar in.
        </Text>
        <Paper
          withBorder
          style={{
            width: '100%',
          }}
        >
          <Group noWrap p="xs" position="apart">
            <Group noWrap>
              <ThemeIcon variant="light">
                <IconEnvelope side={14} />
              </ThemeIcon>
              <Text weight={500}>E-postnotiser</Text>
            </Group>
            <Switch
              checked={email}
              onChange={(e) => setEmail(e.currentTarget.checked)}
            />
          </Group>
        </Paper>
        <Paper
          withBorder
          style={{
            width: '100%',
          }}
        >
          <Group noWrap p="xs" position="apart">
            <Group noWrap>
              <ThemeIcon variant="light">
                <IconNotificationsFilled side={14} />
              </ThemeIcon>
              <Text weight={500}>Pushnotiser</Text>
            </Group>
            <Switch
              checked={push}
              onChange={(e) => setPush(e.currentTarget.checked)}
            />
          </Group>
        </Paper>
        <Text size="xs" color="dimmed">
          Du kan alltid ändra dessa inställningar i dina användarinställningar.
        </Text>
      </Stack>
      <Group position="right" className={classes.footer}>
        <Button onClick={submit} loading={loading}>
          Klar
        </Button>
      </Group>
    </>
  );
};

export default AllowNotificationsModal;
