import {
  Card,
  Group,
  Badge,
  Text,
  createStyles,
  SimpleGrid,
  Paper,
  Skeleton,
  HoverCard,
  Divider,
} from "@mantine/core";
import { isEmpty } from "lodash";
import { IconArrowDownRight, IconArrowUpRight } from "../../components/Icons";
import {
  InsightsActionDictionary,
  InsightsFieldDictionary,
} from "../../utils/Insights";
import { ByggInsights } from "../../utils/Types";

const useStyles = createStyles((theme, _params, getRef) => ({
  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: theme.spacing.sm,
  },

  value: {
    lineHeight: 1,
  },

  diff: {
    lineHeight: 1,
    display: "flex",
    alignItems: "center",

    span: {
      marginRight: 4,
    },
  },

  icon: {
    width: 24,
    height: 24,
    objectFit: "contain",
  },

  insightsCard: {
    overflow: "hidden",
    transition: "box-shadow 0.15s 0.15s ease",

    img: {
      transition: "all 0.4s ease, transform 0.4s 0.15s ease",
      transformOrigin: "40% 70%",
      maskImage:
        "radial-gradient(farthest-corner at top right, black 50%, transparent)",
      maskSize: "200% 200%",
      maskPosition: "top right",
    },

    "&:hover": {
      boxShadow: theme.shadows.sm,
      transition: "box-shadow 0.15s ease",

      img: {
        transition: "all 0.4s ease, transform 0.4s ease",
        transform: "scale(5)",
        maskPosition: "bottom left",
      },
    },
  },
}));

const CampaignRow = ({ data }: { data?: ByggInsights }) => {
  const { classes } = useStyles();

  if (!data) {
    return (
      <Card withBorder radius="sm" p="md">
        <Card.Section className={classes.section}>
          <Group position="apart">
            <Group align="center" spacing="sm">
              <Skeleton height={24} width={256} />
            </Group>
            <Skeleton height={20} width={64} radius={"xl"} />
          </Group>
        </Card.Section>
        <Card.Section>
          <SimpleGrid
            cols={1}
            breakpoints={[
              { minWidth: "xs", cols: 2 },
              { minWidth: "sm", cols: 2 },
              { minWidth: "md", cols: 3 },
              { minWidth: "xl", cols: 4 },
            ]}
            p="sm"
          >
            {["1", "2", "3", "4"].map((key) => {
              return (
                <Paper withBorder p="md" key={key}>
                  <Group position="apart">
                    <Skeleton height={12} width={128} />
                    <Skeleton height={22} width={22} circle />
                  </Group>
                  <Group align="flex-end" spacing="xs" mt={25}>
                    <Skeleton height={24} width={36} />
                    <Skeleton height={14} width={36} />
                  </Group>
                  <Skeleton height={10} width={192} mt={12} />
                </Paper>
              );
            })}
          </SimpleGrid>
        </Card.Section>
      </Card>
    );
  }

  return (
    <Card withBorder radius="sm" p="md">
      <Card.Section className={classes.section}>
        <Group position="apart">
          <Group align="center" spacing="sm">
            <Text size="xl" weight={500}>
              {data.campaign_name}
            </Text>
          </Group>
          <Badge color="gray">{data.account_name}</Badge>
        </Group>
      </Card.Section>
      <Card.Section>
        {!isEmpty(data.fields) && (
          <>
            <Divider label="Mätvärden" m={"sm"} mb={0} labelPosition="center" />
            <SimpleGrid
              cols={1}
              breakpoints={[
                { minWidth: "xs", cols: 2 },
                { minWidth: "sm", cols: 2 },
                { minWidth: "md", cols: 3 },
                { minWidth: "xl", cols: 4 },
              ]}
              p="sm"
            >
              {Object.keys(data.fields).flatMap((key) => {
                const row = data.fields[key];
                const dictionary = InsightsFieldDictionary[key];
                if (!row || !dictionary) return [];

                const diff = getDiff(row.curr, row.comp);

                return (
                  <HoverCard
                    key={key}
                    width="target"
                    shadow="sm"
                    transition={"pop"}
                    withArrow
                  >
                    <HoverCard.Target>
                      <Paper withBorder p="md" className={classes.insightsCard}>
                        <Group position="apart" noWrap>
                          <Group spacing={8} noWrap>
                            <Text
                              size="xs"
                              color="dimmed"
                              weight={700}
                              transform="uppercase"
                              lineClamp={1}
                            >
                              {dictionary.title}
                            </Text>
                          </Group>
                          {dictionary.icon && (
                            <img
                              className={classes.icon}
                              src={dictionary.icon}
                              alt={dictionary.title + " icon"}
                            />
                          )}
                        </Group>
                        <Group align="flex-end" spacing="xs" mt={25}>
                          <Text
                            size="xl"
                            weight={700}
                            className={classes.value}
                          >
                            {roundValue(row.curr)} {dictionary.suffix}
                          </Text>
                          {diff && (
                            <Text
                              color={diff > 0 ? dictionary.invertedColors ? "red" : "teal" : dictionary.invertedColors ? "teal" : "red"}
                              size="sm"
                              weight={500}
                              className={classes.diff}
                            >
                              <span>{diff}%</span>
                              {diff > 0 ? (
                                <IconArrowUpRight side={14} />
                              ) : (
                                <IconArrowDownRight side={14} />
                              )}
                            </Text>
                          )}
                        </Group>
                        {diff ? (
                          <Text size="xs" color="dimmed" mt={7}>
                            Jämfört med föregående period (
                            {roundValue(row.comp)} {dictionary.suffix})
                          </Text>
                        ) : undefined}
                      </Paper>
                    </HoverCard.Target>
                    <HoverCard.Dropdown>
                      <Text size="sm">{dictionary.description}</Text>
                    </HoverCard.Dropdown>
                  </HoverCard>
                );
              })}
            </SimpleGrid>
          </>
        )}
        {!isEmpty(data.actions) && (
          <>
            <Divider label="Händelser" m={"sm"} mb={0} labelPosition="center" />
            <SimpleGrid
              cols={1}
              breakpoints={[
                { minWidth: "xs", cols: 2 },
                { minWidth: "sm", cols: 2 },
                { minWidth: "md", cols: 3 },
                { minWidth: "xl", cols: 4 },
              ]}
              p="sm"
            >
              {Object.keys(data.actions).flatMap((key) => {
                const row = data.actions[key];
                const dictionary = InsightsActionDictionary[key];
                if (!row || !dictionary) return [];

                const diff = getDiff(row.curr, row.comp);

                return (
                  <HoverCard
                    key={key}
                    width="target"
                    shadow="sm"
                    transition={"pop"}
                    withArrow
                  >
                    <HoverCard.Target>
                      <Paper withBorder p="md" className={classes.insightsCard}>
                        <Group position="apart" noWrap>
                          <Group spacing={8} noWrap>
                            <Text
                              size="xs"
                              color="dimmed"
                              weight={700}
                              transform="uppercase"
                              lineClamp={1}
                            >
                              {dictionary.title}
                            </Text>
                          </Group>
                          {dictionary.icon && (
                            <img
                              className={classes.icon}
                              src={dictionary.icon}
                              alt={dictionary.title + " icon"}
                            />
                          )}
                        </Group>
                        <Group align="flex-end" spacing="xs" mt={25}>
                          <Text
                            size="xl"
                            weight={700}
                            className={classes.value}
                          >
                            {roundValue(row.curr)} {dictionary.suffix}
                          </Text>
                          {diff && (
                            <Text
                              color={diff > 0 ? "teal" : "red"}
                              size="sm"
                              weight={500}
                              className={classes.diff}
                            >
                              <span>{diff}%</span>
                              {diff > 0 ? (
                                <IconArrowUpRight side={14} />
                              ) : (
                                <IconArrowDownRight side={14} />
                              )}
                            </Text>
                          )}
                        </Group>
                        {diff ? (
                          <Text size="xs" color="dimmed" mt={7}>
                            Jämfört med föregående period (
                            {roundValue(row.comp)} {dictionary.suffix})
                          </Text>
                        ) : undefined}
                      </Paper>
                    </HoverCard.Target>
                    <HoverCard.Dropdown>
                      <Text size="sm">{dictionary.description}</Text>
                    </HoverCard.Dropdown>
                  </HoverCard>
                );
              })}
            </SimpleGrid>
          </>
        )}
      </Card.Section>
    </Card>
  );
};

export default CampaignRow;

const getDiff = (curr?: string, comp?: string) => {
  if (!curr || !comp) return undefined;
  const currValue = parseFloat(curr);
  const prevValue = parseFloat(comp);

  if (currValue === 0) {
    if (prevValue === 0) {
      return undefined;
    } else {
      return -100;
    }
  }
  if (prevValue === 0) {
    return Infinity;
  }

  const diff = currValue / prevValue - 1;

  return Math.round(diff * 100);
};

const roundValue = (value?: string) => {
  if (!value) return value;
  return Math.round(parseFloat(value)).toLocaleString();
};
