import {
  Divider,
  Group,
  Paper,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
} from '@mantine/core';
import React, { useContext, useEffect, useState } from 'react';
import { ByggleadsContext } from '../../providers/ByggleadsProvider';
import { ByggLead, ByggLeadTemplate } from '../../utils/Types';
import { IconPlatformFB, IconPlatformIG } from '../Icons';

const AdMeta = ({ lead }: { lead?: ByggLead }) => {
  return (
    <>
      <Divider label={lead ? "Övrig info" : undefined} labelPosition="center" mt={'md'} />
      <SimpleGrid p={'xs'} cols={3} spacing="xs">
        {lead ? (
          <>
            {lead.platform && <Platform lead={lead} />}
            {lead.template && <Template lead={lead} />}
          </>
        ) : (
          ['1', '2'].map((key) => {
            return <Skeleton key={key} height={70} width={'100%'} />;
          })
        )}
      </SimpleGrid>
    </>
  );
};

export default AdMeta;

const Platform = ({ lead }: { lead: ByggLead }) => {
  const instagramGradient =
    'radial-gradient(ellipse at 10% 12%, #4168c9e1 0%, #4168c900 60%), radial-gradient(ellipse at 42% 90%, #ffc455 3%, #ff543f 40%, #fc5245 44%, #e64771 63%, #d53e91 70%, #cc39a4 84%, #c837ab 100%)';
  const facebookGradient = 'linear-gradient(135deg, #2aa4f4, #007ad9)';

  const getPlatformBranding = (): {
    title: string;
    bg?: string;
    icon?: React.ReactNode;
    color?: string;
  } => {
    switch (lead.platform) {
      case 'ig':
        return {
          title: 'Instagram',
          bg: instagramGradient,
          icon: <IconPlatformIG color="#fff" side={20} />,
          color: '#fff',
        };
      case 'fb':
        return {
          title: 'Facebook',
          bg: facebookGradient,
          icon: <IconPlatformFB color="#fff" side={20} />,
          color: '#fff',
        };
      default:
        return {
          title: 'Okänd',
        };
    }
  };

  const platformBranding = getPlatformBranding();

  return (
    <Paper
      withBorder
      p={'sm'}
      style={{
        background: platformBranding.bg,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Group color={platformBranding.color} align="flex-start" position="apart">
        <Stack spacing={0}>
          <Text size="xs" color={platformBranding.color || 'dimmed'}>
            Plattform
          </Text>
          <Text color={platformBranding.color}>{platformBranding.title}</Text>
        </Stack>
        {platformBranding.icon && platformBranding.icon}
      </Group>
    </Paper>
  );
};

const Template = ({ lead }: { lead: ByggLead }) => {
  const { templates } = useContext(ByggleadsContext);

  const [templateData, setTemplateData] = useState<ByggLeadTemplate>();

  useEffect(() => {
    if (!lead.template || !templates.length) return;

    templates.forEach((template) => {
      if (template.id === lead.template.id) {
        setTemplateData(template);
      }
    });
  }, [templates, lead]);

  if (!templateData) return <></>;

  return (
    <Paper withBorder p={'sm'}>
      <Text size="xs" color="dimmed">
        Leadmall
      </Text>
      <Text>{templateData.title}</Text>
    </Paper>
  );
};
