import React from 'react';
import {
  Card,
  Group,
  SimpleGrid,
  Text,
  ThemeIcon,
  createStyles,
} from '@mantine/core';
import { IconAddress, IconMeetingSeats } from '../../components/Icons';
import { useModals } from '@mantine/modals';
import MeetingSeats from './SettingsModals/MeetingSeats';

const useStyles = createStyles((theme) => ({
  card: {},

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
  },

  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: 90,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },
}));

const AdminSettingsGrid = () => {
  const { classes, theme } = useStyles();
  const modals = useModals();

  return (
    <Card withBorder radius="sm" className={classes.card}>
      <Group position="left">
        <Text size="lg" weight={500}>
          Övriga inställningar
        </Text>
      </Group>
      <SimpleGrid
        cols={1}
        breakpoints={[
          { minWidth: 'xs', cols: 2 },
          { minWidth: 'sm', cols: 2 },
          { minWidth: 'md', cols: 3 },
          { minWidth: 'xl', cols: 4 },
        ]}
        mt="md"
      >
        <Card
          withBorder
          className={classes.item}
          onClick={() => {
            const id = modals.openModal({
              onClose: () => modals.closeModal(id),
              withCloseButton: false,
              children: <MeetingSeats onClose={() => modals.closeModal(id)} />,
              centered: true,
              size: "xs"
            });
          }}
        >
          <IconMeetingSeats side={32} color={theme.colors.green[6]} />
          <Text size="xs" mt={7}>
            Mötesplatser
          </Text>
        </Card>
      </SimpleGrid>
    </Card>
  );
};

export default AdminSettingsGrid;
