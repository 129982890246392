import {
  Badge,
  Box,
  Button,
  Card,
  Center,
  CloseButton,
  createStyles,
  Divider,
  Group,
  ScrollArea,
  Skeleton,
  Stack,
  Text,
  ThemeIcon,
  Title,
  UnstyledButton,
} from '@mantine/core';
import { getDoc } from 'firebase/firestore';
import { AnimatePresence, motion } from 'framer-motion';
import { isArray } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import EmptyScreen from '../../components/EmptyScreen';
import { IconSparkle, Logo1x1 } from '../../components/Icons';
import { ByggleadsContext } from '../../providers/ByggleadsProvider';
import { formatDate, isTimestamp } from '../../utils/Functions';
import { framerPropsTitleStack } from '../../utils/Globals';
import useStateEffect from '../../utils/hooks/useStateEffect';
import { ByggBulletin, ByggUser } from '../../utils/Types';
import { BulletinTypes } from '../admin/BulletinCreate';

const useStyles = createStyles((theme, _params, getRef) => ({
  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: theme.spacing.sm,
  },

  clickable: {
    cursor: 'pointer',

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
    },

    '&:active': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
      transform: 'translateY(1px)',
    },
  },
}));
const Bulletin = ({
  setDimLatestLead,
}: {
  setDimLatestLead: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { classes } = useStyles();
  const { bulletins } = useContext(ByggleadsContext);
  const [urgentBulletin, setUrgentBulletin] = useState<ByggBulletin>();
  useEffect(() => {
    setDimLatestLead(urgentBulletin ? true : false);
  }, [urgentBulletin]);

  useEffect(() => {
    if (!isArray(bulletins)) return;

    let timer: NodeJS.Timeout | undefined = undefined;

    let found = false;

    bulletins.forEach((bulletin) => {
      if (!bulletin.render?.new || found) return;

      found = true;
      timer = setTimeout(() => {
        setUrgentBulletin(bulletin);
      }, 1000);
    });

    if (!found) {
      setUrgentBulletin(undefined);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [bulletins]);

  return (
    <Card
      withBorder
      p="md"
      radius="md"
      sx={{
        transform: 'translate3d(0,0,0)',
      }}
    >
      <Card.Section className={classes.section}>
        <Group spacing={'md'}>
          <ThemeIcon variant="light" size={'lg'}>
            <Logo1x1 color="currentColor" side={18} />
          </ThemeIcon>
          <Text size="lg" weight={500}>
            Från Byggleads
          </Text>
        </Group>
      </Card.Section>
      <Card.Section>
        <AnimatePresence exitBeforeEnter>
          {isArray(bulletins) ? (
            <motion.div
              key={bulletins[0]?.ref?.id || 'LOADED'}
              {...framerPropsTitleStack}
            >
              <BulletinList bulletins={bulletins} />
            </motion.div>
          ) : (
            <motion.div key={'LOADING'} {...framerPropsTitleStack}>
              <BulletinLoading />
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence exitBeforeEnter>
          {urgentBulletin ? (
            <UrgentBulletin
              key={urgentBulletin.ref.id}
              bulletin={urgentBulletin}
              setBulletin={setUrgentBulletin}
            />
          ) : (
            <motion.div key="Invisible" />
          )}
        </AnimatePresence>
      </Card.Section>
    </Card>
  );
};

export default Bulletin;

const BulletinList = ({ bulletins }: { bulletins: ByggBulletin[] }) => {
  if (bulletins.length) {
    return (
      <ScrollArea
        style={{
          height: '100%',
          minHeight: 256,
        }}
      >
        <Stack spacing={0}>
          {bulletins.map((e, index) => {
            return (
              <BulletinRow
                key={e.ref.id}
                bulletin={e}
                index={index}
                bulletins={bulletins}
              />
            );
          })}
        </Stack>
      </ScrollArea>
    );
  }

  return <EmptyScreen label="Inga meddelanden från vårt håll" />;
};

const BulletinLoading = () => {
  return (
    <Stack>
      {['1', '2', '3'].map((row) => {
        return (
          <Group px="sm" pt="xs" pb={4} align="flex-start" key={row}>
            <Skeleton height={32} width={32} />
            <Stack spacing={2}>
              <Skeleton height={16} width={140} />
              <Skeleton height={14} width={200} />
              <Skeleton height={10} width={156} />
            </Stack>
          </Group>
        );
      })}
    </Stack>
  );
};

const BulletinRow = ({
  bulletin,
  index,
  bulletins,
}: {
  bulletin: ByggBulletin;
  index: number;
  bulletins: ByggBulletin[];
}) => {
  const { classes } = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();

  const [author, setAuthor] = useStateEffect<ByggUser | undefined>(
    undefined,
    () => {
      const getUser = async () => {
        const tempAuthor = await getDoc(bulletin.createdBy)
          .then((snapshot) => {
            if (snapshot.exists()) {
              return snapshot.data() as ByggUser;
            } else {
              return undefined;
            }
          })
          .catch((err) => {
            console.error(err);
            return undefined;
          });

        if (tempAuthor) {
          setAuthor(tempAuthor);
        }
      };
      getUser();
    },
    [bulletin]
  );

  const isFirstRead = useMemo(() => {
    const lastBulletin = bulletins[index - 1];
    if (!lastBulletin) return false;
    if (bulletin.render?.unread) return false;
    if (lastBulletin.render?.unread) {
      return true;
    }
    return false;
  }, [bulletins, bulletin, index]);

  return (
    <>
      {isFirstRead && (
        <Divider
          label="Redan lästa"
          labelPosition="center"
          px={'sm'}
          mt={'xs'}
          mb={-4}
        />
      )}

      <UnstyledButton
        className={classes.clickable}
        onClick={() => {
          searchParams.append('bulletin', bulletin.ref.path);
          setSearchParams(searchParams);
        }}
      >
        <Group px="sm" pt="xs" pb={4} align="flex-start">
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <ThemeIcon
              color={
                bulletin.render?.unread
                  ? BulletinTypes[bulletin.category].color
                  : 'gray'
              }
              variant={bulletin.render?.unread ? 'filled' : 'light'}
              size="lg"
            >
              {BulletinTypes[bulletin.category].icon}
            </ThemeIcon>
            {bulletin.render?.new && (
              <Badge
                color="red"
                variant="outline"
                size="xs"
                sx={(theme) => ({
                  position: 'absolute',
                  bottom: 0,
                  left: '50%',
                  transform: 'translate(-50%, 50%)',
                  backgroundColor:
                    theme.colorScheme === 'dark'
                      ? theme.colors.dark[6]
                      : theme.white,
                })}
              >
                NY!
              </Badge>
            )}
          </Box>
          <Stack
            spacing={0}
            style={{
              flex: 1,
            }}
          >
            <Text size="sm" weight={700} style={{ lineHeight: 1.25 }}>
              {bulletin.title}
            </Text>
            <Text size="sm" style={{ lineHeight: 1.25 }}>
              {bulletin.byline}
            </Text>
            <Text size="xs" color="dimmed">
              <Text
                component="span"
                size="xs"
                color={
                  bulletin.render?.unread
                    ? BulletinTypes[bulletin.category].color
                    : undefined
                }
                weight={500}
              >
                {BulletinTypes[bulletin.category].label}
              </Text>
              {author && ' · ' + author.name}
              {isTimestamp(bulletin.timeCreated) &&
                ' · ' +
                  formatDate(bulletin.timeCreated.toDate(), 'relative_general')}
            </Text>
          </Stack>
        </Group>
      </UnstyledButton>
    </>
  );
};

const UrgentBulletin = ({
  bulletin,
  setBulletin,
}: {
  bulletin: ByggBulletin;
  setBulletin: React.Dispatch<React.SetStateAction<ByggBulletin | undefined>>;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <motion.div
      key="BulletINg"
      style={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
      initial={{
        y: '100%',
      }}
      animate={{
        y: 0,
        transition: {
          duration: 0.3,
          type: 'tween',
          ease: 'circOut',
        },
      }}
      exit={{
        y: '100%',
        transition: {
          duration: 0.3,
          type: 'tween',
          ease: 'circIn',
          delay: 0.25,
        },
      }}
    >
      <motion.div
        style={{
          width: '100%',
          height: '100%',
        }}
        initial={{
          scale: 0.95,
        }}
        animate={{
          scale: 1,
          transition: {
            duration: 0.3,
            type: 'tween',
            ease: 'circOut',
            delay: 0.15,
          },
        }}
        exit={{
          scale: 0.95,
          transition: {
            duration: 0.25,
            type: 'tween',
            ease: 'circIn',
          },
        }}
      >
        <Box
          sx={(theme) => ({
            borderRadius: theme.radius.md,
            backgroundImage: `linear-gradient(150deg, ${
              theme.colors[BulletinTypes[bulletin.category].color][4]
            }, ${theme.colors[BulletinTypes[bulletin.category].color][6]})`,
            width: '100%',
            height: '100%',
            position: 'relative',
          })}
        >
          <Center
            sx={() => ({
              height: '100%',
            })}
          >
            <Stack
              align="left"
              spacing="xs"
              justify="center"
              sx={() => ({
                height: '100%',
                minWidth: '85%',
              })}
              p="md"
            >
              <Group spacing={'xs'}>
                <Badge
                  leftSection={
                    <Center>
                      <IconSparkle side={16} />
                    </Center>
                  }
                  variant="light"
                  color={BulletinTypes[bulletin.category].color}
                  sx={(theme) => ({
                    color: theme.colorScheme === 'dark' ? 'white' : undefined,
                  })}
                >
                  Ny!
                </Badge>

                <Badge
                  leftSection={
                    <Center>{BulletinTypes[bulletin.category].icon}</Center>
                  }
                  variant="filled"
                  color={BulletinTypes[bulletin.category].color}
                  sx={(theme) => ({
                    backgroundColor:
                      theme.colors[BulletinTypes[bulletin.category].color][8],
                  })}
                >
                  {BulletinTypes[bulletin.category].label}
                </Badge>
              </Group>

              <Title
                sx={{
                  color: '#ffffff',
                }}
                order={2}
              >
                {bulletin.title}
              </Title>

              <Divider
                sx={(theme) => ({
                  width: 128,
                  opacity: 0.75,
                })}
                color="white"
                my={'xs'}
              />

              <Button
                variant="white"
                color={BulletinTypes[bulletin.category].color}
                onClick={() => {
                  searchParams.append('bulletin', bulletin.ref.path);
                  setSearchParams(searchParams);
                }}
                sx={{
                  width: 'fit-content',
                }}
              >
                Läs anslaget
              </Button>
            </Stack>
          </Center>
          <CloseButton
            sx={(theme) => ({
              position: 'absolute',
              top: theme.spacing.md,
              right: theme.spacing.md,
              color: 'white',

              '&:hover': {
                backgroundColor: '#ffffff40',
              },
            })}
            onClick={() => setBulletin(undefined)}
          />
        </Box>
      </motion.div>
    </motion.div>
  );
};
