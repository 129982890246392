import {
  Avatar,
  Badge,
  Card,
  Center,
  CloseButton,
  createStyles,
  Group,
  Skeleton,
  Stack,
  Title,
} from '@mantine/core';
import { useResizeObserver } from '@mantine/hooks';
import RichTextEditor from '@mantine/rte';
import {
  arrayUnion,
  DocumentReference,
  getDoc,
  onSnapshot,
  updateDoc,
} from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';
import { BulletinTypes } from '../../pages/admin/BulletinCreate';
import { ByggleadsContext } from '../../providers/ByggleadsProvider';
import { formatDate, getAvatarURL, isTimestamp } from '../../utils/Functions';
import useStateEffect from '../../utils/hooks/useStateEffect';
import { ByggBulletin, ByggUser } from '../../utils/Types';
import EmptyScreen from '../EmptyScreen';
import { IconCalendar } from '../Icons';

const useStyles = createStyles((theme, _params, getRef) => ({
  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: theme.spacing.sm,
  },
}));

const BulletinModal = ({
  bulletinRef,
  handleClose,
}: {
  bulletinRef: DocumentReference;
  handleClose: () => void;
}) => {
  const { theme } = useStyles();
  const { user } = useContext(ByggleadsContext);

  const [bulletinUnavailable, setBulletinUnavailable] = useState(false);
  const [bulletin, setBulletin] = useStateEffect<ByggBulletin | undefined>(
    undefined,
    () => {
      const listener = onSnapshot(bulletinRef, (snapshot) => {
        const tempBulletin = {
          ...snapshot.data(),
          ref: snapshot.ref,
        } as ByggBulletin;
        if (!tempBulletin.published || !snapshot.exists()) {
          setBulletinUnavailable(true);
          return;
        }
        setBulletin(tempBulletin);
      });

      return () => listener();
    },
    []
  );

  const [author, setAuthor] = useStateEffect<ByggUser | undefined>(
    undefined,
    () => {
      if (!bulletin) return;
      const getUser = async () => {
        const tempAuthor = await getDoc(bulletin.createdBy)
          .then((snapshot) => {
            if (snapshot.exists()) {
              return snapshot.data() as ByggUser;
            } else {
              return undefined;
            }
          })
          .catch((err) => {
            console.error(err);
            return undefined;
          });

        if (tempAuthor) {
          setAuthor(tempAuthor);
        }
      };
      getUser();
    },
    [bulletin]
  );

  const [metaRef, metaRect] = useResizeObserver();

  useEffect(() => {
    if (!bulletin) return;
    if (!user?.ref) return;
    const params: Partial<ByggBulletin> = {
      readBy: arrayUnion(user.ref),
    };
    updateDoc(bulletin.ref, params).catch((err) => console.error(err));
  }, [bulletin, user]);

  if (bulletinUnavailable) {
    return (
      <Card>
        <Card.Section
          style={{
            height: 540,
            position: 'relative',
          }}
        >
          <EmptyScreen label="Anslaget är inte tillgängligt." />
          <CloseButton
            sx={(theme) => ({
              position: 'absolute',
              top: theme.spacing.md,
              right: theme.spacing.md,
            })}
            onClick={handleClose}
          />
        </Card.Section>
      </Card>
    );
  }

  if (!bulletin) {
    return (
      <Card>
        <Card.Section>
          <Skeleton width="100%" height={540} />
        </Card.Section>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Section>
        <Group
          position="right"
          align="flex-start"
          spacing="xl"
          mb="sm"
          p={'md'}
          sx={(theme) => ({
            position: 'relative',
            minHeight: 400,
            backgroundImage: `linear-gradient(150deg, ${
              theme.colors[BulletinTypes[bulletin.category].color][4]
            }, ${theme.colors[BulletinTypes[bulletin.category].color][6]})`,
          })}
        >
          <Stack
            sx={(theme) => ({
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            })}
            spacing={0}
            justify="flex-end"
          >
            <Center
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
              pt={metaRect.height + metaRect.y * 2 + theme.spacing.md}
              pb={metaRect.height + metaRect.y * 2 + theme.spacing.md}
            >
              <Stack align="center" spacing="sm">
                <Title
                  sx={{
                    color: '#ffffff',
                  }}
                  p="md"
                  align="center"
                >
                  {bulletin.title}
                </Title>
              </Stack>
            </Center>

            <Group spacing={'sm'} px="md" py="xs" ref={metaRef}>
              <Badge
                leftSection={
                  <Center mr={2}>
                    <Avatar
                      src={getAvatarURL(author)}
                      size={16}
                      radius={'xl'}
                    />
                  </Center>
                }
                variant="filled"
                color={BulletinTypes[bulletin.category].color}
                sx={{
                  paddingLeft: 1,
                  overflow: 'visible',
                  backgroundColor:
                    theme.colors[BulletinTypes[bulletin.category].color][8],
                }}
              >
                {author?.name}
              </Badge>

              <Badge
                leftSection={
                  <Center>{BulletinTypes[bulletin.category].icon}</Center>
                }
                variant="light"
                color={BulletinTypes[bulletin.category].color}
                sx={(theme) => ({
                  color: theme.colorScheme === 'dark' ? 'white' : undefined,
                })}
              >
                {BulletinTypes[bulletin.category].label}
              </Badge>

              {isTimestamp(bulletin.timeCreated) && (
                <Badge
                  leftSection={
                    <Center
                      style={{
                        color: 'white',
                      }}
                    >
                      <IconCalendar side={16} />
                    </Center>
                  }
                  variant="outline"
                  sx={{
                    color: 'white',
                    borderColor: 'white',
                  }}
                >
                  {formatDate(
                    bulletin.timeCreated.toDate(),
                    'relative_general'
                  )}
                </Badge>
              )}
            </Group>
          </Stack>
          <CloseButton
            sx={(theme) => ({
              color: 'white',

              '&:hover': {
                backgroundColor: '#ffffff40',
              },
            })}
            onClick={handleClose}
          />
        </Group>
      </Card.Section>
      <Card.Section>
        <RichTextEditor
          readOnly
          value={bulletin.content}
          onChange={() => {}}
          py={'xl'}
          sx={(theme) => ({
            border: 'none',
          })}
        />
      </Card.Section>
    </Card>
  );
};

export default BulletinModal;
