import {
  Button,
  Container,
  createStyles,
  Group,
  Menu,
  Stack,
  Title,
} from "@mantine/core";
import { AnimatePresence, motion } from "framer-motion";
import { IconCalendar, IconExpand } from "../../components/Icons";
import {
  framerPropsContentStack,
  framerPropsTitleStack,
} from "../../utils/Globals";
import CampaignRow from "./CampaignRow";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import { ByggleadsContext } from "../../providers/ByggleadsProvider";
import { DateRangePicker } from "@mantine/dates";
import moment from "moment";
import { ByggInsights } from "../../utils/Types";
import { ErrorCodes } from "../../utils/ErrorCodes";
import useStateEffect from "../../utils/hooks/useStateEffect";
import EmptyScreen from "../../components/EmptyScreen";
import { showNotification } from "@mantine/notifications";
import { v4 as uuid } from "uuid";

const useStyles = createStyles((theme, _params, getRef) => ({
  titleStack: {
    justifyContent: "flex-start",
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    borderBottom: "solid 1px",
    borderColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[5]
        : theme.colors.gray[2],
  },
}));

let insightsFetchID = uuid();

const CampaignsDashboard = () => {
  const { classes } = useStyles();
  const { workspace } = useContext(ByggleadsContext);

  const [currDateRange, setCurrDateRange] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const [compDateRange, setCompDateRange] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const [datePreset, setDatePreset] = useState<
    | "Idag"
    | "Denna veckan"
    | "1 vecka"
    | "2 veckor"
    | "Denna månad"
    | "1 månad"
    | "Detta kvartal"
    | "1 kvartal"
    | "Detta år"
    | "1 år"
  >();

  useEffect(() => {
    if (!currDateRange[0] && !currDateRange[1]) {
      setCurrDateRange([
        moment().subtract(1, "month").toDate(),
        moment().subtract(1, "day").toDate(),
      ]);
      setCompDateRange([
        moment().subtract(2, "month").toDate(),
        moment().subtract(1, "month").subtract(1, "day").toDate(),
      ]);
      setDatePreset("1 månad");
      return;
    }
    setCompDateRange([
      moment(currDateRange[0]).subtract(2, "week").toDate(),
      moment(currDateRange[1]).subtract(1, "week").subtract(1, "day").toDate(),
    ]);
  }, [currDateRange]);

  const [insights, setInsights] = useStateEffect<ByggInsights[] | undefined>(
    undefined,
    () => {
      setInsights(undefined);
      if (!workspace?.ref) {
        return;
      }
      let operationID = uuid();
      insightsFetchID = operationID;

      const getMetaInsights = httpsCallable(
        getFunctions(undefined, "europe-west1"),
        "getMetaInsights"
      );

      const sinceCurr = moment(currDateRange[0]);
      const untilCurr = moment(currDateRange[1]);
      const sinceComp = moment(compDateRange[0]);
      const untilComp = moment(compDateRange[1]);

      if (
        !sinceCurr.isValid() ||
        !untilCurr.isValid() ||
        !sinceComp.isValid() ||
        !untilComp.isValid()
      )
        return;

      getMetaInsights({
        workspace: workspace.ref.id,
        currSince: sinceCurr.format("YYYY-MM-DD"),
        currUntil: untilCurr.format("YYYY-MM-DD"),
        compSince: sinceComp.format("YYYY-MM-DD"),
        compUntil: untilComp.format("YYYY-MM-DD"),
      })
        .then((res) => {
          if (insightsFetchID === operationID) {
            setInsights(res.data as ByggInsights[]);
          }
        })
        .catch((err) => {
          console.error(err);
          if (insightsFetchID === operationID) {
            showNotification({
              message: ErrorCodes.standard.message,
              color: "red",
            });
          }
        });
    },
    [workspace, currDateRange]
  );

  return (
    <>
      <Stack spacing={0} className={classes.titleStack} p={"md"}>
        <motion.div {...framerPropsTitleStack}>
          <Group align={"center"} position="apart">
            <Title order={2}>Annonskampanjer</Title>
            <Group spacing={4}>
              <DateRangePicker
                variant="filled"
                placeholder="Datumspann"
                icon={<IconCalendar side={16} />}
                locale="sv"
                inputFormat="YYYY-MM-DD"
                clearable={false}
                value={currDateRange}
                onChange={(value) => {
                  setCurrDateRange(value);
                  setCompDateRange(() => {
                    const since = moment(value[0]);
                    const until = moment(value[1]);
                    const diff = since.diff(until, "days") * -1;
                    return [
                      since.subtract(diff + 1, "days").toDate(),
                      until.subtract(diff + 1, "days").toDate(),
                    ];
                  });
                  setDatePreset(undefined);
                }}
              />
              <DatePresetPicker
                setCurrDateRange={setCurrDateRange}
                setCompDateRange={setCompDateRange}
                setDatePreset={setDatePreset}
                datePreset={datePreset}
              />
            </Group>
          </Group>
        </motion.div>
      </Stack>
      <Container size={"lg"} py={"md"}>
        <motion.div {...framerPropsContentStack}>
          <Stack spacing={"xl"}>
            <AnimatePresence exitBeforeEnter>
              {insights ? (
                insights.length ? (
                  <motion.div
                    {...framerPropsTitleStack}
                    key={"INSIGHTS_ACTUAL"}
                  >
                    <Stack spacing={"xl"}>
                      {insights.map((data, index) => {
                        return <CampaignRow data={data} key={index} />;
                      })}
                    </Stack>
                  </motion.div>
                ) : (
                  <motion.div {...framerPropsTitleStack} key="INSIGHTS_EMPTY">
                    <EmptyScreen label="Vi hittar ingen data för den valda tidsperioden." />
                  </motion.div>
                )
              ) : (
                <motion.div {...framerPropsTitleStack} key={"INSIGHTS_LOADING"}>
                  <CampaignRow />
                </motion.div>
              )}
            </AnimatePresence>
          </Stack>
        </motion.div>
      </Container>
    </>
  );
};

export default CampaignsDashboard;

const DatePresetPicker = ({
  setCurrDateRange,
  setCompDateRange,
  setDatePreset,
  datePreset,
}: {
  setCurrDateRange: React.Dispatch<
    React.SetStateAction<[Date | null, Date | null]>
  >;
  setCompDateRange: React.Dispatch<
    React.SetStateAction<[Date | null, Date | null]>
  >;
  setDatePreset: React.Dispatch<
    React.SetStateAction<
      | "Idag"
      | "Denna veckan"
      | "1 vecka"
      | "2 veckor"
      | "Denna månad"
      | "1 månad"
      | "Detta kvartal"
      | "1 kvartal"
      | "Detta år"
      | "1 år"
      | undefined
    >
  >;
  datePreset:
    | "Idag"
    | "Denna veckan"
    | "1 vecka"
    | "2 veckor"
    | "Denna månad"
    | "1 månad"
    | "Detta kvartal"
    | "1 kvartal"
    | "Detta år"
    | "1 år"
    | undefined;
}) => {
  return (
    <Menu withArrow transition="pop" withinPortal>
      <Menu.Target>
        <Button
          variant="light"
          color={"gray"}
          rightIcon={<IconExpand side={16} />}
        >
          <AnimatePresence exitBeforeEnter>
            {datePreset && (
              <motion.span
                {...framerPropsTitleStack}
                key={datePreset}
                style={{ display: "inline-flex" }}
              >
                {datePreset}
              </motion.span>
            )}
          </AnimatePresence>
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          onClick={() => {
            setCurrDateRange([new Date(), new Date()]);
            setCompDateRange([
              moment().subtract(1, "days").toDate(),
              moment().subtract(1, "days").toDate(),
            ]);
            setDatePreset("Idag");
          }}
        >
          Idag
        </Menu.Item>
        {!moment().startOf("week").isSame(moment(), "date") && (
          <Menu.Item
            onClick={() => {
              setCurrDateRange([
                moment().startOf("week").toDate(),
                moment().subtract(1, "day").toDate(),
              ]);
              setCompDateRange([
                moment().subtract(1, "week").startOf("week").toDate(),
                moment().subtract(1, "week").subtract(1, "day").toDate(),
              ]);
              setDatePreset("Denna veckan");
            }}
          >
            Denna veckan
          </Menu.Item>
        )}
        <Menu.Item
          onClick={() => {
            setCurrDateRange([
              moment().subtract(1, "week").toDate(),
              moment().subtract(1, "day").toDate(),
            ]);
            setCompDateRange([
              moment().subtract(2, "week").toDate(),
              moment().subtract(1, "week").subtract(1, "day").toDate(),
            ]);
            setDatePreset("1 vecka");
          }}
        >
          1 vecka
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            setCurrDateRange([
              moment().subtract(2, "week").toDate(),
              moment().subtract(1, "day").toDate(),
            ]);
            setCompDateRange([
              moment().subtract(4, "week").toDate(),
              moment().subtract(2, "week").subtract(1, "day").toDate(),
            ]);
            setDatePreset("2 veckor");
          }}
        >
          2 veckor
        </Menu.Item>
        {!moment().startOf("month").isSame(moment(), "date") && (
          <Menu.Item
            onClick={() => {
              setCurrDateRange([
                moment().startOf("month").toDate(),
                moment().subtract(1, "day").toDate(),
              ]);
              setCompDateRange([
                moment().subtract(1, "month").startOf("month").toDate(),
                moment().subtract(1, "month").subtract(1, "day").toDate(),
              ]);
              setDatePreset("Denna månad");
            }}
          >
            Denna månad
          </Menu.Item>
        )}
        <Menu.Item
          onClick={() => {
            setCurrDateRange([
              moment().subtract(1, "month").toDate(),
              moment().subtract(1, "day").toDate(),
            ]);
            setCompDateRange([
              moment().subtract(2, "month").toDate(),
              moment().subtract(1, "month").subtract(1, "day").toDate(),
            ]);
            setDatePreset("1 månad");
          }}
        >
          1 månad
        </Menu.Item>
        {!moment().startOf("quarter").isSame(moment(), "date") && (
          <Menu.Item
            onClick={() => {
              setCurrDateRange([
                moment().startOf("quarter").toDate(),
                moment().subtract(1, "day").toDate(),
              ]);
              setCompDateRange([
                moment().subtract(1, "quarter").startOf("quarter").toDate(),
                moment().subtract(1, "quarter").subtract(1, "day").toDate(),
              ]);
              setDatePreset("Detta kvartal");
            }}
          >
            Detta kvartal
          </Menu.Item>
        )}
        <Menu.Item
          onClick={() => {
            setCurrDateRange([
              moment().subtract(1, "quarter").toDate(),
              moment().subtract(1, "day").toDate(),
            ]);
            setCompDateRange([
              moment().subtract(2, "quarter").toDate(),
              moment().subtract(1, "quarter").subtract(1, "day").toDate(),
            ]);
            setDatePreset("1 kvartal");
          }}
        >
          1 kvartal
        </Menu.Item>
        {!moment().startOf("year").isSame(moment(), "date") && (
          <Menu.Item
            onClick={() => {
              setCurrDateRange([
                moment().startOf("year").toDate(),
                moment().subtract(1, "day").toDate(),
              ]);
              setCompDateRange([
                moment().subtract(1, "year").startOf("year").toDate(),
                moment().subtract(1, "year").subtract(1, "day").toDate(),
              ]);
              setDatePreset("Detta år");
            }}
          >
            Detta år
          </Menu.Item>
        )}
        <Menu.Item
          onClick={() => {
            setCurrDateRange([
              moment().subtract(1, "year").toDate(),
              moment().subtract(1, "day").toDate(),
            ]);
            setCompDateRange([
              moment().subtract(2, "year").toDate(),
              moment().subtract(1, "year").subtract(1, "day").toDate(),
            ]);
            setDatePreset("1 år");
          }}
        >
          1 år
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
