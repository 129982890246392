import {
  createStyles,
  UnstyledButtonProps,
  UnstyledButton,
  Group,
  Avatar,
  Text,
  Skeleton,
} from "@mantine/core";
import { IconMenu } from "./Icons";

const useStyles = createStyles((theme, _params, getRef) => ({
  user: {
    display: "block",
    width: "100%",
    padding: theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },
  avatar: {
    backgroundColor: theme.colors.gray[1],
  },
}));

interface UserButtonProps extends UnstyledButtonProps {
  image?: string;
  name: string;
  workspace: string;
  icon?: React.ReactNode | "NONE";
  loading?: boolean;
  onClick?: () => void;
}

const UserButton = ({
  image,
  name,
  workspace,
  icon,
  loading,
  onClick,
  ...others
}: UserButtonProps) => {
  const { classes } = useStyles();

  return (
    <UnstyledButton
      className={classes.user}
      {...others}
      onClick={loading ? undefined : onClick}
    >
      <Group>
        {loading ? (
          <Skeleton circle width={40} height={40} />
        ) : (
          <Avatar src={image} radius="xl" className={classes.avatar} />
        )}

        <div style={{ flex: 1 }}>
          {!loading ? (
            <Text size="sm" weight={500}>
              {name}
            </Text>
          ) : (
            <Skeleton height={14} mb={6} width={112} />
          )}

          {!loading ? (
            <Text color="dimmed" size="xs">
              {workspace}
            </Text>
          ) : (
            <Skeleton height={12} width={96} />
          )}
        </div>

        {icon ? icon === "NONE" ? null : icon : <IconMenu side={14} />}
      </Group>
    </UnstyledButton>
  );
};

export default UserButton;
