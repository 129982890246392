import {
  Card,
  createStyles,
  Divider,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { refEqual } from 'firebase/firestore';
import { AnimatePresence, motion } from 'framer-motion';
import { isArray } from 'lodash';
import moment from 'moment';
import React, { useContext } from 'react';
import { StatusUnhandledRef } from '../../App';
import {
  ByggleadsContext,
  ByggleadsProps,
} from '../../providers/ByggleadsProvider';
import { isTimestamp } from '../../utils/Functions';
import { framerPropsContentStack } from '../../utils/Globals';
import useStateEffect from '../../utils/hooks/useStateEffect';
import { ByggLead } from '../../utils/Types';

const useStyles = createStyles((theme, _params, getRef) => ({
  cardStack: {
    height: '100%',
  },

  cardTitle: {
    margin: -theme.spacing.md,
    marginBottom: 0,
    padding: theme.spacing.md,
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xs,
    color:
      theme.colorScheme === 'dark'
        ? theme.colors[theme.primaryColor][2]
        : theme.colors[theme.primaryColor][6],
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors[theme.primaryColor][8] + '59'
        : theme.colors[theme.primaryColor][0],

    h1: {
      fontWeight: 700,
    },

    '&.success': {
      color:
        theme.colorScheme === 'dark'
          ? theme.colors.teal[2]
          : theme.colors.teal[6],
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.teal[8] + '59'
          : theme.colors.teal[0],
    },

    '&.danger': {
      color:
        theme.colorScheme === 'dark'
          ? theme.colors.red[2]
          : theme.colors.red[6],
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.red[8] + '59'
          : theme.colors.red[0],
    },
  },
}));

const LeadStats = () => {
  const { leads, workspace } = useContext(ByggleadsContext);

  return (
    <AnimatePresence exitBeforeEnter>
      {isArray(leads) ? (
        <motion.div key={`loaded${leads.length}`} {...framerPropsContentStack}>
          <StatCards leads={leads} workspace={workspace} />
        </motion.div>
      ) : (
        <motion.div key="loading" {...framerPropsContentStack}>
          <LoadingCards />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default React.memo(LeadStats);

const StatCards = ({
  leads,
  workspace,
}: {
  leads: ByggleadsProps['leads'];
  workspace: ByggleadsProps['workspace'];
}) => {
  const { classes, cx } = useStyles();

  const [monthLeads, setMonthLeads] = useStateEffect<{
    curr: ByggLead[];
    comp: ByggLead[];
  }>(
    {
      curr: [],
      comp: [],
    },
    () => {
      if (!isArray(leads)) return;
      const tempLeadsCurr = leads.flatMap((lead) => {
        if (!isTimestamp(lead.timeCreated)) return [];
        const startOfMonth = moment().subtract(1, 'month');
        const leadCreated = moment(lead.timeCreated.toDate());
        if (leadCreated.isAfter(startOfMonth)) return lead;
        return [];
      });
      const tempLeadsComp = leads.flatMap((lead) => {
        if (!isTimestamp(lead.timeCreated)) return [];
        const startOfPeriod = moment().subtract(2, 'month');
        const endOfPeriod = moment().subtract(1, 'month');
        const leadCreated = moment(lead.timeCreated.toDate());
        if (leadCreated.isBetween(startOfPeriod, endOfPeriod)) return lead;
        return [];
      });
      setMonthLeads({
        curr: tempLeadsCurr,
        comp: tempLeadsComp,
      });
    },
    [leads]
  );
  const monthDiff = monthLeads.curr.length - monthLeads.comp.length;

  const [totalLeads, setTotalLeads] = useStateEffect<{
    curr: ByggLead[];
    comp: number;
  }>(
    {
      curr: [],
      comp: 0,
    },
    () => {
      if (!isArray(leads)) return;
      const firstLead = leads[leads.length - 1];
      if (!firstLead) return;
      let comp = 0;
      if (isTimestamp(firstLead.timeCreated)) {
        const leadCreated = moment(firstLead.timeCreated.toDate());
        comp = moment().diff(leadCreated, 'days');
      }
      setTotalLeads({ curr: leads, comp: comp });
    },
    [leads]
  );

  const [unhandledLeads, setUnhandledLeads] = useStateEffect<{
    curr: ByggLead[];
    comp: ByggLead[];
  }>(
    {
      curr: [],
      comp: [],
    },
    () => {
      if (!isArray(leads)) return;
      const tempLeadsCurr = leads.filter((e) =>
        refEqual(e.status, StatusUnhandledRef)
      );
      const tempLeadsComp = tempLeadsCurr.flatMap((lead) => {
        if (!isTimestamp(lead.timeCreated)) return [];
        const HOURS_72_AGO = moment().subtract(72, 'hour');
        const leadCreated = moment(lead.timeCreated.toDate());
        if (leadCreated.isBefore(HOURS_72_AGO)) return lead;
        return [];
      });
      setUnhandledLeads({
        curr: tempLeadsCurr,
        comp: tempLeadsComp,
      });
    },
    [leads]
  );

  return (
    <SimpleGrid
      cols={1}
      sx={(theme) => ({
        height: '100%',
      })}
      breakpoints={[{ minWidth: 'xs', cols: 3 }]}
    >
      <Card withBorder p={0} radius="md">
        <Stack className={classes.cardStack} spacing={'xs'} p="md">
          <Stack
            className={cx(classes.cardTitle, monthDiff > 10 && 'success')}
            spacing={0}
          >
            <Title order={1}>{monthLeads.curr.length}</Title>
            <Text size="xs" weight={700} transform="uppercase">
              Leads senaste månaden
            </Text>
          </Stack>
          <Text size="sm">
            Det är{' '}
            {monthDiff === 0
              ? 'lika många som'
              : monthDiff > 0
              ? `${monthDiff} fler än`
              : `${monthDiff} färre än`}{' '}
            månaden innan{' '}
            {monthDiff === 0
              ? '😊'
              : monthDiff > 0
              ? monthDiff > 10
                ? '🚀'
                : '🤠'
              : '😕'}
          </Text>
        </Stack>
      </Card>
      <Card withBorder p={0} radius="md">
        <Stack className={classes.cardStack} spacing={'xs'} p="md">
          <Stack className={classes.cardTitle} spacing={0}>
            <Title order={1}>{totalLeads.curr.length}</Title>
            <Text size="xs" weight={700} transform="uppercase">
              Leads totalt
            </Text>
          </Stack>
          <Text size="sm">
            Så många leads har {workspace?.name} fått under sina{' '}
            {totalLeads.comp} dagar på Byggleads.
          </Text>
        </Stack>
      </Card>
      <Card withBorder p={0} radius="md">
        <Stack className={classes.cardStack} spacing={'xs'} p="md">
          <Stack
            className={cx(
              classes.cardTitle,
              unhandledLeads.curr.length > 10 && 'danger'
            )}
            spacing={0}
          >
            <Title order={1}>{unhandledLeads.curr.length}</Title>
            <Text size="xs" weight={700} transform="uppercase">
              Obehandlade leads
            </Text>
          </Stack>
          <Text size="sm">
            {unhandledLeads.comp.length} av dessa är mer än 3 dygn gamla. <br />
            Ju förr du hanterar dem, ju högre blir chansen till en affär.
          </Text>
        </Stack>
      </Card>
    </SimpleGrid>
  );
};

const LoadingCards = () => {
  const { classes, cx } = useStyles();

  return (
    <SimpleGrid
      cols={1}
      breakpoints={[{ minWidth: 'xs', cols: 3 }]}
      style={{
        height: '100%',
      }}
    >
      <Card withBorder p={0} radius="md">
        <Stack className={classes.cardStack} spacing={'xs'} p="md">
          <Stack
            className={cx(classes.cardTitle)}
            style={{ background: 'none' }}
            spacing={0}
          >
            <Skeleton height={36} width={48} mb={8} />
            <Skeleton height={18} width={72} />
          </Stack>
          <Skeleton height={16} width={112} />
          <Skeleton height={16} width={96} />
        </Stack>
      </Card>
      <Card withBorder p={0} radius="md">
        <Stack className={classes.cardStack} spacing={'xs'} p="md">
          <Stack
            className={cx(classes.cardTitle)}
            style={{ background: 'none' }}
            spacing={0}
          >
            <Skeleton height={36} width={48} mb={8} />
            <Skeleton height={18} width={72} />
          </Stack>
          <Skeleton height={16} width={112} />
          <Skeleton height={16} width={96} />
        </Stack>
      </Card>
      <Card withBorder p={0} radius="md">
        <Stack className={classes.cardStack} spacing={'xs'} p="md">
          <Stack
            className={cx(classes.cardTitle)}
            style={{ background: 'none' }}
            spacing={0}
          >
            <Skeleton height={36} width={48} mb={8} />
            <Skeleton height={18} width={72} />
          </Stack>
          <Skeleton height={16} width={112} />
          <Skeleton height={16} width={96} />
        </Stack>
      </Card>
    </SimpleGrid>
  );
};
