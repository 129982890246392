import { useEffect, useRef, useState } from 'react';
import {
  ActionIcon,
  createStyles,
  NumberInput,
  NumberInputHandlers,
  Stack,
  Button,
  Text,
  Divider,
  List,
} from '@mantine/core';
import { IconMinus, IconPlus } from '../../../components/Icons';
import useStateEffect from '../../../utils/hooks/useStateEffect';
import { doc, getFirestore, onSnapshot, updateDoc } from 'firebase/firestore';

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `6px ${theme.spacing.xs}px`,
    borderRadius: theme.radius.sm,
    border: `1px solid ${
      theme.colorScheme === 'dark' ? 'transparent' : theme.colors.gray[3]
    }`,
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,

    '&:focus-within': {
      borderColor: theme.colors[theme.primaryColor][6],
    },
  },

  control: {
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    border: `1px solid ${
      theme.colorScheme === 'dark' ? 'transparent' : theme.colors.gray[3]
    }`,

    '&:disabled': {
      borderColor:
        theme.colorScheme === 'dark' ? 'transparent' : theme.colors.gray[3],
      opacity: 0.8,
      backgroundColor: 'transparent',
    },
  },

  input: {
    textAlign: 'center',
    paddingRight: `${theme.spacing.sm}px !important`,
    paddingLeft: `${theme.spacing.sm}px !important`,
    height: 28,
    flex: 1,
  },
}));

const MeetingSeats = ({ onClose }: { onClose: () => void }) => {
  const { classes } = useStyles();
  const handlers = useRef<NumberInputHandlers>(null);
  const [inputKey, setInputKey] = useState(0);
  const [waitingList, setWaitingList] = useState<string[]>([]);
  const [seats, setSeats] = useStateEffect<number | undefined>(
    0,
    () => {
      const listener = onSnapshot(
        doc(getFirestore(), 'platform', 'meeting'),
        (snapshot) => {
          const data = snapshot.data();
          if (!data) return;
          setSeats(data.seats as number);
          setWaitingList(data.mailing_list as string[]);
          setInputKey((p) => p + 1);
        },
        (error) => console.error(error)
      );

      return () => listener();
    },
    []
  );

  const min = 0;
  const max = 40;

  const [loading, setLoading] = useState(false);
  const save = async () => {
    if (loading) return;
    setLoading(true);

    await updateDoc(doc(getFirestore(), 'platform', 'meeting'), {
      seats: seats || 0,
    })
      .then(() => {
        setLoading(false);
        onClose();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Stack>
      <div className={classes.wrapper}>
        <ActionIcon<'button'>
          size={28}
          variant="transparent"
          onClick={() => handlers.current?.decrement()}
          disabled={seats === min}
          className={classes.control}
          onMouseDown={(event) => event.preventDefault()}
        >
          <IconMinus side={16} />
        </ActionIcon>

        <NumberInput
          variant="unstyled"
          min={min}
          max={max}
          handlersRef={handlers}
          value={seats}
          onChange={setSeats}
          classNames={{ input: classes.input }}
          key={inputKey}
        />

        <ActionIcon<'button'>
          size={28}
          variant="transparent"
          onClick={() => handlers.current?.increment()}
          disabled={seats === max}
          className={classes.control}
          onMouseDown={(event) => event.preventDefault()}
        >
          <IconPlus side={16} />
        </ActionIcon>
      </div>
      <Button variant="filled" onClick={save} loading={loading}>
        Spara
      </Button>
      <Divider label="Nuvarande väntelista" />
      <List>
        {waitingList.map((email, index) => (
          <List.Item key={index}>{email}</List.Item>
        ))}
      </List>
    </Stack>
  );
};

export default MeetingSeats;
