import WishList from '../assets/InsightsIcons/WishList.svg';
import Website from '../assets/InsightsIcons/Website.svg';
import Reactions from '../assets/InsightsIcons/Reactions.svg';
import Register from '../assets/InsightsIcons/Register.svg';
import Saves from '../assets/InsightsIcons/Saves.svg';
import Search from '../assets/InsightsIcons/Search.svg';
import Share from '../assets/InsightsIcons/Share.svg';
import Spend1 from '../assets/InsightsIcons/Spend1.svg';
import Spend2 from '../assets/InsightsIcons/Spend2.svg';
import Spend3 from '../assets/InsightsIcons/Spend3.svg';
import Spend4 from '../assets/InsightsIcons/Spend4.svg';
import Views1 from '../assets/InsightsIcons/Views1.svg';
import Views2 from '../assets/InsightsIcons/Views2.svg';
import Views3 from '../assets/InsightsIcons/Views3.svg';
import Views4 from '../assets/InsightsIcons/Views4.svg';
import Conversion2 from '../assets/InsightsIcons/Conversion2.svg';
import Crowd from '../assets/InsightsIcons/Crowd.svg';
import Custom from '../assets/InsightsIcons/Custom.svg';
import Engagement from '../assets/InsightsIcons/Engagement.svg';
import Lead1 from '../assets/InsightsIcons/Lead1.svg';
import Lead2 from '../assets/InsightsIcons/Lead2.svg';
import Likes from '../assets/InsightsIcons/Likes.svg';
import PaymentInfo1 from '../assets/InsightsIcons/PaymentInfo1.svg';
import PaymentInfo2 from '../assets/InsightsIcons/PaymentInfo2.svg';
import Purchase from '../assets/InsightsIcons/Purchase.svg';
import Conversations from '../assets/InsightsIcons/Conversations.svg';
import Conversion1 from '../assets/InsightsIcons/Conversion1.svg';
import Contact from '../assets/InsightsIcons/Contact.svg';
import Click from '../assets/InsightsIcons/Click.svg';
import Checkout1 from '../assets/InsightsIcons/Checkout1.svg';
import AddToCart2 from '../assets/InsightsIcons/AddToCart2.svg';
import AddToCart1 from '../assets/InsightsIcons/AddToCart1.svg';

export interface InsightsCampaign {
  enabled: boolean;
  id: string;
  account_id: string;
  fields: (
    | 'impressions'
    | 'reach'
    | 'clicks'
    | 'frequency'
    | 'spend'
    | 'conversions'
    | 'cpc'
    | 'cpm'
    | 'cpp'
    | 'ctr'
  )[];
  actions: (
    | 'landing_page_view'
    | 'like'
    | 'link_click'
    | 'offsite_conversion.fb_pixel_add_payment_info'
    | 'offsite_conversion.fb_pixel_add_to_cart'
    | 'offsite_conversion.fb_pixel_add_to_wishlist'
    | 'offsite_conversion.fb_pixel_complete_registration'
    | 'offsite_conversion.fb_pixel_custom'
    | 'offsite_conversion.fb_pixel_initiate_checkout'
    | 'offsite_conversion.fb_pixel_lead'
    | 'offsite_conversion.fb_pixel_purchase'
    | 'offsite_conversion.fb_pixel_search'
    | 'offsite_conversion.fb_pixel_view_content'
    | 'onsite_conversion.messaging_first_reply'
    | 'onsite_conversion.post_save'
    | 'onsite_conversion.purchase'
    | 'outbound_click'
    | 'post'
    | 'post_reaction'
    | 'contact_total'
    | 'contact_website'
    | 'page_engagement'
    | 'post_engagement'
    | 'onsite_conversion.lead_grouped'
    | 'lead'
    | 'leadgen_grouped'
    | 'omni_purchase'
    | 'omni_add_to_cart'
    | 'omni_view_content'
  )[];
}

export interface CampaignSnippet {
  id: string;
  name: string;
  status: 'ACTIVE' | 'PAUSED' | 'DELETED' | 'ARCHIVED';
  account_id: string;
  account_name: string;
}

export interface InsightsFieldInterface {
  key: string;
  title: string;
  description: string;
  suffix?: string;
  facebookLabel: string;
  icon?: string;
  invertedColors?: boolean;
  group?: string;
}
export const InsightsFieldDictionary: Record<
  string,
  Omit<InsightsFieldInterface, 'key'>
> = {
  impressions: {
    title: 'Exponeringar',
    description: 'Antal gånger dina annonser visades.',
    facebookLabel: 'The number of times your ads were on screen.',
    suffix: 'st',
    icon: Views1,
  },
  reach: {
    title: 'Räckvidd',
    description:
      'Antal användare som såg dina annonser minst en gång. Räckvidd skiljer sig från exponeringar som kan inkludera flera visningar av dina annonser från samma användare. Detta värde är uppskattat.',
    facebookLabel:
      'The number of people who saw your ads at least once. Reach is different from impressions, which may include multiple views of your ads by the same people. This metric is estimated.',
    suffix: 'st',
    icon: Crowd,
  },
  clicks: {
    title: 'Klick',
    description: 'Antalet klick på dina annonser.',
    facebookLabel: 'The number of clicks on your ads.',
    suffix: 'st',
    icon: Click,
  },
  frequency: {
    title: 'Frekvens',
    description:
      'Det genomsnittliga antalet gånger varje användare såg din annons. Detta värde är uppskattat.',
    facebookLabel:
      'The average number of times each person saw your ad. This metric is estimated.',
    suffix: 'ggr',
    icon: Views3,
  },
  spend: {
    title: 'Spend',
    description:
      'Det uppskattade beloppet du spenderat på din kampanj, annonsuppsättning eller annons under dess aktiva period. Detta värde är uppskattat.',
    facebookLabel:
      "The estimated total amount of money you've spent on your campaign, ad set or ad during its schedule. This metric is estimated.",
    suffix: 'kr',
    icon: Spend2,
    invertedColors: true,
  },
  // conversions: {
  //   title: 'Konverteringar',
  //   description:
  //     'Det uppskattade antalet konverteringar genom alla kanaler (d.v.s. hemsida, mobilapp, offline och på facebook) som tillskrivs dina annonser.',
  //   facebookLabel:
  //     'The total number of conversions across all channels (i.e. website, mobile app, offline, and on-facebook) attributed to your ads.',
  //   suffix: 'st',
  //   icon: Conversion2,
  // },
  cpc: {
    title: 'CPC',
    description:
      'Den genomsnittliga kostnaden för varje klick under hela kampanjens aktiva period.',
    facebookLabel: 'The average cost for each click (all).',
    suffix: 'kr',
    icon: Spend1,
    invertedColors: true,
  },
  cpm: {
    title: 'CPM',
    description: 'Den genomsnittliga kostnaden för 1000 exponeringar.',
    facebookLabel: 'The average cost for 1,000 impressions.',
    suffix: 'kr',
    icon: Spend2,
    invertedColors: true,
  },
  cpp: {
    title: 'CPP',
    description:
      'Den genomsnittliga kostnaden för att nå 1000 användare. Detta värde är uppskattat.',
    facebookLabel:
      'The average cost to reach 1,000 people. This metric is estimated.',
    suffix: 'kr',
    icon: Spend3,
    invertedColors: true,
  },
  ctr: {
    title: 'CTR',
    description:
      'Den procentuella andelen gånger personer såg din annons och genomförde ett klick under hela kampanjens aktiva period.',
    facebookLabel:
      'The percentage of times people saw your ad and performed a click (all).',
    suffix: '%',
    icon: Spend4,
  },
};

export interface InsightsActionInterface {
  key: string;
  title: string;
  description: string;
  suffix?: string;
  facebookLabel: string;
  icon?: string;
  invertedColors?: boolean;
  group?: string;
}
export const InsightsActionDictionary: Record<
  string,
  Omit<InsightsActionInterface, 'key'>
> = {
  landing_page_view: {
    title: 'Landningssidevisningar',
    description: 'Öppningar av landningssida från annonsen.',
    suffix: 'st',
    facebookLabel: 'Landing Page Views',
    icon: Views3,
  },
  like: {
    title: 'Gillamarkeringar för sidan',
    description:
      'Antalet gillamarkeringar för affärskontot som tillskrivs dina annonser.',
    suffix: 'st',
    facebookLabel: 'Page Likes',
    icon: Likes,
  },
  link_click: {
    title: 'Länkklick',
    description: 'Antalet länkklick som tillskrivs dina annonser.',
    suffix: 'st',
    facebookLabel: 'Link Clicks',
    icon: Website,
  },
  'offsite_conversion.fb_pixel_add_payment_info': {
    title: 'Pixel-betalningsinfo',
    description:
      'Antalet tilläggningar av betalningsinformation som tillskrivs dina annonser. Mäts med en Meta-pixel.',
    suffix: 'st',
    facebookLabel: 'Adds Payment Info',
    group: 'Facebook Pixel',
    icon: PaymentInfo1,
  },
  'offsite_conversion.fb_pixel_add_to_cart': {
    title: 'Pixel-kundvagn',
    description:
      'Antalet tilläggningar i kundvagn som tillskrivs dina annonser. Mäts med en Meta-pixel.',
    suffix: 'st',
    facebookLabel: 'Adds To Cart',
    group: 'Facebook Pixel',
    icon: AddToCart1,
  },
  'offsite_conversion.fb_pixel_add_to_wishlist': {
    title: 'Pixel-önskelista',
    description:
      'Antalet tilläggningar till önskelista som tillskrivs dina annonser. Mäts med en Meta-pixel.',
    suffix: 'st',
    facebookLabel: 'Adds To Wishlist',
    group: 'Facebook Pixel',
    icon: WishList,
  },
  'offsite_conversion.fb_pixel_complete_registration': {
    title: 'Antal pixel-registreringar',
    description:
      'Antalet kontoregistreringar som tillskrivs dina annonser. Mäts med en Meta-pixel.',
    suffix: 'st',
    facebookLabel: 'Completed Registration',
    group: 'Facebook Pixel',
    icon: Register,
  },
  'offsite_conversion.fb_pixel_custom': {
    title: 'Anpassade pixel-händelser',
    description:
      'Antalet anpassade händelser som tillskrivs dina annonser. Mäts med en Meta-pixel.',
    suffix: 'st',
    facebookLabel: 'Custom pixel events defined by the advertiser',
    group: 'Facebook Pixel',
    icon: Custom,
  },
  'offsite_conversion.fb_pixel_initiate_checkout': {
    title: 'Kassainitieringar',
    description:
      'Antalet kassainitieringar som tillskrivs dina annonser. Mäts med en Meta-pixel.',
    suffix: 'st',
    facebookLabel: 'Initiates Checkout',
    group: 'Facebook Pixel',
    icon: Checkout1,
  },
  'offsite_conversion.fb_pixel_lead': {
    title: 'Pixel-leads',
    description:
      'Antalet leads som tillskrivs dina annonser. Mäts med en Meta-pixel.',
    suffix: 'st',
    facebookLabel: 'Pixel-leads',
    group: 'Facebook Pixel',
    icon: Lead1,
  },
  'offsite_conversion.fb_pixel_purchase': {
    title: 'Pixel-köp',
    description:
      'Antal köp som tillskrivs dina annonser. Mäts med en Meta-pixel.',
    suffix: 'st',
    facebookLabel: 'Purchases',
    group: 'Facebook Pixel',
    icon: Purchase,
  },
  'offsite_conversion.fb_pixel_search': {
    title: 'Pixel-sökningar',
    description:
      'Antal sökningar som tillskrivs dina annonser. Mäts med en Meta-pixel.',
    suffix: 'st',
    facebookLabel: 'Searchs',
    group: 'Facebook Pixel',
    icon: Search,
  },
  'offsite_conversion.fb_pixel_view_content': {
    title: 'Pixel-innehållsvisningar',
    description:
      'Antal innehållsvisningar som tillskrivs dina annonser. Mäts med en Meta-pixel.',
    suffix: 'st',
    facebookLabel: 'Views Content',
    group: 'Facebook Pixel',
    icon: Views3,
  },
  'onsite_conversion.messaging_first_reply': {
    title: 'Nya konversationer',
    description:
      'Antalet nya Messenger-konversationer som tillskrivs dina annonser.',
    suffix: 'st',
    facebookLabel: 'New Messaging Conversations',
    group: 'Facebook Messenger',
    icon: Conversations,
  },
  'onsite_conversion.post_save': {
    title: 'Inläggssparningar',
    description:
      'Antalet gånger användare sparat ditt inlägg som tillskrivs dina annonser.',
    suffix: 'st',
    facebookLabel: 'Post Saves',
    icon: Saves,
  },
  'onsite_conversion.purchase': {
    title: 'Köp',
    description: 'Köp på en Meta-plattform.',
    suffix: 'st',
    facebookLabel: 'On-Facebook Purchases',
    icon: Purchase,
  },
  outbound_click: {
    title: 'Utåtgående klick',
    description: 'Antalet utåtgående länkklick som tillskrivs dina annonser.',
    suffix: 'st',
    facebookLabel: 'Outbound Clicks',
    icon: Website,
  },
  post: {
    title: 'Inläggsdelningar',
    description:
      'Antalet delningar av ditt inlägg som tillskrivs dina annonser.',
    suffix: 'st',
    facebookLabel: 'Post Shares',
    icon: Share,
  },
  post_reaction: {
    title: 'Inläggsreaktioner',
    description: 'Antalet inläggsreaktioner som tillskrivs dina annonser.',
    suffix: 'st',
    facebookLabel: 'Post Reactions',
    icon: Reactions,
  },
  contact_total: {
    title: 'Kontakter',
    description: 'Antalet kontakter som tillskrivs dina annonser.',
    suffix: 'st',
    facebookLabel: 'Contacts',
    icon: Contact,
  },
  contact_website: {
    title: 'Hemsidekontakter',
    description: 'Antalet hemsidekontakter som tillskrivs dina annonser.',
    suffix: 'st',
    facebookLabel: 'Website Contacts',
    icon: Contact,
  },
  page_engagement: {
    title: 'Sidinteraktioner',
    description:
      'Antalet interaktioner på ditt affärskonto som tillskrivs dina annonser.',
    suffix: 'st',
    facebookLabel: 'Page Engagement',
    group: 'Grouped action types',
    icon: Engagement,
  },
  post_engagement: {
    title: 'Inläggsinteraktioner',
    description:
      'Antalet interaktioner på ditt inlägg som tillskrivs dina annonser.',
    suffix: 'st',
    facebookLabel: 'Post Engagement',
    group: 'Grouped action types',
    icon: Engagement,
  },
  'onsite_conversion.lead_grouped': {
    title: 'On-Meta leads',
    description:
      'Antalet leads på en Meta-plattform som tillskrivs dina annonser.',
    suffix: 'st',
    facebookLabel: 'All On-Facebook Leads',
    group: 'Grouped action types',
    icon: Lead2,
  },
  lead: {
    title: 'Alla leads',
    description: 'Alla leads från alla mätkällor som tillskrivs dina annonser.',
    suffix: 'st',
    facebookLabel: 'All offsite leads plus all On-Facebook leads',
    group: 'Grouped action types',
    icon: Lead2,
  },
  leadgen_grouped: {
    title: 'Meta leads (inkl. Messenger)',
    description:
      'Antalet leads på en Meta-plattform som tillskrivs dina annonser. Detta inkluderar Messenger och Instant Forms.',
    suffix: 'st',
    facebookLabel: 'On-Facebook leads coming from Messenger and Instant Forms',
    group: 'Grouped action types',
    icon: Lead2,
  },
  omni_purchase: {
    title: 'Köp',
    description: 'Alla köp från alla mätkällor som tillskrivs dina annonser.',
    suffix: 'st',
    facebookLabel: 'Purchases',
    group: 'Grouped action types',
    icon: Purchase,
  },
  omni_add_to_cart: {
    title: 'Kundvagn',
    description:
      'Alla tilläggningar i kundvagn från alla mätkällor som tillskrivs dina annonser.',
    suffix: 'st',
    facebookLabel: 'Adds to Cart',
    group: 'Grouped action types',
    icon: AddToCart2,
  },
  omni_view_content: {
    title: 'Innehållsvisningar',
    description:
      'Alla innehållsvisningar från alla mätkällor som tillskrivs dina annonser.',
    suffix: 'st',
    facebookLabel: 'Content Views',
    group: 'Grouped action types',
    icon: Views3,
  },
};
