import {
  Popover,
  ActionIcon,
  Badge,
  ScrollArea,
  Stack,
  Group,
  Anchor,
  ThemeIcon,
  Text,
  createStyles,
  Tabs,
  Button,
  Space,
} from "@mantine/core";
import {
  collection,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  writeBatch,
  WriteBatch,
} from "firebase/firestore";
import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  useSearchParams,
  useNavigate,
  NavigateFunction,
} from "react-router-dom";
import { ByggleadsContext } from "../../providers/ByggleadsProvider";
import { isTimestamp, formatDate } from "../../utils/Functions";
import { framerPropsTitleStack, scrollAreaProps } from "../../utils/Globals";
import useStateEffect from "../../utils/hooks/useStateEffect";
import { ByggNotification, NotificationConfig } from "../../utils/Types";
import EmptyScreen from "../EmptyScreen";
import {
  IconNewLead,
  IconNewAssigned,
  IconPulse,
  IconChat,
  IconNotificationsFilled,
  IconNew,
  IconAll,
} from "../Icons";
import { v4 as uuid } from "uuid";
import PopoverHeader from "../PopoverHeader";
import { useResizeObserver } from "@mantine/hooks";

const useStyles = createStyles((theme, _params, getRef) => ({
  inlineBadgeWrapper: {
    display: "inline-flex",
  },

  inlineBadge: {
    transform: "translateY(-1px)",
  },

  loadMoreButton: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    zIndex: 6
  },
}));

const NotificationBox = () => {
  const { classes } = useStyles();
  const { user, notifications } = useContext(ByggleadsContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const opened = searchParams.get("notif");
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<"tab-unread" | "tab-read">(
    "tab-unread"
  );
  const [allNotifications, setAllNotifications] = useState<ByggNotification[]>(
    []
  );
  const [notificationLimit, setNotificationLimit] = useState(0);
  const [loadMoreRef, loadMoreRect] = useResizeObserver();
  const [limitedNotifications, setLimitedNotifications] = useStateEffect<
    ByggNotification[]
  >(
    notifications,
    () => {
      if (activeTab === "tab-unread") {
        setLimitedNotifications(notifications);
      } else {
        if (!notificationLimit) {
          setNotificationLimit(15);
        }
        setLimitedNotifications(allNotifications);
      }
    },
    [notifications, activeTab, allNotifications]
  );

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!notificationLimit || !user?.ref) return;
    setLoading(true);

    const listener = onSnapshot(
      query(
        collection(getFirestore(), user.ref.path, "notifications"),
        orderBy("timeCreated", "desc"),
        limit(notificationLimit)
      ),
      (snapshot) => {
        const tempNotifications = snapshot.docs.map(
          (e) => ({ ...e.data(), ref: e.ref } as ByggNotification)
        );
        setAllNotifications(tempNotifications);
        setLoading(false);
      },
      (err) => {
        console.error(err);
        setLoading(false);
      }
    );

    return () => {
      listener();
    };
  }, [notificationLimit]);

  const notificationCount = notifications.filter(
    (e) => e.seen === false
  ).length;

  const [dismissAllLoading, setDismissAllLoading] = useState(false);
  const dismissAll = async () => {
    if (dismissAllLoading) return;
    setDismissAllLoading(true);

    const params: Partial<ByggNotification> = {
      opened: true,
      seen: true,
    };
    const batch: WriteBatch = writeBatch(getFirestore());

    limitedNotifications.forEach((notification) => {
      if (!notification.ref) return;
      batch.update(notification.ref, params);
    });

    await batch.commit().catch((err) => {
      console.error(err);
    });

    setDismissAllLoading(false);
  };

  return (
    <Popover
      opened={!!opened}
      onClose={() => {
        searchParams.delete("notif");
        setSearchParams(searchParams);
      }}
      position="bottom"
      transition="pop"
      width={320}
      withinPortal
    >
      <Popover.Target>
        <ActionIcon
          size="lg"
          color={notifications.length ? "indigo" : "gray"}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            position: "relative",
          })}
          onClick={() => {
            opened
              ? searchParams.delete("notif")
              : searchParams.append("notif", "true");
            setSearchParams(searchParams);
          }}
        >
          <IconNotificationsFilled side={18} />
          <AnimatePresence exitBeforeEnter>
            <motion.div
              style={{
                position: "absolute",
                pointerEvents: "none",
                top: -4,
                right: -4,
              }}
              key={notificationCount > 9 ? 10 : notificationCount}
              {...badgeFramerProps}
            >
              {notificationCount ? (
                <Badge
                  color="red"
                  variant="filled"
                  style={{
                    padding: 0,
                    width: 20,
                    height: 20,
                  }}
                >
                  {notificationCount > 9 ? "9+" : notificationCount}
                </Badge>
              ) : undefined}
            </motion.div>
          </AnimatePresence>
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown p={0}>
        <PopoverHeader
          label="Notifikationer"
          onClose={() => {
            searchParams.delete("notif");
            setSearchParams(searchParams);
          }}
          parentPaddingY={0}
          parentPaddingX={0}
          paddingY="sm"
          paddingX={"sm"}
        />
        <Tabs
          value={activeTab}
          onTabChange={(value: "tab-unread" | "tab-read") =>
            setActiveTab(value)
          }
        >
          <Tabs.List grow>
            <Tabs.Tab value="tab-unread" icon={<IconNew side={14} />}>
              Olästa
            </Tabs.Tab>
            <Tabs.Tab value="tab-read" icon={<IconAll side={14} />}>
              Alla
            </Tabs.Tab>
          </Tabs.List>
        </Tabs>
        <ScrollArea
          style={{
            height: 400,
          }}
          styles={{
            viewport: {
              "> div": {
                minHeight: "100%",
                position: "relative",
              },
            },
            scrollbar: {
              zIndex: 5,
            },
          }}
          {...scrollAreaProps}
        >
          <Stack spacing={0}>
            <AnimatePresence exitBeforeEnter>
              {limitedNotifications.length > 0 ? (
                <motion.div
                  key={`${activeTab} ${limitedNotifications.length}`}
                  {...framerPropsTitleStack}
                >
                  {limitedNotifications.map((row) => {
                    return (
                      <NotificationRow
                        key={row.ref?.id || uuid()}
                        row={row}
                        navigate={navigate}
                      />
                    );
                  })}
                  {activeTab === "tab-read" &&
                  limitedNotifications.length === notificationLimit ? (
                    <Button
                      variant="subtle"
                      fullWidth
                      onClick={() => {
                        setNotificationLimit((e) => e + 15);
                      }}
                      loading={loading}
                    >
                      Visa fler
                    </Button>
                  ) : null}
                  {activeTab === "tab-unread" && (
                    <Space h={loadMoreRect.height + loadMoreRect.y * 2}/>
                  )}
                </motion.div>
              ) : (
                <motion.div
                  key={`${activeTab} EMPTY`}
                  {...framerPropsTitleStack}
                >
                  <EmptyScreen />
                </motion.div>
              )}
            </AnimatePresence>
          </Stack>
        </ScrollArea>
        <AnimatePresence>
          {activeTab === "tab-unread" ? (
            <motion.div
              key={"BOTTOM_TOOLBAR_UNREAD"}
              {...framerPropsTitleStack}
              className={classes.loadMoreButton}
            >
              <Group
                p={"xs"}
                position="right"
                sx={(theme) => ({
                  borderTop: `solid 1px ${
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[5]
                      : theme.colors.gray[2]
                  }`,
                })}
                ref={loadMoreRef}
              >
                <Button
                  compact
                  variant="subtle"
                  color="red"
                  loading={dismissAllLoading}
                  onClick={dismissAll}
                  disabled={!limitedNotifications.length}
                >
                  Avfärda alla
                </Button>
              </Group>
            </motion.div>
          ) : (
            <motion.div
              key={"BOTTOM_TOOLBAR_READ"}
              {...framerPropsTitleStack}
            ></motion.div>
          )}
        </AnimatePresence>
      </Popover.Dropdown>
    </Popover>
  );
};

const NotificationRow = React.memo(
  ({
    row,
    navigate,
  }: {
    row: ByggNotification;
    navigate: NavigateFunction;
  }) => {
    const icon = useMemo(() => {
      // @ts-ignore
      const typeValue = NotificationConfig[row.type];
      switch (typeValue) {
        case NotificationConfig.new_lead:
          return {
            icon: <IconNewLead side={16} />,
            color: "teal",
          };
        case NotificationConfig.new_assigned:
          return {
            icon: <IconNewAssigned side={16} />,
            color: "grape",
          };
        case NotificationConfig.status_changed:
          return {
            icon: <IconPulse side={16} />,
            color: "pink",
          };
        case NotificationConfig.new_comment:
          return {
            icon: <IconChat side={16} />,
            color: "indigo",
          };
        default:
          break;
      }
      return;
    }, [row]);

    return (
      <Group
        noWrap
        px={"sm"}
        py="xs"
        align="flex-start"
        position="apart"
        sx={(theme) => ({
          cursor: "pointer",
          backgroundColor:
            theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,

          ".dismiss": {
            opacity: 0,
          },

          "&:hover": {
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[8]
                : theme.colors.gray[1],

            ".dismiss": {
              opacity: 1,
            },
          },
        })}
        onMouseEnter={() => {
          if (row.ref) {
            const params: Partial<ByggNotification> = {
              seen: true,
            };
            updateDoc(row.ref, params);
          }
        }}
        onClick={() => {
          if (!row.intent) return;
          if (row.intent.charAt(0) === "/") {
            navigate(row.intent);
          } else {
            window.open(row.intent, "_blank");
          }

          if (row.ref) {
            const params: Partial<ByggNotification> = {
              opened: true,
            };
            updateDoc(row.ref, params);
          }
        }}
      >
        <Stack spacing={0}>
          {row.title && (
            <Text size="xs" weight={500}>
              <NotificationBadge row={row} />
              {row.title}
            </Text>
          )}
          <Text size="xs">
            {!row.title && <NotificationBadge row={row} />}
            {row.body}
          </Text>
          <Group spacing="xs">
            {isTimestamp(row.timeCreated) ? (
              <>
                <Text size="xs" color="dimmed">
                  {formatDate(row.timeCreated.toDate(), "relative_general")}
                </Text>
              </>
            ) : undefined}
            {!row.opened && (
              <>
                <Text size="xs" color="dimmed" className="dismiss" weight={500}>
                  ·
                </Text>
                <Anchor
                  size="xs"
                  color="red"
                  className="dismiss"
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                    if (row.ref) {
                      const params: Partial<ByggNotification> = {
                        opened: true,
                        seen: true,
                      };
                      updateDoc(row.ref, params);
                    }
                  }}
                >
                  Avfärda
                </Anchor>
              </>
            )}
          </Group>
        </Stack>
        {icon && (
          <ThemeIcon variant="light" color={icon.color}>
            {icon.icon}
          </ThemeIcon>
        )}
      </Group>
    );
  }
);

const NotificationBadge = React.memo(({ row }: { row: ByggNotification }) => {
  const { classes } = useStyles();
  return (
    <AnimatePresence exitBeforeEnter>
      {!row.seen ? (
        <motion.span
          key="new"
          className={classes.inlineBadgeWrapper}
          {...badgeFramerProps}
        >
          <Badge
            component="span"
            size="xs"
            color="teal"
            mr={4}
            className={classes.inlineBadge}
          >
            Ny!
          </Badge>
        </motion.span>
      ) : !row.opened ? (
        <motion.span
          key="opened"
          className={classes.inlineBadgeWrapper}
          {...badgeFramerProps}
        >
          <Badge
            component="span"
            size="xs"
            color="yellow"
            mr={4}
            className={classes.inlineBadge}
          >
            Oöppnad
          </Badge>
        </motion.span>
      ) : (
        <span key="nothing"></span>
      )}
    </AnimatePresence>
  );
});

const badgeFramerProps = {
  initial: {
    scale: 0.75,
    opacity: 0,
  },
  animate: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "tween",
      ease: "easeOut",
    },
  },
  exit: {
    scale: 0.75,
    opacity: 0,
    transition: {
      duration: 0.1,
      type: "tween",
      ease: "easeIn",
    },
  },
};

export default NotificationBox;
