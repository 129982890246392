import {
  ActionIcon,
  Avatar,
  Button,
  Card,
  Container,
  createStyles,
  Drawer,
  Group,
  Menu,
  Popover,
  ScrollArea,
  Select,
  SelectItem,
  Skeleton,
  Stack,
  Table,
  Text,
  Title,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import { motion } from 'framer-motion';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ByggleadsContext } from '../../providers/ByggleadsProvider';
import { ErrorCodes } from '../../utils/ErrorCodes';
import {
  framerPropsContentStack,
  framerPropsTitleStack,
  scrollAreaProps,
} from '../../utils/Globals';
import { ByggInvite, ByggUser, ByggWorkspaceMember } from '../../utils/Types';
import {
  getFirestore,
  where,
  query,
  collection,
  doc,
  updateDoc,
  arrayRemove,
  getDocs,
  arrayUnion,
  serverTimestamp,
  addDoc,
  refEqual,
} from 'firebase/firestore';
import {
  IconAdAccounts,
  IconCampaignsGroup,
  IconDeleteUser,
  IconEdit,
  IconLeave,
  IconMenu,
  IconSubscription,
  IconTrash,
} from '../../components/Icons';
import {
  getAvatarURL,
  getInitialsAvatar,
  isPermitted,
} from '../../utils/Functions';
import { useModals } from '@mantine/modals';
import TextConfirmModal from '../../components/Modals/TextConfirmModal';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { showNotification } from '@mantine/notifications';
import WorkspaceEdit from './WorkspaceEdit';
import { v4 as uuid } from 'uuid';
import EmailInputModal from '../../components/Modals/EmailInputModal';
import NotificationsSettings from './NotificationsSettings';
import useStateEffect from '../../utils/hooks/useStateEffect';
import { find, isArray } from 'lodash';
import AdAccountsModal from './AdAccountsModal';
import CampaignsModal from './CampaignsModal';

const useStyles = createStyles((theme, _params, getRef) => ({
  titleStack: {
    justifyContent: 'flex-start',
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    borderBottom: 'solid 1px',
    borderColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[5]
        : theme.colors.gray[2],
  },
  heroImage: {
    width: 48,
    height: 48,

    '> img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
  dangerBanner: {
    borderColor:
      theme.colorScheme === 'dark'
        ? `${theme.colors.red[8]}25`
        : theme.colors.red[1],
    borderWidth: 2,
  },
  tableArea: {
    width: '100%',
  },
  dimmedButton: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[2]
        : theme.colors.gray[6],

    '&:hover': {
      color: 'inherit',
    },
  },
  sectionHeader: {
    borderBottomColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[5]
        : theme.colors.gray[2],
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    marginInline: -theme.spacing.lg,
    paddingInline: theme.spacing.lg,
  },

  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: theme.spacing.sm,
  },
}));

const WorkspaceDashboard = () => {
  const { classes } = useStyles();
  const { workspaces, workspace, role } = useContext(ByggleadsContext);

  const [workspaceOptions, setWorkspaceOptions] = useStateEffect<SelectItem[]>(
    [],
    () => {
      if (!isArray(workspaces)) return;
      setWorkspaceOptions(
        workspaces.map((workspace): SelectItem => {
          return {
            label: workspace.name,
            value: workspace.ref!.path,
          };
        })
      );
    },
    [workspaces]
  );

  const [editWorkspace, setEditWorkspace] = useState(false);

  return (
    <>
      <Stack spacing={0} className={classes.titleStack} p={'md'}>
        <motion.div {...framerPropsTitleStack}>
          <Group align={'center'} position="apart">
            <Title order={2}>Arbetsplats</Title>
            <WorkspaceSelector
              setEditWorkspace={setEditWorkspace}
              workspaces={workspaceOptions}
            />
          </Group>
        </motion.div>
      </Stack>
      <Container size={'lg'} py="md">
        <motion.div {...framerPropsContentStack}>
          <Stack spacing={'xl'}>
            <MembersSection />
            {isPermitted(['admin', 'superadmin'], role) && (
              <NotificationsSettings />
            )}
          </Stack>
        </motion.div>
      </Container>
      <Drawer
        opened={editWorkspace}
        onClose={() => setEditWorkspace(false)}
        withCloseButton={false}
        padding={0}
        size="xl"
        transitionDuration={500}
      >
        <WorkspaceEdit
          workspaceRef={workspace?.ref}
          closeAction={() => setEditWorkspace(false)}
        />
      </Drawer>
    </>
  );
};

export default WorkspaceDashboard;

const WorkspaceSelector = ({
  workspaces,
  setEditWorkspace,
}: {
  workspaces: SelectItem[];
  setEditWorkspace: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { theme } = useStyles();
  const { user, workspace, role } = useContext(ByggleadsContext);
  const navigate = useNavigate();
  const modals = useModals();

  const setAsOrganization = async (event: string) => {
    if (!workspaces.some((e) => e.value === event)) return;
    if (!user?.ref) return;
    const ref = doc(getFirestore(), event);

    await updateDoc(user.ref, {
      workspace: ref,
    });
  };
  return (
    <>
      {workspace && (
        <Group noWrap spacing={'xs'}>
          <Select
            transition={'pop'}
            transitionDuration={80}
            transitionTimingFunction="ease"
            data={workspaces}
            value={user?.workspace?.path || ''}
            creatable
            shouldCreate={() => true}
            getCreateLabel={() => '+ Skapa arbetsplats'}
            onCreate={() => {
              navigate('/workspace/create');
              return '';
            }}
            onChange={setAsOrganization}
            variant="filled"
          />
          <Menu
            withArrow
            width={300}
            transition="pop"
            transitionDuration={80}
            withinPortal
          >
            <Menu.Target>
              <ActionIcon size="lg">
                <IconMenu
                  side={14}
                  color={
                    theme.colorScheme === 'dark'
                      ? theme.colors.gray[4]
                      : theme.colors.gray[7]
                  }
                />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item style={{ pointerEvents: 'none' }}>
                <Group>
                  <Avatar radius="xl" src={getInitialsAvatar(workspace.name)} />

                  <div>
                    <Text weight={500}>{workspace.name}</Text>
                    <Text size="xs" color="dimmed">
                      {workspace.registration}
                    </Text>
                  </div>
                </Group>
              </Menu.Item>

              <Menu.Divider />

              <Menu.Label>Inställningar</Menu.Label>
              <Menu.Item
                icon={<IconEdit side={14} />}
                onClick={() => setEditWorkspace(true)}
              >
                Redigera arbetsplats
              </Menu.Item>
              <Menu.Item icon={<IconSubscription side={14} />} disabled>
                Hantera medlemsskap
              </Menu.Item>

              {isPermitted(['superadmin'], role) && (
                <>
                  <Menu.Divider />

                  <Menu.Label>Annonskampanjer</Menu.Label>
                  <Menu.Item
                    icon={<IconAdAccounts side={14} />}
                    onClick={() => {
                      const id = modals.openModal({
                        children: (
                          <AdAccountsModal
                            onClose={() => {
                              modals.closeModal(id);
                            }}
                          />
                        ),
                        padding: 0,
                        withCloseButton: false,
                      });
                    }}
                  >
                    Annonskonton
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconCampaignsGroup side={14} />}
                    onClick={() => {
                      const id = modals.openModal({
                        children: (
                          <CampaignsModal
                            onClose={() => {
                              modals.closeModal(id);
                            }}
                          />
                        ),
                        padding: 0,
                        withCloseButton: false,
                        size: 'xl',
                      });
                    }}
                  >
                    Kampanjer
                  </Menu.Item>
                </>
              )}

              <Menu.Divider />

              <Menu.Label>Farozon</Menu.Label>
              <Menu.Item
                icon={<IconLeave side={14} />}
                onClick={() => {
                  modals.openConfirmModal({
                    title: 'Vill du verkligen lämna arbetsplatsen?',
                    centered: true,
                    labels: {
                      confirm: 'Ja, lämna',
                      cancel: 'Nej, avbryt',
                    },
                    confirmProps: { color: 'red' },
                    onConfirm: async () => {
                      if (!workspace?.ref || !user?.ref) {
                        return;
                      }
                      const userRef = user.ref;
                      await updateDoc(workspace.ref, {
                        members: arrayRemove(userRef),
                        admins: arrayRemove(userRef),
                      });

                      const workspaces = await getDocs(
                        query(
                          collection(getFirestore(), 'workspaces'),
                          where('members', 'array-contains', userRef)
                        )
                      );

                      await updateDoc(userRef, {
                        workspace: workspaces.size
                          ? workspaces.docs[0].ref
                          : null,
                      });
                    },
                  });
                }}
              >
                Lämna arbetsplats
              </Menu.Item>
              {isPermitted(['admin', 'superadmin'], role) && (
                <Menu.Item
                  color="red"
                  icon={<IconTrash side={14} />}
                  onClick={() => {
                    const id = modals.openModal({
                      title: 'Vill du verkligen ta bort arbetsplatsen?',
                      children: (
                        <TextConfirmModal
                          control={workspace?.name || ''}
                          message={
                            <Text size="sm">
                              All data tillhörande denna arbetsplats kommer
                              försvinna för gott. Det innefattar bland annat
                              leads och kundinformation. <br /> <br />
                              <Text
                                component="span"
                                weight={700}
                                color="red"
                                size="sm"
                              >
                                Denna åtgärd går inte att ångra.
                              </Text>{' '}
                              Är du säker på att du vill göra detta?
                            </Text>
                          }
                          onConfirm={async () => {
                            const remove = httpsCallable(
                              getFunctions(undefined, 'europe-west1'),
                              'destroyWorkspace'
                            );
                            await remove({
                              path: workspace?.ref?.path,
                            }).catch((err) => {
                              showNotification({
                                message: ErrorCodes.standard.message,
                                color: 'red',
                              });
                              console.error(err);
                            });
                            modals.closeModal(id);
                          }}
                          confirmText="Ja, ta bort"
                          onCancel={() => modals.closeModal(id)}
                          cancelText="Nej, avbryt"
                        />
                      ),
                    });
                  }}
                >
                  Radera arbetsplats
                </Menu.Item>
              )}
            </Menu.Dropdown>
          </Menu>
          <Group position="center"></Group>
        </Group>
      )}
    </>
  );
};

const MembersSection = () => {
  const { classes } = useStyles();
  const { members } = useContext(ByggleadsContext);

  const loadingRows = ['', '', ''].map(() => (
    <tr key={uuid()}>
      <td>
        <Group spacing="sm" noWrap>
          <Skeleton height={38} width={38} circle />
          <div style={{ width: '70%' }}>
            <Skeleton height={14} radius="xl" />
            <Skeleton height={10} width="70%" radius="xl" mt={'xs'} />
          </div>
        </Group>
      </td>

      <td>
        <Skeleton height={24} radius="xs" />
      </td>
      <td>
        <Skeleton height={28} width={28} radius="xs" />
      </td>
    </tr>
  ));

  return (
    <Card withBorder radius="sm" p="md">
      <Card.Section className={classes.section}>
        <Text size="lg" weight={500}>
          Medlemmar
        </Text>
      </Card.Section>
      <Card.Section>
        <ScrollArea style={{ width: '100%' }} {...scrollAreaProps}>
          <Table sx={{ minWidth: 800 }}>
            <thead>
              <tr>
                <th>Medlem</th>
                <th>Roll</th>
                <th>Åtgärder</th>
              </tr>
            </thead>
            <tbody>
              {members.length ? (
                <>
                  {members.map((item) => (
                    <MemberRow item={item} />
                  ))}
                  <AddUserRow />
                </>
              ) : (
                loadingRows
              )}
            </tbody>
          </Table>
        </ScrollArea>
      </Card.Section>
    </Card>
  );
};

const MemberRow = ({ item }: { item: ByggWorkspaceMember }) => {
  const { workspace, user, role } = useContext(ByggleadsContext);
  const [memberRole, setMemberRole] = useState(item.role)

  const roleOptions: SelectItem[] = [
    {
      label: 'Medlem',
      value: 'user',
    },
    {
      label: 'Administratör',
      value: 'admin',
    },
  ];

  return (
    <tr key={item.user.uid + item.role}>
      <td>
        <Group spacing="sm">
          <Avatar size={'md'} src={getAvatarURL(item.user)} radius={40} />
          <div>
            <Text size="sm" weight={500}>
              {item.user.name}
            </Text>
            <Text size="xs" color="dimmed">
              {item.user.email}
            </Text>
          </div>
        </Group>
      </td>

      <td>
        <Select
          data={roleOptions}
          defaultValue={item.role}
          variant="unstyled"
          disabled={!isPermitted(['admin', 'superadmin'], role)}
          transition="pop"
          value={memberRole}
          onChange={(value: "admin" | "user") => {
            setMemberRole(value);
            if (workspace?.ref && item.user.ref) {
              const userRef = item.user.ref;
              if (value === 'admin' && item.role !== 'admin') {
                updateDoc(workspace?.ref, {
                  admins: arrayUnion(userRef),
                });
              }
              if (value === 'user' && item.role !== 'user') {
                updateDoc(workspace?.ref, {
                  admins: arrayRemove(userRef),
                });
              }
            }
          }}
        />
      </td>
      <td>
        {isPermitted(['admin', 'superadmin'], role) &&
          user?.uid !== item.user.uid && (
            <Tooltip label="Ta bort medlem" withArrow transition="pop">
              <RemoveUserButton user={item.user} />
            </Tooltip>
          )}
      </td>
    </tr>
  );
};

const RemoveUserButton = ({ user }: { user: ByggUser }) => {
  const { theme } = useStyles();
  const { workspace } = useContext(ByggleadsContext);

  const [confirmRemove, setConfirmRemove] = useState(false);
  const [color, setColor] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  return (
    <Popover
      opened={confirmRemove}
      onClose={() => setConfirmRemove(false)}
      position="bottom"
      withArrow
      withinPortal
      transition="pop"
      transitionDuration={80}
    >
      <Popover.Target>
        <ActionIcon
          onClick={() => setConfirmRemove((p) => !p)}
          size="lg"
          loading={loading}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
          })}
          onMouseEnter={() => setColor(theme.colors.red[5])}
          onMouseLeave={() => setColor(undefined)}
        >
          <IconDeleteUser side={18} color={color} />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <Group position="right" spacing="xs">
          <Text
            weight={500}
            size="sm"
            mr={'sm'}
            style={{
              color:
                theme.colorScheme === 'dark'
                  ? theme.colors.dark[0]
                  : theme.black,
            }}
          >
            Ta bort medlem?
          </Text>
          <Button
            compact
            variant="light"
            color="red"
            onClick={async () => {
              setLoading(true);
              const remove = httpsCallable(
                getFunctions(undefined, 'europe-west1'),
                'removeUserFromWorkspace'
              );
              await remove({
                userPath: user.ref.path,
                workspacePath: workspace?.ref?.path,
              }).catch((err) => {
                showNotification({
                  message: ErrorCodes.standard.message,
                  color: 'red',
                });
                console.error(err);
              });
              setLoading(false);
            }}
          >
            Ja
          </Button>
          <Button
            compact
            variant="subtle"
            onClick={() => setConfirmRemove(false)}
          >
            Nej
          </Button>
        </Group>
      </Popover.Dropdown>
    </Popover>
  );
};

const AddUserRow = () => {
  const { classes } = useStyles();
  const modals = useModals();
  const { user, workspace } = useContext(ByggleadsContext);

  return (
    <tr>
      <td>
        <UnstyledButton
          className={classes.dimmedButton}
          onClick={() => {
            const id = modals.openModal({
              title: 'Bjud in till arbetsplats',
              size: 'sm',
              children: (
                <EmailInputModal
                  message="Skriv in användarens e-postadress för att bjuda in till arbetsplatsen. Om det inte redan finns ett konto anknutet till e-postadressen kommer personen få möjlighet att skapa ett konto, och därefter läggas till som medlem."
                  handleSubmit={async (values: { email: string }) => {
                    if (!values.email) {
                      showNotification({
                        message: 'Fyll i en e-postadress',
                        color: 'red',
                      });
                    }
                    if (!user?.ref || !workspace?.ref) {
                      showNotification({
                        message: ErrorCodes.standard.message,
                        color: 'red',
                      });
                      return;
                    }

                    const inviteParams: ByggInvite = {
                      email: values.email,
                      createdBy: user.ref,
                      timeCreated: serverTimestamp(),
                      status: 'pending',
                      emailInviteSent: false,
                    };

                    await addDoc(
                      collection(
                        getFirestore(),
                        'workspaces',
                        workspace.ref.id,
                        'invites'
                      ),
                      inviteParams
                    ).catch((err) => {
                      console.error(err);
                      showNotification({
                        message: ErrorCodes.standard.message,
                        color: 'red',
                      });
                      return;
                    });

                    showNotification({
                      message: `Snyggt! Vi har bjudit in ${values.email} till arbetsplatsen.`,
                    });

                    modals.closeModal(id);
                  }}
                />
              ),
            });
          }}
        >
          <Text size="sm">+ Lägg till medlem</Text>
        </UnstyledButton>
      </td>
    </tr>
  );
};
