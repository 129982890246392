import {
  ActionIcon,
  Anchor,
  Button,
  Card,
  CloseButton,
  createStyles,
  Group,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { arrayUnion, updateDoc } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { IconCross } from "../../components/Icons";
import { ByggleadsContext } from "../../providers/ByggleadsProvider";
import useStateEffect from "../../utils/hooks/useStateEffect";

const useStyles = createStyles((theme, _params, getRef) => ({
  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: theme.spacing.sm,
  },
}));

const AdAccountsModal = ({ onClose }: { onClose: () => void }) => {
  const { classes } = useStyles();
  const { workspace } = useContext(ByggleadsContext);

  const [accounts, setAccounts] = useStateEffect<string[]>(
    [""],
    () => {
      setAccounts(workspace?.adAccounts || [""]);
    },
    [workspace]
  );

  useEffect(() => {
    if (accounts.length === 0) {
      setAccounts([""]);
    }
  }, [accounts]);

  const [loading, setLoading] = useState(false);
  const submit = async () => {
    if (loading || !workspace?.ref) return;
    setLoading(true);

    let cleanedAccounts = accounts.flatMap((account) => {
      if (account) return account;
      return [];
    });

    await updateDoc(workspace.ref, {
      adAccounts: arrayUnion(...cleanedAccounts),
    })
      .then(() => {
        onClose();
      })
      .catch((err) => {
        console.error(err);
      });

    setLoading(false);
  };

  return (
    <Card>
      <Card.Section className={classes.section}>
        <Group position="apart" noWrap>
          <Title order={4}>Annonskonton för {workspace?.name}</Title>
          <CloseButton onClick={onClose} />
        </Group>
      </Card.Section>
      <Card.Section className={classes.section}>
        <Stack spacing={"xs"}>
          {accounts.map((account, index) => {
            let error: string | undefined = undefined;
            if (account.length > 0) {
              if (account.includes("act_")) {
                if (account.length !== 19) {
                  error = "ID:t är felformatterat";
                }
              } else {
                if (account.length !== 15) {
                  error = "ID:t är felformatterat";
                }
              }
            }

            return (
              <Group spacing={4} key={index} align="flex-start">
                <TextInput
                  placeholder="Annonskontots ID"
                  value={account}
                  onChange={(e) => {
                    setAccounts((p) => {
                      let copy = [...p];
                      copy[index] = e.target.value;
                      return copy;
                    });
                  }}
                  error={error}
                  style={{
                    flex: 1,
                  }}
                />
                <ActionIcon
                  size={36}
                  onClick={() => {
                    setAccounts((p) => {
                      let copy = [...p];
                      copy.splice(index, 1);
                      return copy;
                    });
                  }}
                >
                  <IconCross side={16} />
                </ActionIcon>
              </Group>
            );
          })}
          <Anchor size="sm" onClick={() => setAccounts((p) => [...p, ""])}>
            + Lägg till konto
          </Anchor>
          <Text size="xs" color="dimmed">
            ID:t innehåller alltid 15 siffror och kan föregås med prefixet
            "act_"
          </Text>
        </Stack>
      </Card.Section>
      <Card.Section>
        <Group p={"sm"} position="right">
          <Button onClick={submit}>Spara</Button>
        </Group>
      </Card.Section>
    </Card>
  );
};

export default AdAccountsModal;
