import { CloseButton, Group, MantineStyleSystemProps, Text } from "@mantine/core";

const PopoverHeader = ({
  label,
  onClose,
  parentPaddingY = "sm",
  parentPaddingX = "md",
  paddingY,
  paddingX
}: {
  label: string;
  onClose?: () => void;
  parentPaddingY?: MantineStyleSystemProps["p"];
  parentPaddingX?: MantineStyleSystemProps["p"];
  paddingY?: MantineStyleSystemProps["p"];
  paddingX?: MantineStyleSystemProps["p"];
}) => {
  return (
    <Group
      noWrap
      sx={(theme) => ({
        borderBottom: `1px solid ${
          theme.colorScheme === "dark"
            ? theme.colors.dark[5]
            : theme.colors.gray[2]
        }`,
      })}
      px={paddingY === undefined ? parentPaddingX : paddingY}
      py={paddingX === undefined ? parentPaddingY : paddingX}
      mt={"-" + parentPaddingY}
      mx={"-" + parentPaddingX}
      align="center"
      mb={parentPaddingY}
    >
      <Text weight={500} size="sm" style={{ flex: 1 }}>
        {label}
      </Text>
      {onClose && <CloseButton size={"sm"} onClick={onClose} />}
    </Group>
  );
};

export default PopoverHeader;
