import {
  Avatar,
  Badge,
  Box,
  Card,
  createStyles,
  Group,
  MantineProvider,
  Paper,
  Skeleton,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import { refEqual } from 'firebase/firestore';
import { AnimatePresence, motion } from 'framer-motion';
import { isArray } from 'lodash';
import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { StatusArchivedRef } from '../../App';
import { IconAssigned, IconCalendar, IconPulse } from '../../components/Icons';
import { ByggleadsContext } from '../../providers/ByggleadsProvider';
import {
  formatDate,
  getAvatarURL,
  getInitialsAvatar,
  getInitialsAvatarColor,
  isTimestamp,
  randFloatFromSeed,
} from '../../utils/Functions';
import { framerPropsContentStack } from '../../utils/Globals';
import useStateEffect from '../../utils/hooks/useStateEffect';
import { ByggLead } from '../../utils/Types';

const useStyles = createStyles((theme, _params, getRef) => ({
  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: theme.spacing.sm,
  },
}));

const LatestLead = ({ dimLatestLead }: { dimLatestLead: boolean }) => {
  const { leads } = useContext(ByggleadsContext);

  return (
    <AnimatePresence exitBeforeEnter>
      {isArray(leads) && leads[0] ? (
        <motion.div
          {...framerPropsContentStack}
          key={leads[0].ref.id || 'LOADED'}
        >
          <LeadCard dimLatestLead={dimLatestLead} />
        </motion.div>
      ) : (
        <motion.div {...framerPropsContentStack} key="LOADING">
          <LoadingCard />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default LatestLead;

const LeadCard = ({ dimLatestLead }: { dimLatestLead: boolean }) => {
  const { theme } = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const { leads } = useContext(ByggleadsContext);

  const [latestLead, setLatestLead] = useStateEffect<ByggLead | undefined>(
    undefined,
    () => {
      if (!isArray(leads) || !leads[0]) return;
      let tempLead: ByggLead | undefined = undefined;
      leads.forEach((lead) => {
        if (!refEqual(lead.status, StatusArchivedRef) && !tempLead) {
          tempLead = lead;
        }
      });
      setLatestLead(tempLead);
    },
    [leads]
  );

  const [color, setColor] = useStateEffect(
    'indigo',
    () => {
      setColor(
        Object.keys(theme.colors)[
          Math.floor(
            randFloatFromSeed(latestLead?.title) *
              Object.keys(theme.colors).length
          )
        ]
      );
    },
    [latestLead]
  );

  return (
    <Card
      p="md"
      radius="md"
      withBorder={dimLatestLead}
      sx={(theme) => ({
        backgroundColor: dimLatestLead ? undefined : 'transparent',
        border: dimLatestLead ? undefined : 'solid 1px transparent',
        cursor: 'pointer',
        position: 'relative',
        zIndex: 2,
        transition: 'all 0.5s ease',

        '&::after': {
          content: '""',
          backgroundImage: `linear-gradient(150deg, ${theme.colors[color][4]}, ${theme.colors[color][7]})`,
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: -2,
          transition: 'opacity 0.5s ease',
          opacity: dimLatestLead ? 0 : 1,
        },
      })}
      onClick={() => {
        if (!latestLead?.ref.path) return;
        searchParams.append('lead', latestLead.ref.path);
        setSearchParams(searchParams);
      }}
    >
      <Card.Section>
        <Stack
          align="center"
          justify="center"
          spacing={0}
          p={'xl'}
          sx={(theme) => ({
            color: dimLatestLead ? undefined : 'white',
            transition: 'all 0.5s ease',
          })}
        >
          <Avatar
            src={getInitialsAvatar(
              latestLead?.title || '',
              getInitialsAvatarColor(theme, latestLead?.title || '')
            )}
            size="lg"
            mb="lg"
            sx={(theme) => ({
              boxShadow: theme.shadows.md,
            })}
          />
          <Text size="xl" weight={500}>
            {latestLead?.title || ''}
          </Text>
          <Text
            size="xs"
            style={{ opacity: 0.5 }}
            weight={700}
            transform="uppercase"
          >
            Ditt senaste lead
          </Text>
        </Stack>
      </Card.Section>
      <MantineProvider theme={{ colorScheme: 'light' }}>
        <Paper
          p="lg"
          withBorder={dimLatestLead}
          shadow={dimLatestLead ? undefined : 'md'}
          sx={(theme) => ({
            transition: 'all 0.5s ease',
            border: dimLatestLead ? undefined : 'solid 1px transparent',
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'grid',
              gridTemplateColumns: 'max-content auto',
              rowGap: theme.spacing.xs,
              columnGap: theme.spacing.lg,
            })}
          >
            <Group>
              <IconPulse side={16} />
              <Text size="sm" weight={500}>
                Status:
              </Text>
            </Group>
            <Stack align="flex-start">
              <Badge
                style={{ pointerEvents: 'none' }}
                color={latestLead?.render?.status?.color}
              >
                {latestLead?.render?.status?.title}
              </Badge>
            </Stack>
            <Group>
              <IconCalendar side={16} />
              <Text size="sm" weight={500}>
                Skapad:
              </Text>
            </Group>
            <Stack align="flex-start">
              {latestLead && isTimestamp(latestLead.timeCreated) && (
                <Badge style={{ pointerEvents: 'none' }} color={'gray'}>
                  {formatDate(
                    latestLead.timeCreated.toDate(),
                    'relative_general'
                  )}
                </Badge>
              )}
            </Stack>
            <Group>
              <IconAssigned side={16} />
              <Text size="sm" weight={500}>
                Tilldelad:
              </Text>
            </Group>
            <Stack align="flex-start">
              <Group spacing={0} noWrap>
                {latestLead?.render?.assigned?.length ? (
                  latestLead.render.assigned.map((member, index) => {
                    return (
                      <Tooltip
                        label={member.user.name}
                        key={member.user.uid}
                        withArrow
                        transition={'pop'}
                      >
                        <Avatar
                          src={getAvatarURL(member.user)}
                          size={20}
                          radius={'xl'}
                          style={{
                            marginLeft: -(Math.min(index, 1) * 4),
                            boxShadow: `0 0 0 3px white`,
                          }}
                        />
                      </Tooltip>
                    );
                  })
                ) : (
                  <Text color="dimmed" size="sm">
                    —
                  </Text>
                )}
              </Group>
            </Stack>
          </Box>
        </Paper>
      </MantineProvider>
    </Card>
  );
};

const LoadingCard = () => {
  return (
    <Card withBorder p="md" radius="md">
      <Card.Section>
        <Stack align="center" justify="center" spacing={0} p={'xl'}>
          <Skeleton width={56} height={56} mb="lg" />
          <Skeleton width={156} height={24} mb={8} />
          <Skeleton width={120} height={18} />
        </Stack>
      </Card.Section>
      <Paper p="lg" withBorder>
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateColumns: 'max-content auto',
            rowGap: theme.spacing.xs,
            columnGap: theme.spacing.lg,
          })}
        >
          <Skeleton width={96} height={20} />
          <Skeleton width={256} height={20} />
          <Skeleton width={96} height={20} />
          <Skeleton width={256} height={20} />
          <Skeleton width={96} height={20} />
          <Skeleton width={256} height={20} />
        </Box>
      </Paper>
    </Card>
  );
};
