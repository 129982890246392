import React, { useContext, useEffect, useState } from "react";
import {
  AppShell,
  Header,
  MediaQuery,
  Burger,
  Group,
  Paper,
  createStyles,
  Anchor,
  ScrollArea,
  Center,
} from "@mantine/core";
import { AnimatePresence, motion } from "framer-motion";
import { getFramerMotionProps, scrollAreaProps } from "../../utils/Globals";
import {
  IconAdminSettingsFilled,
  IconAdminSettingsOutlined,
  IconCampaignsFilled,
  IconCampaignsOutlined,
  IconHomeFilled,
  IconHomeOutlined,
  IconLeadsFilled,
  IconLeadsOutlined,
  IconWorkspaceFilled,
  IconWorkspaceOutlined,
  LogoFull,
} from "../Icons";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useNavigationType,
  useSearchParams,
} from "react-router-dom";
import Page404 from "../../pages/404";
import Nav from "./Nav";
import WorkspaceDashboard from "../../pages/workspace/WorkspaceDashboard";
import WorkspaceCreate from "../../pages/workspace/WorkspaceCreate";
import AdminSettings from "../../pages/admin/AdminSettings";
import LeadsDashboard from "../../pages/leads/LeadsDashboard";
import { useModals } from "@mantine/modals";
import {
  ByggleadsContext,
  ByggleadsProps,
} from "../../providers/ByggleadsProvider";
import { ByggInvite, ByggWorkspace, ByggUser } from "../../utils/Types";
import HandleInviteModal from "../Modals/HandleInviteModal";
import {
  getFirestore,
  onSnapshot,
  collectionGroup,
  query,
  where,
  orderBy,
  getDoc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import CampaignsDashboard from "../../pages/campaigns/CampaignsDashboard";
import AllowNotificationsModal from "../Modals/AllowNotificationsModal";
import { Unsubscribe, isSupported } from "firebase/messaging";
import { onMessageListener, requestFCMToken } from "../../firebase/firebase";
import NotificationBox from "./NotificationBox";
import Dashboard from "../../pages/start/Dashboard";
import LeadDetails from "../LeadDetails/LeadDetails";
import { doc } from "firebase/firestore";
import useStateEffect from "../../utils/hooks/useStateEffect";
import BulletinModal from "../Modals/BulletinModal";
import ErrorModal from "../Modals/ErrorModal";
import { get } from "lodash";
import { ByggError, ErrorCodes } from "../../utils/ErrorCodes";
import { hideNotification, showNotification } from "@mantine/notifications";
import { v4 as uuid } from "uuid";

const useStyles = createStyles((theme, _params, getRef) => ({
  footer: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  section: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    marginBottom: theme.spacing.md,

    "&:not(:last-of-type)": {
      borderBottom: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[2]
      }`,
    },
  },
}));

const Layout = () => {
  const { theme } = useStyles();
  const navigationType = useNavigationType();
  const navigate = useNavigate();
  const location = useLocation();
  const [opened, setOpened] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const modals = useModals();

  const { role, alerts, workspace, user } = useContext(ByggleadsContext);

  const [routes, setRoutes] = useStateEffect<ByggRoute[]>(
    constructRoutes(role, alerts, workspace),
    () => {
      setRoutes(constructRoutes(role, alerts, workspace));
    },
    [role, alerts, workspace]
  );

  useEffect(() => {
    if (!user?.email) return;
    const modalIDs: string[] = [];

    const listener = onSnapshot(
      query(
        collectionGroup(getFirestore(), "invites"),
        where("email", "==", user.email),
        where("status", "==", "pending"),
        orderBy("timeCreated", "desc")
      ),
      (snapshot) => {
        snapshot.docs.forEach((snapshot) => {
          const invite = snapshot.data() as ByggInvite;
          const inviteRef = snapshot.ref;
          const workspaceRef = snapshot.ref.parent.parent;
          if (!workspaceRef) return;
          getDoc(workspaceRef).then((snapshot) => {
            const workspace = snapshot.data() as ByggWorkspace;

            getDoc(invite.createdBy).then((snapshot) => {
              let invitedBy = snapshot.data() as ByggUser;
              invitedBy.ref = snapshot.ref;

              const id = modals.openModal({
                children: (
                  <HandleInviteModal
                    invite={invite}
                    workspace={workspace}
                    invitedBy={invitedBy}
                    inviteRef={inviteRef}
                    handleClose={() => modals.closeModal(id)}
                  />
                ),
                padding: 0,
                withCloseButton: false,
                centered: true,
              });
              modalIDs.push(id);
            });
          });
        });
      },
      (err) => {
        console.error(err);
      }
    );

    return () => {
      listener();
      modalIDs.forEach((id) => modals.closeModal(id));
    };
  }, [user]);

  useEffect(() => {
    if (!user) return;
    const userRef = user.ref;
    if (user?.notifications) {
      let id = uuid();
      let listener: Unsubscribe | (() => void) = () => {};
      const DO_NOT_SHOW_KEY = "do_not_show_push_alert";
      isSupported().then((result) => {
        if (!result) return;
        requestFCMToken().then((token) => {
          if (token) {
            updateDoc(userRef, {
              fcmTokens: arrayUnion(token),
            });

            listener = onMessageListener(navigate);
          } else {
            if (!localStorage.getItem(DO_NOT_SHOW_KEY)) {
              showNotification({
                id,
                title: "Du tillåter inte notiser",
                autoClose: false,
                message: (
                  <>
                    Du har valt att ta emot pushnotiser från Byggleads, men du
                    har inte godkänt detta i din webbläsare.
                    <br />
                    <Anchor
                      inherit
                      onClick={() => {
                        hideNotification(id);
                      }}
                      href="https://www.lifewire.com/manage-push-notifications-in-your-browser-4050414"
                      target="_blank"
                    >
                      Visa hur man gör
                    </Anchor>{" "}
                    <Anchor
                      inherit
                      color="red"
                      onClick={() => {
                        localStorage.setItem(DO_NOT_SHOW_KEY, "true");
                        hideNotification(id);
                      }}
                      align="right"
                      style={{
                        float: "right",
                      }}
                    >
                      Visa inte igen
                    </Anchor>
                  </>
                ),
              });
            }
          }
        });
      });
      return () => {
        hideNotification(id);
        listener();
      };
    }

    const id = modals.openModal({
      children: (
        <AllowNotificationsModal handleClose={() => modals.closeModal(id)} />
      ),
      padding: 0,
      withCloseButton: false,
      centered: true,
    });

    return () => {
      modals.closeModal(id);
    };
  }, [user]);

  const leadPath = searchParams.get("lead");
  useEffect(() => {
    if (!leadPath) return;
    const id = modals.openModal({
      onClose: () => {
        modals.closeModal(id);
        searchParams.delete("lead");
        setSearchParams(searchParams);
      },
      size: 1140,
      children: (
        <LeadDetails
          leadRef={doc(getFirestore(), leadPath)}
          handleClose={() => {
            modals.closeModal(id);
            searchParams.delete("lead");
            setSearchParams(searchParams);
          }}
        />
      ),
      withCloseButton: false,
      padding: 0,
    });

    return () => {
      modals.closeModal(id);
    };
  }, [leadPath]);

  const bulletinPath = searchParams.get("bulletin");
  useEffect(() => {
    if (!bulletinPath) return;
    const bulletinRef = doc(getFirestore(), bulletinPath);

    const id = modals.openModal({
      padding: 0,
      withCloseButton: false,
      onClose: () => {
        modals.closeModal(id);
        searchParams.delete("bulletin");
        setSearchParams(searchParams);
      },
      children: (
        <BulletinModal
          bulletinRef={bulletinRef}
          handleClose={() => {
            modals.closeModal(id);
            searchParams.delete("bulletin");
            setSearchParams(searchParams);
          }}
        />
      ),
      size: "xl",
    });
  }, [bulletinPath]);

  const [firstError, setFirstError] = useStateEffect<string | undefined>(
    "",
    () => {
      setFirstError(
        [
          ...Array.from(alerts.workspace.errors),
          ...Array.from(alerts.campaigns.errors),
          ...Array.from(alerts.leads.errors),
          ...Array.from(alerts.adminSettings.errors),
        ][0]
      );
    },
    [
      alerts.workspace.errors.size,
      alerts.campaigns.errors.size,
      alerts.adminSettings.errors.size,
      alerts.leads.errors.size,
    ]
  );

  useEffect(() => {
    if (!firstError) return;

    const errorData: ByggError | undefined = get(ErrorCodes, firstError);

    if (!errorData || !errorData.mainButton) return;
    if (location.pathname.includes(errorData.mainButton.link)) return;

    modals.closeAll();

    const id = modals.openModal({
      children: (
        <ErrorModal
          handleClose={() => modals.closeModal(id)}
          error={errorData}
        />
      ),
      padding: 0,
      withCloseButton: false,
      centered: true,
      closeOnClickOutside: false,
      styles: () => ({
        root: {
          backdropFilter: "blur(6px)",
        },
      }),
    });

    return () => {
      modals.closeModal(id);
    };
  }, [firstError, location]);

  return (
    <motion.div {...getFramerMotionProps("opacity", navigationType)}>
      <Paper
        style={{
          borderRadius: 0,
        }}
      >
        <AppShell
          styles={{
            main: {
              background:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[8]
                  : theme.colors.gray[0],
            },
          }}
          padding={0}
          navbarOffsetBreakpoint="sm"
          asideOffsetBreakpoint="sm"
          fixed
          navbar={<Nav opened={opened} setOpened={setOpened} routes={routes} />}
          header={
            <Header height={70} p="md">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                  <Burger
                    opened={opened}
                    onClick={() => setOpened((o) => !o)}
                    size="sm"
                    color={theme.colors.dark[3]}
                    mr="xl"
                  />
                </MediaQuery>

                <Link to={"/"}>
                  <Center>
                    <LogoFull side={36} />
                  </Center>
                </Link>

                <Group ml="auto" position="center" my="xl">
                  <NotificationBox />
                </Group>
              </div>
            </Header>
          }
        >
          <Router routes={routes} />
        </AppShell>
      </Paper>
    </motion.div>
  );
};

export default Layout;

const Router = ({ routes }: { routes: ByggRoute[] }) => {
  const location = useLocation();

  return (
    <ScrollArea
      sx={(theme) => ({
        overflowX: "hidden",
        height: "calc(100vh - var(--mantine-header-height, 0px) + 0px)",
        width: "100%",
      })}
      styles={{
        scrollbar: {
          zIndex: 99,
        },
        viewport: {
          "> div": {
            display: "block !important",
          },
        },
      }}
      {...scrollAreaProps}
    >
      <AnimatePresence exitBeforeEnter>
        <Routes location={location} key={location.pathname}>
          {routes.map((route) => {
            return (
              <Route
                path={route.path}
                element={route.element}
                key={route.path}
              />
            );
          })}
        </Routes>
      </AnimatePresence>
    </ScrollArea>
  );
};

export interface ByggRoute {
  label?: string;
  path: string;
  exact?: boolean;
  element: React.ReactNode;
  icon?: {
    filled: React.ReactNode;
    outlined: React.ReactNode;
  };
  alerts?: {
    notifications?: number;
    warnings?: number;
    errors?: number;
  };
}

const constructRoutes = (
  role: ByggleadsProps["role"],
  alerts: ByggleadsProps["alerts"],
  workspace: ByggleadsProps["workspace"]
): ByggRoute[] => {
  let routes: ByggRoute[] = [];
  // Add all default routes here. Also the ones that are not navigatable
  // through the nav sidebar
  routes.push(
    {
      path: "*",
      element: <Page404 />,
    },
    {
      path: "/workspace/create",
      element: <WorkspaceCreate />,
    },
    {
      label: "Hem",
      path: "/",
      exact: true,
      element: <Dashboard />,
      icon: {
        filled: <IconHomeFilled side={20} />,
        outlined: <IconHomeOutlined side={20} />,
      },
      alerts: {
        notifications: alerts.workspace.notifications.size || undefined,
        warnings: alerts.workspace.warnings.size || undefined,
        errors: alerts.workspace.errors.size || undefined,
      },
    }
  );

  // Add navigatable routes here, in the order we want them displayed
  if (role === "superadmin") {
    routes.push({
      label: "Adminverktyg",
      path: "/admin",
      element: <AdminSettings />,
      icon: {
        filled: <IconAdminSettingsFilled side={20} />,
        outlined: <IconAdminSettingsOutlined side={20} />,
      },
      alerts: {
        notifications: alerts.adminSettings.notifications.size || undefined,
        warnings: alerts.adminSettings.warnings.size || undefined,
        errors: alerts.adminSettings.errors.size || undefined,
      },
    });
  }

  if (workspace) {
    routes.push({
      label: "Leads",
      path: "/leads",
      element: <LeadsDashboard />,
      icon: {
        filled: <IconLeadsFilled side={20} />,
        outlined: <IconLeadsOutlined side={20} />,
      },
      alerts: {
        notifications: alerts.leads.notifications.size || undefined,
        warnings: alerts.leads.warnings.size || undefined,
        errors: alerts.leads.errors.size || undefined,
      },
    });
  }

  if (workspace?.campaigns && Object.keys(workspace.campaigns)) {
    routes.push({
      label: "Annonskampanjer",
      path: "/campaigns",
      element: <CampaignsDashboard />,
      icon: {
        filled: <IconCampaignsFilled side={20} />,
        outlined: <IconCampaignsOutlined side={20} />,
      },
      alerts: {
        notifications: alerts.campaigns.notifications.size || undefined,
        warnings: alerts.campaigns.warnings.size || undefined,
        errors: alerts.campaigns.errors.size || undefined,
      },
    });
  }

  routes.push({
    label: "Arbetsplats",
    path: "/workspace",
    element: <WorkspaceDashboard />,
    icon: {
      filled: <IconWorkspaceFilled side={20} />,
      outlined: <IconWorkspaceOutlined side={20} />,
    },
    alerts: {
      notifications: alerts.workspace.notifications.size || undefined,
      warnings: alerts.workspace.warnings.size || undefined,
      errors: alerts.workspace.errors.size || undefined,
    },
  });
  return routes;
};
