import {
  Avatar,
  Button,
  Card,
  Checkbox,
  createStyles,
  Group,
  Text,
  Tooltip,
  TransferList,
  TransferListData,
  TransferListItem,
  TransferListItemComponent,
  TransferListItemComponentProps,
} from '@mantine/core';
import {
  collection,
  doc,
  DocumentReference,
  getFirestore,
  onSnapshot,
  refEqual,
  updateDoc,
} from 'firebase/firestore';
import { orderBy } from 'lodash';
import { useContext, useState } from 'react';
import { ByggleadsContext } from '../../providers/ByggleadsProvider';
import { getAvatarURL } from '../../utils/Functions';
import useStateEffect from '../../utils/hooks/useStateEffect';
import { ByggUser } from '../../utils/Types';

const useStyles = createStyles((theme, _params, getRef) => ({
  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: theme.spacing.sm,

    '&.footer': {
      borderTop: `1px solid ${
        theme.colorScheme === 'dark'
          ? theme.colors.dark[5]
          : theme.colors.gray[2]
      }`,
      borderBottom: 'none',
    },
  },
}));

const AdminSelector = () => {
  const { classes, cx } = useStyles();
  const { user } = useContext(ByggleadsContext);

  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useStateEffect<ByggUser[]>(
    [],
    () => {
      const listener = onSnapshot(
        collection(getFirestore(), 'users'),
        (snapshot) => {
          const tempUsers = snapshot.docs.map(
            (e) => ({ ...e.data(), ref: e.ref } as ByggUser)
          );
          setUsers(tempUsers);
        }
      );
      return () => listener();
    },
    []
  );

  const [admins, setAdmins] = useStateEffect<DocumentReference[]>(
    [],
    () => {
      const listener = onSnapshot(
        doc(getFirestore(), 'platform', 'admins'),
        (snapshot) => {
          setAdmins(snapshot.data()?.admins as DocumentReference[]);
        }
      );

      return () => listener();
    },
    []
  );

  const [originalListData, setOriginalListData] = useState<TransferListData>([
    [],
    [],
  ]);
  const [listData, setListData] = useStateEffect<TransferListData>(
    [[], []],
    () => {
      const allUsers: TransferListItem[] = users.map((e) => ({
        label: `${e.name} ${e.email}`,
        value: e.ref.path,
        user: e,
      }));
      setListData([
        allUsers.filter((e) => !admins.some((a) => a.path === e.value)),
        allUsers.filter((e) => admins.some((a) => a.path === e.value)),
      ]);
      setOriginalListData([
        allUsers.filter((e) => !admins.some((a) => a.path === e.value)),
        allUsers.filter((e) => admins.some((a) => a.path === e.value)),
      ]);
    },
    [users, admins]
  );

  const canSave = (): {
    result: boolean;
    error?: string;
  } => {
    if (!user)
      return {
        result: false,
        error: '',
      };

    if (!listData[1].length) {
      return {
        result: false,
        error: 'Du kan inte ta bort alla administratörer från organisationen.',
      };
    }

    if (
      !listData[1].some((e) =>
        refEqual(doc(getFirestore(), e.value), user.ref)
      )
    ) {
      return {
        result: false,
        error:
          'Du kan inte ta bort dig själv som administratör från organisationen.',
      };
    }

    let prevList = orderBy(originalListData[0], ['value'], ['asc']);
    let currList = orderBy(listData[0], ['value'], ['asc']);

    var result = false;
    currList.forEach((e, index) => {
      if (e.value !== prevList[index]?.value) {
        result = true;
      }
    });

    return {
      result,
    };
  };

  const submit = async () => {
    if (loading || !user?.ref.path) return;
    if (!canSave().result) {
      return
    }
    setLoading(true);

    await updateDoc(doc(getFirestore(), 'platform', 'admins'), {
      admins: listData[1].map((e) => doc(getFirestore(), e.value)),
    }).catch((err) => {
      console.error(err);
    });

    setLoading(false);
  };

  return (
    <Card withBorder radius="sm" p="md">
      <Card.Section className={classes.section}>
        <Group position="apart">
          <Text size="lg" weight={500}>
            Ändra administratörer
          </Text>
          {canSave().error ? (
            <Tooltip
              label={canSave().error}
              withArrow
              color={'red'}
            >
              <Button
                loading={loading}
                onClick={submit}
                disabled={!canSave().result}
              >
                Spara ändringar
              </Button>
            </Tooltip>
          ) : (
            <Button
              loading={loading}
              onClick={submit}
              disabled={!canSave().result}
            >
              Spara ändringar
            </Button>
          )}
        </Group>
      </Card.Section>
      <Card.Section p="md">
        <TransferList
          breakpoint={'sm'}
          value={listData}
          onChange={setListData}
          showTransferAll={false}
          titles={['Användare', 'Administratörer']}
          nothingFound="Inget här"
          searchPlaceholder="Sök..."
          itemComponent={ItemComponent}
        />
      </Card.Section>
    </Card>
  );
};

export default AdminSelector;

const ItemComponent: TransferListItemComponent = ({
  data,
  selected,
}: TransferListItemComponentProps) => {
  return (
    <Group noWrap spacing="xs">
      <Avatar src={getAvatarURL(data.user)} radius="xl" size="sm" />
      <div style={{ flex: 1 }}>
        <Text size="xs" weight={500}>
          {data.user.name}
        </Text>
        <Text size="xs" color="dimmed" weight={400}>
          {data.user.email}
        </Text>
      </div>
      <Checkbox
        checked={selected}
        onChange={() => {}}
        tabIndex={-1}
        sx={{ pointerEvents: 'none' }}
      />
    </Group>
  );
};
