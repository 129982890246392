import {
  getDoc,
  DocumentReference,
  DocumentSnapshot,
  DocumentData,
  Timestamp,
  refEqual,
} from 'firebase/firestore';
import { ByggleadsProps } from '../providers/ByggleadsProvider';
import { ByggBulletin, ByggUser } from './Types';
import moment from 'moment';
import { IconPlatformIG, IconPlatformFB } from '../components/Icons';
import { DefaultMantineColor, MantineTheme } from '@mantine/core';
import { MD5 } from 'crypto-js';
import { find, isArray } from 'lodash';

const fetchedDocuments: DocumentSnapshot[] = [];
export const getDocWithCache = (
  ref: DocumentReference
): Promise<DocumentSnapshot> => {
  return new Promise((resolve, reject) => {
    const filtered = fetchedDocuments.filter((e) => refEqual(e.ref, ref));
    if (filtered.length) {
      resolve(filtered[0]);
      return;
    }
    return getDoc(ref).then((snapshot) => {
      fetchedDocuments.push(snapshot);
      resolve(snapshot);
    });
  });
};

export const getAvatarURL = (user: ByggUser | undefined, seed?: string) => {
  if (user?.photoURL?.w480) {
    return user.photoURL.w480;
  }
  if (user?.photoURL?.fromProvider) {
    return user.photoURL.fromProvider;
  }
  if (user?.uid) {
    return `https://avatars.dicebear.com/api/croodles-neutral/${user.uid}.svg?b=%23ffffff`;
  }
  return `https://avatars.dicebear.com/api/croodles-neutral/${
    seed || 'OKAY'
  }.svg?b=%23ffffff`;
};

export const getInitialsAvatar = (name: string, color?: string) => {
  return `https://avatars.dicebear.com/api/initials/${encodeURI(name)}.svg?${
    color ? '&b=' + encodeURIComponent(color) : ''
  }`;
};

export const getAll = (
  refs: DocumentReference[]
): Promise<DocumentSnapshot<DocumentData>[]> => {
  const promises = refs.map((ref) => {
    return getDoc(ref);
  });
  return Promise.all(promises);
};

export const isPermitted = (
  allowed: ('user' | 'admin' | 'superadmin')[],
  role: ByggleadsProps['role']
) => {
  return allowed.some((e) => e === role);
};

export const cleanUp = (input?: string) => {
  return (input || '').toLowerCase().replace(/[^a-zA-Z0-9]+/g, '_');
};

export const shortenString = (input: string, length = 24, wrap = false) => {
  if (input.length <= length) {
    return input;
  }
  if (!wrap) {
    return input.substring(0, length) + '...';
  }
  return (
    input.substring(0, length / 2) +
    '...' +
    input.substring(input.length - length / 2, input.length)
  );
};

export const reverseClean = (input?: string) => {
  return (input || '').replace(/_/g, ' ');
};

export const capitalizeFirstLetter = (input: string) => {
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export const isTimestamp = (value: any): value is Timestamp => {
  if (value === null || value === undefined) return false;
  return value['seconds'] !== undefined;
};

export const formatDate = (
  date: Date,
  conf:
    | 'calendar_date'
    | 'calendar_datetime'
    | 'numbers_date'
    | 'numbers_datetime'
    | 'hour'
    | 'relative_general'
    | 'relative_exact' = 'numbers_date',
  extraConfig?: {
    sameDay?: string;
    nextDay?: string;
    nextWeek?: string;
    lastDay?: string;
    lastWeek?: string;
    sameElse?: string;
  }
) => {
  switch (conf) {
    case 'relative_exact':
      return moment(date).calendar(null, {
        sameDay: `[${moment(date).fromNow()}]`,
        nextDay: '[imorgon]',
        nextWeek: '[på] dddd',
        lastDay: '[igår] HH:mm',
        lastWeek: `[i] dddd[s] HH:mm`,
        sameElse: 'DD MMMM YYYY [kl.] HH:mm',
        ...extraConfig,
      });
    case 'relative_general':
      return moment(date).calendar(null, {
        sameDay: `[${moment(date).fromNow()}]`,
        nextDay: '[imorgon]',
        nextWeek: '[på] dddd',
        lastDay: '[igår]',
        lastWeek: `[${
          moment(date).diff(moment(new Date()), 'days') * -1 + 1
        } dagar sedan]`,
        sameElse: 'DD MMMM YYYY',
        ...extraConfig,
      });
    case 'calendar_date':
      return moment(date).format('Do MMM YYYY');
    case 'calendar_datetime':
      return moment(date).format('Do MMM YYYY [kl.] HH:mm');
    case 'numbers_date':
      return moment(date).format('YYYY-MM-DD');
    case 'numbers_datetime':
      return moment(date).format('YYYY-MM-DD HH:mm');
    case 'hour':
      return moment(date).format('HH:mm');
  }
};

export const getPlatformBranding = (
  platform: string
):
  | {
      title: string;
      bg?: string;
      icon?: React.ReactNode;
      color?: string;
      bgColor: DefaultMantineColor;
    }
  | undefined => {
  const instagramGradient =
    'radial-gradient(ellipse at 10% 12%, #4168c9e1 0%, #4168c900 60%), radial-gradient(ellipse at 42% 90%, #ffc455 3%, #ff543f 40%, #fc5245 44%, #e64771 63%, #d53e91 70%, #cc39a4 84%, #c837ab 100%)';
  const facebookGradient = 'linear-gradient(135deg, #2aa4f4, #007ad9)';

  switch (platform) {
    case 'ig':
      return {
        title: 'Instagram',
        bg: instagramGradient,
        icon: <IconPlatformIG color="#fff" side={20} />,
        color: '#fff',
        bgColor: 'grape',
      };
    case 'fb':
      return {
        title: 'Facebook',
        bg: facebookGradient,
        icon: <IconPlatformFB color="#fff" side={20} />,
        color: '#fff',
        bgColor: 'blue',
      };
    default:
      return undefined;
  }
};

export const randFloatFromSeed = (seed?: string) => {
  seed = seed ? seed : 'x';
  seed = MD5(seed).toString();
  seed = seed.replace(/\D/g, '');
  seed = '0.' + seed;
  return parseFloat(seed);
};

export const getInitialsAvatarColor = (theme: MantineTheme, name: string) => {
  let color = Object.keys(theme.colors)[
    Math.floor(randFloatFromSeed(name) * Object.keys(theme.colors).length)
  ];
  return theme.colors[color][8];
};

export const getIsUnread = (inputBulletin: ByggBulletin, user: ByggUser) => {
  return !find(isArray(inputBulletin.readBy) ? inputBulletin.readBy : [], (e) =>
    refEqual(e, user.ref)
  );
};
