import {
  createStyles,
  UnstyledButton,
  Group,
  Avatar,
  Badge,
  Tooltip,
  Text,
} from '@mantine/core';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  getInitialsAvatar,
  isTimestamp,
  formatDate,
  getAvatarURL,
  getInitialsAvatarColor,
} from '../../../utils/Functions';
import { ByggLead } from '../../../utils/Types';

const useStyles = createStyles((theme, _params, getRef) => ({
  clickable: {
    cursor: 'pointer',

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
    },

    '&:active': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
      transform: 'translateY(1px)',
    },
  },
}));

const LeadRow = ({
  lead,
  searchQuery,
}: {
  lead: ByggLead;
  searchQuery?: string;
}) => {
  const { classes, theme } = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <UnstyledButton
      component="tr"
      className={classes.clickable}
      onClick={() => {
        searchParams.append('lead', lead.ref.path);
        setSearchParams(searchParams);
      }}
    >
      <td>
        <Group spacing={'xs'}>
          <Avatar
            src={getInitialsAvatar(
              lead.title,
              getInitialsAvatarColor(theme, lead.title)
            )}
            size="sm"
          />
          <Text size="sm" weight={500}>
            {lead.title}
          </Text>
        </Group>
      </td>
      <td>
        {lead.render?.status ? (
          <Badge color={lead.render.status.color}>
            {lead.render.status.title}
          </Badge>
        ) : (
          '—'
        )}
      </td>
      <td>
        {isTimestamp(lead.timeCreated) && (
          <Badge color={'gray'}>
            {formatDate(lead.timeCreated.toDate(), 'relative_general')}
          </Badge>
        )}
      </td>
      <td>
        <Group spacing={0} noWrap>
          {lead.render?.assigned && lead.render.assigned.length ? (
            lead.render.assigned.map((member, index) => {
              return (
                <Tooltip
                  label={member.user.name}
                  key={member.user.uid}
                  withArrow
                  transition={'pop'}
                >
                  <Avatar
                    src={getAvatarURL(member.user)}
                    size="sm"
                    radius={'xl'}
                    style={{
                      marginLeft: -(Math.min(index, 1) * 4),
                      boxShadow: `0 0 0 3px ${
                        theme.colorScheme === 'dark'
                          ? theme.colors.dark[6]
                          : theme.white
                      }`,
                    }}
                  />
                </Tooltip>
              );
            })
          ) : (
            <Text color="dimmed" size="sm">
              —
            </Text>
          )}
        </Group>
      </td>
      {searchQuery && <td>{getMatchingSearch(searchQuery, lead)}</td>}
    </UnstyledButton>
  );
};

export default React.memo(LeadRow);

const getMatchingSearch = (searchQuery: string, lead: ByggLead) => {
  const queryRegExp = new RegExp(`(${searchQuery})`, 'gi');
  let result: React.ReactNode | undefined = undefined;

  if (lead.title.match(queryRegExp)) {
    const parts = lead.title.split(queryRegExp);
    result = (
      <Text size="sm">
        ...titel:{' '}
        {parts.map((part, index) => {
          if (part.match(queryRegExp)) {
            return (
              <Text
                color="yellow"
                component="span"
                key={index}
                weight={500}
                size="sm"
              >
                {part}
              </Text>
            );
          }
          return (
            <Text inherit component="span" key={index}>
              {part}
            </Text>
          );
        })}
      </Text>
    );
  }

  lead.fields.forEach((field) => {
    if (result) return;
    if (!field.value) return;
    if (
      !(typeof field.value === 'string') &&
      !(typeof field.value === 'number')
    )
      return;

    const value = field.value.toString();
    if (value.match(queryRegExp)) {
      const parts = value.split(queryRegExp);
      result = (
        <Text size="sm">
          ...{field.title.toLowerCase()}:{' '}
          {parts.map((part, index) => {
            if (part.match(queryRegExp)) {
              return (
                <Text
                  color="yellow"
                  component="span"
                  key={index}
                  weight={500}
                  size="sm"
                >
                  {part}
                </Text>
              );
            }
            return (
              <Text inherit component="span" key={index}>
                {part}
              </Text>
            );
          })}
          ...
        </Text>
      );
    }
  });

  return result;
};
