import {
  Avatar,
  Badge,
  Box,
  createStyles,
  Divider,
  Paper,
  ScrollArea,
  Skeleton,
  Text,
  Timeline,
} from '@mantine/core';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { formatDate, getAvatarURL, isTimestamp } from '../../utils/Functions';
import { ByggLead, ByggLeadTimelineEntry } from '../../utils/Types';
import { IconAssigned, IconCreate, IconPulse } from '../Icons';
import {
  getFirestore,
  onSnapshot,
  query,
  orderBy,
  collection,
  refEqual,
  doc,
} from 'firebase/firestore';
import {
  ByggleadsContext,
  ByggleadsProps,
} from '../../providers/ByggleadsProvider';
import { find, isArray } from 'lodash';
import { useResizeObserver } from '@mantine/hooks';
import useStateEffect from '../../utils/hooks/useStateEffect';

interface ByggLeadTimelineDisplay {
  title: React.ReactNode;
  message: React.ReactNode;
  timeCreated: Date;
  icon: React.ReactNode;
}

const useStyles = createStyles((theme, _params, getRef) => ({
  inlineBadge: {
    verticalAlign: 'text-bottom',

    '&.user': {
      paddingLeft: 0,
    },
  },

  scrollArea: {
    transform: 'translate3d(0,0,0)',
    position: 'relative',

    '&::after': {
      content: '""',
      position: 'fixed',
      right: 0,
      bottom: 0,
      left: 0,
      height: theme.spacing.lg,
      backgroundImage: `linear-gradient(-180deg, ${
        theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white
      }00, ${
        theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white
      })`,
    },

    '&::before': {
      content: '""',
      position: 'fixed',
      left: 0,
      top: 0,
      right: 0,
      height: theme.spacing.lg,
      backgroundImage: `linear-gradient(-180deg, ${
        theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white
      }, ${
        theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white
      }00)`,
      zIndex: 1,
    },
  },
}));

const LeadTimeline = ({ lead }: { lead?: ByggLead }) => {
  const { classes, theme } = useStyles();
  const { members, statuses } = useContext(ByggleadsContext);

  const [timelineRef, timelineRect] = useResizeObserver();
  const [viewportHeight, setViewportHeight] = useStateEffect(
    256,
    () => {
      setViewportHeight(timelineRect.height);
    },
    [timelineRect]
  );

  const [entries, setEntries] = useState<ByggLeadTimelineDisplay[] | 'loading'>(
    'loading'
  );
  useEffect(() => {
    if (!members.length || !statuses.length || !lead) return;
    const listener = onSnapshot(
      query(
        collection(getFirestore(), lead.ref.path, 'timeline'),
        orderBy('timeCreated', 'desc')
      ),
      async (snapshot) => {
        const rawEntries = snapshot.docs.map(
          (snapshot) =>
            ({
              ...snapshot.data(),
              ref: snapshot.ref,
            } as ByggLeadTimelineEntry)
        );

        const displayEntries: ByggLeadTimelineDisplay[] = rawEntries.flatMap(
          (entry) => {
            let params: ByggLeadTimelineDisplay = {
              title: '',
              message: '',
              timeCreated: new Date(),
              icon: <></>,
            };
            if (isTimestamp(entry.timeCreated)) {
              params.timeCreated = entry.timeCreated.toDate();
            }

            if (entry.event === 'created') {
              params = {
                ...params,
                ...getCreatedEvent(lead),
              };
            }

            if (entry.event === 'status') {
              params = {
                ...params,
                ...getStatusEvent(classes, entry, statuses),
              };
            }

            if (entry.event === 'assigned') {
              getAssignedEvent(classes, entry, members);
              params = {
                ...params,
                ...getAssignedEvent(classes, entry, members),
              };
            }

            return params.title ? params : [];
          }
        );

        setEntries(displayEntries);
      },
      (err) => {
        console.error(err);
      }
    );
    return () => {
      listener();
    };
  }, [lead, statuses, members]);

  return (
    <>
      {entries.length ? (
        <Divider label={isArray(entries) ? "Tidslinje" : undefined} labelPosition="center" mt={'md'} />
      ) : undefined}
      <ScrollArea
        px={'xs'}
        sx={{
          height: `min(${viewportHeight}px, 256px)`,
          display: entries.length ? undefined : 'none',
        }}
        type="always"
        className={classes.scrollArea}
      >
        <Box ref={timelineRef}>
          <Timeline
            active={isArray(entries) ? 0 : -1}
            bulletSize={24}
            lineWidth={2}
            py="lg"
            style={{ height: '100%' }}
          >
            {isArray(entries)
              ? entries.map((entry, index) => {
                  return (
                    <Timeline.Item
                      title={entry.title}
                      bullet={entry.icon}
                      key={index}
                    >
                      <Text size="sm" color="dimmed">
                        {entry.message}
                      </Text>
                      <Text size="xs" mt={4}>
                        {formatDate(entry.timeCreated, 'relative_exact')}
                      </Text>
                    </Timeline.Item>
                  );
                })
              : ['1', '2'].map((key) => {
                  return (
                    <Timeline.Item
                      key={key}
                      title={<Skeleton height={20} width={192} />}
                      bullet={<Skeleton height={24} width={24} circle />}
                    >
                      <Skeleton height={14} width={256} my={4} />
                      <Skeleton height={10} width={96} my={2} mt={2} />
                    </Timeline.Item>
                  );
                })}
          </Timeline>
        </Box>
      </ScrollArea>
    </>
  );
};

export default LeadTimeline;

const getCreatedEvent = (lead: ByggLead) => {
  let params: Partial<ByggLeadTimelineDisplay> = {};

  const title = 'Lead skapat';
  let message =
    typeof lead.createdBy === 'string'
      ? 'Leadet har inkommit till Byggleads automatiskt'
      : 'Leadet har skapats manuellt i Byggleads';

  switch (lead.platform) {
    case 'fb':
      message += ' från Facebook';
      break;
    case 'ig':
      message += ' från Instagram';
      break;
    default:
      break;
  }

  message += '.';

  params.title = title;
  params.message = message;
  params.icon = <IconCreate side={16} />;

  return params;
};

const getStatusEvent = (
  classes: Record<'inlineBadge', string>,
  entry: ByggLeadTimelineEntry,
  statuses: ByggleadsProps['statuses']
) => {
  let params: Partial<ByggLeadTimelineDisplay> = {};

  const prevStatus = find(
    statuses,
    (e) => refEqual(e.ref, entry.beforeRefs[0])
  );
  const currStatus = find(
    statuses,
    (e) => refEqual(e.ref, entry.afterRefs[0])
  );
  // If the previous status is undefined, that means it's been deleted.
  // Therefore, we should indicate this.
  if (!prevStatus) {
    params.title = (
      <>
        Status ändrad till{' '}
        <Badge
          size="sm"
          color={currStatus?.color || 'gray'}
          className={classes.inlineBadge}
        >
          {currStatus?.title || 'Okänd'}
        </Badge>
      </>
    );
    params.message =
      'Statusen ändrades automatiskt eftersom leadets föregående status blev borttagen.';
  } else {
    params.title = (
      <>
        Status ändrad till{' '}
        <Badge
          size="sm"
          color={currStatus?.color || 'gray'}
          className={classes.inlineBadge}
        >
          {currStatus?.title || 'Okänd'}
        </Badge>
      </>
    );
    params.message = (
      <>
        Status ändrades från{' '}
        <Badge
          size="xs"
          color={prevStatus?.color || 'gray'}
          className={classes.inlineBadge}
        >
          {prevStatus?.title || 'Okänd'}
        </Badge>{' '}
        till{' '}
        <Badge
          size="xs"
          color={currStatus?.color || 'gray'}
          className={classes.inlineBadge}
        >
          {currStatus?.title || 'Okänd'}
        </Badge>
      </>
    );
  }

  params.icon = <IconPulse side={16} />;

  return params;
};

const getAssignedEvent = (
  classes: Record<'inlineBadge', string>,
  entry: ByggLeadTimelineEntry,
  members: ByggleadsProps['members']
) => {
  let params: Partial<ByggLeadTimelineDisplay> = {};

  const prevPaths = entry.beforeRefs.map((e) => e.path);
  const currPaths = entry.afterRefs.map((e) => e.path);

  const prevAssigned = members.filter((e) =>
    prevPaths.some((p) => refEqual(doc(getFirestore(), p), e.user.ref))
  );
  let currAssigned = members.filter((e) =>
    currPaths.some((p) => refEqual(doc(getFirestore(), p), e.user.ref))
  );

  const removed = prevAssigned.flatMap((p) => {
    if (!currAssigned.some((c) => refEqual(c.user.ref, p.user.ref))) {
      return p;
    }
    return [];
  });

  if (currAssigned.length) {
    params.title = (
      <span
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          marginTop: -5,
          marginBottom: removed.length ? undefined : -3,
        }}
      >
        Leadet tilldelades
        {currAssigned.map((member, index, array) => {
          return (
            <Paper
              component="span"
              key={member.user.uid}
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                verticalAlign: 'center',
                marginLeft: 4,
              }}
              withBorder
              py={2}
              px={6}
            >
              <Avatar
                size={20}
                radius={'xl'}
                src={getAvatarURL(member.user)}
                mr={6}
              />
              <Text size="sm">{member.user.name}</Text>
            </Paper>
          );
        })}
      </span>
    );
  } else {
    params.title = 'Alla tilldelade togs bort';
  }

  if (removed.length) {
    params.message = (
      <>
        {removed.map((member, index, array) => {
          return (
            <Badge
              color={'gray'}
              className={`${classes.inlineBadge} user`}
              style={{
                marginRight: index !== array.length - 1 ? 4 : undefined,
                transform: 'translateY(2px)',
              }}
              size="sm"
              leftSection={
                <Avatar
                  size={16}
                  radius={'xl'}
                  src={getAvatarURL(member.user)}
                />
              }
              key={member.user.uid}
            >
              {member.user.name}
            </Badge>
          );
        })}{' '}
        togs bort som tilldelade.
      </>
    );
    params.message = (
      <span
        style={{
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        {removed.map((member, index, array) => {
          return (
            <Paper
              component="span"
              key={member.user.uid}
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                verticalAlign: 'center',
                marginRight: 4,
              }}
              withBorder
              py={0}
              px={6}
            >
              <Avatar
                size={16}
                radius={'xl'}
                src={getAvatarURL(member.user)}
                mr={6}
              />
              <Text size="sm">{member.user.name}</Text>
            </Paper>
          );
        })}
        togs bort som tilldelade.
      </span>
    );
  }

  params.icon = <IconAssigned side={16} />;

  return params;
};
