import {
  ActionIcon,
  Badge,
  Button,
  Card,
  CloseButton,
  createStyles,
  Group,
  Popover,
  Table,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggingStyle,
} from "react-beautiful-dnd";
import { ByggleadsContext } from "../../providers/ByggleadsProvider";
import { ByggLeadStatus } from "../../utils/Types";
import { IconGripVertical, IconCheck } from "../Icons";
import {
  getFirestore,
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";

const useStyles = createStyles((theme, _params, getRef) => ({
  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: theme.spacing.sm,
  },

  statusTableRow: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
  },
}));

const HandleStatusesModal = ({
  setOpen,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { classes } = useStyles();
  const { statuses } = useContext(ByggleadsContext);

  const [localStatuses, setLocalStatuses] =
    useState<ByggLeadStatus[]>(statuses);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLocalStatuses(statuses);
    }, 250);

    return () => {
      clearTimeout(timer);
    };
  }, [statuses]);

  return (
    <>
      <Card>
        <Card.Section className={classes.section}>
          <Group position="apart" noWrap>
            <Title order={4}>Hantera statusar</Title>
            <CloseButton onClick={() => setOpen(false)} />
          </Group>
        </Card.Section>
        <Card.Section>
          <DragDropContext
            onDragEnd={({ destination, source }) => {
              const srcI = source.index;
              const desI = destination?.index;

              if (srcI === undefined || desI === undefined) return;
              let statusCopy = [...statuses];

              statusCopy.splice(desI, 0, statusCopy.splice(srcI, 1)[0]);

              const ownStatuses = statusCopy.filter(
                (e) => !e.ref.path.includes("platform/defaults/statuses")
              );

              ownStatuses.forEach((status, index) => {
                updateDoc(status.ref, {
                  order: index + 1,
                });
              });

              setLocalStatuses(statusCopy);
            }}
          >
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th>Titel</th>
                  <th>Färg</th>
                  <th>Åtgärder</th>
                </tr>
              </thead>
              <Droppable droppableId="status-dnd" direction="vertical">
                {(provided) => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {localStatuses.map((status, index) => {
                      return (
                        <TableStatusRow
                          status={status}
                          index={index}
                          key={status.ref.id}
                        />
                      );
                    })}
                    {provided.placeholder}
                    <AddTableStatusRow />
                  </tbody>
                )}
              </Droppable>
            </Table>
          </DragDropContext>
        </Card.Section>
      </Card>
    </>
  );
};

const TableStatusRow = ({
  status,
  index,
}: {
  status: ByggLeadStatus;
  index: number;
}) => {
  const { classes, theme } = useStyles();
  const [statusTitle, setStatusTitle] = useState(status.title);
  const [selectedColor, setSelectedColor] = useState<ByggLeadStatus["color"]>(
    status.color
  );
  const [colorSelectorOpen, setColorSelectorOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const isPlatformStatus = () => {
    return status.ref.path.includes("platform/defaults/statuses");
  };

  useEffect(() => {
    if (selectedColor === status.color) return;

    updateDoc(status.ref, {
      color: selectedColor,
    }).catch((err) => {
      console.error(err);
    });
  }, [selectedColor]);

  useEffect(() => {
    if (statusTitle === status.title) return;
    const timer = setTimeout(() => {
      updateDoc(status.ref, {
        title: statusTitle,
      }).catch((err) => {
        console.error(err);
      });
    }, 250);

    return () => {
      clearTimeout(timer);
    };
  }, [statusTitle]);

  const deleteStatus = async () => {
    setDeleteLoading(true);

    await deleteDoc(status.ref).catch((err) => {
      console.error(err);
    });

    setDeleteLoading(false);
  };

  const body = (
    <>
      <td style={{ width: 270 }}>
        <TextInput
          disabled={isPlatformStatus()}
          maxLength={20}
          value={statusTitle}
          onChange={(e) => setStatusTitle(e.target.value)}
        />
      </td>
      <td style={{ width: 128 }}>
        <Popover
          opened={colorSelectorOpen}
          onClose={() => setColorSelectorOpen(false)}
          position="bottom"
          withArrow
          withinPortal
        >
          <Popover.Target>
            <Badge
              color={selectedColor}
              onClick={() => setColorSelectorOpen((p) => !p)}
              style={{ cursor: "pointer" }}
            >
              {colorDictionary[selectedColor]}
            </Badge>
          </Popover.Target>
          <Popover.Dropdown>
            <ColorSelector
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              setOpen={setColorSelectorOpen}
            />
          </Popover.Dropdown>
        </Popover>
      </td>
      <td>
        {!isPlatformStatus() && (
          <Popover
            opened={confirmDelete}
            onClose={() => setConfirmDelete(false)}
            position="bottom"
            withArrow
            transition="pop"
            transitionDuration={80}
            withinPortal
          >
            <Popover.Target>
              <Button
                compact
                color={"red"}
                variant="light"
                loading={deleteLoading}
                onClick={() => setConfirmDelete(true)}
              >
                Ta bort
              </Button>
            </Popover.Target>
            <Popover.Dropdown>
              <Group position="right" spacing="xs">
                <Text
                  weight={500}
                  size="sm"
                  mr={"sm"}
                  style={{
                    color:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[0]
                        : theme.black,
                  }}
                >
                  Ta bort status?
                </Text>
                <Button
                  compact
                  variant="light"
                  color="red"
                  onClick={() => {
                    setConfirmDelete(false);
                    deleteStatus();
                  }}
                >
                  Ja
                </Button>
                <Button
                  compact
                  variant="subtle"
                  onClick={() => setConfirmDelete(false)}
                >
                  Nej
                </Button>
              </Group>
            </Popover.Dropdown>
          </Popover>
        )}
      </td>
    </>
  );

  if (isPlatformStatus()) {
    return (
      <tr className={classes.statusTableRow}>
        <td style={{ width: 24, padding: 0, paddingLeft: 8 }}></td>
        {body}
      </tr>
    );
  }

  return (
    <Draggable key={status.ref.id} index={index} draggableId={status.ref.id}>
      {(provided, snapshot) => {
        const modalElement = document.getElementById(
          "STATUS_HANDLE_MODAL-body"
        );
        const isDragging = (_: any): _ is DraggingStyle => {
          return snapshot.isDragging;
        };
        if (isDragging(provided.draggableProps.style) && modalElement) {
          provided.draggableProps.style.left =
            provided.draggableProps.style.left -
            modalElement.getBoundingClientRect().x;
          provided.draggableProps.style.top =
            provided.draggableProps.style.top -
            modalElement.getBoundingClientRect().y;
        }
        return (
          <tr
            ref={provided.innerRef}
            className={classes.statusTableRow}
            {...provided.draggableProps}
          >
            <td
              style={{
                width: 24,
                padding: 0,
                paddingLeft: 8,
              }}
            >
              <Text
                {...provided.dragHandleProps}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                }}
                color="dimmed"
                component="div"
              >
                <IconGripVertical side={16} />
              </Text>
            </td>
            {body}
          </tr>
        );
      }}
    </Draggable>
  );
};

const AddTableStatusRow = () => {
  const { workspace } = useContext(ByggleadsContext);
  const [statusTitle, setStatusTitle] = useState("");
  const [selectedColor, setSelectedColor] =
    useState<ByggLeadStatus["color"]>("gray");
  const [colorSelectorOpen, setColorSelectorOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    if (!workspace?.ref) return;
    setLoading(true);

    const params: Partial<ByggLeadStatus> = {
      title: statusTitle,
      color: selectedColor,
      order: 999,
    };

    await addDoc(
      collection(getFirestore(), workspace.ref.path, "statuses"),
      params
    )
      .then(() => {
        setStatusTitle("");
        setSelectedColor("gray");
      })
      .catch((err) => {
        console.error(err);
      });

    setLoading(false);
  };

  return (
    <tr>
      <td style={{ width: 24, padding: 0 }}></td>
      <td style={{ width: 256 }}>
        <TextInput
          maxLength={20}
          value={statusTitle}
          onChange={(e) => setStatusTitle(e.target.value)}
        />
      </td>
      <td style={{ width: 128 }}>
        <Popover opened={colorSelectorOpen} position="bottom" withArrow>
          <Popover.Target>
            <Badge
              color={selectedColor}
              onClick={() => setColorSelectorOpen((p) => !p)}
              style={{ cursor: "pointer" }}
            >
              {colorDictionary[selectedColor]}
            </Badge>
          </Popover.Target>
          <Popover.Dropdown>
            <ColorSelector
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              setOpen={setColorSelectorOpen}
            />
          </Popover.Dropdown>
        </Popover>
      </td>
      <td style={{ width: 192 }}>
        <Button
          compact
          variant="default"
          disabled={!statusTitle}
          loading={loading}
          onClick={submit}
        >
          Lägg till
        </Button>
      </td>
    </tr>
  );
};

const ColorSelector = ({
  selectedColor,
  setSelectedColor,
  setOpen,
}: {
  selectedColor: ByggLeadStatus["color"];
  setSelectedColor: React.Dispatch<
    React.SetStateAction<ByggLeadStatus["color"]>
  >;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { classes, theme } = useStyles();
  return (
    <Group>
      {Object.keys(theme.colors).map((color) => {
        return (
          <ActionIcon
            key={color}
            color={color}
            size="sm"
            radius={"xl"}
            variant={selectedColor === color ? "light" : "filled"}
            onClick={() => {
              setSelectedColor(color as ByggLeadStatus["color"]);
              setOpen(false);
            }}
          >
            {selectedColor === color && <IconCheck side={12} />}
          </ActionIcon>
        );
      })}
    </Group>
  );
};

const colorDictionary = {
  dark: "Mörk",
  gray: "Grå",
  red: "Röd",
  pink: "Rosa",
  grape: "Druva",
  violet: "Violett",
  indigo: "Indigoblå",
  blue: "Blå",
  cyan: "Cyan",
  teal: "Turkos",
  green: "Grön",
  lime: "Limegrön",
  yellow: "Gul",
  orange: "Orange",
};

export default HandleStatusesModal;
