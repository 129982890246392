import { Button, createStyles, Group, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { IconEnvelope } from '../Icons';

const useStyles = createStyles((theme, _params, getRef) => ({
  button: {
    width: '100%',
  },
}));

const EmailInputModal = ({
  handleSubmit,
  message,
}: {
  handleSubmit: (values: { email: string }) => void;
  message?: string;
}) => {
  const { classes } = useStyles();
  const [loading] = useState(false);

  const form = useForm({
    initialValues: {
      email: '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Ange e-postadress'),
    },
  });

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        {message && <Text size="xs">{message}</Text>}
        <TextInput
          required
          label="E-postadress"
          placeholder="exempel@epost.se"
          type="email"
          mt={'md'}
          icon={<IconEnvelope side={16} />}
          {...form.getInputProps('email')}
        />

        <Group position="apart" mt="md" className={classes.button}>
          <Button type="submit" loading={loading} className={classes.button}>
            Bjud in
          </Button>
        </Group>
      </form>
    </>
  );
};

export default EmailInputModal;
