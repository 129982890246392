import {
  Container,
  createStyles,
  Group,
  Select,
  SelectItem,
  SimpleGrid,
  Stack,
  Title,
} from '@mantine/core';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { motion } from 'framer-motion';
import { isArray } from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { ByggleadsContext } from '../../providers/ByggleadsProvider';
import {
  framerPropsContentStack,
  framerPropsTitleStack,
} from '../../utils/Globals';
import useStateEffect from '../../utils/hooks/useStateEffect';
import Bulletin from './Bulletin';
import LatestLead from './LatestLead';
import LeadStats from './LeadStats';
import NewLeads from './NewLeads';

const useStyles = createStyles((theme, _params, getRef) => ({
  titleStack: {
    justifyContent: 'flex-start',
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    borderBottom: 'solid 1px',
    borderColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[5]
        : theme.colors.gray[2],
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
  },
  splitButtonLeft: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  splitButtonRight: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginLeft: 1,
  },
  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: theme.spacing.sm,
  },
  blur: {
    filter: 'blur(6px)',
  },
}));

const Dashboard = () => {
  const { classes, theme } = useStyles();
  const { user } = useContext(ByggleadsContext);

  const [dimLatestLead, setDimLatestLead] = useState(false);

  const name = useMemo(() => {
    if (!user?.name) return '';
    return " " + user.name.split(' ').shift();
  }, [user]);

  return (
    <>
      <Stack spacing={0} className={classes.titleStack} p={'md'}>
        <motion.div {...framerPropsTitleStack}>
          <Group align={'center'} position="apart">
            <Title order={2}>👋 Hej{name}!</Title>
            <WorkspaceSelector />
          </Group>
        </motion.div>
      </Stack>
      <Container size={'lg'} py="md">
        <motion.div {...framerPropsContentStack} key="loaded">
          <SimpleGrid
            spacing="md"
            cols={1}
            breakpoints={[{ minWidth: 'lg', cols: 2 }]}
          >
            <LatestLead dimLatestLead={dimLatestLead} />
            <Bulletin setDimLatestLead={setDimLatestLead} />
            <LeadStats />
            <NewLeads />
          </SimpleGrid>
        </motion.div>
      </Container>
    </>
  );
};

export default Dashboard;

const WorkspaceSelector = () => {
  const { user, workspaces } = useContext(ByggleadsContext);

  const [workspaceOptions, setWorkspaceOptions] = useStateEffect<SelectItem[]>(
    [],
    () => {
      if (!isArray(workspaces)) return;
      setWorkspaceOptions(
        workspaces.map((workspace): SelectItem => {
          return {
            label: workspace.name,
            value: workspace.ref!.path,
          };
        })
      );
    },
    [workspaces]
  );

  const setAsOrganization = async (event: string) => {
    if (!workspaceOptions.some((e) => e.value === event)) return;
    if (!user?.ref) return;
    const ref = doc(getFirestore(), event);

    await updateDoc(user.ref, {
      workspace: ref,
    });
  };

  if (!workspaceOptions.length) return <></>;

  return (
    <Select
      transition={'pop'}
      transitionDuration={80}
      transitionTimingFunction="ease"
      data={workspaceOptions}
      value={user?.workspace?.path || ''}
      onChange={setAsOrganization}
      variant="filled"
    />
  );
};
