import { Box, Button, createStyles, Group, Stack, Text } from "@mantine/core";
import { motion } from "framer-motion";
import { Link, useNavigationType } from "react-router-dom";
import { IconEnvelope, IconFacebook, IconGoogle } from "../../components/Icons";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  getAuth,
  signInWithPopup,
} from "firebase/auth";
import { showNotification} from "@mantine/notifications";
import { getFramerMotionProps } from "../../utils/Globals";
import { getFirestore, setDoc, doc } from "firebase/firestore";
import { ErrorCodes } from "../../utils/ErrorCodes";
import { FirebaseError } from "firebase/app";

const useStyles = createStyles((theme, _params, getRef) => ({
  main: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
  button: {
    width: "100%",
  },
}));

const SignIn = () => {
  const { classes, theme } = useStyles();
  const navigationType = useNavigationType();

  const signInWithGoogle = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((credential) => {
        setDoc(
          doc(getFirestore(), "users", credential.user.uid),
          {
            name: credential.user.displayName,
            email: credential.user.email,
            phone: credential.user.phoneNumber,
            photoURL: {
              fromProvider: credential.user.photoURL,
            },
            uid: credential.user.uid,
          },
          { merge: true }
        );
      })
      .catch((err: FirebaseError) => {
        console.error(err);
        switch (err.code) {
          case "auth/popup-closed-by-user":
            showNotification({
              title: "Någonting gick fel Google-inloggningen",
              message: ErrorCodes[err.code].message,
              color: "red",
            });
            break;
          default:
            showNotification({
              title: "Någonting gick fel Google-inloggningen",
              message: ErrorCodes.standard.message,
              color: "red",
            });
            break;
        }
      });
  };

  const signInWithFacebook = () => {
    const auth = getAuth();
    const provider = new FacebookAuthProvider();
    signInWithPopup(auth, provider)
      .then((credential) => {
        setDoc(
          doc(getFirestore(), "users", credential.user.uid),
          {
            name: credential.user.displayName,
            email: credential.user.email,
            phone: credential.user.phoneNumber,
            photoURL: {
              fromProvider: credential.user.photoURL,
            },
            uid: credential.user.uid,
          },
          { merge: true }
        );
      })
      .catch((err: FirebaseError) => {
        console.error(err);
        switch (err.code) {
          case "auth/popup-closed-by-user":
            showNotification({
              title: "Någonting gick fel Facebook-inloggningen",
              message: ErrorCodes[err.code].message,
              color: "red",
            });
            break;
          default:
            showNotification({
              title: "Någonting gick fel Facebook-inloggningen",
              message: ErrorCodes.standard.message,
              color: "red",
            });
            break;
        }
      });
  };

  return (
    <motion.div
      {...getFramerMotionProps("horizontal", navigationType)}
      className={classes.main}
    >
      <Box sx={{ maxWidth: 320, width: "100%" }} m="auto">
        <Text size="xl" mb="lg" align="center">
          Logga in
        </Text>
        <Stack align={"center"} spacing="xs">
          <Button
            leftIcon={<IconGoogle side={16} />}
            variant="default"
            color="gray"
            className={classes.button}
            onClick={signInWithGoogle}
          >
            Fortsätt med Google
          </Button>
          <Button
            leftIcon={<IconFacebook side={16} />}
            sx={(theme) => ({
              backgroundColor: "#4267B2",
              color: "#fff",
              "&:hover": {
                backgroundColor: theme.fn.darken("#4267B2", 0.1),
              },
            })}
            className={classes.button}
            onClick={signInWithFacebook}
          >
            Logga in med Facebook
          </Button>
          <Link to={"/login"} className={classes.button}>
            <Button
              leftIcon={
                <IconEnvelope
                  side={16}
                  color={
                    theme.colorScheme === "dark" ? theme.white : theme.black
                  }
                />
              }
              variant="default"
              className={classes.button}
            >
              Logga in med e-post
            </Button>
          </Link>
          <Link to={"/register"}>
            <Button variant="subtle" className={classes.button}>
              Registrera konto
            </Button>
          </Link>
        </Stack>
      </Box>
    </motion.div>
  );
};

export default SignIn;
