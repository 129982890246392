import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { DocumentReference, onSnapshot, updateDoc } from 'firebase/firestore';
import { isValidPhoneNumber } from 'libphonenumber-js';
import {
  Button,
  Card,
  CloseButton,
  createStyles,
  Group,
  ScrollArea,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
} from '@mantine/core';
import { ByggWorkspace } from '../../utils/Types';
import { showNotification } from '@mantine/notifications';
import { ErrorCodes } from '../../utils/ErrorCodes';
import {
  IconAddress,
  IconEnvelope,
  IconPhone,
  IconUser,
  IconWebsite,
  IconWorkspaceFilled,
} from '../../components/Icons';
import { scrollAreaProps } from '../../utils/Globals';

const useStyles = createStyles((theme, _params, getRef) => ({
  main: {
    height: '100%',
    position: 'relative',
  },
  header: {
    borderBottom: `solid 1px ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
  },
  footer: {
    borderTop: `solid 1px ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
  },
  form: {
    padding: theme.spacing.md,
  },
  inputCard: {},
  inputCardHeader: {
    borderBottom: `solid 1px ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },
  input: {
    width: 320,
    maxWidth: '100%',
  },
}));

const WorkspaceEdit = ({
  workspaceRef,
  closeAction,
}: {
  workspaceRef?: DocumentReference;
  closeAction: () => void;
}) => {
  const { classes, theme, cx } = useStyles();
  const [loading, setLoading] = useState(false);

  const form = useForm({
    initialValues: {
      name: '',
      registration: '',
      website: '',
      address_street: '',
      address_zip: '',
      address_city: '',
      contact_name: '',
      contact_phone: '',
      contact_email: '',
    },

    validate: {
      name: (value) => (value ? null : 'Ange arbetsplatsens namn'),
      registration: (value) => (value ? null : 'Ange organisationsnummer'),
      address_street: (value) =>
        value.split(' ').length > 1 ? null : 'Ange gatuadress & husnummer',
      address_zip: (value) => (value ? null : 'Ange postnummer'),
      address_city: (value) => (value ? null : 'Ange stad'),
      contact_name: (value) =>
        value.split(' ').length > 1 ? null : 'Ange för- och efternamn',
      contact_email: (value) =>
        /^\S+@\S+$/.test(value) ? null : 'Ange e-postadress',
      contact_phone: (value) => {
        if (!value) return null;
        if (isValidPhoneNumber(value, 'SE')) return null;
        return 'Ange ett giltigt telefonnummer';
      },
    },
  });

  const [workspace, setWorkspace] = useState<ByggWorkspace | null>(null);

  useEffect(() => {
    if (!workspaceRef) return;
    const listener = onSnapshot(
      workspaceRef,
      (snapshot) => {
        if (snapshot.data()) {
          let tempWorkspace = snapshot.data() as ByggWorkspace;
          tempWorkspace.ref = snapshot.ref;
          setWorkspace(tempWorkspace);
        } else {
          setWorkspace(null);
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return () => {
      listener();
    };
  }, [workspaceRef]);

  useEffect(() => {
    if (!workspace) return;
    form.setValues({
      name: workspace.name,
      registration: workspace.registration,
      website: workspace.website || '',
      address_street: workspace.address.street,
      address_zip: workspace.address.zip,
      address_city: workspace.address.city,
      contact_name: workspace.contact.name,
      contact_email: workspace.contact.email,
      contact_phone: workspace.contact.phone,
    });
  }, [workspace]);

  const saveChanges = async (values: {
    name: string;
    registration: string;
    website: string;
    address_street: string;
    address_zip: string;
    address_city: string;
    contact_name: string;
    contact_phone: string;
    contact_email: string;
  }) => {
    if (!workspaceRef) return;
    setLoading(true);

    const data: Partial<ByggWorkspace> = {
      name: values.name,
      contact: {
        name: values.contact_name,
        email: values.contact_email,
        phone: values.contact_phone,
      },
      address: {
        street: values.address_street,
        zip: values.address_zip,
        city: values.address_city,
      },
      website: values.website,
      registration: values.registration,
    };

    await updateDoc(workspaceRef, data)
      .then(() => {
        closeAction();
      })
      .catch((err) => {
        showNotification({
          message: ErrorCodes.standard.message,
          color: 'red',
        });
        console.error(err);
      });

    setLoading(false);
  };

  return (
    <ScrollArea className={classes.main} {...scrollAreaProps}>
      <Group p="md" className={classes.header} position="apart">
        <Text>Redigera arbetsplats</Text>
        <CloseButton onClick={closeAction} />
      </Group>
      <form className={classes.form} onSubmit={form.onSubmit(saveChanges)}>
        <Stack spacing="sm">
          <Card withBorder className={classes.inputCard}>
            <Card.Section className={classes.inputCardHeader}>
              <Group position="apart" p="md">
                <Text size="md" weight={500}>
                  Bolagsinformation
                </Text>
                <ThemeIcon variant="light">
                  <IconWorkspaceFilled side={18} />
                </ThemeIcon>
              </Group>
            </Card.Section>
            <Stack py="sm" align="flex-start">
              <TextInput
                required
                label="Namn"
                placeholder="Arbetsplatsens namn"
                className={classes.input}
                icon={<IconWorkspaceFilled side={16} />}
                {...form.getInputProps('name')}
              />
              <TextInput
                required
                label="Org.nr."
                placeholder="123456-7890"
                className={classes.input}
                icon={<IconWorkspaceFilled side={16} />}
                {...form.getInputProps('registration')}
              />
              <TextInput
                label="Hemsida"
                placeholder="byggleads.se"
                className={classes.input}
                icon={<IconWebsite side={16} />}
                {...form.getInputProps('website')}
              />
            </Stack>
          </Card>
          <Card withBorder className={classes.inputCard}>
            <Card.Section className={classes.inputCardHeader}>
              <Group position="apart" p="md">
                <Text size="md" weight={500}>
                  Adress
                </Text>
                <ThemeIcon variant="light">
                  <IconAddress side={18} />
                </ThemeIcon>
              </Group>
            </Card.Section>
            <Stack py="sm" align="flex-start">
              <TextInput
                required
                label="Gatuadress"
                placeholder="Exempeladress 4"
                className={classes.input}
                icon={<IconAddress side={16} />}
                {...form.getInputProps('address_street')}
              />
              <TextInput
                required
                label="Postnummer"
                placeholder="12345"
                className={classes.input}
                icon={<IconAddress side={16} />}
                {...form.getInputProps('address_zip')}
              />
              <TextInput
                required
                label="Stad"
                placeholder="Postort"
                className={classes.input}
                icon={<IconAddress side={16} />}
                {...form.getInputProps('address_city')}
              />
            </Stack>
          </Card>
          <Card withBorder className={classes.inputCard}>
            <Card.Section className={classes.inputCardHeader}>
              <Group position="apart" p="md">
                <Text size="md" weight={500}>
                  Kontaktperson
                </Text>
                <ThemeIcon variant="light">
                  <IconUser side={18} />
                </ThemeIcon>
              </Group>
            </Card.Section>
            <Stack py="sm" align="flex-start">
              <TextInput
                required
                label="Namn"
                placeholder="För- och efternamn"
                className={classes.input}
                icon={<IconUser side={16} />}
                {...form.getInputProps('contact_name')}
              />
              <TextInput
                required
                label="E-postadress"
                placeholder="din@epost.se"
                type="email"
                className={classes.input}
                icon={<IconEnvelope side={16} />}
                {...form.getInputProps('contact_email')}
              />
              <TextInput
                required
                label="Telefonnummer"
                placeholder="070-123 45 67"
                type="tel"
                className={classes.input}
                icon={<IconPhone side={16} />}
                {...form.getInputProps('contact_phone')}
              />
            </Stack>
          </Card>
        </Stack>
      </form>
      <Group p="md" position="right" className={classes.footer}>
        <Button
          loading={loading}
          onClick={() => {
            const validate = form.validate()
            if (!validate.hasErrors) {
              saveChanges(form.values)
            }
          }}
        >
          Spara ändringar
        </Button>
      </Group>
    </ScrollArea>
  );
};

export default WorkspaceEdit;
