import React, { useContext, useEffect, useState } from 'react';
import {
  createStyles,
  Box,
  Text,
  Group,
  SimpleGrid,
  DefaultMantineColor,
  ThemeIcon,
  Skeleton,
  Stack,
  Card,
  RingProgress,
  Center,
} from '@mantine/core';
import {
  IconArrowDownRight,
  IconArrowUpRight,
  IconNewLead,
} from '../../components/Icons';
import {
  ByggleadsContext,
  ByggleadsProps,
} from '../../providers/ByggleadsProvider';
import moment from 'moment';
import { filter, findIndex, isArray, orderBy } from 'lodash';
import { getPlatformBranding, isTimestamp } from '../../utils/Functions';
import { ByggLead } from '../../utils/Types';
import { AnimatePresence, motion } from 'framer-motion';
import { framerPropsContentStack } from '../../utils/Globals';

const useStyles = createStyles((theme) => ({
  progressLabel: {
    lineHeight: 1,
    fontSize: theme.fontSizes.sm,
  },

  stat: {
    borderBottom: 'solid 3px',
    paddingBottom: 5,
  },

  statCount: {
    lineHeight: 1.3,
  },

  diff: {
    display: 'flex',
    alignItems: 'center',
  },

  icon: {
    // color:
    //   theme.colorScheme === "dark"
    //     ? theme.colors.dark[3]
    //     : theme.colors.gray[4],
  },

  fullHeight: {
    height: '100%',
  },
}));

interface StatsSegmentsProps {
  count: number;
  part: number;
  color: DefaultMantineColor;
  label?: string;
  gradient?: string;
}

const NewLeads = () => {
  const { classes } = useStyles();
  const { leads } = useContext(ByggleadsContext);

  return (
    <AnimatePresence exitBeforeEnter>
      {isArray(leads) ? (
        <motion.div
          {...framerPropsContentStack}
          key={`loaded${leads.length}`}
          className={classes.fullHeight}
        >
          <NewLeadsCard leads={leads} />
        </motion.div>
      ) : (
        <motion.div
          {...framerPropsContentStack}
          key="loading"
          className={classes.fullHeight}
        >
          <LoadingCard />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default React.memo(NewLeads);

const NewLeadsCard = ({ leads }: { leads: ByggleadsProps['leads'] }) => {
  const { classes, theme } = useStyles();

  const [leadsComparative, setLeadsComparative] = useState<{
    curr: ByggLead[];
    last: ByggLead[];
  }>({
    curr: [],
    last: [],
  });

  const [segmentData, setSegmentData] = useState<StatsSegmentsProps[]>([]);

  useEffect(() => {
    if (leads === 'loading') return;
    const PERIOD = 'week';
    const thisPeriod = {
      start: moment().startOf(PERIOD).toDate(),
      end: moment().toDate(),
    };
    const lastPeriod = {
      start: moment(thisPeriod.start).subtract(1, PERIOD).toDate(),
      end: moment(thisPeriod.end).subtract(1, PERIOD).toDate(),
    };

    const thisPeriodsLeads = filter(leads, (e) => {
      if (isTimestamp(e.timeCreated)) {
        if (
          moment(e.timeCreated.toDate()).isBetween(
            thisPeriod.start,
            thisPeriod.end
          )
        ) {
          return true;
        }
      }
      return false;
    });
    const lastPeriodsLeads = filter(leads, (e) => {
      if (isTimestamp(e.timeCreated)) {
        if (
          moment(e.timeCreated.toDate()).isBetween(
            lastPeriod.start,
            lastPeriod.end
          )
        ) {
          return true;
        }
      }
      return false;
    });

    setLeadsComparative({
      curr: thisPeriodsLeads,
      last: lastPeriodsLeads,
    });
  }, [leads]);

  useEffect(() => {
    let tempSegments: StatsSegmentsProps[] = [
      {
        label: 'Okänd',
        color: theme.colorScheme === 'dark' ? '#373A40' : '#f1f3f5',
        count: 0,
        part: 0,
      },
    ];

    leadsComparative.curr.forEach((lead) => {
      const clarified = getPlatformBranding(lead.platform);
      if (!clarified) {
        tempSegments[0].count += 1;
        return;
      }
      const index = findIndex(
        tempSegments,
        (e) => e.label === clarified?.title
      );
      if (index !== -1) {
        tempSegments[index].count += 1;
      } else {
        tempSegments.push({
          color: clarified.bgColor,
          count: 1,
          part: 0,
          label: clarified.title,
          gradient: clarified.bg,
        });
      }
    });

    tempSegments.forEach((segment, index) => {
      tempSegments[index].part = Math.round(
        (segment.count / leadsComparative.curr.length) * 100
      );
    });

    tempSegments = orderBy(tempSegments, (e) => e.part, 'desc');

    tempSegments = tempSegments.filter((e) => e.count > 0);

    setSegmentData(tempSegments);
  }, [leadsComparative, theme.colorScheme]);

  const segments = segmentData.flatMap((segment) => {
    if (segment.label === 'Okänd') return [];
    return {
      value: segment.part,
      color: segment.color,
      label: segment.part > 10 ? `${segment.part}%` : undefined,
    };
  });

  const descriptions = segmentData.map((stat) => (
    <Box
      key={stat.label}
      sx={(theme) => ({
        borderBottomColor: stat.color.includes('#')
          ? stat.color
          : theme.colors[stat.color][6],
      })}
      className={classes.stat}
    >
      <Text transform="uppercase" size="xs" color="dimmed" weight={700}>
        {stat.label}
      </Text>

      <Group position="apart" align="flex-end" spacing={0}>
        <Text weight={700}>{stat.count}</Text>
        <Text
          color={stat.color.includes('#') ? undefined : stat.color}
          weight={700}
          size="sm"
          className={classes.statCount}
        >
          {stat.part}%
        </Text>
      </Group>
    </Box>
  ));

  const diffPercent = Math.round(
    (leadsComparative.curr.length / leadsComparative.last.length - 1) * 100
  );
  return (
    <Card withBorder p="md" radius="md" className={classes.fullHeight}>
      <Group position="apart">
        <Group align="flex-end" spacing="xs">
          <Text
            size="xl"
            weight={700}
            style={{
              lineHeight: 1,
            }}
          >
            {leadsComparative.curr.length} nya leads
          </Text>
          {!isNaN(diffPercent) && (
            <Text
              color={diffPercent >= 0 ? 'teal' : 'red'}
              className={classes.diff}
              size="sm"
              weight={700}
              style={{
                lineHeight: 1,
              }}
            >
              <span>{diffPercent === Infinity ? '∞' : diffPercent}%</span>
              {diffPercent >= 0 ? (
                <IconArrowUpRight side={16} style={{ marginBottom: 4 }} />
              ) : (
                <IconArrowDownRight side={16} style={{ marginBottom: 4 }} />
              )}
            </Text>
          )}
        </Group>
        <ThemeIcon variant="light" size="lg" color="teal">
          <IconNewLead side={18} className={classes.icon} />
        </ThemeIcon>
      </Group>

      <Text color="dimmed" size="sm">
        Jämfört med samma period förra veckan
      </Text>

      <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'xs', cols: 1 }]} mt="md">
        <Center>
          <RingProgress sections={segments} size={160} thickness={20} />
        </Center>
        <Stack
          sx={(theme) => ({
            flex: 1,
          })}
          pt="lg"
        >
          {descriptions}
        </Stack>
      </SimpleGrid>
    </Card>
  );
};

const LoadingCard = () => {
  const { classes } = useStyles();

  return (
    <Card withBorder p="md" radius="md" className={classes.fullHeight}>
      <Group position="apart">
        <Group align="flex-end" spacing="xs">
          <Skeleton width={110} height={26} />
          <Skeleton width={52} height={16} />
        </Group>
        <Skeleton width={32} height={32} />
      </Group>

      <Skeleton width={250} height={14} mt={4} />

      <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'xs', cols: 1 }]} mt="md">
        <Center>
          <Skeleton height={120} width={120} circle />
        </Center>
        <Stack
          sx={(theme) => ({
            flex: 1,
          })}
          pt="lg"
        >
          <Stack>
            <Skeleton height={14} width={64} />
            <Skeleton height={32} />
          </Stack>
          <Stack>
            <Skeleton height={14} width={64} />
            <Skeleton height={32} />
          </Stack>
          <Stack>
            <Skeleton height={14} width={64} />
            <Skeleton height={32} />
          </Stack>
        </Stack>
      </SimpleGrid>
    </Card>
  );
};
