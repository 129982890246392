import { Button, Group, Text, TextInput } from "@mantine/core";
import React, { useState } from "react";

const TextConfirmModal = ({
  control,
  message,
  onConfirm,
  confirmText = "Bekräfta",
  onCancel,
  cancelText = "Avbryt",
}: {
  control: string;
  message?: React.ReactNode;
  onConfirm: () => void;
  confirmText?: string;
  onCancel: () => void;
  cancelText?: string;
}) => {
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <>
      {message || <></>}
      <Text size="sm" mt={"xl"}>
        För att bekräfta, vänligen skriv "{control}" i fältet:
      </Text>
      <TextInput
        placeholder="Skriv här"
        mt={"xs"}
        value={response}
        onChange={(e) => setResponse(e.target.value)}
        ref={(input) => {
          setTimeout(() => {
            if (input) {
              input.focus();
            }
          }, 100);
        }}
      />
      <Group mt={"xs"} position="right">
        <Button onClick={onCancel} variant="outline">
          {cancelText}
        </Button>
        <Button
          color={"red"}
          disabled={response.toLowerCase() !== control.toLowerCase()}
          onClick={() => {
            onConfirm();
            setTimeout(() => {
              setLoading(true);
            }, 150);
          }}
          loading={loading}
        >
          {confirmText}
        </Button>
      </Group>
    </>
  );
};

export default TextConfirmModal;
