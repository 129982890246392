import { DocumentReference, updateDoc } from "firebase/firestore";
import {
  Menu,
  UnstyledButton,
  Group,
  Badge,
  Modal,
  Text,
  Center,
  Skeleton,
} from "@mantine/core";
import { useContext, useState } from "react";
import { ByggleadsContext } from "../../providers/ByggleadsProvider";
import { ByggLeadStatus } from "../../utils/Types";
import HandleStatusesModal from "../Modals/HandleStatusesModal";
import { IconExpand } from "../Icons";

const StatusSelector = ({
  leadRef,
  selectedStatus,
}: {
  leadRef: DocumentReference;
  selectedStatus?: ByggLeadStatus;
}) => {
  const { statuses } = useContext(ByggleadsContext);

  const [addStatusOpen, setAddStatusOpen] = useState(false);

  if (!selectedStatus) {
    return (
      <Group spacing={"xs"}>
        <Skeleton height={12} width={40} />
        <Skeleton height={20} width={94} radius="xl" />
      </Group>
    );
  }

  return (
    <>
      <Menu
        styles={{
          itemLabel: { width: "100%" },
        }}
        transition="pop"
        withArrow
        withinPortal
      >
        <Menu.Target>
          <UnstyledButton>
            <Group spacing={"xs"}>
              <Text weight={500} size="xs">
                Status:
              </Text>
              <Badge
                color={selectedStatus.color}
                style={{ pointerEvents: "none" }}
                rightSection={
                  <Center>
                    <IconExpand side={14} />
                  </Center>
                }
              >
                {selectedStatus.title}
              </Badge>
            </Group>
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown>
          {statuses.map((status) => {
            return (
              <Menu.Item
                py={8}
                onClick={() => {
                  updateDoc(leadRef, {
                    status: status.ref,
                  });
                }}
                key={status.ref.id}
              >
                <Badge
                  color={status.color}
                  fullWidth
                  style={{ pointerEvents: "none" }}
                >
                  {status.title}
                </Badge>
              </Menu.Item>
            );
          })}
          <Menu.Item py={6} onClick={() => setAddStatusOpen(true)}>
            <Text align="center" size="sm" style={{ width: "100%" }}>
              + Hantera statusar
            </Text>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <Modal
        opened={addStatusOpen}
        onClose={() => setAddStatusOpen(false)}
        size={"lg"}
        padding={0}
        withCloseButton={false}
        id="STATUS_HANDLE_MODAL"
      >
        <HandleStatusesModal setOpen={setAddStatusOpen} />
      </Modal>
    </>
  );
};

export default StatusSelector;
