import { useEffect, useState, useRef, useCallback } from 'react';

const useDoubleClick = (callback: () => void, timeout: number = 300) => {
  const [timesClicked, setTimesClicked] = useState(0);
  const timerId = useRef<NodeJS.Timeout | null>(null);

  const click = useCallback(() => {
    setTimesClicked(timesClicked + 1);
  }, [timesClicked]);

  useEffect(() => {
    if (!timerId.current && timesClicked > 0) {
      timerId.current = setTimeout(() => {
        if (timerId.current) {
          clearTimeout(timerId.current);
        }
        timerId.current = null;
        setTimesClicked(0);
      }, timeout);
    }

    if (timesClicked > 1) {
      setTimesClicked(0);
      callback();
    }
  }, [callback, timesClicked, timerId, timeout]);

  return click;
};

export default useDoubleClick;
