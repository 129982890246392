import {
  Accordion,
  Avatar,
  Box,
  Button,
  Card,
  Center,
  Checkbox,
  CloseButton,
  createStyles,
  Group,
  Modal,
  PasswordInput,
  Popover,
  SegmentedControl,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
  Title,
  useMantineColorScheme,
} from "@mantine/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IconChevronDown,
  IconDevice,
  IconEnvelope,
  IconHidden,
  IconMoon,
  IconNotificationsFilled,
  IconPassword,
  IconPhone,
  IconSun,
  IconUser,
  IconVisible,
} from "../../../components/Icons";
import { ByggleadsContext } from "../../../providers/ByggleadsProvider";
import { useForm } from "@mantine/form";
import PasswordStrength from "../../../components/PasswordStrength";
import { isValidPhoneNumber } from "libphonenumber-js";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { uploadBytes, getStorage, ref } from "firebase/storage";
// import { useNotifications } from '@mantine/notifications';
import { getAvatarURL } from "../../../utils/Functions";
import { updateDoc } from "firebase/firestore";
import {
  getAuth,
  updateEmail,
  updatePassword,
  updateProfile,
  updatePhoneNumber,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { ErrorCodes } from "../../../utils/ErrorCodes";
import useStateEffect from "../../../utils/hooks/useStateEffect";
import useDoubleClick from "../../../utils/hooks/useDoubleClick";
import { ByggUser, NotificationConfig } from "../../../utils/Types";
import { isEmpty, xor } from "lodash";
import { showNotification } from "@mantine/notifications";

const useStyles = createStyles((theme, _params, getRef) => ({
  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: theme.spacing.sm,

    "&.noPadding": {
      padding: 0,
    },
  },
  avatarBig: {
    width: 80,
    height: 80,
  },
}));

const UserSettings = ({ handleClose }: { handleClose: () => void }) => {
  const { classes, theme, cx } = useStyles();
  const { user } = useContext(ByggleadsContext);
  const [passwordValid, setPasswordValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [confirmSignout, setConfirmSignout] = useState(false);

  const doubleClick = useDoubleClick(() => {
    setConfirmSignout(false);
    signOut(getAuth());
    handleClose();
  });

  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      password: "",
    },

    validate: {
      name: (value) =>
        value.split(" ").length >= 2 ? null : "Ange för- och efternamn",
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Ange e-postadress"),
      phone: (value) => {
        if (!value) return null;
        if (isValidPhoneNumber(value, "SE")) return null;
        return "Ange ett giltigt telefonnummer";
      },
      password: (value) => {
        if (!value) return null;
        if (passwordValid) return null;
        return "Ange ett starkt lösenord";
      },
    },
  });

  const [isEmailProvider, setIsEmailProvider] = useState(false);
  useEffect(() => {
    if (getAuth().currentUser?.providerData[0].providerId === "password") {
      setIsEmailProvider(true);
    }
  }, []);

  useEffect(() => {
    form.setValues({
      name: user?.name || "",
      email: user?.email || "",
      phone: user?.phone || "",
      password: "",
    });
  }, [user]);

  const avatarFileRef = useRef(null);
  const uploadImage = async (files: File[]) => {
    const avatarFile = files[0];
    if (!avatarFile || !user) {
      showNotification({
        message:
          "Någonting gick fel! Testa att ladda om sidan och försök igen, eller testa med en annan fil.",
        color: "red",
      });
      return;
    }

    setLoading(true);

    await uploadBytes(
      ref(getStorage(), `willProcess/users/${user.uid}/${avatarFile.name}`),
      avatarFile
    )
      .then(() => {
        showNotification({
          title: "Din nya profilbild har laddats upp",
          message:
            "Det kommer dröja några sekunder innan du ser den, vi håller fortfarande på att behandla den :)",
        });
      })
      .catch((err) => {
        showNotification({
          message:
            "Någonting gick fel! Testa att ladda om sidan och försök igen, eller testa med en annan fil.",
          color: "red",
        });
        console.error(err);
      });

    setLoading(false);
  };

  const showGeneralErrorMessage = () => {
    showNotification({
      message:
        "Någonting gick fel! Testa att ladda om sidan och försök igen, eller kontakta support.",
      color: "red",
    });
  };

  const [emailNotifications, setEmailNotifications] = useStateEffect<
    NotificationConfig[]
  >(
    [],
    () => {
      if (!user?.notifications?.email) return;
      setEmailNotifications(user?.notifications.email);
    },
    [user]
  );

  const [pushNotifications, setPushNotifications] = useStateEffect<
    NotificationConfig[]
  >(
    [],
    () => {
      if (!user?.notifications?.push) return;
      setPushNotifications(user?.notifications.push);
    },
    [user]
  );

  const saveChanges = async () => {
    const valid = form.validate();
    if (valid.hasErrors) return;
    if (!user) {
      showGeneralErrorMessage();
      return;
    }
    setLoading(true);

    const auth = getAuth();
    const name = form.getInputProps("name").value;
    const email = form.getInputProps("email").value;
    const phone = form.getInputProps("phone").value;
    const password = form.getInputProps("password").value;

    const userRef = user.ref;

    let hasErrors = false;

    if (name && auth.currentUser && name !== user.name) {
      await updateProfile(auth.currentUser, {
        displayName: name,
      })
        .then(async () => {
          await updateDoc(userRef, {
            name: form.getInputProps("name").value,
          }).catch((err) => {
            showGeneralErrorMessage();
            console.error(err);
            hasErrors = true;
          });
        })
        .catch((err) => {
          showGeneralErrorMessage();
          console.error(err);
          hasErrors = true;
        });
    }

    if (email && auth.currentUser && email !== user.email) {
      await updateEmail(auth.currentUser, email)
        .then(async () => {
          await updateDoc(userRef, {
            email: form.getInputProps("email").value,
          }).catch((err: FirebaseError) => {
            showGeneralErrorMessage();
            console.error(err);
            hasErrors = true;
          });
        })
        .catch((err) => {
          console.error(err);
          console.log(err.code);
          if (err.code === "auth/requires-recent-login") {
            setLoginModalOpen(true);
          } else {
            showGeneralErrorMessage();
          }
          hasErrors = true;
        });
    }

    if (phone && auth.currentUser && phone !== user.phone) {
      await updatePhoneNumber(auth.currentUser, phone)
        .then(async () => {
          await updateDoc(userRef, {
            phone: form.getInputProps("phone").value,
          }).catch((err) => {
            showGeneralErrorMessage();
            console.error(err);
            hasErrors = true;
          });
        })
        .catch((err) => {
          showGeneralErrorMessage();
          console.error(err);
          hasErrors = true;
        });
    }

    if (password && auth.currentUser) {
      await updatePassword(auth.currentUser, password).catch((err) => {
        showGeneralErrorMessage();
        console.error(err);
        hasErrors = true;
      });
    }

    if (
      !isEmpty(xor(emailNotifications, user.notifications?.email)) ||
      !isEmpty(xor(pushNotifications, user.notifications?.push))
    ) {
      const params: Partial<ByggUser> = {
        notifications: {
          email: emailNotifications,
          push: pushNotifications,
        },
      };
      await updateDoc(user.ref, params).catch((err) => {
        showGeneralErrorMessage();
        console.error(err);
        hasErrors = true;
      });
    }

    setLoading(false);

    if (!hasErrors) {
      handleClose();
    }
  };

  return (
    <>
      <Card>
        <Card.Section className={classes.section}>
          <Group position="apart" noWrap>
            <Title order={4}>Profil</Title>
            <CloseButton onClick={handleClose} />
          </Group>
        </Card.Section>
        <Card.Section className={cx(classes.section, "noPadding")}>
          <Dropzone
            openRef={avatarFileRef}
            onDrop={uploadImage}
            onReject={(err) => {
              if (err[0].errors[0].code === "file-too-large") {
                showNotification({
                  message: "Filen är för stor, maxstorlek är 20mb",
                  color: "red",
                });
              } else {
                showNotification({
                  message:
                    "Någonting gick fel, vänligen försök med en annan fil",
                  color: "red",
                });
              }
            }}
            accept={[MIME_TYPES.jpeg, MIME_TYPES.png]}
            maxSize={20 * 1024 ** 2}
            multiple={false}
            style={{ width: "100%", border: "none", transition: "none" }}
            p={"sm"}
            loading={loading}
            radius={0}
          >
            <Group position="apart" style={{ width: "100%" }}>
              <Avatar
                src={getAvatarURL(user)}
                className={classes.avatarBig}
                radius={500}
              />
              <Stack style={{ flex: 1 }} spacing={0}>
                <Text weight={500}>Lägg till profilbild</Text>
                <Text size="sm">
                  Släpp en bildfil eller klicka för att välja
                </Text>
              </Stack>
            </Group>
          </Dropzone>
        </Card.Section>
        <Card.Section className={classes.section}>
          <form>
            <SimpleGrid
              cols={1}
              breakpoints={[{ minWidth: "xs", cols: 2 }]}
              spacing="xs"
            >
              <TextInput
                required
                label="Namn"
                placeholder="För- och efternamn"
                disabled={!isEmailProvider}
                icon={<IconUser side={16} />}
                {...form.getInputProps("name")}
              />
              <TextInput
                required
                label="E-postadress"
                placeholder="din@epost.se"
                type="email"
                disabled={!isEmailProvider}
                icon={<IconEnvelope side={16} />}
                {...form.getInputProps("email")}
              />
              <TextInput
                label="Telefonnummer"
                placeholder="070-123 45 67"
                type="tel"
                disabled={!isEmailProvider}
                autoComplete="new-password"
                icon={<IconPhone side={16} />}
                {...form.getInputProps("phone")}
              />
              <PasswordStrength
                target={
                  <PasswordInput
                    label="Byt lösenord"
                    placeholder="********"
                    autoComplete="new-password"
                    disabled={!isEmailProvider}
                    icon={<IconPassword side={16} />}
                    visibilityToggleIcon={({ reveal, size }) =>
                      reveal ? (
                        <IconHidden side={size} />
                      ) : (
                        <IconVisible side={size} />
                      )
                    }
                    {...form.getInputProps("password")}
                  />
                }
                value={form.getInputProps("password").value}
                setPasswordValid={setPasswordValid}
              />
            </SimpleGrid>
          </form>
        </Card.Section>
        <Card.Section className={classes.section}>
          <ThemeSection />
        </Card.Section>
        <Card.Section className={classes.section}>
          <NotificationsSection
            email={emailNotifications}
            setEmail={setEmailNotifications}
            push={pushNotifications}
            setPush={setPushNotifications}
          />
        </Card.Section>
        <Card.Section p={"sm"}>
          <Group position="apart">
            <Popover opened={confirmSignout} withArrow position="bottom">
              <Popover.Target>
                <Button
                  color="red"
                  variant="subtle"
                  onClick={() => {
                    setConfirmSignout(true);
                    doubleClick();
                  }}
                  onMouseLeave={() => setConfirmSignout(false)}
                >
                  Logga ut
                </Button>
              </Popover.Target>
              <Popover.Dropdown>
                <Text size="sm">Dubbelklicka för att logga ut</Text>
              </Popover.Dropdown>
            </Popover>
            <Button onClick={saveChanges} loading={loading}>
              Spara ändringar
            </Button>
          </Group>
        </Card.Section>
      </Card>
      <Modal
        opened={loginModalOpen}
        onClose={() => setLoginModalOpen(false)}
        padding={0}
        withCloseButton={false}
      >
        <LoginModal handleClose={() => setLoginModalOpen(false)} />
      </Modal>
    </>
  );
};

export default UserSettings;

const ThemeSection = ({}: {}) => {
  const { classes, theme } = useStyles();

  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const [mutableColorScheme, setMutableColorScheme] = useStateEffect<
    "light" | "dark" | "device"
  >(
    colorScheme,
    () => {
      const storedTheme = localStorage.getItem("theme");

      if (storedTheme !== "dark" && storedTheme !== "light") {
        setMutableColorScheme("device");
      } else {
        setMutableColorScheme(colorScheme);
      }
    },
    [colorScheme]
  );

  return (
    <>
      <Text
        weight={500}
        size="sm"
        m={2}
        color={
          theme.colorScheme === "dark"
            ? theme.colors.dark[0]
            : theme.colors.gray[9]
        }
      >
        Färgtema
      </Text>
      <SegmentedControl
        style={{ width: "100%" }}
        onChange={(value: "light" | "dark" | "device") => {
          setMutableColorScheme(value);
          switch (value) {
            case "device":
              localStorage.removeItem("theme");
              document.dispatchEvent(new Event("theme_change"));
              break;
            case "light":
              toggleColorScheme("light");
              break;
            case "dark":
              toggleColorScheme("dark");
              break;
          }
        }}
        value={mutableColorScheme}
        data={[
          {
            value: "device",
            label: (
              <Center>
                <IconDevice side={16} weight="medium" />
                <Box mx={10}>Enhetsinställning</Box>
              </Center>
            ),
          },
          {
            value: "light",
            label: (
              <Center>
                <IconSun side={16} weight="medium" />
                <Box mx={10}>Ljust</Box>
              </Center>
            ),
          },
          {
            value: "dark",
            label: (
              <Center>
                <IconMoon side={16} weight="medium" />
                <Box mx={10}>Mörkt</Box>
              </Center>
            ),
          },
        ]}
      />
    </>
  );
};

const NotificationsSection = ({
  email,
  setEmail,
  push,
  setPush,
}: {
  email: NotificationConfig[];
  setEmail: React.Dispatch<React.SetStateAction<NotificationConfig[]>>;
  push: NotificationConfig[];
  setPush: React.Dispatch<React.SetStateAction<NotificationConfig[]>>;
}) => {
  const { theme } = useStyles();

  return (
    <>
      <Text
        weight={500}
        size="sm"
        m={2}
        color={
          theme.colorScheme === "dark"
            ? theme.colors.dark[0]
            : theme.colors.gray[9]
        }
      >
        Notiser
      </Text>
      <Accordion
        multiple
        chevron={<IconChevronDown side={12} weight="bold" />}
        styles={{
          control: {
            padding: theme.spacing.xs,
            borderRadius: theme.radius.sm,
          },
          item: {
            border: "1px solid transparent",
            borderRadius: theme.radius.sm,
            transition: "border-color 0.2s ease, margin 0.2s ease",

            "&[data-active]": {
              borderColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[5]
                  : theme.colors.gray[3],

              marginBottom: theme.spacing.xs,
            },
          },
        }}
      >
        <Accordion.Item value="item-1">
          <Accordion.Control>
            <Group spacing="xs">
              <ThemeIcon variant="light">
                <IconNotificationsFilled side={14} />
              </ThemeIcon>
              Pushnotiser
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <Checkbox.Group
              value={email}
              onChange={(e) => setEmail(e.map((e) => e as NotificationConfig))}
            >
              {Object.keys(NotificationConfig).map((key, index) => {
                return (
                  <Checkbox
                    key={key}
                    value={key}
                    label={Object.values(NotificationConfig)[index]}
                  />
                );
              })}
            </Checkbox.Group>
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="item-2">
          <Accordion.Control>
            <Group spacing="xs">
              <ThemeIcon variant="light">
                <IconEnvelope side={14} />
              </ThemeIcon>
              E-post
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <Checkbox.Group
              value={push}
              onChange={(e) => setPush(e.map((e) => e as NotificationConfig))}
            >
              {Object.keys(NotificationConfig).map((key, index) => {
                return (
                  <Checkbox
                    key={key}
                    value={key}
                    label={Object.values(NotificationConfig)[index]}
                  />
                );
              })}
            </Checkbox.Group>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

const LoginModal = ({ handleClose }: { handleClose: () => void }) => {
  const { classes } = useStyles();

  const [loading, setLoading] = useState(false);

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Ange e-postadress"),
      password: (value) => (value ? null : "Ange lösenord"),
    },
  });

  const signIn = async (values: { email: string; password: string }) => {
    setLoading(true);

    await signInWithEmailAndPassword(getAuth(), values.email, values.password)
      .then((credential) => {
        handleClose();
      })
      .catch((err: FirebaseError) => {
        console.error(err);
        switch (err.code) {
          case "auth/wrong-password":
          case "auth/invalid-email":
          case "auth/user-not-found":
          case "auth/user-disabled":
            showNotification({
              title: "Kunde inte logga in",
              message: ErrorCodes[err.code].message,
              color: "red",
            });
            break;
          default:
            showNotification({
              title: "Kunde inte logga in",
              message: ErrorCodes.standard.message,
              color: "red",
            });
            break;
        }
      });

    setLoading(false);
  };

  return (
    <Card>
      <Card.Section className={classes.section}>
        <Group position="apart" noWrap>
          <Title order={4}>Logga in på nytt</Title>
          <CloseButton onClick={handleClose} />
        </Group>
        <Text size="sm" color={"dimmed"} mt="xs">
          För att uppdatera din profil måste du nyligen ha loggat in. Var vänlig
          logga in på nytt.
        </Text>
      </Card.Section>
      <Card.Section className={classes.section}>
        <form onSubmit={form.onSubmit(signIn)}>
          <TextInput
            required
            label="E-postadress"
            placeholder="din@epost.se"
            type="email"
            icon={<IconEnvelope side={16} />}
            {...form.getInputProps("email")}
          />
          <PasswordInput
            required
            label="Lösenord"
            placeholder="********"
            mt="sm"
            icon={<IconPassword side={16} />}
            visibilityToggleIcon={({ reveal, size }) =>
              reveal ? <IconHidden side={size} /> : <IconVisible side={size} />
            }
            {...form.getInputProps("password")}
          />
        </form>
      </Card.Section>
      <Card.Section p={"sm"}>
        <Group position="right">
          <Button
            type="submit"
            loading={loading}
            onClick={() => form.onSubmit(signIn)}
          >
            Logga in
          </Button>
        </Group>
      </Card.Section>
    </Card>
  );
};
