import {
  Button,
  Card,
  CloseButton,
  createStyles,
  Group,
  Modal,
  NumberInput,
  Select,
  SelectItem,
  Skeleton,
  Table,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { ByggLead, ByggLeadField, ByggLeadTemplate } from "../../utils/Types";
import LeadTemplateCreate from "./LeadTemplateCreate";
import { ByggleadsContext } from "../../providers/ByggleadsProvider";
import { DatePicker, TimeInput } from "@mantine/dates";
import {
  IconAddress,
  IconCalendar,
  IconEnvelope,
  IconNumber,
  IconPhone,
  IconText,
  IconUser,
  IconWorkspaceFilled,
} from "../../components/Icons";
import { find } from "lodash";
import { isTimestamp } from "../../utils/Functions";
import useStateEffect from "../../utils/hooks/useStateEffect";
import moment, { isDate } from "moment";
import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { StatusUnhandledRef } from "../../App";
import { ErrorCodes } from "../../utils/ErrorCodes";
import { showNotification } from "@mantine/notifications";

const useStyles = createStyles((theme, _params, getRef) => ({
  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: theme.spacing.sm,

    "&.noPadding": {
      padding: 0,
    },
  },

  workspaceSelect: {
    backgroundColor: "transparent",
    border: "none",
    width: 192,
    maxWidth: "100%",

    input: {
      backgroundColor: "transparent",
      border: "none",
      padding: 0,
    },
  },
}));

interface inputField {
  value: string | Date | number;
  id: string;
}

const LeadCreate = ({ handleClose }: { handleClose: () => void }) => {
  const { classes, cx } = useStyles();
  const { templates, user, workspace } = useContext(ByggleadsContext);
  const [createTemplate, setCreateTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<ByggLeadTemplate>();
  const [templateOptions, setTemplateOptions] = useState<SelectItem[]>([]);
  const [selectedTemplateOption, setSelectedTemplateOption] =
    useState<string>("");

  useEffect(() => {
    setTemplateOptions(
      templates.map((template) => {
        return {
          value: template.id,
          label: template.title,
        };
      })
    );
  }, [templates]);

  useEffect(() => {
    if (selectedTemplateOption || !templateOptions.length) return;
    setSelectedTemplateOption(templateOptions[0].value);
  }, [templateOptions, selectedTemplateOption]);

  useEffect(() => {
    if (!selectedTemplateOption) return;
    const result = find(templates, (e) => e.id === selectedTemplateOption);
    setSelectedTemplate(result);
  }, [selectedTemplateOption, templates]);

  const [title, setTitle] = useState({
    value: "",
    error: "",
  });
  const [inputFields, setInputFields] = useState<inputField[]>([]);

  useEffect(() => {
    if (!selectedTemplate) return;
    setInputFields(() =>
      selectedTemplate.fields.map((field) => ({
        id: field.id,
        value: isTimestamp(field.value)
          ? field.value.toDate()
          : field.value || "",
      }))
    );
  }, [selectedTemplate]);

  const [loading, setLoading] = useState(false);
  const submit = async () => {
    if (loading || !user?.ref || !selectedTemplate || !workspace?.ref) return;
    if (!title.value) {
      setTitle((p) => ({
        ...p,
        error: "Leadet måste ha en titel.",
      }));
      return;
    }
    setLoading(true);
    console.log(inputFields);

    let fields: ByggLeadField[] = selectedTemplate.fields.map((field) => {
      let value = find(inputFields, (e) => e.id === field.id)?.value;
      return {
        ...field,
        value: isDate(value) ? Timestamp.fromDate(value) : value || field.value,
      };
    });

    let params: Partial<ByggLead> = {
      title: title.value,
      timeCreated: serverTimestamp(),
      createdBy: user.ref,
      seen: false,
      opened: false,
      fields: fields,
      status: StatusUnhandledRef,
      assigned: [],
      template: selectedTemplate.ref,
      platform: "",
      discussionParticipants: [],
    };

    await addDoc(
      collection(getFirestore(), workspace.ref.path, "leads"),
      params
    )
      .then(() => {
        handleClose();
      })
      .catch((err) => {
        console.error(err);
        showNotification({
          message: ErrorCodes["standard"].message,
          color: "red",
        });
        return;
      });

    setLoading(false);
  };

  return (
    <>
      <Card>
        <Card.Section className={classes.section}>
          <Group position="apart" noWrap>
            <Title order={4}>Skapa lead</Title>
            <CloseButton onClick={handleClose} />
          </Group>
        </Card.Section>
        <Card.Section className={cx(classes.section, "noPadding")}>
          <Table>
            <thead>
              <tr>
                <th>Titel</th>
                <th>Värde</th>
              </tr>
            </thead>
            <tbody>
              {selectedTemplate ? (
                <>
                  <tr key={"TITLE"}>
                    <td>
                      <Text size="sm">Leadets titel</Text>
                    </td>
                    <td>
                      <TextInput
                        type="text"
                        value={title.value}
                        onChange={(e) =>
                          setTitle({
                            value: e.target.value,
                            error: "",
                          })
                        }
                        error={title.error}
                        {...getPlaceholderAndIcon("text")}
                      />
                    </td>
                  </tr>
                  {selectedTemplate.fields.map((field) => {
                    return (
                      <tr key={field.id}>
                        <td>
                          <Text size="sm">{field.title}</Text>
                        </td>
                        <td>
                          <InputField
                            field={field}
                            setInputFields={setInputFields}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <>
                  {[1, 2, 3, 4, 5, 6].map((index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <Skeleton height={32} />
                        </td>
                        <td>
                          <Skeleton height={32} />
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </Table>
        </Card.Section>
        <Card.Section p={"sm"}>
          <Group position="apart">
            <LeadTemplateSelector
              options={templateOptions}
              selectedTemplateOption={selectedTemplateOption}
              setSelectedTemplateOption={setSelectedTemplateOption}
              setCreateTemplate={setCreateTemplate}
            />
            <Button
              onClick={submit}
              loading={loading}
              disabled={!selectedTemplate}
            >
              Skapa lead
            </Button>
          </Group>
        </Card.Section>
      </Card>
      <Modal
        opened={createTemplate}
        onClose={() => setCreateTemplate(false)}
        size="xl"
        withCloseButton={false}
        padding={0}
        id="TEMPLATE_CREATE_MODAL"
      >
        <LeadTemplateCreate
          handleClose={() => {
            setCreateTemplate(false);
          }}
        />
      </Modal>
    </>
  );
};

export default LeadCreate;

export const getPlaceholderAndIcon = (typeVariant: string) => {
  const iconSide = 16;

  switch (typeVariant) {
    case "email":
    case "workEmail":
      return {
        placeholder: "exempel@epost.se",
        icon: <IconEnvelope side={iconSide} />,
      };
    case "tel":
    case "workTel":
      return {
        placeholder: "070-123 45 67",
        icon: <IconPhone side={iconSide} />,
      };
    case "date":
      return {
        placeholder: new Date().toLocaleDateString(),
        icon: <IconCalendar side={iconSide} />,
      };
    case "birthDate":
      return {
        placeholder: "1970-01-01",
        icon: <IconCalendar side={iconSide} />,
      };
    case "fullAddress":
      return {
        placeholder: "Exempelvägen 1, 123 45, Exemplia",
        icon: <IconAddress side={iconSide} />,
      };
    case "street":
      return {
        placeholder: "Exempelvägen 1",
        icon: <IconAddress side={iconSide} />,
      };
    case "city":
      return {
        placeholder: "Exempelköping",
        icon: <IconAddress side={iconSide} />,
      };
    case "province":
      return {
        placeholder: "Exemplands län",
        icon: <IconAddress side={iconSide} />,
      };
    case "country":
      return {
        placeholder: "Exemplia",
        icon: <IconAddress side={iconSide} />,
      };
    case "zipCode":
      return {
        placeholder: "123 45",
        icon: <IconAddress side={iconSide} />,
      };
    case "fullName":
      return {
        placeholder: "Namn Namnsson",
        icon: <IconUser side={iconSide} />,
      };
    case "firstName":
      return {
        placeholder: "Namn",
        icon: <IconUser side={iconSide} />,
      };
    case "lastName":
      return {
        placeholder: "Namnsson",
        icon: <IconUser side={iconSide} />,
      };
    case "companyName":
      return {
        placeholder: "Företag AB",
        icon: <IconWorkspaceFilled side={iconSide} />,
      };
    case "number":
      return {
        placeholder: "123",
        icon: <IconNumber side={iconSide} />,
      };
    default:
      return {
        placeholder: "Skriv här",
        icon: <IconText side={iconSide} />,
      };
  }
};

const InputField = ({
  field,
  setInputFields,
}: {
  field: ByggLeadField;
  setInputFields: React.Dispatch<React.SetStateAction<inputField[]>>;
}) => {
  const [stringValue, setStringValue] = useState<string>("");
  const [numberValue, setNumberValue] = useState<number | undefined>(0);

  const [dateValue, setDateValue] = useState<Date | null>(new Date());
  const [timeValue, setTimeValue] = useState<Date>(new Date());
  const [dateTimeValue, setDateTimeValue] = useStateEffect(
    new Date(),
    () => {
      if (!dateValue || !timeValue) return;
      const date = moment(dateValue).format("DD/MM/YYYY");
      const time = moment(timeValue).format("HH:mm:ss");

      setDateTimeValue(
        moment(date + " " + time, "DD/MM/YYYY HH:mm:ss").toDate()
      );
    },
    [dateValue, timeValue]
  );

  useEffect(() => {
    const getValue = () => {
      switch (field.type) {
        case "number":
          return numberValue || 0;
        case "date":
          return dateTimeValue;
        default:
          return stringValue;
      }
    };

    setInputFields((p) => {
      return p.map((value) => {
        if (value.id !== field.id) return value;
        return {
          ...value,
          value: getValue(),
        };
      });
    });
  }, [stringValue, numberValue, dateTimeValue]);

  switch (field.type) {
    case "email":
      return (
        <TextInput
          type="email"
          value={stringValue}
          onChange={(e) => setStringValue(e.target.value)}
          {...getPlaceholderAndIcon(field.typeVariant)}
        />
      );
    case "tel":
      return (
        <TextInput
          type="tel"
          value={stringValue}
          onChange={(e) => setStringValue(e.target.value)}
          {...getPlaceholderAndIcon(field.typeVariant)}
        />
      );
    case "number":
      return (
        <NumberInput
          value={numberValue}
          onChange={(val) => setNumberValue(val)}
          {...getPlaceholderAndIcon(field.typeVariant)}
        />
      );
    case "date":
      return (
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "auto max-content max-content",
            gap: "12px",
            alignItems: "center",
          }}
        >
          <DatePicker
            locale="sv"
            inputFormat="YYYY-MM-DD"
            value={dateValue}
            onChange={setDateValue}
            {...getPlaceholderAndIcon(field.typeVariant)}
          />
          <Text size="sm">kl.</Text>
          <TimeInput value={timeValue} onChange={setTimeValue} withSeconds />
        </div>
      );
    default:
      return (
        <TextInput
          type="text"
          value={stringValue}
          onChange={(e) => setStringValue(e.target.value)}
          {...getPlaceholderAndIcon(field.typeVariant)}
        />
      );
  }
};

const LeadTemplateSelector = ({
  options,
  selectedTemplateOption,
  setSelectedTemplateOption,
  setCreateTemplate,
}: {
  options: SelectItem[];
  selectedTemplateOption: string;
  setSelectedTemplateOption: React.Dispatch<React.SetStateAction<string>>;
  setCreateTemplate: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const setAsTemplate = async (event: string) => {
    if (!options.some((e) => e.value === event)) return;
    setSelectedTemplateOption(event);
  };

  return (
    <Select
      transition={"pop"}
      transitionDuration={80}
      transitionTimingFunction="ease"
      placeholder="Välj en leadmall"
      data={options}
      value={selectedTemplateOption}
      creatable
      shouldCreate={() => true}
      getCreateLabel={() => "+ Skapa leadmall"}
      onCreate={() => {
        setCreateTemplate(true);
        return "";
      }}
      onChange={setAsTemplate}
    />
  );
};
