import {
  ActionIcon,
  Button,
  Container,
  createStyles,
  Group,
  Menu,
  Stack,
  Title,
} from '@mantine/core';
import { motion } from 'framer-motion';
import {
  framerPropsContentStack,
  framerPropsTitleStack,
} from '../../utils/Globals';
import UnhandledLeads from './UnhandledLeads';
import LeadCreate from './LeadCreate';
import LeadTemplateCreate from './LeadTemplateCreate';
import {
  IconCollection,
  IconCollectionAdd,
  IconExpandDown,
} from '../../components/Icons';
import { useModals } from '@mantine/modals';
import LeadTemplateCollection from './LeadTemplateCollection';
import AllLeads from './AllLeads';

const useStyles = createStyles((theme, _params, getRef) => ({
  titleStack: {
    justifyContent: 'flex-start',
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    borderBottom: 'solid 1px',
    borderColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[5]
        : theme.colors.gray[2],
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
  },
  splitButtonLeft: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  splitButtonRight: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: 'none',
    marginLeft: theme.colorScheme === 'dark' ? 1 : 0,
  },
}));

const LeadsDashboard = () => {
  const { classes } = useStyles();
  const modals = useModals();

  return (
    <>
      <Stack spacing={0} className={classes.titleStack} p={'md'}>
        <motion.div {...framerPropsTitleStack}>
          <Group align={'center'} position="apart">
            <Title order={2}>Leads</Title>
            <Group noWrap spacing={0}>
              <Button
                variant="default"
                onClick={() => {
                  const id = modals.openModal({
                    children: (
                      <LeadCreate
                        handleClose={() => {
                          modals.closeModal(id);
                        }}
                      />
                    ),
                    size: 'xl',
                    withCloseButton: false,
                    padding: 0,
                  });
                }}
                className={classes.splitButtonLeft}
              >
                Skapa lead
              </Button>
              <Menu transition="pop" withinPortal>
                <Menu.Target>
                  <ActionIcon
                    size={36}
                    className={classes.splitButtonRight}
                    variant="default"
                  >
                    <IconExpandDown side={20} />
                  </ActionIcon>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item
                    icon={<IconCollection side={16} />}
                    onClick={() => {
                      const id = modals.openModal({
                        children: (
                          <LeadTemplateCollection
                            handleClose={() => {
                              modals.closeModal(id);
                            }}
                          />
                        ),
                        padding: 0,
                        size: 'xl',
                        withCloseButton: false,
                      });
                    }}
                  >
                    Se leadmallar
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconCollectionAdd side={16} />}
                    onClick={() => {
                      const id = modals.openModal({
                        children: (
                          <LeadTemplateCreate
                            handleClose={() => {
                              modals.closeModal(id);
                            }}
                          />
                        ),
                        size: 'xl',
                        withCloseButton: false,
                        padding: 0,
                      });
                    }}
                  >
                    Skapa leadmall
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>
          </Group>
        </motion.div>
      </Stack>
      <Container
        size={'lg'}
        py="md"
        sx={(theme) => ({
          maxWidth: `min(${theme.breakpoints.lg}, 100%)`,
        })}
      >
        <motion.div {...framerPropsContentStack}>
          <Stack spacing={'xl'}>
            <UnhandledLeads />
            <AllLeads />
          </Stack>
        </motion.div>
      </Container>
    </>
  );
};

export default LeadsDashboard;
