import { Center, createStyles, MediaQuery, Paper, Title } from '@mantine/core';
import { AnimatePresence } from 'framer-motion';
import {
  Route,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { motion } from 'framer-motion';

import ImageBG from '../../assets/illustrations/Gradient.jpg';
import Register from './Register';
import SignIn from './SignIn';
import { getFramerMotionProps } from '../../utils/Globals';
import SignInWithEmail from './SignInWithEmail';
import Page404 from '../404';
import ResetPass from './ResetPass';
import { LogoFull } from '../../components/Icons';

const useStyles = createStyles((theme, _params, getRef) => ({
  main: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(10px, 1fr))',
    position: 'fixed',
    left: 0,
    top: 0,
  },
  authSection: {
    height: '100%',
    borderRadius: 0,
    display: 'flex',
  },
  videoWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
    borderRadius: 0,
  },
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  videoOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
  },
}));

const Auth = () => {
  const { classes } = useStyles();
  const location = useLocation();
  const navigationType = useNavigationType();

  return (
    <motion.div
      className={classes.main}
      {...getFramerMotionProps('opacity', navigationType)}
    >
      <Paper className={classes.authSection}>
        <AnimatePresence exitBeforeEnter>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<SignIn />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<SignInWithEmail />} />
            <Route path="/reset" element={<ResetPass />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </AnimatePresence>
        <LogoFull
          side={32}
          style={{
            position: 'fixed',
            top: 32,
            left: 32,
          }}
        />
      </Paper>
      <MediaQuery smallerThan="lg" styles={{ display: 'none' }}>
        <Paper className={classes.videoWrapper}>
          {/* <video
            className={classes.video}
            src={VideoBG}
            muted
            autoPlay
            loop
          ></video> */}
          <img src={ImageBG} className={classes.video} alt="" />
          <Center className={classes.videoOverlay} p="xl">
            {/* <Title
              order={1}
              sx={(theme) => ({
                color: theme.white,
                fontSize: 72,
              })}
            >
              Nya kunder <br />
              på&nbsp;löpande band
            </Title> */}
          </Center>
        </Paper>
      </MediaQuery>
    </motion.div>
  );
};

export default Auth;
