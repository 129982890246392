import {
  Anchor,
  Badge,
  Box,
  Button,
  Card,
  Center,
  CloseButton,
  Code,
  createStyles,
  Group,
  Indicator,
  LoadingOverlay,
  Modal,
  ModalProps,
  Popover,
  ScrollArea,
  SimpleGrid,
  Stack,
  Switch,
  Text,
  TextInput,
  TextInputProps,
  Title,
} from "@mantine/core";
import { useId, useResizeObserver } from "@mantine/hooks";
import { updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { filter, isArray, sortBy } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import EmptyScreen from "../../components/EmptyScreen";
import { IconGripVertical, IconSearch } from "../../components/Icons";
import PopoverHeader from "../../components/PopoverHeader";
import { ByggleadsContext } from "../../providers/ByggleadsProvider";
import { scrollAreaProps } from "../../utils/Globals";
import useStateEffect from "../../utils/hooks/useStateEffect";
import {
  CampaignSnippet,
  InsightsActionDictionary,
  InsightsActionInterface,
  InsightsCampaign,
  InsightsFieldDictionary,
  InsightsFieldInterface,
} from "../../utils/Insights";
import { ByggWorkspace } from "../../utils/Types";

const useStyles = createStyles((theme, _params, getRef) => ({
  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: theme.spacing.sm,
  },
}));

const CampaignsModal = ({ onClose }: { onClose: () => void }) => {
  const { classes } = useStyles();
  const { workspace } = useContext(ByggleadsContext);
  const [stackRef, stackRect] = useResizeObserver();

  const [noAccounts, setNoAccounts] = useState(false);
  const [campaigns, setCampaigns] = useStateEffect<
    Record<string, CampaignSnippet>
  >(
    {},
    () => {
      if (!workspace?.adAccounts || !workspace.adAccounts.length) {
        setCampaigns({});
        setInsightsCampaigns({});
        setNoAccounts(true);
        return;
      }
      setNoAccounts(false);

      const getMetaCampaigns = httpsCallable(
        getFunctions(undefined, "europe-west1"),
        "getMetaCampaigns"
      );

      getMetaCampaigns({
        accountIds: workspace.adAccounts,
      }).then((res) => {
        if (isArray(res.data)) {
          let tempCampaigns: Record<string, CampaignSnippet> = {};
          sortBy(res.data, ["status"]).forEach((row: CampaignSnippet) => {
            tempCampaigns[row.id] = row;
          });
          setCampaigns(tempCampaigns);
        } else {
          throw new Error("Return data was not an array");
        }
      });
    },
    [workspace?.adAccounts]
  );

  const [insightsCampaigns, setInsightsCampaigns] = useStateEffect<
    Record<string, Partial<InsightsCampaign & CampaignSnippet>>
  >(
    {},
    () => {
      if (!workspace) return;
      let tempCampaigns: Record<
        string,
        Partial<InsightsCampaign & CampaignSnippet>
      > = {};
      const emptyCampaign: InsightsCampaign = {
        enabled: false,
        actions: [],
        fields: [],
        id: "",
        account_id: "",
      };
      Object.keys(campaigns).forEach((key: string) => {
        const existingCampaign: InsightsCampaign = workspace.campaigns
          ? workspace.campaigns[key] || emptyCampaign
          : emptyCampaign;

        tempCampaigns[key] = {
          ...existingCampaign,
          ...campaigns[key],
        };
      });

      setInsightsCampaigns(tempCampaigns);
    },
    [campaigns, workspace]
  );

  const [loading, setLoading] = useState(false);
  const submit = async () => {
    if (loading || !workspace?.ref) return;
    setLoading(true);

    let params: Partial<ByggWorkspace> = {
      campaigns: {},
    };
    Object.keys(insightsCampaigns).forEach((key) => {
      let data = insightsCampaigns[key];
      if (
        !data.actions ||
        !data.fields ||
        data.enabled === undefined ||
        !data.id ||
        !data.account_id
      )
        return;
      params.campaigns![key] = {
        actions: data.actions,
        enabled: data.enabled,
        fields: data.fields,
        id: data.id,
        account_id: data.account_id,
      };
    });

    await updateDoc(workspace.ref, params).then(() => {
      onClose();
    });
    setLoading(false);
  };

  const loadingOverlayVisible = useMemo(() => {
    return noAccounts === false && Object.keys(insightsCampaigns).length === 0;
  }, [noAccounts, insightsCampaigns]);

  return (
    <Card>
      <Card.Section className={classes.section}>
        <Group position="apart" noWrap>
          <Title order={4}>Annonskampanjer för {workspace?.name}</Title>
          <CloseButton onClick={onClose} />
        </Group>
      </Card.Section>
      <Card.Section className={classes.section} p={0}>
        {noAccounts && (
          <EmptyScreen label={"Denna arbetsplats har inga annonskonton."} />
        )}
        <ScrollArea
          style={{
            height: `min(70vh, ${stackRect.height + stackRect.y * 2}px)`,
          }}
          {...scrollAreaProps}
        >
          <Stack spacing={"xl"} p="sm" ref={stackRef}>
            {Object.keys(insightsCampaigns).map((key, index) => {
              return (
                <CampaignRow
                  campaign={insightsCampaigns[key]}
                  insightsCampaigns={insightsCampaigns}
                  setInsightsCampaigns={setInsightsCampaigns}
                  key={key}
                />
              );
            })}
            {loadingOverlayVisible && <Box style={{ height: 128 }} />}
          </Stack>
          <LoadingOverlay p={"xl"} visible={loadingOverlayVisible} />
        </ScrollArea>
      </Card.Section>
      <Card.Section>
        <Group p={"sm"} position="right">
          <Button loading={loading} onClick={submit}>
            Spara
          </Button>
        </Group>
      </Card.Section>
    </Card>
  );
};

export default CampaignsModal;

const CampaignRow = ({
  campaign,
  setInsightsCampaigns,
  insightsCampaigns,
}: {
  campaign: Partial<InsightsCampaign & CampaignSnippet>;
  setInsightsCampaigns: React.Dispatch<
    React.SetStateAction<
      Record<string, Partial<InsightsCampaign & CampaignSnippet>>
    >
  >;
  insightsCampaigns: Record<
    string,
    Partial<InsightsCampaign & CampaignSnippet>
  >;
}) => {
  return (
    <Card
      withBorder
      shadow={campaign.enabled ? "sm" : undefined}
      style={{
        transition: "box-shadow 0.15s ease",
      }}
    >
      <Card.Section p={"sm"}>
        <Group position="apart">
          <Group spacing={"xs"}>
            <Switch
              checked={campaign.enabled}
              onChange={(e) => {
                setInsightsCampaigns((p) => {
                  if (!campaign.id) return p;
                  let copy = { ...p };
                  copy[campaign.id].enabled = e.currentTarget.checked;
                  return copy;
                });
              }}
              onLabel="PÅ"
              offLabel="AV"
            />
            <Text size="lg">{campaign.name}</Text>
          </Group>
          <Badge color={campaign.status === "ACTIVE" ? "teal" : "gray"}>
            {campaign.status} CAMPAIGN
          </Badge>
        </Group>
        <Text size="xs" color="dimmed">
          Annonskonto: {campaign.account_name}
        </Text>
        <Group mt={"sm"} align="flex-start">
          <OrderPopover
            label="Mätvärden"
            placeholder="Mätvärden"
            description="Vilka mätvärden som ska redogöras. Innefattar saker som exponeringar, visningar, och spend."
            dataType="FIELD"
            campaign={campaign}
            insightsCampaigns={insightsCampaigns}
            setInsightsCampaigns={setInsightsCampaigns}
          />
          <OrderPopover
            label="Händelser"
            placeholder="Händelser"
            description="Vilka händelser som ska redogöras. Innefattar saker som leads, länkklick, och reaktioner."
            dataType="ACTION"
            campaign={campaign}
            insightsCampaigns={insightsCampaigns}
            setInsightsCampaigns={setInsightsCampaigns}
          />
        </Group>
      </Card.Section>
    </Card>
  );
};

interface OrderPopoverProps extends TextInputProps {
  dataType: "FIELD" | "ACTION";
  campaign: Partial<InsightsCampaign & CampaignSnippet>;
  setInsightsCampaigns: React.Dispatch<
    React.SetStateAction<
      Record<string, Partial<InsightsCampaign & CampaignSnippet>>
    >
  >;
  insightsCampaigns: Record<
    string,
    Partial<InsightsCampaign & CampaignSnippet>
  >;
}

const OrderPopover = ({
  dataType,
  campaign,
  setInsightsCampaigns,
  insightsCampaigns,
  ...other
}: OrderPopoverProps) => {
  const [opened, setOpened] = useState(false);
  const [selectOpened, setSelectOpened] = useState(false);
  const [selected, setSelected] = useStateEffect<string[]>(
    dataType === "FIELD" ? campaign.fields || [] : campaign.actions || [],
    () => {
      setSelected(
        dataType === "FIELD" ? campaign.fields || [] : campaign.actions || []
      );
    },
    [campaign]
  );
  useEffect(() => {
    if (!campaign.id || !insightsCampaigns[campaign.id]) return;
    setInsightsCampaigns((p) => {
      if (!campaign.id) return p;
      let copy = { ...p };
      if (dataType === "FIELD") {
        copy[campaign.id]["fields"] = selected as InsightsCampaign["fields"];
      } else {
        copy[campaign.id]["actions"] = selected as InsightsCampaign["actions"];
      }
      return copy;
    });
  }, [selected]);

  const id = useId();

  return (
    <>
      <Popover
        opened={opened}
        withArrow
        withinPortal
        position="bottom"
        width={320}
        onClose={() => setOpened(false)}
        closeOnClickOutside
        id={id}
      >
        <Popover.Target>
          <TextInput
            readOnly
            {...other}
            value={selected.join(", ")}
            onClick={() => setOpened((p) => !p)}
            style={{ flex: 1 }}
          />
        </Popover.Target>
        <Popover.Dropdown p={"xs"}>
          <PopoverHeader
            label={dataType === "FIELD" ? "Mätvärden" : "Händelser"}
            parentPaddingX="xs"
            parentPaddingY="xs"
            onClose={() => setOpened(false)}
          />
          <DragDropContext
            onDragEnd={({ destination, source }) => {
              const srcI = source.index;
              const desI = destination?.index;

              if (srcI === undefined || desI === undefined) return;

              selected.splice(desI, 0, selected.splice(srcI, 1)[0]);

              setSelected([...selected]);
            }}
          >
            <Droppable droppableId="dnd-list" direction="vertical">
              {(provided) => (
                <Stack
                  spacing={4}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {selected.map((row, index) => {
                    return (
                      <Draggable key={row} index={index} draggableId={row}>
                        {(provided, snapshot) => {
                          return (
                            <Card
                              withBorder
                              p={2}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <Group px={8} spacing={8} noWrap>
                                <Center {...provided.dragHandleProps}>
                                  <IconGripVertical side={14} />
                                </Center>
                                <Box py={2}>
                                  <Text lineClamp={1} size="sm">
                                    {row}
                                  </Text>
                                </Box>
                              </Group>
                            </Card>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                  <Anchor
                    size="sm"
                    weight={500}
                    pt={8}
                    align="center"
                    onClick={() => {
                      setSelectOpened(true);
                      setOpened(false);
                    }}
                  >
                    Lägg till / ta bort mätvärden
                  </Anchor>
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
        </Popover.Dropdown>
      </Popover>
      <SelectModal
        opened={selectOpened}
        onClose={() => {
          setSelectOpened(false);
          setOpened(true);
        }}
        data={
          dataType === "ACTION"
            ? Object.keys(InsightsActionDictionary).map((key) => ({
                ...InsightsActionDictionary[key],
                key,
              }))
            : Object.keys(InsightsFieldDictionary).map((key) => ({
                ...InsightsFieldDictionary[key],
                key,
              }))
        }
        selected={selected}
        setSelected={setSelected}
      />
    </>
  );
};

interface SelectModalProps extends ModalProps {
  data: InsightsActionInterface[] | InsightsFieldInterface[];
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}
const SelectModal = ({
  data,
  selected,
  setSelected,
  ...other
}: SelectModalProps) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useStateEffect<
    SelectModalProps["data"]
  >(
    data,
    () => {
      if (!searchQuery) setFilteredData(data);
      setFilteredData(
        filter(data, (e) => {
          const queryString = e.key + e.group + e.description;
          return queryString.includes(searchQuery);
        })
      );
    },
    [searchQuery]
  );

  return (
    <Modal
      {...other}
      size="lg"
      padding={"md"}
      title="Lägg till / ta bort värden"
    >
      <TextInput
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        icon={<IconSearch side={14} />}
        placeholder="Sök..."
        mb={"md"}
      />
      <SimpleGrid cols={2} spacing={"md"}>
        {filteredData.map((row) => {
          return (
            <Indicator
              withBorder
              size={12}
              disabled={!selected.includes(row.key)}
              key={row.key}
              color="teal"
            >
              <Card
                withBorder
                p={"sm"}
                sx={(theme) => ({
                  cursor: "pointer",
                  borderColor: selected.includes(row.key)
                    ? undefined
                    : "transparent",
                })}
                shadow={selected.includes(row.key) ? "sm" : undefined}
                onClick={() => {
                  setSelected((p) => {
                    let copy = [...p];
                    const index = selected.indexOf(row.key);
                    if (index >= 0) {
                      copy.splice(index, 1);
                      return copy;
                    } else {
                      return [...copy, row.key];
                    }
                  });
                }}
              >
                <Code>{row.key}</Code>
                <Text color="dimmed" mt="xs">
                  {row.description}
                </Text>
              </Card>
            </Indicator>
          );
        })}
      </SimpleGrid>
      <Button onClick={other.onClose} mt={"xl"} fullWidth>
        Klar
      </Button>
    </Modal>
  );
};
