import { Box, Popover, Progress, Text, useMantineTheme } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { IconCheck, IconCross } from "./Icons";

const requirements = [
  { re: /[0-9]/, label: "Innehåller en siffra" },
  { re: /[a-z]/, label: "Innehåller gemener" },
  { re: /[A-Z]/, label: "Innehåller versaler" },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Innehåller specialtecken" },
];

function getStrength(password: string) {
  let multiplier = password.length > 5 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}

const PasswordStrength = ({
  target,
  value,
  setPasswordValid,
  style,
}: {
  target: React.ReactNode;
  value: string;
  setPasswordValid: React.Dispatch<React.SetStateAction<boolean>>;
  style?: React.CSSProperties;
}) => {
  const [popoverOpened, setPopoverOpened] = useState(false);
  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={requirement.label}
      meets={requirement.re.test(value)}
    />
  ));

  useEffect(() => {
    if (!requirements.map((e) => e.re.test(value)).some((e) => e === false)) {
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
    }
  }, [value]);

  const strength = getStrength(value);
  const color = strength === 100 ? "teal" : strength > 50 ? "yellow" : "red";

  return (
    <Popover
      opened={popoverOpened}
      position="bottom"
      withArrow
      trapFocus={false}
      transition="pop-top-left"
      width="target"
      withinPortal
    >
      <Popover.Target>
        <div
          onFocusCapture={() => setPopoverOpened(true)}
          onBlurCapture={() => setPopoverOpened(false)}
          style={style}
        >
          {target}
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <Progress
          color={color}
          value={strength}
          size={5}
          style={{ marginBottom: 10 }}
        />
        <PasswordRequirement
          label="Innehåller minst 6 tecken"
          meets={value.length > 5}
        />
        {checks}
      </Popover.Dropdown>
    </Popover>
  );
};

export default PasswordStrength;

const PasswordRequirement = ({
  meets,
  label,
}: {
  meets: boolean;
  label: string;
}) => {
  const theme = useMantineTheme();

  return (
    <Text
      color={meets ? "teal" : "red"}
      sx={{ display: "flex", alignItems: "center" }}
      mt={7}
      size="sm"
    >
      {meets ? (
        <IconCheck side={theme.fontSizes.sm} />
      ) : (
        <IconCross side={theme.fontSizes.sm} />
      )}{" "}
      <Box ml={10}>{label}</Box>
    </Text>
  );
};
