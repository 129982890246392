import {
  Anchor,
  Box,
  Button,
  createStyles,
  Group,
  Text,
  TextInput,
} from "@mantine/core";
import { useState } from "react";
import { useNavigate, useNavigationType } from "react-router-dom";
import { motion } from "framer-motion";
import { getFramerMotionProps } from "../../utils/Globals";
import { useForm } from "@mantine/form";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { IconEnvelope } from "../../components/Icons";
import { showNotification } from "@mantine/notifications";

const useStyles = createStyles((theme, _params, getRef) => ({
  main: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
  button: {
    width: "100%",
  },
}));

const ResetPass = () => {
  const { classes, theme } = useStyles();
  const navigationType = useNavigationType();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const form = useForm({
    initialValues: {
      email: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Ange e-postadress"),
    },
  });

  const signIn = async (values: { email: string }) => {
    setLoading(true);

    await sendPasswordResetEmail(getAuth(), values.email)
      .then(() => {
        showNotification({
          title: "Återställningslänk skickad",
          message:
            "Vi har skickat en återställningslänk till den angivna mejlen. Kontrollera skräpposten om du inte hittar den i din vanliga inkorg.",
        });
        navigate("/");
      })
      .catch((err) => {
        console.error(err);

        showNotification({
          message:
            "Någonting gick fel! Testa att ladda om sidan och försök igen, eller kontakta support.",
          color: "red",
        });
      });

    setLoading(false);
  };

  return (
    <motion.div
      {...getFramerMotionProps("horizontal", navigationType)}
      className={classes.main}
    >
      <Box sx={{ maxWidth: 320, width: "100%" }} m="auto">
        <Text size="xl" mb="lg" align="center">
          Återställ lösenord
        </Text>
        <form onSubmit={form.onSubmit(signIn)}>
          <TextInput
            required
            label="E-postadress"
            placeholder="din@epost.se"
            type="email"
            icon={<IconEnvelope side={16} />}
            {...form.getInputProps("email")}
          />

          <Group position="apart" mt="md" className={classes.button}>
            <Anchor onClick={() => navigate(-1)}>Tillbaka</Anchor>
            <Button type="submit" loading={loading}>
              Skicka återställningslänk
            </Button>
          </Group>
        </form>
      </Box>
    </motion.div>
  );
};

export default ResetPass;
