import {
  Anchor,
  Button,
  Card,
  CloseButton,
  createStyles,
  DefaultMantineColor,
  Group,
  MultiSelect,
  Paper,
  Select,
  SelectItem,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  TextInput,
  ThemeIcon,
  Title,
} from "@mantine/core";
import RichTextEditor, { Editor, RichTextEditorProps } from "@mantine/rte";
import {
  collection,
  doc,
  DocumentReference,
  getFirestore,
  onSnapshot,
  refEqual,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import React, { useContext, useEffect, useRef, useState } from "react";
import Resizer from "react-image-file-resizer";
import {
  IconAlert,
  IconNew,
  IconNews,
  IconUnknown,
  IconWarning,
} from "../../components/Icons";
import { ByggleadsContext } from "../../providers/ByggleadsProvider";
import useStateEffect from "../../utils/hooks/useStateEffect";
import { ByggBulletin, ByggWorkspace } from "../../utils/Types";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { v4 as uuid } from "uuid";
import { useForm } from "@mantine/form";
import { ErrorCodes } from "../../utils/ErrorCodes";
import { AllWorkspacesRef } from "../../App";
import { showNotification } from "@mantine/notifications";

const useStyles = createStyles((theme, _params, getRef) => ({
  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: theme.spacing.sm,

    "&.noPadding": {
      padding: 0,
    },
  },
}));

const BulletinCreate = ({
  handleClose,
  bulletinRef,
}: {
  handleClose: () => void;
  bulletinRef?: DocumentReference;
}) => {
  const { classes } = useStyles();
  const { user } = useContext(ByggleadsContext);
  const id = useRef(bulletinRef ? bulletinRef.id : uuid());
  const [markdownKey, setMarkdownKey] = useState(uuid())

  const MIN_TITLE_LENGTH = 1;
  const MAX_TITLE_LENGTH = 72;
  const MAX_BYLINE_LENGTH = 80;
  const [workspaceOptions, setWorkspaceOptions] = useStateEffect<SelectItem[]>(
    [],
    () => {
      const listener = onSnapshot(
        collection(getFirestore(), "workspaces"),
        (snapshot) => {
          setWorkspaceOptions(
            snapshot.docs.map((e) => {
              const data = e.data() as ByggWorkspace;
              return {
                value: e.ref.path,
                label: data.name,
              };
            })
          );

          if (!bulletinRef) {
            setWorkspaces([]);
          }
        }
      );

      return () => listener();
    },
    []
  );

  const [workspaces, setWorkspaces] = useState<string[]>([]);

  const form = useForm({
    initialValues: {
      title: "",
      category: "",
      byline: "",
    },

    validate: {
      title: (value) =>
        value.length >= MIN_TITLE_LENGTH && value.length <= MAX_TITLE_LENGTH
          ? null
          : `Titel måste vara mellan ${MIN_TITLE_LENGTH} och ${MAX_TITLE_LENGTH} tecken lång`,
      category: (value) => (value ? null : "Ange kategori"),
      byline: (value) =>
        value.length <= MAX_BYLINE_LENGTH
          ? null
          : `Ingress får vara max ${MAX_BYLINE_LENGTH} tecken långt.`,
    },
  });

  const [markdownValue, setMarkdownValue] = useState("");

  const [bulletin, setBulletin] = useState<ByggBulletin>();

  useEffect(() => {
    if (!bulletinRef) return;
    const listener = onSnapshot(bulletinRef, (snapshot) => {
      if (snapshot.exists()) {
        setBulletin({ ...snapshot.data(), ref: snapshot.ref } as ByggBulletin);
      }
    });
    return () => listener();
  }, [bulletinRef, workspaceOptions]);

  useEffect(() => {
    if (!bulletin) return;

    form.setValues({
      title: bulletin.title,
      category: bulletin.category,
      byline: bulletin.byline,
    });

    setMarkdownValue(bulletin.content);
    setMarkdownKey(uuid())

    setWorkspaces(
      bulletin.workspaces.flatMap((e) => {
        if (refEqual(e, AllWorkspacesRef)) return [];
        return e.path;
      })
    );
  }, [bulletin]);

  const handleImageUpload = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const MAX_WIDTH = 1440;
      const MAX_HEIGHT = 1440;

      const resizable = ["image/png", "image/jpeg"];

      if (resizable.includes(file.type)) {
        try {
          Resizer.imageFileResizer(
            file,
            MAX_WIDTH,
            MAX_HEIGHT,
            "JPEG",
            75,
            0,
            (blob: any) => {
              if (blob instanceof Blob) {
                return uploadBytes(
                  ref(getStorage(), `bulletins/${id.current}/${uuid()}.jpg`),
                  blob
                )
                  .then((res) => {
                    getDownloadURL(res.ref).then((url) => resolve(url));
                  })
                  .catch((err) => {
                    reject(err);
                  });
              }
            },
            "file"
          );
        } catch (err) {
          reject(err);
        }
      } else {
        const MAX_SIZE = 1 * 1000 * 1000; // 1 megabyte
        if (file.size > MAX_SIZE) {
          reject(new Error("Max size is 1 megabyte"));
          return;
        }

        return uploadBytes(
          ref(
            getStorage(),
            `bulletins/${id.current}/${uuid()}.${file.name.split(".").pop()}`
          ),
          file
        )
          .then((res) => {
            getDownloadURL(res.ref).then((url) => resolve(url));
          })
          .catch((err) => {
            reject(err);
          });
      }
    });

  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const submit = async (publish: boolean | null) => {
    if (!user?.ref) return;

    if (loadingSubmit || loadingSave) return;
    if (publish !== null) {
      setLoadingSubmit(true);
    } else {
      setLoadingSave(true);
    }

    let workspaceRefs = workspaces.map((e) => doc(getFirestore(), e));
    if (workspaceRefs.length === 0) {
      workspaceRefs.push(AllWorkspacesRef);
    }

    const published =
      publish === null ? bulletin?.published || false : publish ? true : false;

    let params: Partial<ByggBulletin> = {
      title: form.getInputProps("title").value,
      byline: form.getInputProps("byline").value,
      category: form.getInputProps("category").value,
      content: markdownValue,
      workspaces: workspaceRefs,
      published: published,
    };

    if (!published) {
      params.readBy = [];
    }

    if (!bulletinRef) {
      params.timeCreated = serverTimestamp();
      params.createdBy = user.ref;
      await setDoc(doc(getFirestore(), "bulletins", id.current), params)
        .then((res) => {
          handleClose();
        })
        .catch((err) => {
          showNotification({
            message: ErrorCodes.standard.message,
            color: "red",
          });
        });
    } else {
      await updateDoc(doc(getFirestore(), "bulletins", id.current), params)
        .then((res) => {
          handleClose();
        })
        .catch((err) => {
          showNotification({
            message: ErrorCodes.standard.message,
            color: "red",
          });
        });
    }

    setLoadingSubmit(false);
  };

  return (
    <Paper>
      <Card.Section className={classes.section}>
        <Group position="apart" noWrap>
          <Title order={4}>
            {bulletinRef ? "Redigera anslag" : "Nytt anslag"}
          </Title>
          <CloseButton onClick={handleClose} />
        </Group>
      </Card.Section>
      <Card.Section className={classes.section}>
        <Stack>
          <div>
            <Group mb={5} position="apart">
              <Text
                component="label"
                htmlFor="post-title"
                size="sm"
                weight={500}
              >
                Titel
              </Text>
              <Text
                size="xs"
                weight={
                  form.getInputProps("title").value.length > MAX_TITLE_LENGTH ||
                  form.getInputProps("title").value.length < MIN_TITLE_LENGTH
                    ? 700
                    : 500
                }
                color={
                  form.getInputProps("title").value.length > MAX_TITLE_LENGTH ||
                  form.getInputProps("title").value.length < MIN_TITLE_LENGTH
                    ? "red"
                    : undefined
                }
              >
                {form.getInputProps("title").value.length} / {MAX_TITLE_LENGTH}
              </Text>
            </Group>
            <TextInput
              id="post-title"
              placeholder="Anslagets titel"
              {...form.getInputProps("title")}
            />
          </div>
          <SimpleGrid cols={2}>
            <Group align="flex-end" spacing="xs">
              <Select
                label="Typ av anslag"
                placeholder="Anslagets kategori"
                data={Object.keys(BulletinTypes).map((e) => ({
                  label: BulletinTypes[e].label,
                  value: e,
                }))}
                sx={(theme) => ({
                  flex: 1,
                })}
                {...form.getInputProps("category")}
              />
              <ThemeIcon
                size={36}
                variant="light"
                color={
                  BulletinTypes[form.getInputProps("category").value]?.color ||
                  "gray"
                }
              >
                {BulletinTypes[form.getInputProps("category").value]?.icon || (
                  <IconUnknown side={16} weight="bold" />
                )}
              </ThemeIcon>
            </Group>
            <MultiSelect
              data={workspaceOptions}
              label="Arbetsplatser"
              placeholder="Alla arbetsplatser"
              searchable
              creatable
              shouldCreate={() => workspaces.length > 0}
              getCreateLabel={() => (
                <Anchor weight={500} size="sm">
                  Alla arbetsplatser
                </Anchor>
              )}
              onCreate={() => {
                setWorkspaces([]);
                return "";
              }}
              mt={-1}
              value={workspaces}
              onChange={(e) => setWorkspaces(e)}
            />
          </SimpleGrid>
          <div>
            <Group mb={5} position="apart">
              <Text
                component="label"
                htmlFor="post-byline"
                size="sm"
                weight={500}
              >
                Ingress
              </Text>
              <Text
                size="xs"
                weight={
                  form.getInputProps("byline").value.length > MAX_BYLINE_LENGTH
                    ? 700
                    : 500
                }
                color={
                  form.getInputProps("byline").value.length > MAX_BYLINE_LENGTH
                    ? "red"
                    : undefined
                }
              >
                {form.getInputProps("byline").value.length} /{" "}
                {MAX_BYLINE_LENGTH}
              </Text>
            </Group>
            <Textarea
              id="post-byline"
              placeholder="Ingress"
              {...form.getInputProps("byline")}
            />
          </div>
          <RichTextEditor
            onImageUpload={handleImageUpload}
            controls={BulletinRTEControls}
            value={markdownValue}
            key={markdownKey}
            onChange={(e) => setMarkdownValue(e)}
          />
        </Stack>
      </Card.Section>
      <Card.Section>
        <Group position="right" p="sm">
          <Button
            onClick={() => {
              if (form.validate().hasErrors) return
              submit(null)
            }}
            loading={loadingSave}
            variant="default"
          >
            {bulletinRef ? "Spara ändringar" : "Spara utkast"}
          </Button>
          <Button
            onClick={() => {
              if (form.validate().hasErrors) return
              submit(!bulletin?.published)
            }}
            loading={loadingSubmit}
            color={bulletin?.published ? "red" : undefined}
          >
            {bulletin?.published ? "Avpublicera" : "Publicera"}
          </Button>
        </Group>
      </Card.Section>
    </Paper>
  );
};

export const BulletinRTEControls: RichTextEditorProps["controls"] = [
  ["bold", "italic", "underline", "strike", "clean"],
  ["h2", "h3", "h4", "h5"],
  ["unorderedList", "orderedList"],
  ["link", "image", "video", "blockquote"],
  ["alignLeft", "alignCenter", "alignRight"],
  ["sup", "sub"],
];

export default BulletinCreate;

export const BulletinTypes: Record<
  string,
  { label: string; color: DefaultMantineColor; icon: React.ReactNode }
> = {
  malfunction: {
    label: "Driftstörning",
    color: "red",
    icon: <IconAlert side={16} weight="bold" />,
  },
  maintenance: {
    label: "Varning",
    color: "orange",
    icon: <IconWarning side={16} weight="bold" />,
  },
  new_function: {
    label: "Ny funktion",
    color: "indigo",
    icon: <IconNew side={16} />,
  },
  news: {
    label: "Nyhet",
    color: "green",
    icon: <IconNews side={16} />,
  },
};
