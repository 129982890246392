import {
  Avatar,
  Button,
  Checkbox,
  createStyles,
  Group,
  Menu,
  Skeleton,
  Text,
  Tooltip,
  UnstyledButton,
} from "@mantine/core";
import { filter, isEqual } from "lodash";
import { useContext, useState } from "react";
import { ByggleadsContext } from "../../providers/ByggleadsProvider";
import { getAvatarURL } from "../../utils/Functions";
import { ByggLead, ByggWorkspaceMember } from "../../utils/Types";
import { updateDoc, serverTimestamp, refEqual } from "firebase/firestore";
import { IconCheck } from "../Icons";
import { useDisclosure } from "@mantine/hooks";
import useStateEffect from "../../utils/hooks/useStateEffect";

const useStyles = createStyles((theme, _params, getRef) => ({}));

const AssignedSelector = ({ lead }: { lead?: ByggLead }) => {
  const { classes, theme } = useStyles();
  const { members, user } = useContext(ByggleadsContext);
  const [opened, handlers] = useDisclosure(false);
  const [loading, setLoading] = useState(false);

  const [assigned, setAssigned] = useStateEffect<Set<ByggWorkspaceMember>>(
    new Set([]),
    () => {
      if (!lead) return;
      setAssigned(
        new Set(
          filter(members, (e) =>
            lead.assigned.some((a) => refEqual(a, e.user.ref))
          )
        )
      );
    },
    [lead, members]
  );

  const submit = async () => {
    if (loading || !user?.ref || !lead) return;

    const originalAssigned = new Set(
      filter(members, (e) => lead.assigned.some((a) => refEqual(a, e.user.ref)))
    );

    if (isEqual(originalAssigned, assigned)) {
      handlers.close();
      return;
    }

    setLoading(true);
    await updateDoc(lead.ref, {
      assigned: Array.from(assigned).map((e) => e.user.ref),
      timeUpdated: serverTimestamp(),
    }).then(() => {
      handlers.close();
    });
    setLoading(false);
  };

  if (!lead) {
    return (
      <Group spacing={"xs"}>
        <Skeleton height={12} width={40} />
        <Group spacing={0} noWrap>
          {["1", "2", "3"].map((key, index) => {
            return (
              <Skeleton
                key={key}
                circle
                width={26}
                height={26}
                style={{
                  marginLeft: -(Math.min(index, 1) * 4),
                  boxShadow: `0 0 0 3px ${
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[6]
                      : theme.white
                  }`,
                }}
              />
            );
          })}
        </Group>
      </Group>
    );
  }

  return (
    <Menu
      transition="pop"
      withArrow
      withinPortal
      closeOnItemClick={false}
      opened={opened}
      onOpen={handlers.open}
      onClose={submit}
    >
      <Menu.Target>
        <UnstyledButton>
          <Group spacing={"xs"}>
            <Text weight={500} size="xs">
              Tilldelad:
            </Text>
            <Group spacing={0} noWrap>
              {Array.from(assigned).length ? (
                Array.from(assigned).map((member, index) => {
                  return (
                    <Tooltip
                      label={member.user.name}
                      key={member.user.uid}
                      withArrow
                      transition={"pop"}
                    >
                      <Avatar
                        src={getAvatarURL(member.user)}
                        size="sm"
                        radius={"xl"}
                        style={{
                          marginLeft: -(Math.min(index, 1) * 4),
                          boxShadow: `0 0 0 3px ${
                            theme.colorScheme === "dark"
                              ? theme.colors.dark[6]
                              : theme.white
                          }`,
                        }}
                      />
                    </Tooltip>
                  );
                })
              ) : (
                <Text color="dimmed" size="sm">
                  —
                </Text>
              )}
            </Group>
          </Group>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        {members.map((member, index) => {
          return (
            <Menu.Item
              key={member.user.uid}
              p={8}
              onClick={() => {
                if (assigned.has(member)) {
                  assigned.delete(member);
                } else {
                  assigned.add(member);
                }
                setAssigned(new Set(assigned));
              }}
            >
              <Checkbox
                checked={assigned.has(member)}
                readOnly
                style={{
                  pointerEvents: "none",
                }}
                label={
                  <Group noWrap spacing="xs">
                    <Avatar
                      size="sm"
                      radius="xl"
                      src={getAvatarURL(member.user)}
                    />
                    <Text size="sm">{member.user.name}</Text>
                  </Group>
                }
              />
            </Menu.Item>
          );
        })}
        <Button
          fullWidth
          variant="subtle"
          onClick={submit}
          leftIcon={<IconCheck side={16} weight="bold" />}
          loading={loading}
        >
          Spara
        </Button>
      </Menu.Dropdown>
    </Menu>
  );
};

export default AssignedSelector;
