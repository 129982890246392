import {
  ActionIcon,
  Anchor,
  Badge,
  Box,
  Button,
  Card,
  Center,
  CloseButton,
  createStyles,
  DefaultMantineColor,
  Group,
  Kbd,
  MultiSelect,
  MultiSelectValueProps,
  Popover,
  SelectItemProps,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import EmptyScreen from "../../components/EmptyScreen";
import { ByggLead } from "../../utils/Types";
import { doc, getFirestore, refEqual, Timestamp } from "firebase/firestore";
import LeadTableStatic from "./components/LeadTableStatic";
import {
  IconCalendar,
  IconFilter,
  IconHelp,
  IconPlus,
  IconPulse,
  IconSearch,
} from "../../components/Icons";
import { getHotkeyHandler, useHotkeys } from "@mantine/hooks";
import { DateRangePicker } from "@mantine/dates";
import { ByggleadsContext } from "../../providers/ByggleadsProvider";
import moment from "moment";
import { isTimestamp } from "../../utils/Functions";
import { isArray, isEqual } from "lodash";
import { StatusArchivedRef, StatusUnhandledRef } from "../../App";
import PopoverHeader from "../../components/PopoverHeader";

const useStyles = createStyles((theme, _params, getRef) => ({
  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: theme.spacing.sm,
  },
  headerSection: {
    paddingBottom: 0,
  },
  filterInput: {
    width: `min(384px, calc(100vw - ${theme.spacing.md * 2}))`,
  },
}));

interface LeadFilter {
  dateRange: [Date | null, Date | null];
  statuses: string[];
  searchQuery: string;
}

const AllLeads = () => {
  const { classes, cx } = useStyles();
  const { statuses, leads } = useContext(ByggleadsContext);

  const [filteredLeads, setFilteredLeads] = useState<ByggLead[]>([]);

  const searchBarRef = useRef<HTMLInputElement>(null);

  const [filtersOpen, setFiltersOpen] = useState(false);
  const [hotkeysOpen, setHotkeysOpen] = useState(false);

  useHotkeys([
    ["ctrl+G", () => searchBarRef.current?.focus()],
    ["ctrl+Q", () => setFiltersOpen((p) => !p)],
    ["ctrl+B", () => clearFilters()],
  ]);

  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    const throttle = setTimeout(() => {
      setFilters((p) => ({
        ...p,
        searchQuery: searchQuery,
      }));
    }, 350);

    return () => {
      clearTimeout(throttle);
    };
  }, [searchQuery]);

  const getStartingStatuses = () => {
    const defaultClearedStatuses = [
      StatusUnhandledRef.path,
      StatusArchivedRef.path,
    ];

    return statuses
      .filter(
        (e) =>
          !defaultClearedStatuses.some((s) =>
            refEqual(doc(getFirestore(), s), e.ref)
          )
      )
      .map((e) => e.ref.path);
  };
  const defaultFilters: LeadFilter = {
    dateRange: [null, null],
    statuses: getStartingStatuses(),
    searchQuery: searchQuery,
  };
  const [filters, setFilters] = useState<LeadFilter>(defaultFilters);

  const clearFilters = () => {
    setFilters(() => {
      let clearedFilters = { ...defaultFilters };
      clearedFilters.statuses = [];
      return clearedFilters;
    });
  };

  useEffect(() => {
    setFilters((p) => ({
      ...p,
      statuses: getStartingStatuses(),
    }));
  }, [statuses]);

  useEffect(() => {
    if (!isArray(leads)) return;
    let tempLeads = [...leads];

    if (filters.statuses.length) {
      tempLeads = tempLeads.filter((e) =>
        filters.statuses.some((s) => s === e.status.path)
      );
    }

    const dateStartSeconds = filters.dateRange[0]
      ? Timestamp.fromDate(filters.dateRange[0]).seconds
      : null;
    const dateEndSeconds = filters.dateRange[1]
      ? Timestamp.fromDate(filters.dateRange[1]).seconds
      : null;

    if (dateEndSeconds !== null && dateStartSeconds !== null) {
      tempLeads = tempLeads.filter((e) => {
        if (!isTimestamp(e.timeCreated)) return false;
        return (
          e.timeCreated.seconds >= dateStartSeconds &&
          e.timeCreated.seconds <= dateEndSeconds
        );
      });
    }

    if (searchQuery) {
      const queryRegExp = new RegExp(`(${searchQuery})`, "gi");
      tempLeads = tempLeads.filter((e) => {
        let searchAttributes = e.fields.map((e) => e.value).join(" ");
        searchAttributes += " " + e.title;
        return searchAttributes.match(queryRegExp);
      });
    }

    setFilteredLeads(tempLeads);
  }, [filters, leads]);

  return (
    <Card withBorder radius="sm" p="md">
      <Card.Section className={cx(classes.section)}>
        <Group spacing={"xs"} position="apart">
          <Group spacing={"xs"}>
            <Text size="lg" weight={500}>
              Alla leads
            </Text>
            <Badge color="gray">{filteredLeads.length}</Badge>
          </Group>
          <Group spacing={"xs"} noWrap>
            <TextInput
              variant="default"
              size="xs"
              placeholder="Sök..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              ref={searchBarRef}
              icon={<IconSearch side={16} />}
              onKeyDown={getHotkeyHandler([
                ["ctrl+Q", () => setFiltersOpen((p) => !p)],
                ["ctrl+B", () => clearFilters()],
              ])}
              rightSection={
                <Group spacing={2}>
                  <Kbd style={{ padding: "0 5px", fontSize: "0.7rem" }}>
                    Ctrl
                  </Kbd>
                  +<Kbd style={{ padding: "0 5px", fontSize: "0.7rem" }}>G</Kbd>
                </Group>
              }
              rightSectionWidth={76}
              styles={{ rightSection: { pointerEvents: "none" } }}
            />
            <Group spacing={2} noWrap>
              <Popover
                opened={filtersOpen}
                onClose={() => setFiltersOpen(false)}
                withinPortal
                transition={"pop"}
                width={400}
                position="bottom"
              >
                <Popover.Target>
                  <ActionIcon
                    size={"md"}
                    onClick={() => setFiltersOpen((p) => !p)}
                  >
                    <IconFilter side={16} />
                  </ActionIcon>
                </Popover.Target>
                <Popover.Dropdown>
                  <PopoverHeader
                    label="Filtrering"
                    onClose={() => setFiltersOpen(false)}
                    parentPaddingY="sm"
                    parentPaddingX="md"
                  />
                  <FilterWindow
                    setOpen={setFiltersOpen}
                    filters={filters}
                    setFilters={setFilters}
                  />
                </Popover.Dropdown>
              </Popover>
              <Popover
                opened={hotkeysOpen}
                trapFocus={false}
                closeOnEscape={false}
                transition={"pop"}
                position="bottom"
                width={300}
                withinPortal
              >
                <Popover.Target>
                  <ActionIcon
                    size={"md"}
                    onMouseEnter={() => setHotkeysOpen(true)}
                    onMouseLeave={() => setHotkeysOpen(false)}
                  >
                    <IconHelp side={16} />
                  </ActionIcon>
                </Popover.Target>
                <Popover.Dropdown>
                  <PopoverHeader label="Tangentbordsgenvägar" />
                  <Group position="apart" noWrap mt={"sm"}>
                    <Text size="sm">Öppna filtrering:</Text>
                    <Group spacing={2}>
                      <Kbd style={{ padding: "0 5px", fontSize: "0.7rem" }}>
                        Ctrl
                      </Kbd>
                      +
                      <Kbd style={{ padding: "0 5px", fontSize: "0.7rem" }}>
                        Q
                      </Kbd>
                    </Group>
                  </Group>
                  <Group position="apart" noWrap>
                    <Text size="sm">Sök:</Text>
                    <Group spacing={2}>
                      <Kbd style={{ padding: "0 5px", fontSize: "0.7rem" }}>
                        Ctrl
                      </Kbd>
                      +
                      <Kbd style={{ padding: "0 5px", fontSize: "0.7rem" }}>
                        G
                      </Kbd>
                    </Group>
                  </Group>
                  <Group position="apart" noWrap>
                    <Text size="sm">Rensa filter:</Text>
                    <Group spacing={2}>
                      <Kbd style={{ padding: "0 5px", fontSize: "0.7rem" }}>
                        Ctrl
                      </Kbd>
                      +
                      <Kbd style={{ padding: "0 5px", fontSize: "0.7rem" }}>
                        B
                      </Kbd>
                    </Group>
                  </Group>
                </Popover.Dropdown>
              </Popover>
            </Group>
          </Group>
        </Group>
      </Card.Section>
      <Card.Section>
        {filteredLeads.length || !isArray(leads) ? (
          <LeadTableStatic
            leads={filteredLeads}
            loading={!isArray(leads)}
            searchQuery={filters.searchQuery}
          />
        ) : (
          <EmptyScreen label="Vi hittar inget här. Testa att ändra din filtrering eller din sökfras.">
            <Group spacing={4} align="center">
              <Button variant="subtle" compact onClick={clearFilters}>
                Rensa filtrering:
              </Button>
              <Group spacing={2}>
                <Kbd style={{ padding: "0 5px", fontSize: "0.7rem" }}>Ctrl</Kbd>
                +<Kbd style={{ padding: "0 5px", fontSize: "0.7rem" }}>B</Kbd>
              </Group>
            </Group>
          </EmptyScreen>
        )}
      </Card.Section>
    </Card>
  );
};

export default AllLeads;

const FilterWindow = ({
  setOpen,
  filters,
  setFilters,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  filters: LeadFilter;
  setFilters: React.Dispatch<React.SetStateAction<LeadFilter>>;
}) => {
  const { statuses } = useContext(ByggleadsContext);
  const { classes } = useStyles();

  const [selectedStatuses, setSelectedStatuses] = useState<string[]>(
    filters.statuses
  );

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>(
    filters.dateRange
  );
  const [datePresetsOpened, setDatePresetsOpened] = useState(false);

  useEffect(() => {
    if (
      isEqual(selectedStatuses, filters.statuses) &&
      isEqual(dateRange, filters.dateRange)
    )
      return;

    setFilters((p) => ({
      ...p,
      dateRange: dateRange,
      statuses: selectedStatuses,
    }));
  }, [selectedStatuses, dateRange]);

  return (
    <Stack spacing={"xs"} style={{ maxWidth: "100%" }}>
      <MultiSelect
        data={statuses.map((status) => {
          return {
            value: status.ref.path,
            label: status.title,
          };
        })}
        label="Status"
        placeholder="Filtrera efter status"
        clearable
        withinPortal={false}
        itemComponent={StatusItem}
        valueComponent={StatusValue}
        icon={<IconPulse side={16} />}
        className={classes.filterInput}
        value={selectedStatuses}
        onChange={setSelectedStatuses}
      />
      <Stack spacing={8}>
        <DateRangePicker
          label="Datum"
          placeholder="Filtrera efter datum"
          icon={<IconCalendar side={16} />}
          className={classes.filterInput}
          value={dateRange}
          onChange={setDateRange}
          withinPortal={false}
          locale="sv"
          inputFormat="D MMM YYYY"
        />
        <Popover
          opened={datePresetsOpened}
          onClose={() => setDatePresetsOpened(false)}
          position="bottom"
          withinPortal={false}
          transition={"pop"}
        >
          <Popover.Target>
            <Badge
              style={{ cursor: "pointer" }}
              onClick={() => setDatePresetsOpened((p) => !p)}
              leftSection={
                <Center>
                  <IconPlus side={12} />
                </Center>
              }
            >
              Förinställningar
            </Badge>
          </Popover.Target>
          <Popover.Dropdown>
            <PopoverHeader label="Förinställningar" onClose={() => setDatePresetsOpened(false)} />
            <Stack spacing={"xs"}>
              <Anchor
                color={"gray"}
                size="sm"
                onClick={() => {
                  setDateRange([
                    moment()
                      .subtract(6, "days")
                      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                      .toDate(),
                    new Date(),
                  ]);
                  setDatePresetsOpened(false);
                }}
              >
                Senaste 7 dagarna
              </Anchor>
              <Anchor
                color={"gray"}
                size="sm"
                onClick={() => {
                  setDateRange([
                    moment()
                      .subtract(13, "days")
                      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                      .toDate(),
                    new Date(),
                  ]);
                  setDatePresetsOpened(false);
                }}
              >
                Senaste 14 dagarna
              </Anchor>
              <Anchor
                color={"gray"}
                size="sm"
                onClick={() => {
                  setDateRange([
                    moment()
                      .subtract(29, "days")
                      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                      .toDate(),
                    new Date(),
                  ]);
                  setDatePresetsOpened(false);
                }}
              >
                Senaste 30 dagarna
              </Anchor>
              <Anchor
                color={"gray"}
                size="sm"
                onClick={() => {
                  setDateRange([
                    moment()
                      .subtract(89, "days")
                      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                      .toDate(),
                    new Date(),
                  ]);
                  setDatePresetsOpened(false);
                }}
              >
                Senaste 90 dagarna
              </Anchor>
              <Anchor
                color={"gray"}
                size="sm"
                onClick={() => {
                  setDateRange([
                    moment()
                      .weekday(0)
                      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                      .toDate(),
                    new Date(),
                  ]);
                  setDatePresetsOpened(false);
                }}
              >
                Denna vecka
              </Anchor>
              <Anchor
                color={"gray"}
                size="sm"
                onClick={() => {
                  setDateRange([
                    moment()
                      .date(1)
                      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                      .toDate(),
                    new Date(),
                  ]);
                  setDatePresetsOpened(false);
                }}
              >
                Denna månad
              </Anchor>
              <Anchor
                color={"gray"}
                size="sm"
                onClick={() => {
                  setDateRange([
                    moment()
                      .dayOfYear(1)
                      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                      .toDate(),
                    new Date(),
                  ]);
                  setDatePresetsOpened(false);
                }}
              >
                Detta år
              </Anchor>
            </Stack>
          </Popover.Dropdown>
        </Popover>
        <Group className={classes.filterInput} spacing={0}></Group>
      </Stack>
      <Button
        onClick={() => {
          setOpen(false);
        }}
      >
        Klar
      </Button>
    </Stack>
  );
};

const StatusItem = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ label, value, ...others }: SelectItemProps, ref) => {
    const { statuses } = useContext(ByggleadsContext);

    const getColor = (): DefaultMantineColor => {
      let result = "gray";
      if (value) {
        statuses.forEach((e) => {
          if (refEqual(e.ref, doc(getFirestore(), value))) {
            result = e.color;
          }
        });
      }

      return result;
    };

    return (
      <Box ref={ref} {...others}>
        <Badge color={getColor()} style={{ pointerEvents: "none" }}>
          {label}
        </Badge>
      </Box>
    );
  }
);

const StatusValue = ({
  value,
  label,
  onRemove,
  classNames,
  ...others
}: MultiSelectValueProps & { value: string }) => {
  const { statuses } = useContext(ByggleadsContext);

  const getColor = (): DefaultMantineColor => {
    let result = "gray";
    statuses.forEach((e) => {
      if (refEqual(e.ref, doc(getFirestore(), value))) {
        result = e.color;
      }
    });

    return result;
  };

  return (
    <Box {...others}>
      <Badge
        color={getColor()}
        rightSection={
          <CloseButton
            onMouseDown={onRemove}
            variant="transparent"
            color={getColor()}
            size={22}
            iconSize={14}
            tabIndex={-1}
          />
        }
      >
        {label}
      </Badge>
    </Box>
  );
};
