import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { NavigateFunction } from "react-router-dom";
import { updateDoc, getFirestore, doc } from "firebase/firestore";
import { ByggNotification } from "../utils/Types";
import { hideNotification, showNotification } from "@mantine/notifications";
import { v4 as uuid } from "uuid";

const fireCredentials = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

initializeApp(fireCredentials);

export const requestFCMToken = () => {
  const messaging = getMessaging();
  return getToken(messaging, {
    vapidKey: process.env.REACT_APP_MESSAGING_VAPID,
  })
    .then((currentToken) => {
      if (currentToken) {
        // console.log('current token for client: ', currentToken);
        // Perform any other neccessary action with the token
        return currentToken;
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
        return undefined;
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      return undefined;
    });
};

export const onMessageListener = (navigate: NavigateFunction) => {
  const messaging = getMessaging();
  return onMessage(messaging, (payload) => {
    // if (displayedNotifications.includes(payload.collapseKey)) return;
    // displayedNotifications.push(payload.collapseKey);

    const id = uuid();
    showNotification({
      id,
      title: payload.notification?.title,
      message: payload.notification?.body,
      style: { cursor: "pointer" },
      onClick: () => {
        if (payload?.data?.intent) {
          hideNotification(id);
          if (payload.data.intent.charAt(0) === "/") {
            navigate(payload.data.intent);
          } else {
            window.open(payload.data.intent, "_blank");
          }
          if (payload.data.path) {
            const params: Partial<ByggNotification> = {
              opened: true,
              seen: true,
            };
            updateDoc(doc(getFirestore(), payload.data.path), params);
          }
        }
      },
    });
  });
};
