import { DocumentReference, Timestamp, FieldValue } from "firebase/firestore";
import React from "react";
import { InsightsCampaign } from "./Insights";

export interface ByggUser {
  name: string;
  email?: string;
  phone?: string;
  photoURL?: {
    path?: string;
    fromProvider?: string;
    w64?: string;
    w128?: string;
    w240?: string;
    w480?: string;
    w720?: string;
    w960?: string;
    w1280?: string;
    w1400?: string;
    w1600?: string;
    w1920?: string;
  };
  workspace?: DocumentReference;
  uid: string;
  ref: DocumentReference;
  fcmTokens?: [];
  notifications?: {
    email: NotificationConfig[];
    push: NotificationConfig[];
  };
}

export enum NotificationConfig {
  new_lead = "Nya leads",
  new_comment = "Nya kommentarer",
  new_assigned = "Nya tilldelningar",
  status_changed = "Statusändringar",
  general = "Generella",
  invite = "Inbjudningar",
  reminder = "Påminnelse",
}

export interface ByggWorkspace {
  name: string;
  registration: string;
  website?: string;
  admins: DocumentReference[];
  members: DocumentReference[];
  address: {
    street: string;
    zip: string;
    city: string;
  };
  contact: {
    name: string;
    phone: string;
    email: string;
  };
  ref?: DocumentReference;
  creator: DocumentReference;
  adAccounts?: string[];
  campaigns?: Record<string, InsightsCampaign>;
  notifications?: {
    new_lead: DocumentReference[];
    new_comment: DocumentReference[];
    new_assigned: DocumentReference[];
    status_changed: DocumentReference[];
  };
}

export interface ByggWorkspaceMember {
  user: ByggUser;
  role: "admin" | "user";
}

export interface ByggInvite {
  email: string;
  status: "approved" | "denied" | "pending";
  emailInviteSent: boolean;
  createdBy: DocumentReference;
  timeCreated: Timestamp | FieldValue;
}

export interface ZapierField {
  title: string;
  type: "text" | "tel" | "email" | "address" | "date";
}

export interface ByggLead {
  title: string;
  timeCreated: Timestamp | FieldValue;
  createdBy:
    | DocumentReference
    | "Facebook"
    | "Instagram"
    | "TikTok"
    | "LinkedIn"
    | "Google"
    | "Forms";
  seen: boolean;
  opened: boolean;
  status: DocumentReference;
  assigned: DocumentReference[];
  fields: ByggLeadField[];
  template: DocumentReference;
  platform: string;
  campaignID: string;
  adsetID: string;
  adID: string;
  leadID: string;
  formID: string;
  requestBody: object;
  ref: DocumentReference;
  queryMatch?: React.ReactNode;
  discussionParticipants: DocumentReference[];
  doNotAutoArchive?: boolean;
  render?: {
    status?: ByggLeadStatus;
    assigned?: ByggWorkspaceMember[];
  };
}

export interface ByggLeadTemplate {
  title: string;
  fields: ByggLeadField[];
  id: string;
  workspace: string;
  createdAt: Timestamp | FieldValue;
  createdBy: DocumentReference;
  ref: DocumentReference;
}

export interface ByggLeadField {
  title: string;
  type: "string" | "number" | "date" | string;
  typeExtended: string;
  typeVariant:
    | "text"
    | "date"
    | "number"
    | "shortText"
    | "longText"
    | "select"
    | "email"
    | "tel"
    | "fullAddress"
    | "street"
    | "city"
    | "province"
    | "country"
    | "zipCode"
    | "fullName"
    | "firstName"
    | "lastName"
    | "birthDate"
    | "gender"
    | "relationship"
    | "military"
    | "jobTitle"
    | "workTel"
    | "workEmail"
    | "companyName"
    | string;
  id: string;
  value?: string | number | Timestamp;
}

export interface ByggLeadStatus {
  title: string;
  color:
    | "dark"
    | "gray"
    | "red"
    | "pink"
    | "grape"
    | "violet"
    | "indigo"
    | "blue"
    | "cyan"
    | "teal"
    | "green"
    | "lime"
    | "yellow"
    | "orange";
  ref: DocumentReference;
  order: number;
}

export interface ByggLeadTimelineEntry {
  event: "assigned" | "created" | "edited" | "status";
  beforeRefs: DocumentReference[];
  afterRefs: DocumentReference[];
  timeCreated: Timestamp | FieldValue;
  ref: DocumentReference;
}

export interface ByggComment {
  id: string;
  ref: DocumentReference;
  body: string;
  sender: DocumentReference;
  answering?: DocumentReference;
  timeCreated: Timestamp | FieldValue;
}

export const CampaignStatusDict = {
  ACTIVE: {
    label: "Aktiv",
    color: "teal",
  },
  PAUSED: {
    label: "Pausad",
    color: "yellow",
  },
  DELETED: {
    label: "Borttagen",
    color: "red",
  },
  ARCHIVED: {
    label: "Arkiverad",
    color: "red",
  },
};

export interface ByggNotification {
  title?: string;
  body: string;
  timeCreated: FieldValue | Timestamp;
  intent?: string;
  priority: boolean;
  seen: boolean;
  opened: boolean;
  type: NotificationConfig;
  ref?: DocumentReference;
}

export interface ByggBulletin {
  title: string;
  byline: string;
  category: string;
  workspaces: DocumentReference[];
  content: string;
  createdBy: DocumentReference;
  timeCreated: Timestamp | FieldValue;
  published: boolean;
  ref: DocumentReference;
  readBy: DocumentReference[] | FieldValue;
  render?: {
    new?: boolean;
    unread?: boolean;
  };
}

export interface ByggInsights {
  campaign_name: string;
  account_name: string;
  fields: Record<
    string,
    {
      curr: string;
      comp?: string;
    }
  >;
  actions: Record<
    string,
    {
      curr: string;
      comp?: string;
    }
  >;
}
