import {
  createStyles,
  Stack,
  Group,
  ThemeIcon,
  Text,
  Center,
  Button,
} from '@mantine/core';
import { Link } from 'react-router-dom';
import { ByggError } from '../../utils/ErrorCodes';
import { IconAlert } from '../Icons';

const useStyles = createStyles((theme, _params, getRef) => ({
  popupImage: {
    width: '100%',
    height: 256,
    maxHeight: '35vh',
    objectFit: 'contain',
    backgroundImage: `linear-gradient(150deg, ${theme.colors.red[4]}, ${theme.colors.red[6]})`,
    borderTopLeftRadius: theme.radius.sm,
    borderTopRightRadius: theme.radius.sm,
    padding: theme.spacing.sm,
  },
  fullWidth: {
    width: '100%',
  },
  userButton: {
    pointerEvents: 'none',
  },
  footer: {
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: theme.spacing.md,
  },
}));

const ErrorModal = ({
  handleClose,
  error,
}: {
  handleClose: () => void;
  error: ByggError;
}) => {
  const { classes } = useStyles();

  return (
    <>
      <Center className={classes.popupImage}>
        <Stack align={'center'} spacing="xs">
          <ThemeIcon size={48} color={'red'} variant="light">
            <IconAlert side={28} weight={'bold'} />
          </ThemeIcon>
          <Text
            size="sm"
            transform="uppercase"
            weight={700}
            style={{ color: 'white' }}
          >
            Åtgärd krävs
          </Text>
        </Stack>
      </Center>

      <Stack px="md" mt={'lg'} spacing={'xs'} pb={'xl'} align={'flex-start'}>
        <Text size="lg" weight={500}>
          {error.title}
        </Text>
        <Text size="sm">{error.message}</Text>
      </Stack>
      <Group position="right" className={classes.footer}>
        {error.secondaryButtons &&
          error.secondaryButtons.map((button) => {
            return (
              <Link key={button.link} to={button.link} onClick={handleClose}>
                <Button variant={'default'}>{button.label}</Button>
              </Link>
            );
          })}
        {error.mainButton && (
          <Link
            key={error.mainButton.link}
            to={error.mainButton.link}
            onClick={handleClose}
          >
            <Button variant={'filled'}>{error.mainButton.label}</Button>
          </Link>
        )}
      </Group>
    </>
  );
};

export default ErrorModal;
