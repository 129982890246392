import { Badge, Card, createStyles, Group, Text } from '@mantine/core';
import React, { useContext, useEffect, useState } from 'react';
import EmptyScreen from '../../components/EmptyScreen';
import { ByggLead } from '../../utils/Types';
import LeadTableStatic from './components/LeadTableStatic';
import { isArray } from 'lodash';
import { ByggleadsContext } from '../../providers/ByggleadsProvider';
import { StatusUnhandledRef } from '../../App';
import { refEqual } from 'firebase/firestore';

const useStyles = createStyles((theme, _params, getRef) => ({
  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: theme.spacing.sm,
  },
}));

const UnhandledLeads = () => {
  const { classes } = useStyles();
  const { leads } = useContext(ByggleadsContext);

  const [unhandledLeads, setUnhandledLeads] = useState<ByggLead[]>([]);

  useEffect(() => {
    if (!isArray(leads)) return;
    setUnhandledLeads(
      leads.filter((e) => refEqual(e.status, StatusUnhandledRef))
    );
  }, [leads]);

  return (
    <Card withBorder radius="sm" p="md">
      <Card.Section className={classes.section}>
        <Group spacing={'xs'}>
          <Text size="lg" weight={500}>
            Obehandlade leads
          </Text>
          <Badge
            color={unhandledLeads.length >= 5 ? 'red' : undefined}
            variant={unhandledLeads.length >= 10 ? 'outline' : undefined}
          >
            {unhandledLeads.length}
          </Badge>
        </Group>
      </Card.Section>
      <Card.Section>
        {unhandledLeads.length || !isArray(leads) ? (
          <LeadTableStatic leads={unhandledLeads} loading={!isArray(leads)} />
        ) : (
          <EmptyScreen label="Inga obehandlade leads!" />
        )}
      </Card.Section>
    </Card>
  );
};

export default UnhandledLeads;
