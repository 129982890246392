import {
  Avatar,
  Button,
  Card,
  CloseButton,
  createStyles,
  Group,
  MultiSelect,
  MultiSelectValueProps,
  Paper,
  SelectItem,
  SelectItemProps,
  SimpleGrid,
  Text,
} from '@mantine/core';
import { forwardRef, useContext, useState } from 'react';
import { ByggleadsContext } from '../../providers/ByggleadsProvider';
import { getAvatarURL } from '../../utils/Functions';
import useStateEffect from '../../utils/hooks/useStateEffect';
import { v4 as uuid } from 'uuid';
import { ByggUser, ByggWorkspace } from '../../utils/Types';
import { find, isEmpty, xor } from 'lodash';
import { updateDoc, doc, getFirestore, refEqual } from 'firebase/firestore';
import { showNotification } from '@mantine/notifications';
import { ErrorCodes } from '../../utils/ErrorCodes';

const useStyles = createStyles((theme, _params, getRef) => ({
  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: theme.spacing.sm,
  },
}));

const NotificationsSettings = () => {
  const { classes } = useStyles();
  const { workspace, members } = useContext(ByggleadsContext);

  const [newLead, setNewLead] = useStateEffect<string[]>(
    [],
    () => {
      if (!workspace?.notifications?.new_lead) return;
      setNewLead(workspace.notifications.new_lead.map((e) => e.path));
    },
    [workspace]
  );
  const [newDiscussion, setNewDiscussion] = useStateEffect<string[]>(
    [],
    () => {
      if (!workspace?.notifications?.new_comment) return;
      setNewDiscussion(workspace.notifications.new_comment.map((e) => e.path));
    },
    [workspace]
  );
  const [newAssigned, setNewAssigned] = useStateEffect<string[]>(
    [],
    () => {
      if (!workspace?.notifications?.new_assigned) return;
      setNewAssigned(workspace.notifications.new_assigned.map((e) => e.path));
    },
    [workspace]
  );
  const [statusChange, setStatusChange] = useStateEffect<string[]>(
    [],
    () => {
      if (!workspace?.notifications?.status_changed) return;
      setStatusChange(
        workspace.notifications.status_changed.map((e) => e.path)
      );
    },
    [workspace]
  );

  const hasChanges = () => {
    if (!workspace?.notifications) {
      if (
        newLead.length ||
        newDiscussion.length ||
        newAssigned.length ||
        statusChange.length
      ) {
        return true;
      }
      return false;
    }
    let has = false;
    if (
      !isEmpty(
        xor(
          newLead,
          workspace.notifications.new_lead.map((e) => e.path)
        )
      )
    ) {
      has = true;
    }
    if (
      !isEmpty(
        xor(
          newDiscussion,
          workspace.notifications.new_comment.map((e) => e.path)
        )
      )
    ) {
      has = true;
    }
    if (
      !isEmpty(
        xor(
          newAssigned,
          workspace.notifications.new_assigned.map((e) => e.path)
        )
      )
    ) {
      has = true;
    }
    if (
      !isEmpty(
        xor(
          statusChange,
          workspace.notifications.status_changed.map((e) => e.path)
        )
      )
    ) {
      has = true;
    }
    return has;
  };

  const [loading, setLoading] = useState(false);
  const submit = async () => {
    if (loading || !workspace?.ref) return;
    setLoading(true);

    const params: Partial<ByggWorkspace> = {
      notifications: {
        new_lead: newLead.map((e) => doc(getFirestore(), e)),
        new_comment: newDiscussion.map((e) => doc(getFirestore(), e)),
        new_assigned: newAssigned.map((e) => doc(getFirestore(), e)),
        status_changed: statusChange.map((e) => doc(getFirestore(), e)),
      },
    };

    await updateDoc(workspace.ref, params).catch((err) => {
      console.error(err);
      showNotification({
        message: ErrorCodes.standard.message,
        color: 'red',
      });
    });

    setLoading(false);
  };

  return (
    <Card withBorder radius="sm" p="md">
      <Card.Section className={classes.section}>
        <Text size="lg" weight={500}>
          Notifikationsinställningar
        </Text>
        <Text size="sm" color={'dimmed'}>
          Kontrollera vem som alltid får notifikationer vid olika händelser
        </Text>
      </Card.Section>
      <Card.Section p={'sm'} className={classes.section}>
        <SimpleGrid
          cols={1}
          breakpoints={[{ minWidth: 'md', cols: 2 }]}
          spacing="xs"
        >
          <MultiSelect
            label="Vid nya leads"
            placeholder="Ingen"
            itemComponent={ItemComponent}
            valueComponent={ValueComponent}
            value={newLead}
            onChange={setNewLead}
            data={members.map(
              (e): SelectItem => ({
                value: e.user.ref.path,
                label: e.user.name,
              })
            )}
          />
          <MultiSelect
            label="Vid diskussionsmeddelande"
            placeholder="Ingen"
            itemComponent={ItemComponent}
            valueComponent={ValueComponent}
            value={newDiscussion}
            onChange={setNewDiscussion}
            data={members.map(
              (e): SelectItem => ({
                value: e.user.ref.path,
                label: e.user.name,
              })
            )}
          />
          <MultiSelect
            label="Vid tilldelning"
            placeholder="Ingen"
            itemComponent={ItemComponent}
            valueComponent={ValueComponent}
            value={newAssigned}
            onChange={setNewAssigned}
            data={members.map(
              (e): SelectItem => ({
                value: e.user.ref.path,
                label: e.user.name,
              })
            )}
          />
          <MultiSelect
            label="Vid statusändring"
            placeholder="Ingen"
            itemComponent={ItemComponent}
            valueComponent={ValueComponent}
            value={statusChange}
            onChange={setStatusChange}
            data={members.map(
              (e): SelectItem => ({
                value: e.user.ref.path,
                label: e.user.name,
              })
            )}
          />
        </SimpleGrid>
      </Card.Section>
      <Card.Section>
        <Group position="right" p="sm">
          <Button onClick={submit} loading={loading} disabled={!hasChanges()}>
            Spara
          </Button>
        </Group>
      </Card.Section>
    </Card>
  );
};

export default NotificationsSettings;

const ValueComponent = ({
  value,
  label,
  onRemove,
  classNames,
  ...others
}: MultiSelectValueProps & { value: string }) => {
  const { members } = useContext(ByggleadsContext);

  const [member, setMember] = useStateEffect<ByggUser | undefined>(
    undefined,
    () => {
      const tempMember = find(members, (e) =>
        refEqual(e.user.ref, doc(getFirestore(), value))
      );
      setMember(tempMember?.user);
    },
    [members]
  );

  return (
    <Paper
      {...others}
      key={member ? member.uid : uuid()}
      withBorder
      py={2}
      px={6}
    >
      <Group spacing={4}>
        {member && (
          <Avatar size={20} radius={'xl'} src={getAvatarURL(member)} mr={6} />
        )}
        <Text size="sm">{member ? member.name : label}</Text>

        <CloseButton
          onMouseDown={onRemove}
          variant="transparent"
          size={22}
          iconSize={14}
          tabIndex={-1}
        />
      </Group>
    </Paper>
  );
};

const ItemComponent = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ label, value, ...others }: SelectItemProps, ref) => {
    const { members } = useContext(ByggleadsContext);

    const [member, setMember] = useStateEffect<ByggUser | undefined>(
      undefined,
      () => {
        if (!value) return;
        const tempMember = find(members, (e) =>
          refEqual(e.user.ref, doc(getFirestore(), value))
        );
        setMember(tempMember?.user);
      },
      [members, value]
    );

    return (
      <Paper
        ref={ref}
        {...others}
        key={member ? member.uid : uuid()}
        py={'xs'}
        px={'sm'}
      >
        <Group spacing={4}>
          {member && (
            <Avatar size={20} radius={'xl'} src={getAvatarURL(member)} mr={6} />
          )}
          <Text size="sm">{member ? member.name : label}</Text>
        </Group>
      </Paper>
    );
  }
);
