export interface ByggError {
  message: string;
  mainButton?: {
    link: string;
    label: string;
  };
  secondaryButtons?: { link: string; label: string }[];
  title?: string;
}

export const ErrorCodes: Record<string, ByggError> = {
  'auth/unverified-email': {
    message: 'Du måste verifiera din e-postadress.',
  },
  'auth/wrong-password': {
    message: 'Felaktigt lösenord',
  },
  'auth/invalid-email': {
    message: 'Ogiltig e-postadress',
  },
  'auth/user-not-found': {
    message: 'Det här kontot finns inte',
  },
  'auth/user-disabled': {
    message: 'Kontot är avstängt',
  },
  'auth/popup-closed-by-user': {
    message:
      'Det verkar som att du avbröt inloggningsprocessen. Om du inte gjorde detta, kontakta support.',
  },
  'auth/email-already-in-use': {
    message: 'E-postadressen är redan registrerad',
  },
  missing_workspace: {
    message:
      "Du saknar en arbetsplats. Du kan skapa en genom att klicka på knappen 'Skapa arbetsplats'.",
    title: 'Du saknar en arbetsplats.',
    mainButton: {
      label: 'Skapa arbetsplats',
      link: '/workspace/create',
    },
    secondaryButtons: [],
  },
  missing_payment: {
    message: '',
    title: '',
    secondaryButtons: [],
  },

  standard: {
    message:
      'Någonting gick fel. Vänligen försök igen, eller kontakta support.',
  },
};
