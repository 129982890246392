import { Button, createStyles, Stack, Text, Title } from '@mantine/core';
import { motion } from 'framer-motion';
import { useNavigate, useNavigationType } from 'react-router-dom';
import { getFramerMotionProps } from '../utils/Globals';

const useStyles = createStyles((theme, _params, getRef) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    padding: theme.spacing.sm,
  },
  inner: {
    maxWidth: 420,
    width: '100%',
  },
}));

const Page404 = ({ backAction }: { backAction?: () => {} }) => {
  const { classes } = useStyles();
  const navigationType = useNavigationType();
  const navigate = useNavigate();

  const navigateHome = () => navigate('/');

  return (
    <motion.div
      {...getFramerMotionProps('horizontal', navigationType)}
      className={classes.main}
    >
      <Stack spacing={0} className={classes.inner} align="flex-start">
        <Title order={1}>404</Title>
        <Text size="xl">Hoppsan! Denna sidan hittar vi inte.</Text>
        <Text>Den här sidan existerar inte eller har blivit borttagen.</Text>
        <Button mt={'md'} onClick={backAction || navigateHome}>
          Ta mig hem
        </Button>
      </Stack>
    </motion.div>
  );
};

export default Page404;
