import {
  ActionIcon,
  Button,
  Card,
  CloseButton,
  createStyles,
  Group,
  Modal,
  Select,
  Stack,
  Table,
  Text,
  TextInput,
  Title,
  Tooltip,
  UnstyledButton,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useContext, useEffect, useState } from "react";
import { IconCross, IconGripVertical } from "../../components/Icons";
import { getFirestore, updateDoc, doc, deleteDoc } from "firebase/firestore";
import { ByggleadsContext } from "../../providers/ByggleadsProvider";
import { ErrorCodes } from "../../utils/ErrorCodes";
import { v4 as uuid } from "uuid";
import { ByggLeadTemplate } from "../../utils/Types";
import {
  cleanByggFields,
  FieldValueRow,
  JSONOutput,
  leadDataTypes,
} from "./LeadTemplateCreate";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { isPermitted } from "../../utils/Functions";
import TextConfirmModal from "../../components/Modals/TextConfirmModal";

const useStyles = createStyles((theme, _params, getRef) => ({
  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: theme.spacing.sm,

    "&.noPadding": {
      padding: 0,
    },
  },

  dimmedButton: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[2]
        : theme.colors.gray[6],

    "&:hover": {
      color: "inherit",
    },
  },
  codeDisplay: {
    position: "relative",
    width: "100%",
  },
  copyButton: {
    position: "absolute",
    top: theme.spacing.sm,
    right: theme.spacing.sm,
  },
  fieldTableRow: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
  },
}));

const LeadTemplateEdit = ({
  template,
  handleClose,
}: {
  template: ByggLeadTemplate | null;
  handleClose: () => void;
}) => {
  const { classes, cx } = useStyles();
  const [loading, setLoading] = useState(false);
  const { workspace, role } = useContext(ByggleadsContext);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [title, setTitle] = useState("");
  const defaultField: FieldValueRow = {
    title: "",
    type: "0.text",
    key: uuid(),
  };
  const [fields, setFields] = useState<FieldValueRow[]>([defaultField]);

  useEffect(() => {
    if (!template) return;

    setTitle(template?.title);
    setFields([
      ...template.fields.map((field): FieldValueRow => {
        return {
          key: field.id,
          title: field.title,
          type: field.typeExtended,
        };
      }),
      defaultField,
    ]);
  }, [template]);

  const submit = async () => {
    let valid = true;
    fields.forEach((field, index) => {
      if (!field.title || !field.type) {
        if (index !== fields.length - 1) {
          valid = false;
        }
      }
    });
    if (!valid) {
      showNotification({
        message: "Samtliga fält måste ha titel och värdetyp",
        color: "red",
      });
      return;
    }
    if (!title) {
      showNotification({
        message: "Du måste fylla i en titel",
        color: "red",
      });
      return;
    }
    if (!workspace?.ref || !template) {
      showNotification({
        message: ErrorCodes.standard.message,
        color: "red",
      });
      return;
    }
    setLoading(true);

    const params: Partial<ByggLeadTemplate> = {
      title: title,
      fields: cleanByggFields(fields),
    };

    await updateDoc(
      doc(
        getFirestore(),
        "workspaces",
        workspace.ref.id,
        "leadTemplates",
        template.id
      ),
      params
    )
      .then((res) => {
        handleClose();
      })
      .catch((err) => {
        console.error(err);
        showNotification({
          message: ErrorCodes.standard.message,
          color: "red",
        });
      });

    setLoading(false);
  };

  const deleteTemplate = () => {
    if (!workspace?.ref || !template?.id) {
      showNotification({
        message: ErrorCodes.standard.message,
        color: "red",
      });
      return;
    }
    deleteDoc(
      doc(getFirestore(), workspace.ref.path, "leadTemplates", template.id)
    )
      .then(() => {
        handleClose();
      })
      .catch((err) => {
        console.error(err);
        showNotification({
          message: ErrorCodes.standard.message,
          color: "red",
        });
      });
  };

  return (
    <>
      <Card>
        <Card.Section className={classes.section}>
          <Group position="apart" noWrap>
            <Title order={4}>Ändra mall</Title>
            <CloseButton onClick={handleClose} />
          </Group>
        </Card.Section>
        <Card.Section className={classes.section}>
          <Text size="sm">
            Leadmallen är den viktigaste beståndsdelen för att Byggleads ska
            fungera som det ska. <br />
            Börja med att ge din mall ett namn, och bygg sedan fälten som du
            vill ha dem.
          </Text>
          <Text size="sm" mt="xs" color="green">
            Om du inte förstår hur detta fungerar hjälper vi dig gärna via vår
            livechatt. <br />
            Den öppnar du genom att klicka på den lilla chattbubblan nere i det
            högra hörnet av skärmen.
          </Text>
        </Card.Section>
        <Card.Section className={classes.section}>
          <Stack>
            <TextInput
              placeholder="Mallens namn..."
              label="Ge mallen ett namn"
              required
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Stack>
        </Card.Section>
        <Card.Section className={cx(classes.section, "noPadding")}>
          <DragDropContext
            onDragEnd={({ destination, source }) => {
              const srcI = source.index;
              const desI = destination?.index;

              if (srcI === undefined || desI === undefined) return;

              fields.splice(desI, 0, fields.splice(srcI, 1)[0]);

              setFields([...fields]);
            }}
          >
            <Table>
              <thead>
                <tr>
                  <th style={{ width: 24 }}></th>
                  <th>Titel</th>
                  <th>Värdetyp</th>
                  <th>Åtgärder</th>
                </tr>
              </thead>
              <Droppable droppableId="field-dnd" direction="vertical">
                {(provided) => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {fields.map((row, index) => {
                      return (
                        <FieldRow
                          key={row.key}
                          row={row}
                          fields={fields}
                          setFields={setFields}
                          defaultField={defaultField}
                          index={index}
                          classes={classes}
                        />
                      );
                    })}
                    {provided.placeholder}
                    <tr>
                      <td></td>
                      <td>
                        <UnstyledButton
                          className={classes.dimmedButton}
                          onClick={() => setFields((p) => [...p, defaultField])}
                        >
                          <Text size="sm">+ Lägg till rad</Text>
                        </UnstyledButton>
                      </td>
                    </tr>
                  </tbody>
                )}
              </Droppable>
            </Table>
          </DragDropContext>
        </Card.Section>
        <Card.Section p={"sm"}>
          <Group position="right">
            <Button
              variant="subtle"
              color="red"
              onClick={() => setConfirmDelete(true)}
            >
              Radera mall
            </Button>
            <Button onClick={submit} loading={loading}>
              Spara ändringar
            </Button>
          </Group>
          {isPermitted(["superadmin"], role) && (
            <JSONOutput
              id={template?.id || ""}
              fields={cleanByggFields(fields)}
            />
          )}
        </Card.Section>
      </Card>
      <Modal
        opened={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        title={"Vill du verkligen ta bort leadmallen?"}
      >
        <TextConfirmModal
          control={title}
          onCancel={() => setConfirmDelete(false)}
          onConfirm={deleteTemplate}
        />
      </Modal>
    </>
  );
};

export default LeadTemplateEdit;

const FieldRow = ({
  row,
  fields,
  setFields,
  defaultField,
  index,
  classes,
}: {
  row: FieldValueRow;
  fields: FieldValueRow[];
  setFields: React.Dispatch<React.SetStateAction<FieldValueRow[]>>;
  defaultField: FieldValueRow;
  index: number;
  classes: Record<"dimmedButton" | "fieldTableRow", string>;
}) => {
  const body = (
    <>
      <td>
        <TextInput
          placeholder="Titel..."
          variant="unstyled"
          value={row.title}
          onChange={(e) => {
            row.title = e.target.value;
            setFields([...fields]);
          }}
          onBlur={() => {
            if (fields[fields.length - 1].title) {
              setFields((p) => [...p, defaultField]);
            }
          }}
        />
      </td>
      <td>
        <Select
          placeholder="Typ"
          variant="unstyled"
          data={leadDataTypes}
          value={row.type}
          searchable
          onChange={(e) => {
            row.type = e || "";
            setFields([...fields]);
          }}
        />
      </td>
      <td>
        <Tooltip label="Ta bort rad" withArrow transition="pop">
          <ActionIcon
            size="md"
            sx={(theme) => ({
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[6]
                  : theme.colors.gray[0],
            })}
            onClick={() => {
              if (fields.length === 1) {
                setFields([defaultField]);
              } else {
                fields.splice(index, 1);
                setFields([...fields]);
              }
            }}
          >
            <IconCross side={16} />
          </ActionIcon>
        </Tooltip>
      </td>
    </>
  );

  if (index === fields.length - 1) {
    return (
      <tr>
        <td
          style={{
            width: 24,
            padding: 0,
            paddingLeft: 8,
          }}
        />
        {body}
      </tr>
    );
  }

  return (
    <Draggable index={index} draggableId={row.key}>
      {(provided, snapshot) => {
        return (
          <tr
            className={classes.fieldTableRow}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <td
              style={{
                width: 24,
                padding: 0,
                paddingLeft: 8,
              }}
            >
              <Text
                {...provided.dragHandleProps}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                }}
                color="dimmed"
                component="div"
              >
                <IconGripVertical side={16} />
              </Text>
            </td>
            {body}
          </tr>
        );
      }}
    </Draggable>
  );
};
