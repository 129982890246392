import {
  Anchor,
  Button,
  Card,
  Checkbox,
  Container,
  createStyles,
  Divider,
  Group,
  Paper,
  Stack,
  Stepper,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { AnimatePresence, motion } from "framer-motion";
import { isValidPhoneNumber } from "libphonenumber-js";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ByggleadsContext } from "../../providers/ByggleadsProvider";
import { framerPropsContentStack } from "../../utils/Globals";
import { ByggWorkspace } from "../../utils/Types";
import { getFirestore, setDoc, doc, updateDoc } from "firebase/firestore";
import { v4 as uuid } from "uuid";
import { showNotification } from "@mantine/notifications";
import { ErrorCodes } from "../../utils/ErrorCodes";
import {
  IconAddress,
  IconCheck,
  IconAlert,
  IconEnvelope,
  IconPhone,
  IconUser,
  IconWebsite,
  IconWorkspaceFilled,
} from "../../components/Icons";
import { Center } from "@mantine/core";

const useStyles = createStyles((theme, _params, getRef) => ({
  dangerBanner: {
    borderColor:
      theme.colorScheme === "dark"
        ? `${theme.colors.red[8]}25`
        : theme.colors.red[1],
    borderWidth: 2,
  },
  input: {
    width: 320,
  },
  inputCardWrapper: {
    width: 540,
    maxWidth: "100%",
    marginBlock: 32,
  },
  inputCard: {},
  previewCard: {
    width: 320,
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[8]
        : theme.colors.gray[0],
  },
  buttonSection: {
    borderTop: `solid 1px ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },
}));

const WorkspaceCreate = () => {
  const { classes } = useStyles();
  const { user } = useContext(ByggleadsContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [active, setActive] = useState(0);
  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  type FormKeys =
    | "name"
    | "registration"
    | "website"
    | "address_street"
    | "address_zip"
    | "address_city"
    | "contact_name"
    | "contact_phone"
    | "contact_email"
    | "termsOfService";
  const form = useForm({
    initialValues: {
      name: "",
      registration: "",
      website: "",
      address_street: "",
      address_zip: "",
      address_city: "",
      contact_name: "",
      contact_phone: "",
      contact_email: "",
      termsOfService: false,
    },

    validate: {
      name: (value) => (value ? null : "Ange arbetsplatsens namn"),
      registration: (value) => (value ? null : "Ange organisationsnummer"),
      address_street: (value) =>
        value.split(" ").length > 1 ? null : "Ange gatuadress & husnummer",
      address_zip: (value) => (value ? null : "Ange postnummer"),
      address_city: (value) => (value ? null : "Ange stad"),
      contact_name: (value) =>
        value.split(" ").length > 1 ? null : "Ange för- och efternamn",
      contact_email: (value) =>
        /^\S+@\S+$/.test(value) ? null : "Ange e-postadress",
      contact_phone: (value) => {
        if (value) {
          if (isValidPhoneNumber(value, "SE")) return null;
        }
        return "Ange ett giltigt telefonnummer";
      },
      termsOfService: (value: boolean) =>
        value ? null : "Du måste godkänna användarvillkoren",
    },
  });

  const createWorkspace = async (values: typeof form.values) => {
    console.log(values)
    if (!user) {
      showNotification({
        message: ErrorCodes.standard.message,
        color: "red",
      });
      return;
    }
    setLoading(true);

    const userRef = user.ref;

    const workspaceObject: ByggWorkspace = {
      name: values.name,
      registration: values.registration,
      website: values.website,
      address: {
        street: values.address_street,
        zip: values.address_zip,
        city: values.address_city,
      },
      contact: {
        name: values.contact_name,
        phone: values.contact_phone,
        email: values.contact_email,
      },
      admins: [userRef],
      members: [userRef],
      creator: userRef,
    };

    const id = uuid();
    const ref = doc(getFirestore(), "workspaces", id);

    await setDoc(ref, workspaceObject).catch((err) => {
      console.error(err);
      showNotification({
        message: ErrorCodes.standard.message,
        color: "red",
      });
      setLoading(false);
      return;
    });

    await updateDoc(userRef, {
      workspace: ref,
    }).catch((err) => {
      console.error(err);
      showNotification({
        message: ErrorCodes.standard.message,
        color: "red",
      });
      setLoading(false);
      return;
    });

    setLoading(false);
    navigate("/workspace");
  };

  const containsErrors = (keys: FormKeys[]) => {
    let hasError = false;
    keys.forEach((key) => {
      if (form.getInputProps(key).error) {
        hasError = true;
      }
    });
    return hasError;
  };

  const step1Keys: FormKeys[] = ["name", "registration", "website"];
  const step2Keys: FormKeys[] = [
    "address_street",
    "address_zip",
    "address_city",
  ];
  const step3Keys: FormKeys[] = [
    "contact_name",
    "contact_phone",
    "contact_email",
  ];

  return (
    <motion.div {...framerPropsContentStack}>
      <Container size={"md"}>
        <Stack spacing={"lg"} py="md" mt="xl" align="center">
          <Title order={3} mb="md">
            Skapa arbetsplats
          </Title>
          <form onSubmit={form.onSubmit(createWorkspace)}>
            <AnimatePresence exitBeforeEnter>
              <Stepper
                active={active}
                onStepClick={setActive}
                breakpoint="sm"
                size="xs"
                completedIcon={<IconCheck side={16} weight="bold" />}
                key={`step_${active}`}
              >
                <Stepper.Step
                  label="Steg 1"
                  description="Bolagsinformation"
                  icon={<IconWorkspaceFilled side={16} />}
                  completedIcon={
                    containsErrors(step1Keys) ? (
                      <IconAlert side={16} weight="bold" />
                    ) : undefined
                  }
                  color={containsErrors(step1Keys) ? "red" : undefined}
                >
                  <motion.div {...framerPropsContentStack}>
                    <Center className={classes.inputCardWrapper}>
                      <Card withBorder className={classes.inputCard} p="md">
                        <Stack align="center" py="sm">
                          <TextInput
                            required
                            label="Namn"
                            placeholder="Arbetsplatsens namn"
                            className={classes.input}
                            icon={<IconWorkspaceFilled side={16} />}
                            {...form.getInputProps("name")}
                          />
                          <TextInput
                            required
                            label="Org.nr."
                            placeholder="123456-7890"
                            className={classes.input}
                            icon={<IconWorkspaceFilled side={16} />}
                            {...form.getInputProps("registration")}
                          />
                          <TextInput
                            label="Hemsida"
                            placeholder="byggleads.se"
                            className={classes.input}
                            icon={<IconWebsite side={16} />}
                            {...form.getInputProps("website")}
                          />
                        </Stack>
                        <Card.Section p="md" className={classes.buttonSection}>
                          <Group position="right">
                            <Button
                              variant="filled"
                              onClick={() => {
                                step1Keys.forEach((key) =>
                                  form.validateField(key)
                                );
                                nextStep();
                              }}
                            >
                              Nästa steg
                            </Button>
                          </Group>
                        </Card.Section>
                      </Card>
                    </Center>
                  </motion.div>
                </Stepper.Step>
                <Stepper.Step
                  label="Steg 2"
                  description="Adress"
                  icon={<IconAddress side={16} />}
                  completedIcon={
                    containsErrors(step2Keys) ? (
                      <IconAlert side={16} weight="bold" />
                    ) : undefined
                  }
                  color={containsErrors(step2Keys) ? "red" : undefined}
                >
                  <motion.div {...framerPropsContentStack}>
                    <Center className={classes.inputCardWrapper}>
                      <Card withBorder className={classes.inputCard} p="md">
                        <Stack align="center" py="sm">
                          <TextInput
                            required
                            label="Gatuadress"
                            placeholder="Exempeladress 4"
                            className={classes.input}
                            icon={<IconAddress side={16} />}
                            {...form.getInputProps("address_street")}
                          />
                          <TextInput
                            required
                            label="Postnummer"
                            placeholder="12345"
                            className={classes.input}
                            icon={<IconAddress side={16} />}
                            {...form.getInputProps("address_zip")}
                          />
                          <TextInput
                            required
                            label="Stad"
                            placeholder="Postort"
                            className={classes.input}
                            icon={<IconAddress side={16} />}
                            {...form.getInputProps("address_city")}
                          />
                        </Stack>
                        <Card.Section p="md" className={classes.buttonSection}>
                          <Group position="apart">
                            <Anchor onClick={prevStep}>Tillbaka</Anchor>
                            <Button
                              variant="filled"
                              onClick={() => {
                                step2Keys.forEach((key) =>
                                  form.validateField(key)
                                );
                                nextStep();
                              }}
                            >
                              Nästa steg
                            </Button>
                          </Group>
                        </Card.Section>
                      </Card>
                    </Center>
                  </motion.div>
                </Stepper.Step>

                <Stepper.Step
                  label="Steg 3"
                  description="Kontaktperson"
                  icon={<IconUser side={16} />}
                  completedIcon={
                    containsErrors(step3Keys) ? (
                      <IconAlert side={16} weight="bold" />
                    ) : undefined
                  }
                  color={containsErrors(step3Keys) ? "red" : undefined}
                >
                  <motion.div {...framerPropsContentStack}>
                    <Center className={classes.inputCardWrapper}>
                      <Card withBorder className={classes.inputCard} p="md">
                        <Stack align="center" py="sm">
                          <TextInput
                            required
                            label="Namn"
                            placeholder="För- och efternamn"
                            className={classes.input}
                            icon={<IconUser side={16} />}
                            {...form.getInputProps("contact_name")}
                          />
                          <TextInput
                            required
                            label="E-postadress"
                            placeholder="din@epost.se"
                            type="email"
                            className={classes.input}
                            icon={<IconEnvelope side={16} />}
                            {...form.getInputProps("contact_email")}
                          />
                          <TextInput
                            required
                            label="Telefonnummer"
                            placeholder="070-123 45 67"
                            type="tel"
                            className={classes.input}
                            icon={<IconPhone side={16} />}
                            {...form.getInputProps("contact_phone")}
                          />
                        </Stack>
                        <Card.Section p="md" className={classes.buttonSection}>
                          <Group position="apart">
                            <Anchor onClick={prevStep}>Tillbaka</Anchor>
                            <Button
                              variant="filled"
                              onClick={() => {
                                step3Keys.forEach((key) =>
                                  form.validateField(key)
                                );
                                nextStep();
                              }}
                            >
                              Nästa steg
                            </Button>
                          </Group>
                        </Card.Section>
                      </Card>
                    </Center>
                  </motion.div>
                </Stepper.Step>
                <Stepper.Completed>
                  <motion.div {...framerPropsContentStack}>
                    <Center className={classes.inputCardWrapper}>
                      <Card withBorder className={classes.inputCard} p="md">
                        <Stack align="center" py="sm">
                          <Paper
                            className={classes.previewCard}
                            py="sm"
                            px="md"
                          >
                            <Divider
                              label="Bolagsinformation"
                              labelPosition="center"
                            />
                            <PreviewRow
                              title="Bolagsnamn"
                              value={form.getInputProps("name").value}
                              error={form.getInputProps("name").error}
                              step={0}
                              setActive={setActive}
                            />
                            <PreviewRow
                              title="Org. nr"
                              value={form.getInputProps("registration").value}
                              error={form.getInputProps("registration").error}
                              step={0}
                              setActive={setActive}
                            />
                            <PreviewRow
                              title="Hemsida"
                              value={form.getInputProps("website").value}
                              error={form.getInputProps("website").error}
                              step={0}
                              setActive={setActive}
                            />
                            <Divider label="Adress" labelPosition="center" />
                            <PreviewRow
                              title="Gatuadress"
                              value={form.getInputProps("address_street").value}
                              error={form.getInputProps("address_street").error}
                              step={1}
                              setActive={setActive}
                            />
                            <PreviewRow
                              title="Postnummer"
                              value={form.getInputProps("address_zip").value}
                              error={form.getInputProps("address_zip").error}
                              step={1}
                              setActive={setActive}
                            />
                            <PreviewRow
                              title="Stad"
                              value={form.getInputProps("address_city").value}
                              error={form.getInputProps("address_city").error}
                              step={1}
                              setActive={setActive}
                            />
                            <Divider
                              label="Kontaktperson"
                              labelPosition="center"
                            />
                            <PreviewRow
                              title="Namn"
                              value={form.getInputProps("contact_name").value}
                              error={form.getInputProps("contact_name").error}
                              step={2}
                              setActive={setActive}
                            />
                            <PreviewRow
                              title="E-postadress"
                              value={form.getInputProps("contact_email").value}
                              error={form.getInputProps("contact_email").error}
                              step={2}
                              setActive={setActive}
                            />
                            <PreviewRow
                              title="Telefonnummer"
                              value={form.getInputProps("contact_phone").value}
                              error={form.getInputProps("contact_phone").error}
                              step={2}
                              setActive={setActive}
                            />
                          </Paper>
                          <Checkbox
                            mt="md"
                            label="Jag godkänner användarvillkoren"
                            style={{
                              width: "100%",
                            }}
                            {...form.getInputProps("termsOfService", {
                              type: "checkbox",
                            })}
                          />
                        </Stack>
                        <Card.Section p="md" className={classes.buttonSection}>
                          <Group position="apart">
                            <Anchor onClick={prevStep}>Tillbaka</Anchor>
                            <Button
                              variant="filled"
                              onClick={() => {
                                if (form.validate().hasErrors) return
                                createWorkspace(form.values)
                              }}
                              loading={loading}
                            >
                              Skapa arbetsplats
                            </Button>
                          </Group>
                        </Card.Section>
                      </Card>
                    </Center>
                  </motion.div>
                </Stepper.Completed>
              </Stepper>
            </AnimatePresence>
          </form>
        </Stack>
      </Container>
    </motion.div>
  );
};

export default WorkspaceCreate;

const PreviewRow = ({
  title,
  value,
  error,
  step,
  setActive,
}: {
  title: string;
  value: string;
  error?: React.ReactNode;
  step: number;
  setActive: React.Dispatch<React.SetStateAction<number>>;
}) => {
  return (
    <Text
      size="sm"
      onClick={() => setActive(step)}
      style={{ cursor: "pointer" }}
    >
      <Text component="span" inherit weight={500} color="dimmed">
        {title}
        {": "}
      </Text>
      {value || "—"}
      {error && (
        <>
          <Text color="red" size="xs" mb="xs">
            {error}
          </Text>
        </>
      )}
    </Text>
  );
};
