import {
  Anchor,
  Box,
  Button,
  Checkbox,
  createStyles,
  Group,
  PasswordInput,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useNavigate, useNavigationType } from 'react-router-dom';
import {
  IconEnvelope,
  IconHidden,
  IconPassword,
  IconUser,
  IconVisible,
} from '../../components/Icons';
import { getFramerMotionProps } from '../../utils/Globals';
import {
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
  getAuth,
} from 'firebase/auth';
import { FirebaseError, initializeApp, deleteApp } from 'firebase/app';
import { getFirestore, setDoc, doc } from 'firebase/firestore';
import { v4 as uuid } from 'uuid';
import fireCredentials from '../../firebase/clientApp';
import PasswordStrength from '../../components/PasswordStrength';
import { ErrorCodes } from '../../utils/ErrorCodes';
import { showNotification } from '@mantine/notifications';

const useStyles = createStyles((theme, _params, getRef) => ({
  main: {
    width: '100%',
    height: '100%',
    display: 'flex',
  },
}));

const Register = () => {
  const { classes, theme } = useStyles();
  const navigationType = useNavigationType();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [passwordValid, setPasswordValid] = useState(false);

  const form = useForm({
    initialValues: {
      name: '',
      email: '',
      password: '',
      termsOfService: false,
    },

    validate: {
      name: (value) =>
        value.split(' ').length >= 2 ? null : 'Ange för- och efternamn',
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Ange e-postadress'),
      password: () => (passwordValid ? null : 'Ange ett starkt lösenord'),
      termsOfService: (value: boolean) =>
        value ? null : 'Du måste godkänna användarvillkoren',
    },
  });

  const register = async (values: {
    name: string;
    email: string;
    password: string;
    termsOfService: boolean;
  }) => {
    setLoading(true);

    const appId = uuid();

    const app = initializeApp(fireCredentials, appId);

    await createUserWithEmailAndPassword(
      getAuth(app),
      values.email,
      values.password
    )
      .then(async (credential) => {
        await updateProfile(credential.user, {
          displayName: values.name,
        }).catch((err) => {
          console.error(err);
        });

        await sendEmailVerification(credential.user);

        await setDoc(
          doc(getFirestore(), 'users', credential.user.uid),
          {
            name: values.name,
            email: credential.user.email,
            phone: credential.user.phoneNumber,
            photoURL: {
              fromProvider: credential.user.photoURL,
            },
            uid: credential.user.uid,
          },
          { merge: true }
        ).catch((err) => {
          window.alert(err);
          console.error(err);
        });
        showNotification({
          title: 'Bara en sak kvar...',
          message:
            'Du måste verifiera din e-postadress. Vi har skickat en verifieringslänk till den angivna e-postadressen.',
        });
        return deleteApp(app).then(() => navigate('/'));
      })
      .catch((err: FirebaseError) => {
        console.error(err);
        switch (err.code) {
          case 'auth/email-already-in-use':
          case 'auth/invalid-email':
            showNotification({
              title: 'Kunde inte registrera användare',
              message: ErrorCodes[err.code].message,
              color: 'red',
            });
            break;
          default:
            showNotification({
              title: 'Kunde inte registrera användare',
              message: ErrorCodes.standard.message,
              color: 'red',
            });
            break;
        }
      });

    setLoading(false);
  };

  return (
    <motion.div
      {...getFramerMotionProps('horizontal', navigationType)}
      className={classes.main}
    >
      <Box sx={{ maxWidth: 320, width: '100%' }} m="auto">
        <Text size="xl" mb="lg" align="center">
          Registrera konto
        </Text>
        <form onSubmit={form.onSubmit(register)}>
          <TextInput
            required
            label="Namn"
            placeholder="För- och efternamn"
            icon={<IconUser side={16} />}
            {...form.getInputProps('name')}
          />
          <TextInput
            required
            label="E-postadress"
            placeholder="din@epost.se"
            mt="sm"
            type="email"
            icon={<IconEnvelope side={16} />}
            {...form.getInputProps('email')}
          />
          <PasswordStrength
            target={
              <PasswordInput
                required
                style={{ width: '100%' }}
                label="Lösenord"
                placeholder="********"
                mt="sm"
                description="Ett starkt lösenord bör inkludera både gemener och versaler, minst en siffra, och minst ett specialtecken"
                icon={<IconPassword side={16} />}
                visibilityToggleIcon={({ reveal, size }) =>
                  reveal ? (
                    <IconHidden side={size} />
                  ) : (
                    <IconVisible side={size} />
                  )
                }
                {...form.getInputProps('password')}
              />
            }
            value={form.getInputProps('password').value}
            setPasswordValid={setPasswordValid}
            style={{ width: '100%' }}
          />
          <Checkbox
            mt="md"
            label="Jag godkänner användarvillkoren"
            {...form.getInputProps('termsOfService', { type: 'checkbox' })}
          />

          <Group position="apart" mt="md">
            <Anchor onClick={() => navigate(-1)}>Tillbaka</Anchor>
            <Button type="submit" loading={loading}>
              Registrera konto
            </Button>
          </Group>
        </form>
      </Box>
    </motion.div>
  );
};

export default Register;
