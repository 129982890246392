import {
  Anchor,
  Box,
  Button,
  createStyles,
  Group,
  PasswordInput,
  Text,
  TextInput,
} from '@mantine/core';
import  { useState } from 'react';
import { useNavigate, useNavigationType } from 'react-router-dom';
import { motion } from 'framer-motion';
import { getFramerMotionProps } from '../../utils/Globals';
import { useForm } from '@mantine/form';
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  sendEmailVerification,
} from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { showNotification, updateNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconEnvelope,
  IconHidden,
  IconPassword,
  IconVisible,
} from '../../components/Icons';
import { ErrorCodes } from '../../utils/ErrorCodes';
import {v4 as uuid} from 'uuid'

const useStyles = createStyles((theme, _params, getRef) => ({
  main: {
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  button: {
    width: '100%',
  },
}));

const SignInWithEmail = () => {
  const { classes, theme } = useStyles();
  const navigationType = useNavigationType();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Ange e-postadress'),
      password: (value) => (value ? null : 'Ange lösenord'),
    },
  });

  const signIn = async (values: { email: string; password: string }) => {
    setLoading(true);

    await signInWithEmailAndPassword(getAuth(), values.email, values.password)
      .then((credential) => {
        if (!credential.user.emailVerified) {
          const id = uuid()
          showNotification({
            id,
            title: 'Kunde inte logga in',
            message: (
              <>
                Du måste verifiera din e-postadress genom att klicka på länken
                du har fått till {values.email}.
                <Button
                  variant="default"
                  mt={'xs'}
                  onClick={() => {
                    sendEmailVerification(credential.user).then(() => {
                      updateNotification({
                        id,
                        title: 'Kunde inte logga in',
                        message: (
                          <>
                            Du måste verifiera din e-postadress genom att klicka
                            på länken du har fått till {values.email}.
                            <Button
                              variant="default"
                              disabled
                              leftIcon={<IconCheck side={16} weight="bold" />}
                              mt={'xs'}
                            >
                              Skickat
                            </Button>
                          </>
                        ),
                        color: 'teal',
                      });
                    });
                  }}
                >
                  Skicka på nytt
                </Button>
              </>
            ),
            color: 'red',
            autoClose: 7500,
          });
          signOut(getAuth());
          throw new FirebaseError('auth/unverified-email', '');
        }
      })
      .catch((err: FirebaseError) => {
        console.error(err);
        switch (err.code) {
          case 'auth/wrong-password':
          case 'auth/invalid-email':
          case 'auth/user-not-found':
          case 'auth/user-disabled':
            showNotification({
              title: 'Kunde inte logga in',
              message: ErrorCodes[err.code].message,
              color: 'red',
            });
            break;
          case 'auth/unverified-email':
            break;
          default:
            showNotification({
              title: 'Kunde inte logga in',
              message: ErrorCodes.standard.message,
              color: 'red',
            });
            break;
        }
      });

    setLoading(false);
  };

  return (
    <motion.div
      {...getFramerMotionProps('horizontal', navigationType)}
      className={classes.main}
    >
      <Box sx={{ maxWidth: 320, width: '100%' }} m="auto">
        <Text size="xl" mb="lg" align="center">
          Logga in med e-post
        </Text>
        <form onSubmit={form.onSubmit(signIn)}>
          <TextInput
            required
            label="E-postadress"
            placeholder="din@epost.se"
            type="email"
            icon={<IconEnvelope side={16} />}
            {...form.getInputProps('email')}
          />
          <PasswordInput
            required
            label="Lösenord"
            placeholder="********"
            mt="sm"
            icon={<IconPassword side={16} />}
            visibilityToggleIcon={({ reveal, size }) =>
              reveal ? <IconHidden side={size} /> : <IconVisible side={size} />
            }
            {...form.getInputProps('password')}
          />

          <Group position="apart" mt="md" className={classes.button}>
            <Anchor onClick={() => navigate(-1)}>Tillbaka</Anchor>
            <Button type="submit" loading={loading}>
              Logga in
            </Button>
          </Group>
          <Group position="center" mt={'sm'}>
            <Anchor size="xs" onClick={() => navigate('/reset')}>
              Glömt lösenordet?
            </Anchor>
          </Group>
        </form>
      </Box>
    </motion.div>
  );
};

export default SignInWithEmail;
