import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  setDoc,
  doc,
  getFirestore,
  serverTimestamp,
  onSnapshot,
  query,
  collection,
  orderBy,
  updateDoc,
  arrayUnion,
} from 'firebase/firestore';
import {
  ActionIcon,
  Center,
  CloseButton,
  createStyles,
  Group,
  MediaQuery,
  ScrollArea,
  Stack,
  Text,
  Textarea,
} from '@mantine/core';
import { ByggleadsContext } from '../../providers/ByggleadsProvider';
import { ByggComment, ByggLead } from '../../utils/Types';
import EmptyScreen from '../EmptyScreen';
import { IconSent } from '../Icons';
import { v4 as uuid } from 'uuid';

import CommentRow from './CommentRow';
import { scrollAreaProps } from '../../utils/Globals';
import { useResizeObserver } from '@mantine/hooks';
import useStateEffect from '../../utils/hooks/useStateEffect';

const useStyles = createStyles((theme, _params, getRef) => ({
  chatWindow: {
    borderLeft: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexFlow: 'column',
  },
}));

const ChatWindow = ({
  lead,
  chatOpen,
  setChatOpen,
}: {
  lead?: ByggLead;
  chatOpen: boolean;
  setChatOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { classes, theme, cx } = useStyles();
  const { user } = useContext(ByggleadsContext);

  const [mainRef, mainRect] = useResizeObserver();
  const [headerRef, headerRect] = useResizeObserver();
  const [footerRef, footerRect] = useResizeObserver();

  const viewport = useRef<HTMLDivElement>(null);
  const [viewportHeight, setViewportHeight] = useStateEffect<
    number | undefined
  >(
    0,
    () => {
      setViewportHeight(
        mainRect.height +
          mainRect.y * 2 -
          (headerRect.height + headerRect.y * 2) -
          (footerRect.height + footerRect.y * 2)
      );
    },
    [mainRect, headerRect, footerRect]
  );

  const [message, setMessage] = useState('');

  const keyResponder = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!e.shiftKey && e.code === 'Enter') {
      e.preventDefault();
      sendMessage();
    }
  };

  const sendMessage = () => {
    if (!user?.ref || !lead?.ref.path) return;
    if (!message.toString().replace(/\s/g, '').length) {
      return;
    }
    let messageBody = message.trim();
    if (!messageBody) return;

    const id = uuid();
    const params: Partial<ByggComment> = {
      id: id,
      body: messageBody,
      sender: user.ref,
      timeCreated: serverTimestamp(),
    };
    setDoc(doc(getFirestore(), lead.ref.path, 'comments', id), params)
      .then(() => {
        setMessage('');
      })
      .catch((err) => {
        console.error(err);
      });

    updateDoc(lead.ref, {
      discussionParticipants: arrayUnion(user.ref),
      timeUpdated: serverTimestamp(),
    });
  };

  const [loadingComments, setLoadingComments] = useState(true);
  const [comments, setComments] = useState<ByggComment[]>([]);

  useEffect(() => {
    if (!lead)return
    const listener = onSnapshot(
      query(
        collection(getFirestore(), lead.ref.path, 'comments'),
        orderBy('timeCreated', 'desc')
      ),
      (snapshot) => {
        setComments(
          snapshot.docs.map((snapshot) => {
            let tempComment = snapshot.data() as ByggComment;
            tempComment.ref = snapshot.ref;
            return tempComment;
          })
        );

        setLoadingComments(false);

        viewport.current?.scrollTo({
          top: viewport.current.scrollHeight,
        });
      },
      (err) => {
        console.error(err);
      }
    );

    return () => {
      listener();
    };
  }, [lead]);

  return (
    <div className={cx(classes.chatWindow, chatOpen && 'open')} ref={mainRef}>
      <MediaQuery largerThan={'sm'} styles={{ display: 'none' }}>
        <Group
          position="apart"
          p={theme.spacing.xs}
          style={{
            borderBottom: `1px solid ${
              theme.colorScheme === 'dark'
                ? theme.colors.dark[5]
                : theme.colors.gray[2]
            }`,
          }}
          ref={headerRef}
        >
          <Text>Chatt</Text>
          <CloseButton onClick={() => setChatOpen(false)} />
        </Group>
      </MediaQuery>
      <Center
        style={{
          height: viewportHeight,
        }}
      >
        {!loadingComments &&
          (comments.length ? (
            <ScrollArea
              style={{ height: viewportHeight, width: '100%' }}
              viewportRef={viewport}
              {...scrollAreaProps}
            >
              <Stack
                spacing={2}
                px="xs"
                py="xs"
                style={{ flexDirection: 'column-reverse' }}
              >
                {comments.map((comment, index, array) => {
                  return (
                    <CommentRow
                      comment={comment}
                      nextComment={array[index + 1]}
                      key={comment.id}
                    />
                  );
                })}
              </Stack>
            </ScrollArea>
          ) : (
            <EmptyScreen
              label="Inga kommentarer än så länge"
            />
          ))}
      </Center>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto max-content',
          gap: 4,
          padding: theme.spacing.xs,
          borderTop: `1px solid ${
            theme.colorScheme === 'dark'
              ? theme.colors.dark[5]
              : theme.colors.gray[2]
          }`,
        }}
        ref={footerRef}
      >
        <Textarea
          autosize
          placeholder="Meddelande..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={keyResponder}
          maxRows={8}
        />
        <ActionIcon
          size={'xl'}
          color={'indigo'}
          disabled={!message.toString().replace(/\s/g, '').length}
          onClick={sendMessage}
        >
          <IconSent side={18} />
        </ActionIcon>
      </div>
    </div>
  );
};

export default ChatWindow;
