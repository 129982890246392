import {
  Avatar,
  Center,
  Stack,
  Group,
  Divider,
  createStyles,
  Text,
  Badge,
} from '@mantine/core';
import { useContext } from 'react';
import { ByggleadsContext } from '../../providers/ByggleadsProvider';
import {
  isTimestamp,
  getAvatarURL,
  getInitialsAvatar,
  formatDate,
} from '../../utils/Functions';
import { ByggComment, ByggUser } from '../../utils/Types';
import { Timestamp } from 'firebase/firestore';
import { find } from 'lodash';
import { useSearchParams } from 'react-router-dom';

const useStyles = createStyles((theme, _params, getRef) => ({
  messageRow: {
    display: 'grid',
    gridTemplateColumns: 'max-content auto',
    gap: theme.spacing.xs,
    padding: 4,
    borderRadius: theme.radius.sm,
    position: 'relative',

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[5]
          : theme.colors.gray[1],

      '.timeLabel': {
        opacity: 1,
      },
    },
  },

  highlightedMessage: {
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[4]
        : theme.colors[theme.primaryColor][0],
  },

  messageBody: {
    lineHeight: 1.25,
  },

  newBadge: {
    position: 'absolute',
    top: -8,
    right: 4,
    zIndex: 2,
  },

  chatTimeLabel: {
    width: 38,
    height: '100%',
    opacity: 0,
  },
}));

const CommentRow = ({
  comment,
  nextComment,
}: {
  comment: ByggComment;
  nextComment?: ByggComment;
}) => {
  const { classes, cx } = useStyles();
  const { members } = useContext(ByggleadsContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const highlighted = searchParams.get('comment') === comment.ref.id;

  const shouldShowAvatar = () => {
    if (isNewDate()) return true;
    if (nextComment?.sender.path === comment.sender.path) {
      return false;
    }
    return true;
  };

  const getMember = (): {
    user?: ByggUser;
    unknown?: boolean;
  } => {
    const result = find(members, (m) => m.user.uid === comment.sender.id);
    return result?.user
      ? {
          user: result.user,
        }
      : {
          unknown: true,
        };
  };

  const senderData = getMember();

  const isNewDate = () => {
    if (!nextComment) {
      return true;
    }
    if (
      isTimestamp(comment.timeCreated) &&
      isTimestamp(nextComment?.timeCreated)
    ) {
      if (
        comment.timeCreated.toDate().getDate() !==
        nextComment.timeCreated.toDate().getDate()
      ) {
        return true;
      }
    }
    return false;
  };

  const timeCreated = comment.timeCreated || Timestamp.fromDate(new Date());

  return (
    <>
      <div
        className={cx(
          classes.messageRow,
          highlighted && classes.highlightedMessage
        )}
      >
        {shouldShowAvatar() ? (
          <Avatar
            size={'md'}
            radius="xl"
            src={
              senderData.user
                ? getAvatarURL(senderData.user)
                : getInitialsAvatar('UN')
            }
          />
        ) : (
          <Center className={cx(classes.chatTimeLabel, 'timeLabel')}>
            <Text size="xs" color="dimmed">
              {isTimestamp(comment.timeCreated) &&
                formatDate(comment.timeCreated.toDate(), 'hour')}
            </Text>
          </Center>
        )}
        <Stack color="blue" spacing={2}>
          {shouldShowAvatar() && (
            <Group spacing={'xs'} align="flex-end">
              <Text size="sm" weight={500} className={classes.messageBody}>
                {senderData.user ? senderData.user.name : 'Okänd'}
              </Text>
              {isTimestamp(comment.timeCreated) && (
                <Text size="xs" color="dimmed" className={classes.messageBody}>
                  {formatDate(comment.timeCreated.toDate(), 'hour')}
                </Text>
              )}
            </Group>
          )}
          {comment.body.split('\n').map((text, index) => {
            return (
              <Text size="sm" key={index} className={classes.messageBody}>
                {text}
              </Text>
            );
          })}
        </Stack>

        {highlighted && (
          <Badge
            color="teal"
            size="xs"
            className={classes.newBadge}
            variant="filled"
          >
            Ny!
          </Badge>
        )}
      </div>
      {isNewDate() && isTimestamp(timeCreated) && (
        <Divider
          label={formatDate(timeCreated.toDate(), 'relative_general', {
            sameDay: '[idag]',
          })}
          labelPosition="center"
        />
      )}
    </>
  );
};

export default CommentRow;
