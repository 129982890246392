import {
  Badge,
  createStyles,
  Navbar,
  Skeleton,
  UnstyledButton,
} from "@mantine/core";
import { useModals } from "@mantine/modals";
import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import UserSettings from "../../pages/start/user/UserSettings";
import { ByggleadsContext } from "../../providers/ByggleadsProvider";
import { getAvatarURL } from "../../utils/Functions";
import { ByggRoute } from "./Layout";
import UserButton from "../UserButton";

const useStyles = createStyles((theme, _params, getRef) => ({
  mainNav: {
    transition: "transform 0.4s ease",
  },

  section: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    marginBottom: theme.spacing.md,

    "&:not(:last-of-type)": {
      borderBottom: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[2]
      }`,
    },
  },

  mainLinks: {
    paddingLeft: theme.spacing.md - theme.spacing.xs,
    paddingRight: theme.spacing.md - theme.spacing.xs,
    paddingBottom: theme.spacing.md,
  },

  mainLink: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    fontSize: theme.fontSizes.sm,
    padding: `8px ${theme.spacing.xs}px`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },

    "&.current": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[1],
    },
  },

  mainLinkInner: {
    display: "flex",
    alignItems: "center",
    flex: 1,

    "&.current": {
      color: theme.colorScheme === "dark" ? "white" : "inherit",
    },

    span: {
      marginLeft: theme.spacing.sm,
    },
  },

  mainLinkBadge: {
    padding: 0,
    width: 20,
    height: 20,
    pointerEvents: "none",
  },

  danger: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? `${theme.colors.red[5]}25`
        : theme.colors.red[0],
    borderColor:
      theme.colorScheme === "dark"
        ? `${theme.colors.red[8]}25`
        : theme.colors.red[1],
    borderWidth: 2,
    borderStyle: "solid",
  },

  info: {},
}));

export interface NavPath {
  icon: {
    filled: React.ReactNode;
    outlined: React.ReactNode;
  };
  label: string;
  path: string;
  alerts: {
    notifications?: number;
    warnings?: number;
    errors?: number;
  };
  variant?: "danger" | "info";
}

const Nav = ({
  opened,
  setOpened,
  routes,
}: {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  routes: ByggRoute[];
}) => {
  const { classes, cx } = useStyles();
  const { user, workspace } = useContext(ByggleadsContext);
  const location = useLocation();
  const modals = useModals();

  return (
    <Navbar
      p="md"
      hiddenBreakpoint="sm"
      hidden={!opened}
      width={{ sm: 256, lg: 300 }}
    >
      <Navbar.Section className={classes.section}>
        <UserButton
          loading={!user}
          image={getAvatarURL(user)}
          name={user?.name || ""}
          workspace={workspace?.name || ""}
          onClick={() => {
            setOpened(false);
            const id = modals.openModal({
              children: (
                <UserSettings handleClose={() => modals.closeModal(id)} />
              ),
              size: "lg",
              withCloseButton: false,
              padding: 0,
            });
          }}
        />
      </Navbar.Section>
      <Navbar.Section className={classes.section}>
        <div className={classes.mainLinks}>
          {user
            ? routes.flatMap((route) => {
                if (!route.icon || !route.alerts) return [];
                let current = route.exact
                  ? location.pathname === route.path
                  : location.pathname.includes(route.path);

                return (
                  <Link
                    to={route.path}
                    style={{
                      textDecoration: "none",
                    }}
                    key={route.label}
                  >
                    <UnstyledButton
                      className={cx(
                        classes.mainLink,
                        current && "current",
                        route.alerts.errors && classes.danger
                      )}
                      onClick={() => setOpened(false)}
                    >
                      <div
                        className={cx(
                          classes.mainLinkInner,
                          current && "current"
                        )}
                      >
                        {current ? route.icon.filled : route.icon.outlined}
                        <span>{route.label}</span>
                      </div>
                      {route.alerts.notifications && (
                        <Badge
                          size="sm"
                          className={classes.mainLinkBadge}
                          color={"blue"}
                        >
                          {route.alerts.notifications}
                        </Badge>
                      )}
                      {route.alerts.warnings && (
                        <Badge
                          size="sm"
                          className={classes.mainLinkBadge}
                          color={"yellow"}
                        >
                          {route.alerts.warnings}
                        </Badge>
                      )}
                      {route.alerts.errors && (
                        <Badge
                          size="sm"
                          variant="filled"
                          className={classes.mainLinkBadge}
                          color={"red"}
                        >
                          {route.alerts.errors}
                        </Badge>
                      )}
                    </UnstyledButton>
                  </Link>
                );
              })
            : ["1", "2", "3", "4"].map((row) => {
                return <NavLink setOpened={setOpened} key={row} />;
              })}
        </div>
      </Navbar.Section>
    </Navbar>
  );
};

export default Nav;

const NavLink = ({
  route,
  setOpened,
}: {
  route?: ByggRoute;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { classes, cx } = useStyles();
  const location = useLocation();

  if (!route || !route.icon || !route.alerts) {
    return (
      <UnstyledButton className={cx(classes.mainLink)}>
        <div className={cx(classes.mainLinkInner)}>
          <Skeleton circle width={22} height={22} mr="xs" />
          <Skeleton height={14} width={128} />
        </div>
      </UnstyledButton>
    );
  }

  return (
    <Link
      to={route.path}
      style={{
        textDecoration: "none",
      }}
    >
      <UnstyledButton
        className={cx(
          classes.mainLink,
          location.pathname.includes(route.path) && "current",
          route.alerts.errors && classes.danger
        )}
        onClick={() => setOpened(false)}
      >
        <div
          className={cx(
            classes.mainLinkInner,
            location.pathname.includes(route.path) && "current"
          )}
        >
          {location.pathname.includes(route.path)
            ? route.icon.filled
            : route.icon.outlined}
          <span>{route.label}</span>
        </div>
        {route.alerts.notifications && (
          <Badge size="sm" className={classes.mainLinkBadge} color={"blue"}>
            {route.alerts.notifications}
          </Badge>
        )}
        {route.alerts.warnings && (
          <Badge size="sm" className={classes.mainLinkBadge} color={"yellow"}>
            {route.alerts.warnings}
          </Badge>
        )}
        {route.alerts.errors && (
          <Badge
            size="sm"
            variant="filled"
            className={classes.mainLinkBadge}
            color={"red"}
          >
            {route.alerts.errors}
          </Badge>
        )}
      </UnstyledButton>
    </Link>
  );
};
