import {
  ActionIcon,
  Button,
  Card,
  CloseButton,
  Code,
  createStyles,
  Group,
  Select,
  SelectItem,
  Stack,
  Table,
  Text,
  TextInput,
  Title,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import {
  useClipboard,
  useListState,
  UseListStateHandlers,
} from '@mantine/hooks';
import { useContext, useRef, useState } from 'react';
import {
  IconChevronDown,
  IconChevronUp,
  IconCopy,
  IconCross,
} from '../../components/Icons';
import { isPermitted } from '../../utils/Functions';
import { getFirestore, setDoc, doc, serverTimestamp } from 'firebase/firestore';
import { ByggleadsContext } from '../../providers/ByggleadsProvider';
import { ErrorCodes } from '../../utils/ErrorCodes';
import { v4 as uuid } from 'uuid';
import { ByggLeadField, ByggLeadTemplate } from '../../utils/Types';

import ZapierLogo from '../../assets/zapier.svg';
import { showNotification } from '@mantine/notifications';

const useStyles = createStyles((theme, _params, getRef) => ({
  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: theme.spacing.sm,

    '&.noPadding': {
      padding: 0,
    },
  },

  dimmedButton: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[2]
        : theme.colors.gray[6],

    '&:hover': {
      color: 'inherit',
    },
  },
  codeDisplay: {
    position: 'relative',
    width: '100%',

    backgroundImage: `url(${ZapierLogo})`,
    backgroundPosition: `right ${theme.spacing.md}px bottom ${theme.spacing.md}px`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 64,
  },
  copyButton: {
    position: 'absolute',
    top: theme.spacing.sm,
    right: theme.spacing.sm,
  },
  fieldTableRow: {
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
  },
}));

export interface FieldValueRow {
  title: string;
  type: string;
  key: string;
}
const LeadTemplateCreate = ({ handleClose }: { handleClose: () => void }) => {
  const { classes, cx } = useStyles();
  const [loading, setLoading] = useState(false);
  const { user, workspace, role } = useContext(ByggleadsContext);

  const [title, setTitle] = useState('');
  const id = useRef(uuid());
  const defaultField: FieldValueRow = {
    title: '',
    type: '0.text',
    key: uuid(),
  };
  // const [fields, setFields] = useState<FieldValueRow[]>([defaultField]);
  const [fields, fieldHandlers] = useListState<FieldValueRow>([defaultField]);

  const submit = async () => {
    let valid = true;
    fields.forEach((field, index) => {
      if (!field.title || !field.type) {
        if (index !== fields.length - 1) {
          valid = false;
        }
      }
    });
    if (!valid) {
      showNotification({
        message: 'Samtliga fält måste ha titel och värdetyp',
        color: 'red',
      });
      return;
    }
    if (!title) {
      showNotification({
        message: 'Du måste fylla i en titel',
        color: 'red',
      });
      return;
    }
    if (!workspace?.ref || !user?.ref) {
      showNotification({
        message: ErrorCodes.standard.message,
        color: 'red',
      });
      return;
    }
    setLoading(true);

    const params: Partial<ByggLeadTemplate> = {
      title: title,
      fields: cleanByggFields(fields),
      id: id.current,
      workspace: workspace.ref.id,
      createdAt: serverTimestamp(),
      createdBy: user.ref,
    };

    await setDoc(
      doc(getFirestore(), workspace.ref.path, 'leadTemplates', id.current),
      params
    )
      .then((res) => {
        handleClose();
      })
      .catch((err) => {
        console.error(err);
        showNotification({
          message: ErrorCodes.standard.message,
          color: 'red',
        });
      });

    setLoading(false);
  };

  return (
    <Card>
      <Card.Section className={classes.section}>
        <Group position="apart" noWrap>
          <Title order={4}>Skapa leadmall</Title>
          <CloseButton onClick={handleClose} />
        </Group>
      </Card.Section>
      <Card.Section className={classes.section}>
        <Text size="sm">
          Leadmallen är den viktigaste beståndsdelen för att Byggleads ska
          fungera som det ska. <br />
          Börja med att ge din mall ett namn, och bygg sedan fälten som du vill
          ha dem.
        </Text>
        {/* <Text size="sm" mt="xs" color="green">
          Om du inte förstår hur detta fungerar hjälper vi dig gärna via vår
          livechatt. <br />
          Den öppnar du genom att klicka på den lilla chattbubblan nere i det
          högra hörnet av skärmen.
        </Text> */}
      </Card.Section>
      <Card.Section className={classes.section}>
        <Stack>
          <TextInput
            placeholder="Mallens namn..."
            label="Ge mallen ett namn"
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Stack>
      </Card.Section>
      <Card.Section className={cx(classes.section, 'noPadding')}>
        <Table>
          <thead>
            <tr>
              <th style={{ width: 24 }}></th>
              <th>Titel</th>
              <th>Värdetyp</th>
              <th>Åtgärder</th>
            </tr>
          </thead>
          <tbody>
            {fields.map((row, index) => {
              return (
                <FieldRow
                  key={row.key}
                  row={row}
                  fields={fields}
                  fieldHandlers={fieldHandlers}
                  defaultField={defaultField}
                  index={index}
                  classes={classes}
                />
              );
            })}
            <tr>
              <td></td>
              <td>
                <UnstyledButton
                  className={classes.dimmedButton}
                  onClick={() => fieldHandlers.append(defaultField)}
                >
                  <Text size="sm">+ Lägg till rad</Text>
                </UnstyledButton>
              </td>
            </tr>
          </tbody>
        </Table>
      </Card.Section>
      <Card.Section p={'sm'}>
        <Group position="right">
          <Button onClick={submit} loading={loading}>
            Skapa mall
          </Button>
        </Group>
        {isPermitted(['superadmin'], role) && (
          <JSONOutput id={id.current} fields={cleanByggFields(fields)} />
        )}
      </Card.Section>
    </Card>
  );
};

export default LeadTemplateCreate;

const FieldRow = ({
  row,
  fields,
  fieldHandlers,
  defaultField,
  index,
  classes,
}: {
  row: FieldValueRow;
  fields: FieldValueRow[];
  fieldHandlers: UseListStateHandlers<FieldValueRow>;
  defaultField: FieldValueRow;
  index: number;
  classes: Record<'dimmedButton' | 'fieldTableRow', string>;
}) => {
  const body = (
    <>
      <td>
        <TextInput
          placeholder="Titel..."
          variant="unstyled"
          value={row.title}
          onChange={(e) => {
            fieldHandlers.setItemProp(index, 'title', e.target.value);
          }}
          onBlur={() => {
            if (fields[fields.length - 1].title) {
              fieldHandlers.append(defaultField);
            }
          }}
        />
      </td>
      <td>
        <Select
          placeholder="Typ"
          variant="unstyled"
          data={leadDataTypes}
          value={row.type}
          searchable
          onChange={(e) => {
            fieldHandlers.setItemProp(index, 'type', e || '');
          }}
        />
      </td>
      <td>
        <Tooltip label="Ta bort rad" withArrow transition="pop">
          <ActionIcon
            size="md"
            sx={(theme) => ({
              backgroundColor:
                theme.colorScheme === 'dark'
                  ? theme.colors.dark[6]
                  : theme.colors.gray[0],
            })}
            onClick={() => {
              if (fields.length === 1) {
                fieldHandlers.setState([defaultField]);
                // setFields([defaultField]);
              } else {
                fieldHandlers.remove(index);
                // fields.splice(index, 1);
                // setFields([...fields]);
              }
            }}
          >
            <IconCross side={16} />
          </ActionIcon>
        </Tooltip>
      </td>
    </>
  );

  if (index === fields.length - 1) {
    return (
      <tr>
        <td
          style={{
            width: 24,
            padding: 0,
            paddingLeft: 8,
          }}
        />
        {body}
      </tr>
    );
  }

  return (
    <tr className={classes.fieldTableRow}>
      <td
        style={{
          width: 24,
          padding: 0,
          paddingLeft: 8,
        }}
      >
        <div>
          <ActionIcon
            sx={(theme) => ({
              height: 14,
              minHeight: 14,
            })}
            onClick={() => {
              if (index - 1 < 0) return;
              fieldHandlers.reorder({ from: index, to: index - 1 });
            }}
          >
            <IconChevronUp side={10} />
          </ActionIcon>
          <ActionIcon
            sx={(theme) => ({
              height: 14,
              minHeight: 14,
            })}
            onClick={() => {
              console.log(index, fields.length);
              if (index + 1 === fields.length - 1) return;
              fieldHandlers.reorder({ from: index, to: index + 1 });
            }}
          >
            <IconChevronDown side={10} />
          </ActionIcon>
        </div>
      </td>
      {body}
    </tr>
  );
};

export const JSONOutput = ({
  fields,
  id,
}: {
  fields: ByggLeadField[];
  id: string;
}) => {
  const { classes } = useStyles();
  const clipboard = useClipboard();
  const [label, setLabel] = useState<'Kopiera' | 'Kopierat till urklipp'>(
    'Kopiera'
  );

  return (
    <Code block className={classes.codeDisplay} mt="sm">
      {compileLeadTemplateFields(fields, id)}
      <Tooltip
        label={label}
        className={classes.copyButton}
        transition={'pop'}
        transitionDuration={80}
        withArrow
        position="right"
        color={label === 'Kopiera' ? undefined : 'teal'}
      >
        <ActionIcon
          onClick={() => {
            clipboard.copy(compileLeadTemplateFields(fields, id));
            setLabel('Kopierat till urklipp');
          }}
          onMouseLeave={() => {
            setTimeout(() => {
              setLabel('Kopiera');
            }, 80);
          }}
        >
          <IconCopy side={18} />
        </ActionIcon>
      </Tooltip>
    </Code>
  );
};

export const cleanByggFields = (
  rawFields: FieldValueRow[]
): ByggLeadField[] => {
  return rawFields.flatMap((field) => {
    if (!field.title) {
      return [];
    }
    return {
      title: field.title,
      type: field.type.split('.')[1],
      typeExtended: field.type,
      typeVariant: field.type.split('.')[2],
      id: field.key,
    };
  });
};

export const compileLeadTemplateFields = (
  fields: ByggLeadField[],
  id: string
) => {
  let result = '{';

  fields.forEach((field) => {
    if (!field.title) return;
    result += `\n  "${field.id}": {`;
    result += `\n    "title": "${field.title}",`;
    result += `\n    "value": ""`;
    result += `\n  },`;
  });

  result += `\n  "title": "",`;
  result += `\n  "templateID": "${id}",`;
  result += `\n  "platform": "",`;
  result += `\n  "campaignID": "",`;
  result += `\n  "adsetID": "",`;
  result += `\n  "adID": "",`;
  result += `\n  "leadID": "",`;
  result += `\n  "formID": ""`;

  result += '\n}';
  return result;
};

export const leadDataTypes: SelectItem[] = [
  {
    value: '0.string.text',
    label: 'Text',
    group: 'Generella fält',
  },
  {
    value: '0.date.date',
    label: 'Datum',
    group: 'Generella fält',
  },
  {
    value: '0.number.number',
    label: 'Siffra',
    group: 'Generella fält',
  },
  {
    value: '1.string.shortText',
    label: 'Kort svar',
    group: 'Custom fields',
  },
  {
    value: '1.string.longText',
    label: 'Långt svar',
    group: 'Custom fields',
  },
  {
    value: '2.string.select',
    label: 'Flervalsfråga',
    group: 'Custom fields',
  },
  {
    value: '3.date.date',
    label: 'Bokningsförfrågan',
    group: 'Custom fields',
  },
  {
    value: '4.string.email',
    label: 'E-postadress',
    group: 'Kontaktfält',
  },
  {
    value: '5.string.tel',
    label: 'Telefonnummer',
    group: 'Kontaktfält',
  },
  {
    value: '6.string.fullAddress',
    label: 'Fullständig adress',
    group: 'Kontaktfält',
  },
  {
    value: '6.string.street',
    label: 'Gatuadress',
    group: 'Kontaktfält',
  },
  {
    value: '7.string.city',
    label: 'Stad',
    group: 'Kontaktfält',
  },
  {
    value: '8.string.province',
    label: 'Provins / Region',
    group: 'Kontaktfält',
  },
  {
    value: '9.string.country',
    label: 'Land',
    group: 'Kontaktfält',
  },
  {
    value: '10.string.zipCode',
    label: 'Postnummer',
    group: 'Kontaktfält',
  },
  {
    value: '11.string.fullName',
    label: 'Fullständigt namn',
    group: 'Användarinformation',
  },
  {
    value: '12.string.firstName',
    label: 'Förnamn',
    group: 'Användarinformation',
  },
  {
    value: '13.string.lastName',
    label: 'Efternamn',
    group: 'Användarinformation',
  },
  {
    value: '14.date.birthDate',
    label: 'Födelsedatum',
    group: 'Demografiska frågor',
  },
  {
    value: '15.string.gender',
    label: 'Kön',
    group: 'Demografiska frågor',
  },
  {
    value: '16.string.relationship',
    label: 'Förhållandestatus',
    group: 'Demografiska frågor',
  },
  {
    value: '17.string.military',
    label: 'Militärstatus',
    group: 'Demografiska frågor',
  },
  {
    value: '18.string.jobTitle',
    label: 'Jobbtitel',
    group: 'Arbetsinformation',
  },
  {
    value: '19.string.workTel',
    label: 'Arbetstelefonnummer',
    group: 'Arbetsinformation',
  },
  {
    value: '20.string.workEmail',
    label: 'Arbetsmejladress',
    group: 'Arbetsinformation',
  },
  {
    value: '21.string.companyName',
    label: 'Företagsnamn',
    group: 'Arbetsinformation',
  },
];
