import { Stack, Text } from '@mantine/core';

const EmptyScreen = ({
  label = 'Inget att se här',
  children,
}: {
  label?: string;
  children?: React.ReactNode;
}) => {
  return (
    <Stack align={'center'} py={32} px={'md'} spacing={0}>
      <Text size="sm" color="dimmed" align="center" py={'xs'}>
        {label}
      </Text>
      {children && children}
    </Stack>
  );
};

export default EmptyScreen;
