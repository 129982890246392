import {
  ActionIcon,
  Anchor,
  Avatar,
  Badge,
  Button,
  Card,
  CloseButton,
  createStyles,
  Group,
  Loader,
  MantineTheme,
  MediaQuery,
  Menu,
  Modal,
  ScrollArea,
  Skeleton,
  Table,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { useContext, useState } from "react";
import {
  DocumentReference,
  onSnapshot,
  refEqual,
  updateDoc,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { ByggLead, ByggLeadField, ByggLeadStatus } from "../../utils/Types";
import { getPlaceholderAndIcon } from "../../pages/leads/LeadCreate";
import { ByggleadsContext } from "../../providers/ByggleadsProvider";
import {
  capitalizeFirstLetter,
  formatDate,
  getInitialsAvatar,
  getInitialsAvatarColor,
  isTimestamp,
} from "../../utils/Functions";
import validator from "validator";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { reverseClean } from "../../utils/Functions";
import {
  IconArchive,
  IconChat,
  IconCheckedBox,
  IconMenu,
  IconNotificationsFilled,
  IconUncheckedBox,
} from "../Icons";
import ChatWindow from "./ChatWindow";
import StatusSelector from "./StatusSelector";
import AdMeta from "./AdMeta";
import LeadTimeline from "./LeadTimeline";
import { scrollAreaProps } from "../../utils/Globals";
import { find, isArray } from "lodash";
import AssignedSelector from "./AssignedSelector";
import { StatusArchivedRef } from "../../App";
import { useDisclosure, useResizeObserver } from "@mantine/hooks";
import { ErrorCodes } from "../../utils/ErrorCodes";
import useStateEffect from "../../utils/hooks/useStateEffect";
import moment from "moment";
import { showNotification } from "@mantine/notifications";

const useStyles = createStyles((theme, _params, getRef) => ({
  main: {
    display: "flex",
    flexFlow: "column",
    height: "100%",

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      height: "calc(100vh - 96px)",
    },
  },

  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: theme.spacing.sm,
  },

  layoutSection: {
    flex: 1,
    overflow: "hidden",
  },

  layout: {
    padding: 0,
    gap: 0,
    gridTemplateColumns: "repeat(5, 1fr)",
    display: "grid",
    overflow: "hidden",
    height: "100%",
  },

  layoutInfo: {
    gridColumn: "1 / 4",
    width: "100%",
    overflow: "hidden",

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      gridColumn: "1 / 6",
    },
  },

  layoutChat: {
    gridColumn: "4 / 6",
    position: "relative",

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      gridColumn: "1 / 6",
    },

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      position: "absolute",
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
      borderLeft: "none",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      transform: "translateX(100%)",
      transition: "transform 0.35s ease",
      maxHeight: "none",
    },

    "&.open": {
      transform: "none",
    },
  },
}));

const LeadDetails = ({
  leadRef,
  handleClose,
}: {
  leadRef: DocumentReference;
  handleClose: () => void;
}) => {
  const { classes, cx, theme } = useStyles();
  const { statuses, workspaces } = useContext(ByggleadsContext);
  const [chatOpen, setChatOpen] = useState(false);
  const [unavailable, setUnavailable] = useState(false);

  const [infoRef, infoRect] = useResizeObserver();

  const [lead, setLead] = useStateEffect<ByggLead | undefined>(
    undefined,
    () => {
      if (!isArray(workspaces)) {
        return;
      }
      const workspaceRef = leadRef.parent.parent;
      if (!workspaceRef) {
        setUnavailable(true);
        return;
      }

      if (!workspaces.some((e) => refEqual(e.ref!, workspaceRef))) {
        setUnavailable(true);
        return;
      }

      const listener = onSnapshot(leadRef, (snapshot) => {
        let tempLead = snapshot.data() as ByggLead;
        tempLead.ref = snapshot.ref;
        setLead(tempLead);
      });

      return () => {
        listener();
      };
    },
    [leadRef, workspaces]
  );

  const [selectedStatus, setSelectedStatus] = useStateEffect<
    ByggLeadStatus | undefined
  >(
    undefined,
    () => {
      if (!lead?.status) {
        setSelectedStatus(undefined);
        return;
      }

      const result = find(statuses, (s) => refEqual(s.ref, lead.status));
      setSelectedStatus(result);
    },
    [lead, statuses]
  );

  return (
    <Card className={classes.main} withBorder>
      <Card.Section className={classes.section}>
        <Group position="apart" noWrap>
          <TitleSection lead={lead} theme={theme} />
          <CloseButton onClick={handleClose} />
        </Group>
      </Card.Section>
      <Card.Section className={classes.layoutSection}>
        <div className={classes.layout}>
          <div className={classes.layoutInfo} ref={infoRef}>
            <ScrollArea
              style={{
                width: infoRect.width || "100%",
                height: "100%",
              }}
              {...scrollAreaProps}
            >
              <ScrollArea
                style={{
                  width: infoRect.width || "100%",
                }}
                {...scrollAreaProps}
              >
                <Table
                  style={{
                    minWidth: 512,
                  }}
                >
                  <tbody>
                    {lead
                      ? lead.fields.map((field) => {
                          return <LeadField field={field} key={field.id} />;
                        })
                      : ["1", "2", "3", "4"].map((key) => {
                          return <LeadField key={key} />;
                        })}
                    <tr></tr>
                  </tbody>
                </Table>
              </ScrollArea>
              <Group p={"sm"} position="apart">
                <Group spacing={"xl"}>
                  <StatusSelector
                    leadRef={leadRef}
                    selectedStatus={selectedStatus}
                  />
                  <AssignedSelector lead={lead} />
                </Group>
                <Group spacing={"xs"}>
                  <MediaQuery largerThan={"sm"} styles={{ display: "none" }}>
                    <ActionIcon onClick={() => setChatOpen(true)}>
                      <IconChat side={16} />
                    </ActionIcon>
                  </MediaQuery>
                  {lead && <ActionMenu leadRef={leadRef} lead={lead} />}
                </Group>
              </Group>
              <LeadTimeline lead={lead} />
              <AdMeta lead={lead} />
            </ScrollArea>
          </div>

          <div className={cx(classes.layoutChat, chatOpen && "open")}>
            <ChatWindow
              lead={lead}
              chatOpen={chatOpen}
              setChatOpen={setChatOpen}
            />
          </div>
        </div>
      </Card.Section>
      <Modal
        opened={unavailable}
        onClose={() => {}}
        closeOnClickOutside={false}
        padding={0}
        withCloseButton={false}
        centered
      >
        <Card withBorder>
          <Card.Section className={classes.section}>
            <Text component="span" color="red">
              <Title order={4} color="red">
                Otillgängligt
              </Title>
            </Text>
          </Card.Section>

          <Card.Section className={classes.section}>
            <Text>
              Leadet finns inte eller så saknar du behörighet till det.
            </Text>
          </Card.Section>

          <Card.Section>
            <Group p="sm" position="right">
              <Button variant="default" onClick={handleClose}>
                OK, stäng
              </Button>
            </Group>
          </Card.Section>
        </Card>
      </Modal>
    </Card>
  );
};

export default LeadDetails;

const TitleSection = ({
  lead,
  theme,
}: {
  lead?: ByggLead;
  theme: MantineTheme;
}) => {
  return lead ? (
    <Group>
      <Avatar
        src={getInitialsAvatar(
          lead.title,
          getInitialsAvatarColor(theme, lead.title)
        )}
        size="sm"
      />
      <Title order={4}>{lead.title}</Title>
      {lead && isTimestamp(lead.timeCreated) && (
        <Badge color={"gray"}>
          {formatDate(lead.timeCreated.toDate(), "numbers_datetime")}
        </Badge>
      )}
    </Group>
  ) : (
    <Group>
      <Skeleton width={26} height={26} />
      <Skeleton width={192} height={18} />
      <Skeleton width={96} height={16} radius="xl" />
    </Group>
  );
};

const LeadField = ({ field }: { field?: ByggLeadField }) => {
  if (!field) {
    return (
      <tr>
        <td>
          <Group spacing="sm">
            <Skeleton circle width={16} height={16} />
            <Skeleton width={128} height={12} my={4} />
          </Group>
        </td>
        <td>
          <Skeleton width={128} height={12} />
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <td>
        <Group spacing="sm">
          {getPlaceholderAndIcon(field.typeVariant).icon}
          <Text size="sm" weight={500}>
            {field.title}
          </Text>
        </Group>
      </td>
      <td>
        <FormattedFieldValue field={field} />
      </td>
    </tr>
  );
};

const FormattedFieldValue = ({ field }: { field: ByggLeadField }) => {
  switch (field.typeVariant) {
    case "email":
    case "workEmail":
      if (typeof field.value === "string" && validator.isEmail(field.value)) {
        return (
          <Anchor size="sm" href={`mailto:${field.value}`}>
            {field.value}
          </Anchor>
        );
      } else {
        break;
      }
    case "tel":
    case "workTel":
      if (typeof field.value === "string" && isValidPhoneNumber(field.value)) {
        const phoneNumber = parsePhoneNumber(field.value);
        return (
          <Anchor size="sm" href={`tel:${field.value}`}>
            {phoneNumber.formatNational()}
          </Anchor>
        );
      } else {
        break;
      }
    case "select":
      if (typeof field.value === "string") {
        return (
          <Text size="sm">
            {capitalizeFirstLetter(reverseClean(field.value))}
          </Text>
        );
      } else {
        break;
      }
    default:
      break;
  }

  if (isTimestamp(field.value)) {
    return (
      <Text size="sm">
        {moment(field.value.toDate()).format("YYYY-MM-DD HH:mm:ss")}
      </Text>
    );
  }

  return <Text size="sm">{field.value}</Text>;
};

const ActionMenu = ({
  lead,
  leadRef,
}: {
  lead: ByggLead;
  leadRef: DocumentReference;
}) => {
  const { theme } = useStyles();

  const [opened, handlers] = useDisclosure(false);

  const [loadingReminder, setLoadingReminder] = useState(false);
  const sendReminder = async () => {
    if (!leadRef.parent.parent) {
      showNotification({
        message: ErrorCodes["standard"].message,
        color: "red",
      });
      return;
    }
    setLoadingReminder(true);

    const remindFunction = httpsCallable(
      getFunctions(undefined, "europe-west1"),
      "remindAssigned"
    );

    await remindFunction({
      workspacePath: leadRef.parent.parent.path,
      leadPath: leadRef.path,
    })
      .then(() => {
        handlers.close();
      })
      .catch((err) => {
        console.error(err);
        showNotification({
          message: ErrorCodes["standard"].message,
          color: "red",
        });
      });

    setLoadingReminder(false);
  };

  const [loadingArchive, setLoadingArchive] = useState(false);
  const archiveLead = async () => {
    setLoadingArchive(true);

    const params: Partial<ByggLead> = {
      status: StatusArchivedRef,
    };
    await updateDoc(leadRef, params)
      .then(() => {
        handlers.close();
      })
      .catch((err) => {
        console.error(err);
        showNotification({
          message: ErrorCodes["standard"].message,
          color: "red",
        });
      });

    setLoadingArchive(false);
  };

  return (
    <Menu
      width={250}
      transition="pop"
      withinPortal
      transitionDuration={80}
      closeOnItemClick={false}
      opened={opened}
      onOpen={handlers.open}
      onClose={handlers.close}
    >
      <Menu.Target>
        <ActionIcon size="lg">
          <IconMenu
            side={14}
            color={
              theme.colorScheme === "dark"
                ? theme.colors.gray[4]
                : theme.colors.gray[7]
            }
          />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>Åtgärder</Menu.Label>
        <Menu.Item
          icon={
            loadingReminder ? (
              <Loader size={14} />
            ) : (
              <IconNotificationsFilled side={14} />
            )
          }
          disabled={loadingReminder}
          onClick={sendReminder}
        >
          Påminn tilldelade
        </Menu.Item>
        <Menu.Item
          color="red"
          icon={
            loadingArchive ? <Loader size={14} /> : <IconArchive side={14} />
          }
          disabled={loadingArchive}
          onClick={archiveLead}
        >
          Arkivera lead
        </Menu.Item>
        <Menu.Label>Inställningar</Menu.Label>
        <Menu.Item
          icon={
            lead.doNotAutoArchive ? (
              <IconUncheckedBox side={14} />
            ) : (
              <IconCheckedBox side={14} />
            )
          }
          onClick={() => {
            const params: Partial<ByggLead> = {
              doNotAutoArchive: lead.doNotAutoArchive ? false : true,
            };
            updateDoc(leadRef, params);
          }}
          color={lead.doNotAutoArchive ? undefined : "indigo"}
        >
          <Tooltip
            label={
              lead.doNotAutoArchive
                ? "Arkivera detta lead automatiskt efter 30 dagars inaktivitet"
                : "Detta lead arkiveras automatiskt efter 30 dagars inaktivitet"
            }
            withArrow
            position="bottom"
            transition="pop"
          >
            <Text inherit>
              {lead.doNotAutoArchive
                ? "Arkivera automatiskt"
                : "Arkiveras automatiskt"}
            </Text>
          </Tooltip>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
