import {
  Group,
  Title,
  Button,
  Badge,
  Avatar,
  Text,
  createStyles,
  Stack,
} from '@mantine/core';
import { useState } from 'react';
import { ErrorCodes } from '../../utils/ErrorCodes';
import { getAvatarURL } from '../../utils/Functions';
import { ByggInvite, ByggWorkspace, ByggUser } from '../../utils/Types';
import { DocumentReference, updateDoc } from 'firebase/firestore';

import FriendBubbles from '../../assets/illustrations/friend-bubbles.svg';
import { showNotification } from '@mantine/notifications';

const useStyles = createStyles((theme, _params, getRef) => ({
  popupImage: {
    width: '100%',
    height: 384,
    maxHeight: '35vh',
    objectFit: 'contain',
    backgroundColor: theme.colors[theme.primaryColor][0],
    borderTopLeftRadius: theme.radius.sm,
    borderTopRightRadius: theme.radius.sm,
    padding: theme.spacing.sm,
  },
  fullWidth: {
    width: '100%',
  },
  userButton: {
    pointerEvents: 'none',
  },
}));

const HandleInviteModal = ({
  invite,
  workspace,
  invitedBy,
  inviteRef,
  handleClose,
}: {
  invite: ByggInvite;
  workspace: ByggWorkspace;
  invitedBy: ByggUser;
  inviteRef: DocumentReference;
  handleClose: () => void;
}) => {
  const { classes } = useStyles();
  const [approveLoading, setApproveLoading] = useState(false);
  const [denyLoading, setDenyLoading] = useState(false);

  return (
    <>
      <img src={FriendBubbles} className={classes.popupImage} alt="" />
      <Stack
        px="md"
        mt={'lg'}
        spacing={'xs'}
        pb={'xl'}
        align={'center'}
      >
        <Text size="sm" align="center" className={classes.fullWidth}>
          Du är inbjuden att gå med i
        </Text>
        <Title order={2} align="center" className={classes.fullWidth}>
          {workspace.name}
        </Title>
        <Group mt={'md'}>
          <Button
            variant="filled"
            loading={approveLoading}
            onClick={async () => {
              setApproveLoading(true);
              const params: Partial<ByggInvite> = {
                status: 'approved',
              };
              await updateDoc(inviteRef, params)
                .then(() => {
                  handleClose();
                  showNotification({
                    title: `Vi önskar dig välkommen till ${workspace.name}`,
                    message: `Ge oss några sekunder att behandla ditt godkännande. Du bör snart se att du är med i ${workspace.name}. Om du inte gör det inom 1 minut, kontakta support.`,
                    autoClose: 7500,
                  });
                })
                .catch((err) => {
                  console.error(err);
                  showNotification({
                    message: ErrorCodes.standard.message,
                    color: 'red',
                  });
                });
              setApproveLoading(false);
            }}
          >
            Gå med
          </Button>
          <Button
            variant="light"
            color={'red'}
            loading={denyLoading}
            onClick={async () => {
              setDenyLoading(true);
              const params: Partial<ByggInvite> = {
                status: 'denied',
              };
              await updateDoc(inviteRef, params)
                .then(() => {
                  handleClose();
                })
                .catch((err) => {
                  console.error(err);
                  showNotification({
                    message: ErrorCodes.standard.message,
                    color: 'red',
                  });
                });
              setDenyLoading(false);
            }}
          >
            Neka
          </Button>
        </Group>
        <Group mt={'lg'} spacing={'xs'}>
          <Text size="sm" color={'dimmed'}>
            Inbjuden av
          </Text>
          <Badge
            pl={0}
            size="lg"
            radius={'xl'}
            color={'gray'}
            leftSection={
              <Avatar src={getAvatarURL(invitedBy)} size={24} radius={'xl'} />
            }
          >
            {invitedBy.name}
          </Badge>
        </Group>
      </Stack>
    </>
  );
};

export default HandleInviteModal;
