import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
  useMantineTheme,
} from '@mantine/core';
import Layout from './components/Layout/Layout';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { User, onAuthStateChanged, getAuth } from 'firebase/auth';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import Auth from './pages/login/Auth';
import { NotificationsProvider } from '@mantine/notifications';
import { useLocation, useNavigate } from 'react-router-dom';
import ByggleadsProvider from './providers/ByggleadsProvider';
import { ModalsProvider } from '@mantine/modals';
import 'dayjs/locale/sv';
import moment from 'moment';
import 'moment/locale/sv';
import useStateEffect from './utils/hooks/useStateEffect';
import './firebase/firebase';

import { ByggNotification } from './utils/Types';

moment.locale('sv');

if (navigator?.serviceWorker) {
  navigator.serviceWorker.addEventListener('message', (event) => {
    if (typeof event === 'string') {
      const params: Partial<ByggNotification> = {
        opened: true,
        seen: true,
      };
      updateDoc(doc(getFirestore(), event), params);
    }
  });
}

const App = () => {
  const [user, setUser] = useState<User | null | false>(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [originalURL, setOriginalURL] = useState(
    location.pathname + location.search
  );
  useEffect(() => {
    const listener = onAuthStateChanged(
      getAuth(),
      (credential) => {
        setUser(credential);
        if (user && !credential) {
          setOriginalURL('/');
        }
        if (!user && !credential) {
          navigate('/');
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return () => listener();
  }, []);

  useEffect(() => {
    switch (location.pathname) {
      case '/register':
      case '/login':
        if (user) {
          navigate(originalURL);
        }
        break;
      default:
        break;
    }
  }, [user]);

  const theme = useMantineTheme();
  useEffect(() => {
    if (theme.colorScheme === 'dark') {
      document.body.style.backgroundColor = theme.colors.dark[7];
    } else {
      document.body.style.backgroundColor = theme.white;
    }
  }, [theme]);

  const [colorScheme, setColorScheme] = useStateEffect<ColorScheme>(
    getColorScheme(),
    () => {
      const eventHandler = () => setColorScheme(getColorScheme());

      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', eventHandler);

      document.addEventListener('StorageEvent', eventHandler);
      document.addEventListener('theme_change', eventHandler);

      return () => {
        window
          .matchMedia('(prefers-color-scheme: dark)')
          .removeEventListener('change', eventHandler);

        document.removeEventListener('theme_change', eventHandler);
      };
    },
    []
  );
  const toggleColorScheme = (value?: ColorScheme) => {
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));
    localStorage.setItem(
      'theme',
      value || (colorScheme === 'dark' ? 'light' : 'dark')
    );
  };
  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          fontFamily: 'Inter, sans-serif',
          colorScheme,
          primaryColor: 'indigo',
          headings: { fontFamily: 'Inter, sans-serif', fontWeight: 500 },
        }}
      >
        <ByggleadsProvider>
          <NotificationsProvider position="top-right">
            <ModalsProvider>
              <AnimatePresence exitBeforeEnter>
                {user === false ? (
                  <></>
                ) : user ? (
                  <Layout key="LAYOUT" />
                ) : (
                  <Auth key="AUTH" />
                )}
              </AnimatePresence>
            </ModalsProvider>
          </NotificationsProvider>
        </ByggleadsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
};

export default App;

export const getColorScheme = () => {
  const storedTheme = localStorage.getItem('theme');

  if (storedTheme === 'dark' || storedTheme === 'light') {
    return storedTheme;
  }
  if (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    return 'dark';
  } else {
    return 'light';
  }
};

export const StatusArchivedRef = doc(
  getFirestore(),
  'platform',
  'defaults',
  'statuses',
  'hXuAHuuK0AYMfUh8fZ0M'
);
export const StatusUnhandledRef = doc(
  getFirestore(),
  'platform',
  'defaults',
  'statuses',
  'RVliNT9XpDpr1BCThvYy'
);
export const AllWorkspacesRef = doc(
  getFirestore(),
  'platform',
  'defaults',
  'other',
  'all-workspaces'
);
