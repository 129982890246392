import {
  Card,
  Container,
  createStyles,
  Group,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { motion } from 'framer-motion';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ByggleadsContext } from '../../providers/ByggleadsProvider';
import {
  framerPropsContentStack,
  framerPropsTitleStack,
} from '../../utils/Globals';
import AdminSelector from './AdminSelector';
import BulletinBoardHandler from './BulletinBoardHandler';
import AdminSettingsGrid from './AdminSettingsGrid';

const useStyles = createStyles((theme, _params, getRef) => ({
  titleStack: {
    justifyContent: 'flex-start',
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    borderBottom: 'solid 1px',
    borderColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[5]
        : theme.colors.gray[2],
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
  },
  splitButtonLeft: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  splitButtonRight: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginLeft: 1,
  },
  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: theme.spacing.sm,
  },
}));

const AdminSettings = () => {
  const { classes, theme } = useStyles();
  const { role } = useContext(ByggleadsContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (role !== 'superadmin') {
      navigate('/');
    }
  }, [role]);

  return (
    <>
      <Stack spacing={0} className={classes.titleStack} p={'md'}>
        <motion.div {...framerPropsTitleStack}>
          <Group align={'center'} position="apart">
            <Title order={2}>Adminverktyg</Title>
          </Group>
        </motion.div>
      </Stack>
      <Container size={'lg'} py="md">
        <motion.div {...framerPropsContentStack}>
          <Stack spacing="xl">
            <BulletinBoardHandler />
            <AdminSelector />
            <AdminSettingsGrid />
          </Stack>
        </motion.div>
      </Container>
    </>
  );
};

export default AdminSettings;
