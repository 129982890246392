import {
  createStyles,
  Group,
  ScrollArea,
  Skeleton,
  Table,
} from '@mantine/core';
import React, { useContext, useEffect, useState } from 'react';
import {
  IconAssigned,
  IconCalendar,
  IconExpand,
  IconExpandDown,
  IconExpandUp,
  IconPulse,
  IconText,
} from '../../../components/Icons';
import { ByggleadsContext } from '../../../providers/ByggleadsProvider';
import { isTimestamp } from '../../../utils/Functions';
import { ByggLead } from '../../../utils/Types';
import { orderBy } from 'lodash';
import { scrollAreaProps } from '../../../utils/Globals';
import LeadRow from './LeadRow';
import { refEqual } from 'firebase/firestore';

const useStyles = createStyles((theme, _params, getRef) => ({
  clickable: {
    cursor: 'pointer',

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
    },

    '&:active': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
      transform: 'translateY(1px)',
    },
  },
}));

export interface LeadTableColumn {
  title: string;
  key: string;
}

const LeadTableStatic = ({
  leads,
  loading,
  searchQuery,
}: {
  leads: ByggLead[];
  loading?: boolean;
  searchQuery?: string;
}) => {
  const { classes } = useStyles();
  const { statuses } = useContext(ByggleadsContext);

  const [sortedLeads, setSortedLeads] = useState<ByggLead[]>(leads);
  const [sortKey, setSortKey] = useState<'title' | 'status' | 'timeCreated'>();
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  useEffect(() => {
    if (!sortKey) {
      setSortedLeads(leads);
      return;
    }

    switch (sortKey) {
      case 'title':
        setSortedLeads(() => {
          return orderBy(
            leads,
            ['title'],
            [sortDirection === 'asc' ? 'desc' : 'asc']
          );
        });
        break;
      case 'status':
        setSortedLeads(() => {
          return orderBy(
            leads,
            [
              (l) => {
                let res = undefined;
                statuses.forEach((s) => {
                  if (refEqual(s.ref, l.status)) {
                    res = s.title;
                  }
                });
                return res;
              },
            ],
            [sortDirection === 'asc' ? 'desc' : 'asc']
          );
        });
        break;
      case 'timeCreated':
        setSortedLeads(() => {
          return orderBy(
            leads,
            [
              (l) => {
                if (isTimestamp(l.timeCreated)) {
                  return l.timeCreated.seconds;
                }
                return undefined;
              },
            ],
            [sortDirection]
          );
        });
        break;
    }
  }, [sortKey, sortDirection, statuses, leads]);

  return (
    <ScrollArea style={{ width: '100%' }} {...scrollAreaProps}>
      <Table
        style={{
          minWidth: 768,
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                width: 300,
              }}
              className={classes.clickable}
              onClick={() => {
                if (sortKey !== 'title') {
                  setSortKey('title');
                  setSortDirection('desc');
                } else {
                  setSortDirection((p) => (p === 'asc' ? 'desc' : 'asc'));
                }
              }}
            >
              <Group position="apart" noWrap>
                <Group spacing={'xs'}>
                  Titel
                  <IconText side={16} />
                </Group>
                {sortKey === 'title' ? (
                  sortDirection === 'asc' ? (
                    <IconExpandUp side={16} />
                  ) : (
                    <IconExpandDown side={16} />
                  )
                ) : (
                  <IconExpand side={16} />
                )}
              </Group>
            </th>
            <th
              style={{
                width: 160,
              }}
              className={classes.clickable}
              onClick={() => {
                if (sortKey !== 'status') {
                  setSortKey('status');
                  setSortDirection('desc');
                } else {
                  setSortDirection((p) => (p === 'asc' ? 'desc' : 'asc'));
                }
              }}
            >
              <Group position="apart" noWrap>
                <Group spacing={'xs'}>
                  Status
                  <IconPulse side={16} />
                </Group>
                {sortKey === 'status' ? (
                  sortDirection === 'asc' ? (
                    <IconExpandUp side={16} />
                  ) : (
                    <IconExpandDown side={16} />
                  )
                ) : (
                  <IconExpand side={16} />
                )}
              </Group>
            </th>
            <th
              style={{
                width: 180,
              }}
              className={classes.clickable}
              onClick={() => {
                if (sortKey !== 'timeCreated') {
                  setSortKey('timeCreated');
                  setSortDirection('desc');
                } else {
                  setSortDirection((p) => (p === 'asc' ? 'desc' : 'asc'));
                }
              }}
            >
              <Group position="apart" noWrap>
                <Group spacing={'xs'}>
                  Skapad
                  <IconCalendar side={16} />
                </Group>
                {sortKey === 'timeCreated' ? (
                  sortDirection === 'asc' ? (
                    <IconExpandUp side={16} />
                  ) : (
                    <IconExpandDown side={16} />
                  )
                ) : (
                  <IconExpand side={16} />
                )}
              </Group>
            </th>
            <th>
              <Group position="apart" noWrap>
                <Group spacing={'xs'}>
                  Tilldelad
                  <IconAssigned side={16} />
                </Group>
              </Group>
            </th>
            {searchQuery && <th>Matchar sökning:</th>}
          </tr>
        </thead>
        <tbody>
          {loading
            ? [1, 2, 3, 4, 5].map((key) => {
                return (
                  <tr key={key}>
                    <td>
                      <Group spacing={'xs'}>
                        <Skeleton width={26} height={26} />
                        <Skeleton width={128} height={14} />
                      </Group>
                    </td>
                    <td>
                      <Skeleton width={112} height={16} radius="xl" />
                    </td>
                    <td>
                      <Skeleton width={128} height={16} radius="xl" />
                    </td>
                  </tr>
                );
              })
            : sortedLeads.map((lead) => {
                return (
                  <LeadRow
                    lead={lead}
                    searchQuery={searchQuery}
                    key={lead.ref.id}
                  />
                );
              })}
        </tbody>
      </Table>
    </ScrollArea>
  );
};

export default LeadTableStatic;
