import { TargetAndTransition, Target } from 'framer-motion';
import { NavigationType } from 'react-router-dom';

export interface FramerTransitionProps {
  initial: Target;
  animate: TargetAndTransition;
  exit: TargetAndTransition;
}

export const framerPropsTitleStack: FramerTransitionProps = {
  initial: { opacity: 0, y: 6 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.25,
      type: 'tween',
      ease: 'easeOut',
    },
  },
  exit: {
    opacity: 0,
    y: -6,
    transition: {
      duration: 0.25,
      type: 'tween',
      ease: 'easeIn',
    },
  },
};

export const framerPropsContentStack: FramerTransitionProps = {
  initial: { opacity: 0, scale: 0.98 },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.25,
      type: 'tween',
      ease: 'easeOut',
    },
  },
  exit: {
    opacity: 0,
    scale: 0.98,
    transition: {
      duration: 0.25,
      type: 'tween',
      ease: 'easeIn',
    },
  },
};

export const framerPropsTableRow: FramerTransitionProps = {
  initial: { opacity: 0, scale: 0.98 },
  animate: {
    opacity: 1,
    scale: 1,
    zIndex: 1,
    transition: {
      duration: 0.25,
      type: 'tween',
      ease: 'easeOut',
    },
  },
  exit: {
    height: 0,
    scale: 0.95,
    zIndex: 0,
    transition: {
      duration: 0.25,
      type: 'tween',
      ease: 'easeIn',
    },
  },
};

export const framerProps100Y: FramerTransitionProps = {
  initial: { y: '100%', scale: 0.95 },
  animate: {
    y: 0,
    scale: 1,
    transition: {
      duration: 0.3,
      type: 'tween',
      ease: 'circOut',
    },
  },
  exit: {
    y: '100%',
    scale: 0.95,
    transition: {
      duration: 0.25,
      type: "tween",
      ease: "circIn",
    },
  },
};

export const getFramerMotionProps = (
  type: 'horizontal' | 'vertical' | 'opacity',
  navigationType: NavigationType
): FramerTransitionProps => {
  const opacity = {
    initial: 0,
    animate: 1,
    exit: 0,
  };

  const x = {
    initial:
      type === 'horizontal'
        ? navigationType === NavigationType.Pop
          ? -32
          : 32
        : 0,
    animate: 0,
    exit:
      type === 'horizontal'
        ? navigationType === NavigationType.Pop
          ? 32
          : -32
        : 0,
  };

  const y = {
    initial:
      type === 'vertical'
        ? navigationType === NavigationType.Pop
          ? 32
          : -32
        : 0,
    animate: 0,
    exit:
      type === 'vertical'
        ? navigationType === NavigationType.Pop
          ? -32
          : 32
        : 0,
  };

  const result: FramerTransitionProps = {
    initial: { opacity: opacity.initial, x: x.initial, y: y.initial },
    animate: {
      opacity: opacity.animate,
      x: x.animate,
      y: y.animate,
      transition: {
        duration: 0.25,
        type: 'tween',
        ease: 'easeOut',
      },
    },
    exit: {
      opacity: opacity.exit,
      x: x.exit,
      y: y.exit,
      transition: {
        duration: 0.25,
        type: 'tween',
        ease: 'easeIn',
      },
    },
  };

  return result;
};

export const scrollAreaProps = {
  scrollbarSize: 10,
};
