import {
  createStyles,
  Card,
  Text,
  Group,
  Button,
  Table,
  ThemeIcon,
  Badge,
  Avatar,
  UnstyledButton,
} from '@mantine/core';
import { useModals } from '@mantine/modals';
import { getDoc } from 'firebase/firestore';
import { isArray } from 'lodash';
import { useContext } from 'react';
import { IconEdit } from '../../components/Icons';
import { ByggleadsContext } from '../../providers/ByggleadsProvider';
import { formatDate, getAvatarURL, isTimestamp } from '../../utils/Functions';
import useStateEffect from '../../utils/hooks/useStateEffect';
import { ByggBulletin, ByggUser } from '../../utils/Types';
import BulletinCreate, { BulletinTypes } from './BulletinCreate';

const useStyles = createStyles((theme, _params, getRef) => ({
  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: theme.spacing.sm,
  },

  clickable: {
    cursor: 'pointer',

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
    },

    '&:active': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
      transform: 'translateY(1px)',
    },
  },
}));

const BulletinBoardHandler = () => {
  const { classes } = useStyles();
  const { bulletins } = useContext(ByggleadsContext);
  const modals = useModals();

  return (
    <Card withBorder radius="sm" p="md">
      <Card.Section className={classes.section}>
        <Group position="apart">
          <Text size="lg" weight={500}>
            Anslagstavla
          </Text>
          <Button
            rightIcon={<IconEdit side={14} weight="bold" />}
            variant="default"
            onClick={() => {
              const id = modals.openModal({
                padding: 0,
                withCloseButton: false,
                children: (
                  <BulletinCreate
                    handleClose={() => {
                      modals.closeModal(id);
                    }}
                  />
                ),
                size: 'xl',
              });
            }}
          >
            Nytt anslag
          </Button>
        </Group>
      </Card.Section>
      <Card.Section>
        <Table>
          <thead>
            <tr>
              <th>Titel</th>
              <th>Publicerad</th>
              <th>Skapad</th>
              <th>Kategori</th>
              <th>Skapare</th>
            </tr>
          </thead>
          <tbody>
            {isArray(bulletins) &&
              bulletins.map((e) => {
                return <BulletinRow key={e.ref.id} bulletin={e} />;
              })}
          </tbody>
        </Table>
      </Card.Section>
    </Card>
  );
};

export default BulletinBoardHandler;

const BulletinRow = ({ bulletin }: { bulletin: ByggBulletin }) => {
  const { classes } = useStyles();
  const modals = useModals();

  const [author, setAuthor] = useStateEffect<ByggUser | undefined>(
    undefined,
    () => {
      const getUser = async () => {
        const tempAuthor = await getDoc(bulletin.createdBy)
          .then((snapshot) => {
            if (snapshot.exists()) {
              return snapshot.data() as ByggUser;
            } else {
              return undefined;
            }
          })
          .catch((err) => {
            console.error(err);
            return undefined;
          });

        if (tempAuthor) {
          setAuthor(tempAuthor);
        }
      };
      getUser();
    },
    [bulletin]
  );

  return (
    <UnstyledButton
      component="tr"
      className={classes.clickable}
      onClick={() => {
        const id = modals.openModal({
          padding: 0,
          withCloseButton: false,
          children: (
            <BulletinCreate
              handleClose={() => {
                modals.closeModal(id);
              }}
              bulletinRef={bulletin.ref}
            />
          ),
          size: 'xl',
        });
      }}
    >
      <td>
        <Text size="sm" weight={500}>
          {bulletin.title}
        </Text>
      </td>
      <td>
        <Badge color={bulletin.published ? "teal" : "gray"}>
          {bulletin.published ? "Publicerad" : "Utkast"}
        </Badge>
      </td>
      <td>
        <Badge>
          {isTimestamp(bulletin.timeCreated) &&
            formatDate(bulletin.timeCreated.toDate(), 'relative_exact')}
        </Badge>
      </td>
      <td>
        <Group align="center" spacing="xs">
          <ThemeIcon color={BulletinTypes[bulletin.category].color}>
            {BulletinTypes[bulletin.category].icon}
          </ThemeIcon>
          {BulletinTypes[bulletin.category].label}
        </Group>
      </td>
      <td>
        <Group spacing={8}>
          <Avatar src={getAvatarURL(author)} size="sm" radius="xl" />
          <Text size="sm" weight={500}>
            {author?.name}
          </Text>
        </Group>
      </td>
    </UnstyledButton>
  );
};
