import React, { createContext, useEffect, useState } from 'react';
import {
  ByggWorkspace,
  ByggUser,
  ByggLeadTemplate,
  ByggLeadStatus,
  ByggWorkspaceMember,
  ByggLead,
  ByggNotification,
  ByggBulletin,
} from '../utils/Types';
import {
  onSnapshot,
  doc,
  getFirestore,
  DocumentReference,
  query,
  collection,
  orderBy,
  getDocs,
  where,
  limit,
  Unsubscribe,
  Query,
  refEqual,
} from 'firebase/firestore';
import { User, onAuthStateChanged, getAuth } from 'firebase/auth';
import {
  getAll,
  getIsUnread,
  isPermitted,
  isTimestamp,
} from '../utils/Functions';
import { filter, find, isArray } from 'lodash';
import moment from 'moment';
import { AllWorkspacesRef } from '../App';

const ByggleadsProvider = ({ children }: { children: React.ReactNode }) => {
  const [
    {
      alerts,
      bulletins,
      leads,
      members,
      notifications,
      role,
      statuses,
      templates,
      workspaces,
      workspace,
      user,
    },
    setByggleads,
  ] = useState<ByggleadsProps>(initialByggleads);

  const [firUser, setFirUser] = useState<User | null | false>(false);

  const getRole = (input: 'user' | 'admin' | 'superadmin') => {
    switch (input) {
      case 'user':
        if (role !== 'superadmin') return input;
        return 'superadmin';
      case 'admin':
        if (role !== 'superadmin') return input;
        return 'superadmin';
      case 'superadmin':
        return 'superadmin';
    }
  };

  useEffect(() => {
    const listener = onAuthStateChanged(
      getAuth(),
      (credential) => {
        setFirUser(credential);
      },
      (err) => {
        console.error(err);
      }
    );

    return () => listener();
  }, []);

  useEffect(() => {
    if (!firUser) {
      setByggleads(initialByggleads);
      return;
    }

    const listener = onSnapshot(
      doc(getFirestore(), 'users', firUser.uid),
      (userSnapshot) => {
        let user = userSnapshot.data() as ByggUser;
        user.ref = userSnapshot.ref;
        setByggleads((p) => {
          p.user = user;
          if (!user.workspace) {
            p.alerts.workspace.errors.add('missing_workspace');
          } else {
            p.alerts.workspace.errors.delete('missing_workspace');
          }
          return { ...p };
        });
      },
      (error) => {
        console.error(error);
      }
    );

    return () => {
      listener();
    };
  }, [firUser]);

  useEffect(() => {
    if (!user?.workspace) {
      setByggleads((p) => ({
        ...p,
        workspace: undefined,
      }));
      return;
    }

    const listener = onSnapshot(
      user.workspace,
      (workspaceSnapshot) => {
        let workspace = workspaceSnapshot.data() as ByggWorkspace;
        workspace.ref = workspaceSnapshot.ref;
        setByggleads((p) => ({
          ...p,
          workspace: workspace,
        }));
      },
      (err) => {
        console.error(err);
      }
    );

    return () => {
      listener();
    };
  }, [user]);

  useEffect(() => {
    if (!user?.ref) return;

    let listener: Unsubscribe | undefined = undefined;

    if (isPermitted(['superadmin'], role)) {
      listener = onSnapshot(
        collection(getFirestore(), 'workspaces'),
        (snapshot) => {
          const tempWorkspaces = snapshot.docs.map(
            (snapshot) =>
              ({
                ...snapshot.data(),
                ref: snapshot.ref,
              } as ByggWorkspace)
          );

          setByggleads((p) => ({
            ...p,
            workspaces: tempWorkspaces,
          }));
        },
        (err) => {
          console.error(err);
        }
      );
    } else {
      listener = onSnapshot(
        query(
          collection(getFirestore(), 'workspaces'),
          where('members', 'array-contains', user.ref)
        ),
        (snapshot) => {
          const tempWorkspaces = snapshot.docs.map(
            (snapshot) =>
              ({
                ...snapshot.data(),
                ref: snapshot.ref,
              } as ByggWorkspace)
          );

          setByggleads((p) => ({
            ...p,
            workspaces: tempWorkspaces,
          }));
        },
        (err) => {
          console.error(err);
        }
      );
    }

    return () => (listener ? listener() : undefined);
  }, [user, role]);

  useEffect(() => {
    const listener = onSnapshot(
      doc(getFirestore(), 'platform', 'admins'),
      (snapshot) => {
        if (snapshot.exists()) {
          const document = snapshot.data() as { admins: DocumentReference[] };

          if (!user?.ref) return;

          const userRef = user.ref;

          if (document.admins.some((e) => refEqual(e, userRef))) {
            setByggleads((p) => ({
              ...p,
              role: getRole('superadmin'),
            }));
          } else {
            if (workspace) {
              setByggleads((p) => ({
                ...p,
                role: workspace!.admins.some((e) => refEqual(e, userRef))
                  ? getRole('admin')
                  : getRole('user'),
              }));
            }
          }
        }
      }
    );

    return () => {
      listener();
    };
  }, [user, workspace]);

  useEffect(() => {
    if (!workspace?.ref) return;

    const listener = onSnapshot(
      query(
        collection(getFirestore(), workspace.ref.path, 'leadTemplates'),
        orderBy('title')
      ),
      (snapshot) => {
        const templates = snapshot.docs.map(
          (snapshot) =>
            ({
              ...snapshot.data(),
              ref: snapshot.ref,
            } as ByggLeadTemplate)
        );
        setByggleads((p) => ({
          ...p,
          templates: templates,
        }));
      },
      (err) => {
        console.error(err);
      }
    );

    return () => {
      listener();
    };
  }, [workspace]);

  useEffect(() => {
    if (!workspace?.ref) return;

    const listener = onSnapshot(
      query(
        collection(getFirestore(), workspace.ref.path, 'statuses'),
        orderBy('order', 'asc')
      ),
      async (snapshot) => {
        let statuses = await getDocs(
          query(
            collection(getFirestore(), 'platform', 'defaults', 'statuses'),
            orderBy('order', 'asc')
          )
        )
          .then((snapshot) => {
            return snapshot.docs.map((snapshot) => {
              let tempStatus = snapshot.data() as ByggLeadStatus;
              tempStatus.ref = snapshot.ref;
              return tempStatus;
            });
          })
          .catch((err): ByggLeadStatus[] => {
            console.error(err);
            return [];
          });

        statuses.push(
          ...snapshot.docs.map((snapshot) => {
            let tempStatus = snapshot.data() as ByggLeadStatus;
            tempStatus.ref = snapshot.ref;
            return tempStatus;
          })
        );

        setByggleads((p) => ({
          ...p,
          statuses: statuses,
        }));
      },
      (err) => {
        console.error(err);
      }
    );

    return () => {
      listener();
    };
  }, [workspace]);

  useEffect(() => {
    if (!workspace) {
      setByggleads((p) => ({
        ...p,
        members: [],
      }));
      return;
    }

    const admins = workspace.admins;

    getAll(workspace.members).then((snapshot) => {
      const members = snapshot.map((userDoc): ByggWorkspaceMember => {
        let user = userDoc.data() as ByggUser;
        user.ref = userDoc.ref;
        return {
          user: user,
          role: admins.some((e) => refEqual(e, userDoc.ref)) ? 'admin' : 'user',
        };
      });
      setByggleads((p) => ({
        ...p,
        members: members,
      }));
    });
  }, [workspace]);

  const getLeadRenderProps = (lead: ByggLead) => {
    return {
      status: find(statuses, (s) => refEqual(s.ref, lead.status)),
      assigned: filter(members, (m) =>
        lead.assigned.some((a) => refEqual(a, m.user.ref))
      ),
    };
  };

  useEffect(() => {
    if (!workspace?.ref) {
      setByggleads((p) => ({
        ...p,
        leads: 'loading',
      }));
      return;
    }

    const listener = onSnapshot(
      query(
        collection(getFirestore(), workspace.ref.path, 'leads'),
        orderBy('timeCreated', 'desc')
      ),
      (snapshot) => {
        const leads = snapshot.docs.map(
          (snapshot) =>
            ({
              ...snapshot.data(),
              ref: snapshot.ref,
              render: getLeadRenderProps(snapshot.data() as ByggLead),
            } as ByggLead)
        );

        setByggleads((p) => ({
          ...p,
          leads: leads,
        }));
      },
      (err) => {
        console.error(err);
      }
    );

    return () => {
      listener();
    };
  }, [workspace]);

  useEffect(() => {
    if (!isArray(leads)) return;
    const tempLeads = leads.map((lead) => ({
      ...lead,
      render: getLeadRenderProps(lead),
    }));

    setByggleads((p) => ({
      ...p,
      leads: tempLeads,
    }));
  }, [statuses, members]);

  useEffect(() => {
    if (!workspace?.ref || !user?.ref.path) return;

    let q: Query;

    if (isPermitted(['superadmin'], role)) {
      q = query(
        collection(getFirestore(), 'bulletins'),
        orderBy('timeCreated', 'desc')
      );
    } else {
      q = query(
        collection(getFirestore(), 'bulletins'),
        where('workspaces', 'array-contains-any', [
          workspace.ref,
          AllWorkspacesRef,
        ]),
        where('published', '==', true),
        orderBy('timeCreated', 'desc'),
        limit(10)
      );
    }

    const listener = onSnapshot(q, (snapshot) => {
      let tempBulletins = snapshot.docs.map(
        (snapshot) =>
          ({
            ...snapshot.data(),
            ref: snapshot.ref,
          } as ByggBulletin)
      );

      tempBulletins = tempBulletins.map((bulletin) => ({
        ...bulletin,
        render: {
          unread: getIsUnread(bulletin, user),
          new:
            isTimestamp(bulletin.timeCreated) &&
            moment(bulletin.timeCreated.toDate()).diff(moment(), 'days') > -3 &&
            getIsUnread(bulletin, user),
        },
      }));

      tempBulletins = tempBulletins.sort((a, b) =>
        a.render?.unread === b.render?.unread ? 0 : a.render?.unread ? -1 : 1
      );

      setByggleads((p) => ({
        ...p,
        bulletins: tempBulletins,
      }));
    });

    return () => listener();
  }, [workspace, user, role]);

  useEffect(() => {
    if (!user?.ref) return;

    const listener = onSnapshot(
      query(
        collection(getFirestore(), user.ref.path, 'notifications'),
        orderBy('timeCreated', 'desc'),
        where('opened', '==', false)
      ),
      (snapshot) => {
        const tempNotifications = snapshot.docs.map(
          (e) =>
            ({
              ...e.data(),
              ref: e.ref,
            } as ByggNotification)
        );
        setByggleads((p) => ({
          ...p,
          notifications: tempNotifications,
        }));
      },
      (err) => {
        console.error(err);
      }
    );

    return () => {
      listener();
    };
  }, [user]);

  return (
    <ByggleadsContext.Provider
      value={{
        alerts: alerts,
        bulletins: bulletins,
        leads: leads,
        members: members,
        notifications: notifications,
        role: role,
        statuses: statuses,
        templates: templates,
        workspaces: workspaces,
        user: user,
        workspace: workspace,
        setByggleads,
      }}
    >
      {children}
    </ByggleadsContext.Provider>
  );
};

export default ByggleadsProvider;

export type ByggleadsProps = {
  user?: ByggUser;
  role: 'user' | 'admin' | 'superadmin';
  workspace?: ByggWorkspace;
  workspaces: ByggWorkspace[] | 'loading';
  members: ByggWorkspaceMember[];
  templates: ByggLeadTemplate[];
  statuses: ByggLeadStatus[];
  leads: ByggLead[] | 'loading';
  notifications: ByggNotification[];
  bulletins: ByggBulletin[] | 'loading';
  alerts: {
    workspace: {
      notifications: Set<string>;
      warnings: Set<'missing_data'>;
      errors: Set<'missing_workspace' | 'missing_payment'>;
    };
    adminSettings: {
      notifications: Set<string>;
      warnings: Set<string>;
      errors: Set<string>;
    };
    leads: {
      notifications: Set<string>;
      warnings: Set<string>;
      errors: Set<string>;
    };
    campaigns: {
      notifications: Set<string>;
      warnings: Set<string>;
      errors: Set<string>;
    };
  };
};

export interface GlobalByggleads extends ByggleadsProps {
  setByggleads: React.Dispatch<React.SetStateAction<ByggleadsProps>>;
}

export const initialByggleads: ByggleadsProps = {
  user: undefined,
  workspace: undefined,
  workspaces: 'loading',
  members: [],
  templates: [],
  statuses: [],
  leads: 'loading',
  notifications: [],
  bulletins: 'loading',
  role: 'user',
  alerts: {
    workspace: {
      notifications: new Set([]),
      warnings: new Set([]),
      errors: new Set([]),
    },
    adminSettings: {
      notifications: new Set([]),
      warnings: new Set([]),
      errors: new Set([]),
    },
    leads: {
      notifications: new Set([]),
      warnings: new Set([]),
      errors: new Set([]),
    },
    campaigns: {
      notifications: new Set([]),
      warnings: new Set([]),
      errors: new Set([]),
    },
  },
};

export const ByggleadsContext = createContext<GlobalByggleads>({
  ...initialByggleads,
  setByggleads: () => {},
});
